import React from "react";

import {
  ActivityLicenseApplicationProceedingTypeEnum,
  ActivityLicenseApplicationStatus
} from "../../api_client/medre_api";
import { ActivityLicenseApplicationStatusFM } from "../Messages/ActivityLicenseFM";
import { formatProceedingType } from "../Dto/ActivityLicense/ActivityLicenseProceeding";

export const statuses = [
  {
    value: ActivityLicenseApplicationStatus.Saved,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.Saved}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.Registering,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.Registering}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.Submitted,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.Submitted}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.ProceedingPending,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.ProceedingPending}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.InProceeding,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.InProceeding}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.Paused,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.Paused}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.OnSiteInspectionPending,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.OnSiteInspectionPending}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.ProtocolInPreparation,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.ProtocolInPreparation}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.ReadyForDecision,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.ReadyForDecision}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.UploadAcceptedDecision,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.UploadAcceptedDecision}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.SignAcceptedDecision,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.SignAcceptedDecision}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.SignDeclinedDecision,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.SignDeclinedDecision}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.Accepted,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.Accepted}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.Declined,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.Declined}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.Finished,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.Finished}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.SubmittedByOfficial,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.SubmittedByOfficial}
      />
    )
  },
  {
    value: ActivityLicenseApplicationStatus.ProceedingReopened,
    label: (
      <ActivityLicenseApplicationStatusFM
        id={ActivityLicenseApplicationStatus.ProceedingReopened}
      />
    )
  }
];

export const proceedingTypes = [
  {
    value: ActivityLicenseApplicationProceedingTypeEnum.ActivityLicense,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLicense
    )
  },
  {
    value:
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension
    )
  },
  {
    value:
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseRestoration,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseRestoration
    )
  },
  {
    value:
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseInvalidation,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseInvalidation
    )
  },
  {
    value: ActivityLicenseApplicationProceedingTypeEnum.ActivityLocation,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLocation
    )
  },
  {
    value:
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension
    )
  },
  {
    value:
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationRestoration,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationRestoration
    )
  },
  {
    value:
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationInvalidation,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationInvalidation
    )
  },
  {
    value: ActivityLicenseApplicationProceedingTypeEnum.Service,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.Service
    )
  },
  {
    value: ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension
    )
  },
  {
    value: ActivityLicenseApplicationProceedingTypeEnum.ServiceRestoration,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.ServiceRestoration
    )
  },
  {
    value: ActivityLicenseApplicationProceedingTypeEnum.ServiceDiscard,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.ServiceDiscard
    )
  },
  {
    value: ActivityLicenseApplicationProceedingTypeEnum.LicenseAcquisition,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.LicenseAcquisition
    )
  },
  {
    value:
      ActivityLicenseApplicationProceedingTypeEnum.GeneralPractitionerApplication,
    label: formatProceedingType(
      ActivityLicenseApplicationProceedingTypeEnum.GeneralPractitionerApplication
    )
  }
];

export const ACTIVITY_LICENSE_DOCUMENTATION_REQUIREMENTS_LINK =
  "https://www.terviseamet.ee/et/tervishoid/tervishoiutootajale/tegevusload";

export const getApplicationRowClassName = (
  currentStatus?: ActivityLicenseApplicationStatus,
  isStateFeePaid?: boolean
) =>
  (currentStatus === ActivityLicenseApplicationStatus.Submitted ||
    currentStatus === ActivityLicenseApplicationStatus.ProceedingPending ||
    currentStatus === ActivityLicenseApplicationStatus.SubmittedByOfficial) &&
  !isStateFeePaid
    ? "application-result-row"
    : "";

export const BUSINESS_AREA_ID = "eacbbaff-e909-4933-b12d-d2b845d0d2b9";

  