import React, { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";

import { API, getBaseUrl } from "../../../../../api";
import { AlertMessage } from "../../../../../Alert/AlertMessage";
import { AlertType } from "../../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../../Alert/alertActions";
import { activityLicenseApplicationActions } from "../../../../../ActivityLicense/activityLicenseApplicationActions";
import { SecondaryFormattedButton } from "../../../../../Component/Button/SecondaryFormattedButton";
import { ButtonName } from "../../../../../Component/Button/FormattedButton";
import { DraftActions } from "./Draft/DraftActions";
import { SignActions } from "./Sign/SignActions";
import { SubmittedActions } from "./Proceeding/SubmittedActions";
import { AcceptanceActions } from "./Acceptance/AcceptanceActions";
import { InProceedingActions } from "./Proceeding/InProceedingActions";
import { ProtocolInPreparationActions } from "./Protocol/ProtocolInPreparationActions";
import { ActionButtonClasses } from "../../../../../Component/Button/Button";
import { DecisionActions } from "./Decision/DecisionActions";
import { getDecisionTypeFromProceedingType } from "../../../../../Util/DecisionUtil";
import {
  ActivityLicenseApplication,
  ActivityLicenseApplicationStatus,
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityAppAPI,
  DetailedActivityLicenseApplication,
  DetailedActivityLicenseApplicationProceedingTypeEnum,
  FileObjectType,
  OfficialUser,
  OfficialUserPrivilegesEnum,
  OtsusteTeenusApiFactory as decisionServiceAPI
} from "../../../../../../api_client/medre_api";
import { ProtectedComponent } from "../../../../../Security/ProtectedComponent";
import { DangerFormattedButton } from "../../../../../Component/Button/DangerFormattedButton";
import {
  ApplicationDeleteConfirmationModal
} from "../../../../Portal/Application/Table/ApplicationDeleteConfirmationModal";
import { OfficialForwardingModal } from "./Proceeding/OfficialForwardingModal";
import { ProceedingPendingActions } from "./Proceeding/ProceedingPendingActions";
import { SubmittedByOfficialActions } from "./Proceeding/SubmittedByOfficialActions";
import { DeleteApplicationUtil } from "../../ActionUtils/DeleteApplicationUtil";
import { OnSiteInspectionPendingActions } from "./Proceeding/OnSiteInspectionPendingActions";
import { PausedActions } from "./Proceeding/PausedActions";
import {CompleteApplicationActions} from "./Proceeding/CompleteApplicationActions";
import {RootState} from "../../../../../rootReducer";

interface Props {
  details?: DetailedActivityLicenseApplication;
  setDetails:
  | ((activityLicense: ActivityLicenseApplication) => void)
  | React.Dispatch<
  React.SetStateAction<DetailedActivityLicenseApplication | undefined>
  >;
  refresh: () => void;
}

export const ActivityLicenseApplicationDetailsActions = ({
  details,
  setDetails,
  refresh
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOfficialForwardingModalOpen, setIsOfficialForwardingModalOpen] =
    useState(false);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const isDraft =
    details?.currentStatus?.status ===
    ActivityLicenseApplicationStatus.Registering;

  const continueDraft = () => {
    if (details!.hasOwnProperty("activityLicense")) {
      delete details!.activityLicense?.locations;
    }

    dispatch(activityLicenseApplicationActions.setActivityLicense(details!));
    if (
      details?.proceedingType ===
      DetailedActivityLicenseApplicationProceedingTypeEnum.ActivityLocation
    ) {
      history.push("/new-activity-location");
    } else if (
      details?.proceedingType ===
      DetailedActivityLicenseApplicationProceedingTypeEnum.Service
    ) {
      history.push("/new-activity-license-service");
    } else {
      history.push("/new-activity-license");
    }
  };

  const deleteApplication = (applicationId: string) => {
    DeleteApplicationUtil(
      applicationId,
      dispatch,
      history,
      details?.proceedingType!,
      "applicationDraftDeleteSuccess",
      "applicationDraftDeleteFailure");
  };

  const handleForwardToOfficial = (official: OfficialUser) => {
    officialActivityAppAPI(undefined, getBaseUrl(), API)
      .forwardToOfficial3(details?.id as string, official, {
        withCredentials: true
      })
      .then((res) => {
        const alertMessage = <AlertMessage id="applicationForwardingSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        setDetails(res.data);
      })
      .catch(() => {
        const alertMessage = <AlertMessage id="applicationForwardingFailure" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      });
  };

  const changeStatusTo = (newStatus: ActivityLicenseApplicationStatus) => {
    officialActivityAppAPI(undefined, getBaseUrl(), API)
      .changeApplicationStatus2(details?.id as string, newStatus, {
        withCredentials: true
      })
      .then((res) => {
        const alertMessage = <AlertMessage id="statusUpdateSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        setDetails(res.data);
      })
      .catch((error: AxiosError) => {
        const responseData = error.response?.data;
        let alertMessage;
        if (responseData.error === "TortaException") {
          alertMessage = <AlertMessage id="tortaFailure" />;
          refresh();
        } else {
          alertMessage = <AlertMessage id="statusUpdateFailure" />;
        }
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      });
  };

  const initiateProceeding = () => {
    officialActivityAppAPI(undefined, getBaseUrl(), API)
      .initiateProceeding3(details?.id as string, {
        withCredentials: true
      })
      .then((res) => {
        const alertMessage = <AlertMessage id="initiateProceedingSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        setDetails(res.data);
        refresh();
      })
      .catch((e) => {
        const alertMessage = <AlertMessage id="initiateProceedingFailure" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      });
  };

  const pauseProceeding = (notes: string) =>
    officialActivityAppAPI(undefined, getBaseUrl(), API)
      .pauseApplicationProceeding2(
        details?.id as string,
        { noteText: notes },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        setDetails(response.data);
        const alertMessage = <AlertMessage id="statusUpdateSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        return Promise.resolve();
      })
      .catch((error) => {
        const alertMessage = <AlertMessage id="statusUpdateFailure" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        return Promise.reject(error);
      });

  const continueProceeding = () => {
    officialActivityAppAPI(undefined, getBaseUrl(), API)
      .continueApplicationProceeding1(details?.id as string, {
        withCredentials: true
      })
      .then((response) => {
        setDetails(response.data);
        const alertMessage = <AlertMessage id="continueProceedingSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      })
      .catch((e) => {
        const alertMessage = <AlertMessage id="continueProceedingFailure" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      });
  };

  const addProtocol = (protocolNumber: string) => {
    return officialActivityAppAPI(undefined, getBaseUrl(), API)
      .addProtocol1(
        details?.id as string,
        { noteText: protocolNumber },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        setDetails(response.data);
        const alertMessage = <AlertMessage id="statusUpdateSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        return Promise.resolve();
      })
      .catch((error) => {
        const alertMessage = <AlertMessage id="statusUpdateFailure" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        return Promise.reject(error);
      });
  };

  const confirmApplicationDeletion = () => {
    deleteApplication(details?.id!);
    setIsDeleteModalOpen(false);
  };

  const renderForwardToOfficialButton = () => {
    return <ProtectedComponent
      allowedRoles={[
        OfficialUserPrivilegesEnum.Proceede,
        OfficialUserPrivilegesEnum.Sign
      ]}>
      <SecondaryFormattedButton
        id={"forwardToOfficial"}
        className={ActionButtonClasses}
        onClick={() => setIsOfficialForwardingModalOpen(true)}
      />
    </ProtectedComponent>;
  };

  const renderSubmittedApplicationActionButtons = () => {
    if (!details) {
      return;
    }

    const getDecisionUploadEndpoint = () =>
      decisionServiceAPI(undefined, getBaseUrl(), API).assignDecisionToSigner;

    switch (details.currentStatus?.status) {
      case ActivityLicenseApplicationStatus.Saved:
        return (
          <ProtectedComponent allowedRoles={[
            OfficialUserPrivilegesEnum.Sign,
            OfficialUserPrivilegesEnum.Proceede
          ]}>
            <DangerFormattedButton
              id="deleteApplication"
              className={ActionButtonClasses}
              onClick={() => setIsDeleteModalOpen(true)}
            />
          </ProtectedComponent>
        );
      case ActivityLicenseApplicationStatus.Submitted:
        return (
          <SubmittedActions
            handleForwardToOfficial={handleForwardToOfficial}
            changeStatusTo={changeStatusTo}
            newStatus={ActivityLicenseApplicationStatus.Finished}
            canFinish
            applicationId={details?.id}
            applicationNumber={details?.applicationNumber!}
            deleteApplication={deleteApplication}
          />
        );
      case ActivityLicenseApplicationStatus.SubmittedByOfficial:
        return (
          <SubmittedByOfficialActions
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            changeStatusTo={changeStatusTo}
            deleteApplication={deleteApplication}
            handleForwardToOfficial={handleForwardToOfficial}
            initiateProceeding={initiateProceeding}
            isStateFeePaid={details.stateFee?.paid}
            isStateFeeUsed
            newStatus={ActivityLicenseApplicationStatus.Finished}
          />
        );
      case ActivityLicenseApplicationStatus.ProceedingPending:
        return (
          <ProceedingPendingActions
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            changeStatusTo={changeStatusTo}
            deleteApplication={deleteApplication}
            handleForwardToOfficial={handleForwardToOfficial}
            initiateProceeding={initiateProceeding}
            isDeleteCheckNeeded={false}
            isStateFeePaid={details.stateFee?.paid}
            isStateFeeUsed
            newStatus={ActivityLicenseApplicationStatus.Finished}
          />
        );
      case ActivityLicenseApplicationStatus.InProceeding:
        return (
          <InProceedingActions
            applicationId={details?.id}
            applicationNumber={details.applicationNumber}
            canAcceptOrDecline
            canPause
            changeStatusTo={changeStatusTo}
            deleteApplication={deleteApplication}
            handleForwardToOfficial={handleForwardToOfficial}
            newAcceptanceStatus={ActivityLicenseApplicationStatus.UploadAcceptedDecision}
            newDeclinationStatus={ActivityLicenseApplicationStatus.UploadDeclinedDecision}
            newStatus={ActivityLicenseApplicationStatus.OnSiteInspectionPending}
            pauseProceeding={pauseProceeding}
          />
        );
      case ActivityLicenseApplicationStatus.OnSiteInspectionPending:
        return (
          <OnSiteInspectionPendingActions
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            changeStatusTo={changeStatusTo}
            deleteApplication={deleteApplication}
            handleForwardToOfficial={handleForwardToOfficial}
            newStatus={ActivityLicenseApplicationStatus.ProtocolInPreparation}
          />
        );
      case ActivityLicenseApplicationStatus.ProtocolInPreparation:
        return (
          <ProtocolInPreparationActions
            addProtocol={addProtocol}
            applicationNumber={details.applicationNumber}
            deleteApplication={deleteApplication}
            fileObjectType={
              FileObjectType.ActivityLicenseOnSiteInspectionProtocol
            }
            fileReferences={details?.fileReferences || []}
            handleForwardToOfficial={handleForwardToOfficial}
            id={details?.id}
            refresh={refresh}
          />
        );
      case ActivityLicenseApplicationStatus.ReadyForDecision:
      case ActivityLicenseApplicationStatus.SignReopened:
        return (
          <>
            <AcceptanceActions
              applicationNumber={details.applicationNumber}
              changeStatusTo={changeStatusTo}
              newAcceptanceStatus={
                ActivityLicenseApplicationStatus.UploadAcceptedDecision
              }
              newDeclinationStatus={
                ActivityLicenseApplicationStatus.UploadDeclinedDecision
              }
              applicationId={details?.id}
            />
            {renderForwardToOfficialButton()}
          </>
        );
      case ActivityLicenseApplicationStatus.ProceedingReopened:
        return (
          <>
            <AcceptanceActions
              applicationNumber={details.applicationNumber}
              changeStatusTo={changeStatusTo}
              newAcceptanceStatus={
                ActivityLicenseApplicationStatus.UploadAcceptedDecision
              }
              newDeclinationStatus={
                ActivityLicenseApplicationStatus.UploadDeclinedDecision
              }
              applicationId={details?.id}
              deleteApplication={deleteApplication}
              hasExtendedRoles
            />
            {renderForwardToOfficialButton()}
          </>
        );
      case ActivityLicenseApplicationStatus.UploadAcceptedDecision:
      case ActivityLicenseApplicationStatus.UploadDeclinedDecision:
        return (
          <>
            <DecisionActions
              fileObjectType={FileObjectType.ActivityLicenseDecision}
              decisionUploadEndpoint={getDecisionUploadEndpoint()}
              updateApplication={refresh}
              decision={{
                id: details.id!,
                type: getDecisionTypeFromProceedingType(details.proceedingType)
              }}
            />
            {renderForwardToOfficialButton()}
          </>
        );
      case ActivityLicenseApplicationStatus.SignDeclinedDecision:
        return renderSignButtons(
          "signApplicationDeclinationDecision",
          ActivityLicenseApplicationStatus.SignReopened
        );
      case ActivityLicenseApplicationStatus.SignAcceptedDecision:
        return renderSignButtons(
          "signApplicationAcceptanceDecision",
          ActivityLicenseApplicationStatus.SignReopened
        );
      case ActivityLicenseApplicationStatus.Paused:
        return (
          <PausedActions
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            changeStatusTo={changeStatusTo}
            continueProceeding={continueProceeding}
            deleteApplication={deleteApplication}
            handleForwardToOfficial={handleForwardToOfficial}
            newStatus={ActivityLicenseApplicationStatus.SignDeclinedDecision}
          />
        );
      default:
        return;
    }
  };

  const shouldShowCompleteApplicationButton = (): boolean => {
    const allowedStatuses: ActivityLicenseApplicationStatus[] = [
        ActivityLicenseApplicationStatus.InProceeding,
        ActivityLicenseApplicationStatus.ProceedingReopened,
        ActivityLicenseApplicationStatus.OnSiteInspectionPending,
        ActivityLicenseApplicationStatus.ProtocolInPreparation,
        ActivityLicenseApplicationStatus.SignReopened
    ]

    const allowedProceedingTypes: DetailedActivityLicenseApplicationProceedingTypeEnum[] = [
      DetailedActivityLicenseApplicationProceedingTypeEnum.ActivityLicense,
      DetailedActivityLicenseApplicationProceedingTypeEnum.ActivityLocation,
      DetailedActivityLicenseApplicationProceedingTypeEnum.Service,
    ]

    const isProcessorOfApplication = userInfo?.userId === details?.proceedingOfficialId;

    return (details?.currentStatus?.status ?? false) !== undefined
        && allowedStatuses.includes(details!.currentStatus!.status!)
        && allowedProceedingTypes.includes(details!.proceedingType!)
            && isProcessorOfApplication;
  }

  const renderSignButtons = (
    signButtonId: ButtonName,
    newStatus: ActivityLicenseApplicationStatus
  ) => (
    <SignActions
      signButtonId={signButtonId}
      newStatus={newStatus}
      refresh={refresh}
      changeStatusTo={changeStatusTo}
      decisionId={details?.decisions?.find((d) => !d.signed)?.id!}
    />
  );

  const pushApplicationToState = () : boolean => {
    dispatch(activityLicenseApplicationActions.setActivityLicense(details!));
    return true;
  };

  return (
    <div className="mt-1 mb-4">
      <DraftActions
        isDraft={isDraft}
        applicationId={details?.id || ""}
        applicationNumber={details?.applicationNumber!}
        officialId={details?.proceedingOfficialId || ""}
        deleteApplication={deleteApplication}
        continueDraft={continueDraft}
      />
      {renderSubmittedApplicationActionButtons()}
      <ApplicationDeleteConfirmationModal
        applicationNumber={details?.applicationNumber || 0}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={confirmApplicationDeletion}
      />
      <OfficialForwardingModal
        isOpen={isOfficialForwardingModalOpen}
        onClose={() => setIsOfficialForwardingModalOpen(false)}
        forwardToOfficial={(official) => {
          handleForwardToOfficial(official);
          setIsOfficialForwardingModalOpen(false);
        }}
      />
      {shouldShowCompleteApplicationButton() && pushApplicationToState() && <CompleteApplicationActions/>}
    </div>
  );
};
