import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AxiosPromise } from "axios";

import { canAddLocation } from "../../../../Dto/ActivityLicense/ActivityLicense";
import { renderAccordionHeaderRow } from "../../../../Component/Accordion/AccordionHeaderRowUtil";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";
import { MinusIcon } from "../../../../Component/Icon/MinusIcon";
import { PlusIcon } from "../../../../Component/Icon/PlusIcon";
import { ActivityLocationRow } from "./ActivityLocationRow";
import { renderOverViewRow } from "../../Application/OverView/ApplicationOverViewUtil";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { RootState } from "../../../../rootReducer";
import { activityLicenseTableActions } from "../../../../ActivityLicense/activityLicenseTableActions";
import { ConfirmationModal } from "../../../../Component/Modal/ConfirmationModal";
import useModal from "../../../../Hook/useModal";
import { getBaseUrl, API } from "../../../../api";
import { displayAlert } from "../../../../Util/AlertUtil";
import {
  ActivityLicense,
  ActivityLocation,
  ActivityLicenseRowHeaderStatusEnum,
  TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApiFactory as businessLocationRevocationAPI,
  ActivityLicenseService,
  HealthCareProfessional, OfficialUserPrivilegesEnum, SpecialistData
} from "../../../../../api_client/medre_api";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";

interface Props {
  isEditable?: boolean;
  isOpen: boolean;
  handleClick: () => void;
  refresh?: () => void;
  activityLocations?: ActivityLocation[];
  isHospital: boolean;
  specialistBusinessAreaId?: string;
  isActivityApplicationLicense?: boolean;
  showTorConfirmation?: boolean;
  addTHTEndpoint?: (
    id: string,
    healthCareProfessional: HealthCareProfessional[],
    options?: any
  ) => AxiosPromise<ActivityLicenseService>;
  addSpecialistEndpoint?: (
    id: string,
    specialistCodeId: string,
    specialistData: SpecialistData,
    options?: any
  ) => AxiosPromise<ActivityLicenseService>;
  removeEmployeeEndpoint?: (
    id: string,
    employeeId: string,
    options?: any
  ) => AxiosPromise<string>;
  loadServiceEndpoint: (
    id: string,
    options?: any
  ) => AxiosPromise<ActivityLicenseService>;
  isSuspendServiceEnabled?: boolean;
  isDiscardServiceEnabled?: boolean;
  handleNewActivityLocation?: () => void;
  activityLicense?: ActivityLicense;
  isPharmacyLicense?: boolean;
  isApplicationView?: boolean;
}

export const ActivityLocationsRow = ({
  specialistBusinessAreaId,
  isActivityApplicationLicense,
  isOpen,
  handleClick,
  activityLocations,
  isHospital,
  isEditable,
  handleNewActivityLocation,
  activityLicense,
  refresh,
  isPharmacyLicense = false,
  isApplicationView = false,
  ...childrenProps
}: Props) => {
  const expandedRowNumber = useSelector(
    (state: RootState) => state.activityLicenseTable.expandedLocationsRow
  );
  const dispatch = useDispatch();
  const invalidationModal = useModal();
  const [locationId, setLocationId] = useState<string>("");

  const invalidationConfirmationMessage = (
    <FormattedMessage
      id="ActivityLicenseRow.invalidationMessage"
      defaultMessage="Kas olete kindel, et soovite tegevuskoha kehtetuks tunnistada?"
    />
  );

  const invalidateActivityLocation = useCallback(() => {
    return businessLocationRevocationAPI(undefined, getBaseUrl(), API)
      .invalidateActivityLicense(locationId, {
        withCredentials: true
      })
      .then(() => {
        invalidationModal.handleClose();
        if (refresh) {
          displayAlert("applicationSubmitted", AlertType.Success, dispatch);
          refresh();
        }
      })
      .catch(() => {
        displayAlert("activityLicenseSubmitFailed", AlertType.Danger, dispatch);
      });
  }, [invalidationModal, refresh, dispatch, locationId]);

  const className = isOpen ? "expanded" : "";

  const widthSize = useWindowWidthSize();

  const handleRowClick = (rowNumber: number) => {
    if (expandedRowNumber !== rowNumber) {
      dispatch(activityLicenseTableActions.setExpandedLocationsRow(rowNumber));
    } else {
      dispatch(activityLicenseTableActions.setExpandedLocationsRow(null));
    }
  };

  const handleInvalidationButtonClick = (locationId: string) => {
    setLocationId(locationId);
    invalidationModal.handleOpen();
  };

  const renderRow = (icon: JSX.Element) =>
    renderAccordionHeaderRow(
      widthSize,
      icon,
      <ActivityLicenseFM id="activityLocations" />
    );

  const renderContent = () => {
    return (
      <>
        { activityLocations?.map((location, i) => {
          const canInvalidate =
            isEditable &&
            activityLocations?.length > 1 &&
            activityLocations.filter(
              (l) => l.status !== ActivityLicenseRowHeaderStatusEnum.Invalid
            ).length > 1 &&
            !location.invalidationApplicationId &&
            location.status === ActivityLicenseRowHeaderStatusEnum.Valid;
          return (
            <ActivityLocationRow
              key={location.id}
              location={location}
              isActivityApplicationLicense={isActivityApplicationLicense}
              isOpen={expandedRowNumber === i}
              index={i}
              handleRowClick={handleRowClick}
              isSuspendable={
                activityLocations?.length > 1 &&
                location.status !== ActivityLicenseRowHeaderStatusEnum.Invalid
              }
              isHospital={isHospital}
              specialistBusinessAreaId={specialistBusinessAreaId}
              isEditable={isEditable}
              refresh={refresh}
              handleInvalidationButtonClick={
                canInvalidate && handleInvalidationButtonClick
              }
              activityLicense={activityLicense}
              isPharmacyLicense={isPharmacyLicense}
              isApplicationView={isApplicationView}
              {...childrenProps}
            />
          );
        }) }
        <ConfirmationModal
          isOpen={invalidationModal.isOpen}
          title={invalidationConfirmationMessage}
          onClose={invalidationModal.handleClose}
          onAsyncSave={invalidateActivityLocation}
          saveButtonId="confirm"
          closeButtonId="backToDetailView"
          footerClassName="justify-content-between"
        >
          { invalidationConfirmationMessage }
        </ConfirmationModal>
      </>
    );
  };

  const renderLinks = useCallback(() => {
    return isEditable && activityLicense && canAddLocation(activityLicense) ? (
      <ProtectedComponent
        directRepresentativeOnly
        officialsOnly
        allowedRoles={[OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate]}
        matchAnyCondition>
        <LinkButton id="addLocation" onClick={handleNewActivityLocation} />
      </ProtectedComponent>
    ) : undefined;
  }, [isEditable, handleNewActivityLocation, activityLicense]);

  return isOpen ? (
    <>
      <div
        className={`${className} cursor-pointer accordion-header`}
        onClick={handleClick}
      >
        {renderRow(<MinusIcon />)}
      </div>
      {isEditable &&
        !isPharmacyLicense &&
        renderOverViewRow("general", "activities", renderLinks())}
      {renderContent()}
    </>
  ) : (
    <div
      className={`${className} cursor-pointer accordion-header`}
      onClick={handleClick}
    >
      {renderRow(<PlusIcon />)}
    </div>
  );
};
