import React, { Dispatch } from "react";
import { AxiosError, AxiosPromise } from "axios";

import { AlertType } from "../../Dto/Alert/AlertItem";
import { AlertMessage } from "../../Alert/AlertMessage";
import { alertActions } from "../../Alert/alertActions";
import {
  FileObjectType,
  FileReference,
  FailideLeslaadimiseTeenusApiFactory as fileUploadAPI,
  PowerOfAttorney
} from "../../../api_client/medre_api";
import { getBaseUrl, API } from "../../api";

export const saveFiles = (
  files: File[],
  fileObjectType: FileObjectType,
  objectId?: string,
  saveEndpointOverride?: (
    registryCode: string,
    file: any[],
    options?: any
  ) => AxiosPromise<PowerOfAttorney[] | FileReference[]>,
  registryCode?: string
) => {
  return saveEndpointOverride
    ? saveEndpointOverride(registryCode!, files, {
        withCredentials: true
      })
    : fileUploadAPI(undefined, getBaseUrl(), API).uploadFile(
        objectId!,
        fileObjectType,
        files,
        {
          withCredentials: true
        }
      );
};
export const saveFilesWithAlerts = (
  files: File[],
  fileObjectType: FileObjectType,
  // tslint:disable-next-line:no-any
  objectId: string | undefined,
  dispatch: Dispatch<any>,
  onUploadSuccess: ((files: FileReference[]) => void) | undefined,
  saveEndpointOverride?: (
    registryCode: string,
    file: any[],
    options?: any
  ) => AxiosPromise<PowerOfAttorney[] | FileReference[]>,
  registryCode?: string
) => {
  saveFiles(files, fileObjectType, objectId, saveEndpointOverride, registryCode)
    .then((response) => {
      const alertMessage = <AlertMessage id="fileUploadSuccessful" />;
      const alert = { id: 0, type: AlertType.Success, message: alertMessage };
      dispatch(alertActions.addAlert(alert));
      if (onUploadSuccess) {
        onUploadSuccess(response.data as FileReference[]);
      }
    })
    .catch((error: AxiosError) => {
      let responseData = error.response?.data;
      let alertMessage, alert;
      if (responseData.error === "MaxUploadSizeExceededException") {
        alertMessage = <AlertMessage id="maxFileUploadSizeExceeded" />;
        alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        return dispatch(alertActions.addAlert(alert));
      }
      if (responseData.error === "FileExtensionException") {
        alertMessage = <AlertMessage id="fileExtensionNotAllowed" />;
        alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        return dispatch(alertActions.addAlert(alert));
      }
      alertMessage = <AlertMessage id="fileUploadFailure" />;
      alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      return dispatch(alertActions.addAlert(alert));
    });
};

// tslint:disable-next-line:no-any
export const deleteFilesWithAlerts = (
  minioFileName: string,
  fileObjectType: FileObjectType,
  dispatch: Dispatch<any>,
  onSuccess: (minioFileName: string) => void
) => {
  fileUploadAPI(undefined, getBaseUrl(), API)
    ._delete(minioFileName, fileObjectType, {
      withCredentials: true
    })
    .then(() => {
      onSuccess(minioFileName);
      const alertMessage = <AlertMessage id="fileDeleteSuccessful" />;
      const alert = { id: 0, type: AlertType.Info, message: alertMessage };
      dispatch(alertActions.addAlert(alert));
    })
    .catch(() => {
      const alertMessage = <AlertMessage id="fileDeleteFailure" />;
      const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      dispatch(alertActions.addAlert(alert));
    });
};

// tslint:disable-next-line:no-any
export const deleteFile = (
  minioFileName: string,
  fileObjectType: FileObjectType,
  dispatch: Dispatch<any>,
  onSuccess: (minioFileName: string) => void
) => {
  fileUploadAPI(undefined, getBaseUrl(), API)
    ._delete(minioFileName, fileObjectType, {
      withCredentials: true
    })
    .then(() => onSuccess(minioFileName))
    .catch(() => {
      const alertMessage = <AlertMessage id="fileDeleteFailure" />;
      const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      dispatch(alertActions.addAlert(alert));
    });
};
