import React, { useCallback, useEffect, useState } from "react";
import "./App.scss";
import { IntlProvider } from "react-intl";
import { MainContainer } from "./MainContainer/MainContainer";
import { USER_INFO } from "./Constants";
import { useDispatch, useSelector } from "react-redux";
import { isUserInfoExpired, willSessionExpire } from "./Security/TokenHelpers";
import { SessionExpiredModal } from "./Security/SessionExpiredModal";
import { SessionExpiringModal } from "./Security/SessionExpiringModal";
import { useInterval } from "./Hook/interval";

import messages_et from "../translations/locales/et.json";
import messages_en from "../translations/locales/en.json";
import messages_ru from "../translations/locales/ru.json";
import countries_et from "../translations/countries/et.json";
import countries_en from "../translations/countries/en.json";
import countries_ru from "../translations/countries/ru.json";
import { RootState } from "./rootReducer";
import { configActions } from "./Config/configActions";
import { userActions } from "./Security/userActions";
import { API, getBaseUrl, setAxiosResponseInterceptor, UNINTERCEPTED_API_WITH_CREDENTIALS } from "./api";
import { AlertContainer } from "./Alert/AlertContainer";
import { UserInfo } from "./Dto/Security/UserInfo";
import { Loader } from "./Component/Loader/Loader";
import {
  AdminTeenusApiFactory as adminTeenusApi,
  KasutajaAutentimiseTeenusApiFactory as authAPI
} from "../api_client/medre_api";
import { AuthConfig } from "./Config/ConfigState";
import { displayAlert } from "./Util/AlertUtil";
import { AlertType } from "./Dto/Alert/AlertItem";
import { featureFlagActions } from "./Security/featureFlagActions";

export const App = () => {
  const dispatch = useDispatch();
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isSessionExpiring, setIsSessionExpiring] = useState(false);
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(false);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const locale = useSelector((state: RootState) => state.locale);
  const { clientId, authConfig, isPortal } = useSelector(
    (state: RootState) => state.config
  );

  const TEN_SECONDS = 1000 * 10;
  const messages: Record<string, Record<string, string>> = {
    et: { ...messages_et, ...countries_et },
    en: { ...messages_en, ...countries_en },
    ru: { ...messages_ru, ...countries_ru }
  };

  useEffect(() => {
    if (clientId) {
      setAxiosResponseInterceptor(dispatch, clientId, setIsSessionExpired);
    }
  }, [dispatch, clientId]);

  if (!authConfig) {
    authAPI(undefined, getBaseUrl(), API)
      .getAuthConfig({ withCredentials: true })
      .then((result) => {
        const data = result.data;
        const client = isPortal ? data.portalClientId : data.officeClientId;
        dispatch(
          configActions.setConfig({
            authConfig: data as AuthConfig,
            clientId: client
          })
        );
      })
      .catch(() => {
        setIsSessionExpired(true);
      });
  }

  const checkTokenExpiry = useCallback(() => {
    if (!userInfo) {
      return;
    }
    if (isUserInfoExpired(userInfo.tokenExpiresAt)) {
      setIsSessionExpired(true);
    } else if (
      willSessionExpire(
        userInfo.tokenExpiresAt,
        process.env.REACT_APP_REFRESH_TOKEN_EXPIRY_WARNING_MINUTES
      )
    ) {
      setIsSessionExpiring(true);
    }
  }, [userInfo]);

  useInterval(
    () => {
      checkTokenExpiry();
    },
    userInfo ? TEN_SECONDS : null
  );

  const attemptLogin = useCallback(
    (storedUserInfo: UserInfo) => {
      if (!isUserInfoExpired(storedUserInfo?.tokenExpiresAt) && clientId) {
        setIsLoadingUserInfo(true);
        const nonce = window.localStorage.getItem('nonce');
        UNINTERCEPTED_API_WITH_CREDENTIALS.get(`/public/auth/userinfo`, { headers: { 'X-Nonce': nonce } })
          .then((result) => {
            const updatedUserInfo = result.data;
            updatedUserInfo.roles = storedUserInfo.roles;
            dispatch(userActions.login({ userInfo: updatedUserInfo }));
          })
          .catch(() => {
            setIsSessionExpired(true);
          })
          .finally(() => {
            setIsLoadingUserInfo(false);
          });
      }
    },
    [dispatch, clientId]
  );

  const populateUserInfo = useCallback(() => {
    const userInfoJson = localStorage.getItem(USER_INFO);
    let storedUserInfo = null;
    if (userInfoJson) {
      storedUserInfo = JSON.parse(userInfoJson);
    }

    if (storedUserInfo && !userInfo) {
      attemptLogin(storedUserInfo);
    }
  }, [attemptLogin, userInfo]);

  const getSystemFlags = useCallback(() => {
    adminTeenusApi(undefined, getBaseUrl(), API)
      .getFeatureFlags({ withCredentials: true })
      .then((r) => {
        r.data.forEach(flag => {
          dispatch(featureFlagActions.setFeatureFlag(flag.id!, flag.isEnabled!));
        });
      })
      .catch(() => displayAlert("requestFailed", AlertType.Danger, dispatch));
  }, []);

  useEffect(() => {
    populateUserInfo();

    if (authConfig) {
      getSystemFlags();
    }
  }, [populateUserInfo, getSystemFlags, authConfig]);

  return (
    <div className="App">
      <IntlProvider
        key={locale}
        defaultLocale="et"
        locale={locale}
        messages={messages[locale]}
      >
        { isSessionExpired && <SessionExpiredModal /> }
        { isSessionExpiring && !isSessionExpired && (
          <SessionExpiringModal
            setIsSessionExpiring={setIsSessionExpiring}
            setIsSessionExpired={setIsSessionExpired}
          />
        ) }
        { isLoadingUserInfo ? (
          <Loader absolute backdrop />
        ) : (
          <MainContainer />
        )}
        <AlertContainer />
      </IntlProvider>
    </div>
  );
};
