import React from "react";

import { SuccessBadge } from "../../../Component/Badge/SuccessBadge";
import { DangerBadge } from "../../../Component/Badge/DangerBadge";
import { ActivityLicenseRowHeaderStatusEnum } from "../../../../api_client/medre_api";

interface Props {
  status?: ActivityLicenseRowHeaderStatusEnum;
  index?: number;
  isDetailed?: boolean;
}

export const ActivityLicenseState = ({ status, index }: Props) => {
  switch (status) {
    case ActivityLicenseRowHeaderStatusEnum.Valid:
      return <SuccessBadge id={`accepted-${index}`} message="accepted" />;
    case ActivityLicenseRowHeaderStatusEnum.Paused:
      return <DangerBadge id={`paused-${index}`} message="paused" />;
    case ActivityLicenseRowHeaderStatusEnum.Invalid:
      return <DangerBadge id={`invalid-${index}`} message="invalid" />;
    default:
      return <SuccessBadge id={`accepted-${index}`} message="accepted" />;
  }
};
