import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AxiosError } from "axios";
import { useHistory } from "react-router-dom";

import { SubmittedActions } from "../Application/Details/Proceeding/SubmittedActions";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { getBaseUrl, API } from "../../../../api";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { ActionButtonClasses } from "../../../../Component/Button/Button";
import { displayAlert } from "../../../../Util/AlertUtil";
import { InProceedingActions } from "../Application/Details/Proceeding/InProceedingActions";
import { ProtocolInPreparationActions } from "../Application/Details/Protocol/ProtocolInPreparationActions";
import { AcceptanceActions } from "../Application/Details/Acceptance/AcceptanceActions";
import { ButtonName } from "../../../../Component/Button/FormattedButton";
import { SignActions } from "../Application/Details/Sign/SignActions";
import {
  DetailedLicenseAcquisition,
  ActivityLicenseApplicationStatus,
  OfficialUserPrivilegesEnum,
  AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFactory as officialActivityPermitAPI,
  OfficialUser,
  FileObjectType,
  OtsusteTeenusApiFactory as decisionServiceAPI,
  DecisionType,
} from "../../../../../api_client/medre_api";
import { DecisionActions } from "../Application/Details/Decision/DecisionActions";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import { DangerFormattedButton } from "../../../../Component/Button/DangerFormattedButton";
import {
  ApplicationDeleteConfirmationModal
} from "../../../Portal/Application/Table/ApplicationDeleteConfirmationModal";
import { OfficialForwardingModal } from "../Application/Details/Proceeding/OfficialForwardingModal";
import { ProceedingPendingActions } from "../Application/Details/Proceeding/ProceedingPendingActions";
import { DeleteApplicationUtil } from "../ActionUtils/DeleteApplicationUtil";
import { OnSiteInspectionPendingActions } from "../Application/Details/Proceeding/OnSiteInspectionPendingActions";
import { PausedActions } from "../Application/Details/Proceeding/PausedActions";
import { SubmittedByOfficialActions } from "../Application/Details/Proceeding/SubmittedByOfficialActions";

interface Props {
  details: DetailedLicenseAcquisition | undefined;
  setDetails: (licenseAcquisition: DetailedLicenseAcquisition) => void;
  refresh: () => void;
}

export const LicenseAcquisitionDetailsActions = ({
  details,
  setDetails,
  refresh
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOfficialForwardingModalOpen, setIsOfficialForwardingModalOpen] =
    useState(false);

  const changeStatusTo = (newStatus: ActivityLicenseApplicationStatus) => {
    officialActivityPermitAPI(undefined, getBaseUrl(), API)
      .changeStatus(details?.id as string, newStatus, {
        withCredentials: true
      })
      .then((res) => {
        displayAlert("statusUpdateSuccess", AlertType.Success, dispatch);
        setDetails(res.data);
      })
      .catch((error: AxiosError) => {
        const responseData = error.response?.data;
        if (responseData.error === "TortaException") {
          displayAlert("tortaFailure", AlertType.Danger, dispatch);
          refresh();
        } else {
          displayAlert("statusUpdateFailure", AlertType.Danger, dispatch);
        }
      });
  };

  const handleForwardToOfficial = (official: OfficialUser) => {
    officialActivityPermitAPI(undefined, getBaseUrl(), API)
      .forwardToOfficial2(details?.id as string, official, {
        withCredentials: true
      })
      .then((res) => {
        displayAlert(
          "applicationForwardingSuccess",
          AlertType.Success,
          dispatch
        );
        setDetails(res.data);
      })
      .catch(() => {
        displayAlert(
          "applicationForwardingFailure",
          AlertType.Danger,
          dispatch
        );
      });
  };

  const initiateProceeding = () => {
    officialActivityPermitAPI(undefined, getBaseUrl(), API)
      .initiateProceeding(details?.id as string)
      .then((res) => {
        displayAlert("initiateProceedingSuccess", AlertType.Success, dispatch);
        setDetails(res.data);
        refresh();
      })
      .catch(() => {
        displayAlert("initiateProceedingFailure", AlertType.Danger, dispatch);
      });
  };

  const pauseProceeding = (notes: string) => {
    return officialActivityPermitAPI(undefined, getBaseUrl(), API)
      .pauseApplicationProceeding(details?.id as string, {
        noteText: notes
      })
      .then((response) => {
        displayAlert("statusUpdateSuccess", AlertType.Success, dispatch);
        setDetails(response.data);
        return Promise.resolve();
      })
      .catch((error) => {
        displayAlert("statusUpdateFailure", AlertType.Danger, dispatch);
      });
  };

  const continueProceeding = () => {
    officialActivityPermitAPI(undefined, getBaseUrl(), API)
      .continueProceeding(details?.id as string, {
        withCredentials: true
      })
      .then((response) => {
        displayAlert("continueProceedingSuccess", AlertType.Success, dispatch);
        setDetails(response.data);
      })
      .catch((e) => {
        displayAlert("continueProceedingFailure", AlertType.Danger, dispatch);
      });
  };

  const addProtocol = (protocolNumber: string) => {
    return officialActivityPermitAPI(undefined, getBaseUrl(), API)
      .addProtocol(
        details?.id as string,
        { noteText: protocolNumber },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        setDetails(response.data);
        displayAlert("statusUpdateSuccess", AlertType.Success, dispatch);
        return Promise.resolve();
      })
      .catch((error) => {
        displayAlert("statusUpdateFailure", AlertType.Danger, dispatch);
        return Promise.reject(error);
      });
  };

  const deleteApplication = (applicationId: string) => {
    DeleteApplicationUtil(
      applicationId,
      dispatch,
      history,
      details?.proceedingType!,
      "applicationDraftDeleteSuccess",
      "applicationDraftDeleteFailure");
  };

  const handleAppDelete = () => {
    deleteApplication(details?.id!);
    setIsDeleteModalOpen(false);
  };

  const renderForwardToOfficialButton = () => {
    return <ProtectedComponent
      allowedRoles={[
        OfficialUserPrivilegesEnum.Proceede,
        OfficialUserPrivilegesEnum.Sign
      ]}>
      <SecondaryFormattedButton
        id={"forwardToOfficial"}
        className={ActionButtonClasses}
        onClick={() => setIsOfficialForwardingModalOpen(true)}
      />
    </ProtectedComponent>;
  };

  const renderSubmittedApplicationActionButtons = () => {
    if (!details) {
      return;
    }

    const renderSignButtons = (
      signButtonId: ButtonName,
      newStatus: ActivityLicenseApplicationStatus
    ) => {
      return (
        <SignActions
          signButtonId={signButtonId}
          newStatus={newStatus}
          refresh={refresh}
          changeStatusTo={changeStatusTo}
          decisionId={details?.decisions?.find((d) => !d.signed)?.id!}
        />
      );
    };

    switch (details.status) {
      case ActivityLicenseApplicationStatus.Submitted:
        return (
          <SubmittedActions
            handleForwardToOfficial={handleForwardToOfficial}
            changeStatusTo={changeStatusTo}
            newStatus={ActivityLicenseApplicationStatus.Finished}
            canFinish
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            deleteApplication={deleteApplication}
          />
        );
      case ActivityLicenseApplicationStatus.SubmittedByOfficial:
        return (
          <SubmittedByOfficialActions
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            changeStatusTo={changeStatusTo}
            deleteApplication={deleteApplication}
            handleForwardToOfficial={handleForwardToOfficial}
            initiateProceeding={initiateProceeding}
            isStateFeePaid={details.stateFee?.paid}
            isStateFeeUsed
            newStatus={ActivityLicenseApplicationStatus.Finished}
          />
        );
      case ActivityLicenseApplicationStatus.ProceedingPending:
        return (
          <ProceedingPendingActions
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            changeStatusTo={changeStatusTo}
            deleteApplication={deleteApplication}
            handleForwardToOfficial={handleForwardToOfficial}
            initiateProceeding={initiateProceeding}
            isDeleteCheckNeeded={false}
            isStateFeePaid={details.stateFee?.paid}
            isStateFeeUsed
            newStatus={ActivityLicenseApplicationStatus.Finished}
          />
        );
      case ActivityLicenseApplicationStatus.InProceeding:
        return (
          <InProceedingActions
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            canAcceptOrDecline
            canPause
            changeStatusTo={changeStatusTo}
            deleteApplication={deleteApplication}
            handleForwardToOfficial={handleForwardToOfficial}
            newAcceptanceStatus={ActivityLicenseApplicationStatus.UploadAcceptedDecision}
            newDeclinationStatus={ActivityLicenseApplicationStatus.UploadDeclinedDecision}
            newStatus={ActivityLicenseApplicationStatus.OnSiteInspectionPending}
            pauseProceeding={pauseProceeding}
          />
        );
      case ActivityLicenseApplicationStatus.OnSiteInspectionPending:
        return (
          <OnSiteInspectionPendingActions
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            changeStatusTo={changeStatusTo}
            deleteApplication={deleteApplication}
            handleForwardToOfficial={handleForwardToOfficial}
            newStatus={ActivityLicenseApplicationStatus.ProtocolInPreparation}
          />
        );
      case ActivityLicenseApplicationStatus.ProtocolInPreparation:
        return (
          <ProtocolInPreparationActions
            addProtocol={addProtocol}
            applicationNumber={details.applicationNumber}
            deleteApplication={deleteApplication}
            fileObjectType={
              FileObjectType.LicenseAcquisitionOnSiteInspectionProtocol
            }
            fileReferences={details?.fileReferences || []}
            handleForwardToOfficial={handleForwardToOfficial}
            id={details?.id}
            refresh={refresh}
          />
        );
      case ActivityLicenseApplicationStatus.ReadyForDecision:
      case ActivityLicenseApplicationStatus.SignReopened:
        return (
          <>
            <AcceptanceActions
              applicationNumber={details.applicationNumber}
              changeStatusTo={changeStatusTo}
              newAcceptanceStatus={
                ActivityLicenseApplicationStatus.UploadAcceptedDecision
              }
              newDeclinationStatus={
                ActivityLicenseApplicationStatus.UploadDeclinedDecision
              }
            />
            {renderForwardToOfficialButton()}
          </>
        );
      case ActivityLicenseApplicationStatus.UploadAcceptedDecision:
      case ActivityLicenseApplicationStatus.UploadDeclinedDecision:
        return (
          <>
            <DecisionActions
              fileObjectType={FileObjectType.ActivityLicenseDecision}
              decisionUploadEndpoint={
                decisionServiceAPI(undefined, getBaseUrl(), API)
                  .assignDecisionToSigner
              }
              updateApplication={refresh}
              decision={{
                id: details.id!,
                type: DecisionType.LicenseAcquisition
              }}
            />
            {renderForwardToOfficialButton()}
          </>
        );
      case ActivityLicenseApplicationStatus.SignDeclinedDecision:
        return renderSignButtons(
          "signApplicationDeclinationDecision",
          ActivityLicenseApplicationStatus.SignReopened
        );
      case ActivityLicenseApplicationStatus.SignAcceptedDecision:
        return renderSignButtons(
          "signApplicationAcceptanceDecision",
          ActivityLicenseApplicationStatus.SignReopened
        );
      case ActivityLicenseApplicationStatus.Paused:
        return (
          <PausedActions
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            changeStatusTo={changeStatusTo}
            continueProceeding={continueProceeding}
            deleteApplication={deleteApplication}
            handleForwardToOfficial={handleForwardToOfficial}
            newStatus={ActivityLicenseApplicationStatus.SignDeclinedDecision}
          />
        );
      case ActivityLicenseApplicationStatus.Saved:
        return (
          <ProtectedComponent allowedRoles={[
            OfficialUserPrivilegesEnum.Sign,
            OfficialUserPrivilegesEnum.Proceede
          ]}>
            <DangerFormattedButton
              id="deleteApplication"
              className={ActionButtonClasses}
              onClick={() => setIsDeleteModalOpen(true)}
            />
          </ProtectedComponent>
        );
      case ActivityLicenseApplicationStatus.ProceedingReopened:
        return (
          <>
            <ProtectedComponent allowedRoles={[
              OfficialUserPrivilegesEnum.Sign,
              OfficialUserPrivilegesEnum.Proceede
            ]}>
              <DangerFormattedButton
                id="deleteApplication"
                className={ActionButtonClasses}
                onClick={() => setIsDeleteModalOpen(true)}
              />
            </ProtectedComponent>
            {renderForwardToOfficialButton()}
          </>
        );
      default:
        return;
    }
  };

  return (
    <div className="mt-1 mb-4">
      {renderSubmittedApplicationActionButtons()}
      <ApplicationDeleteConfirmationModal
        applicationNumber={details?.applicationNumber || 0}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleAppDelete}
      />
      <OfficialForwardingModal
        isOpen={isOfficialForwardingModalOpen}
        onClose={() => setIsOfficialForwardingModalOpen(false)}
        forwardToOfficial={(official) => {
          handleForwardToOfficial(official);
          setIsOfficialForwardingModalOpen(false);
        }}
      />
    </div>
  );
};