import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import "../../Application/Renewal/RenewalModal.scss";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { FileObjectType, FileReference, FileTemplate } from "../../../../../api_client/medre_api";
import { ActivityLicenseDocuments } from "../../../Shared/ActivityLicense/Documents/ActivityLicenseDocuments";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  notes: string;
  applicationId: string;
  requiredFiles: FileTemplate[];
  optionalFile?: FileObjectType;
  fileReferences: FileReference[];
  onUploadSuccess: (files: FileReference[]) => void;
  onDeleteSuccess: (minioFileName: string) => void;
  renewApplication: () => void;
  hasOther?: boolean;
}

export const RenewalModal = ({
  isOpen,
  onClose,
  notes,
  applicationId,
  fileReferences,
  requiredFiles,
  optionalFile,
  onUploadSuccess,
  onDeleteSuccess,
  renewApplication,
  hasOther = true
}: Props) => {
  const [areFilesPresent, setAreFilesPresent] = useState(true);

  return (
    <Modal className="renewal-modal" isOpen={isOpen}>
      <ModalHeader tag="h4">
        <FormattedMessage
          id="RenewalModal.header"
          defaultMessage="Taotluse täiendamine"
        />
      </ModalHeader>
      <ModalBody>
        <h6>
          <FormattedMessage
            id="RenewalModal.renewalHeader"
            defaultMessage="Ametniku poolt lisatud puuduliku taotluse põhjus"
          />
        </h6>
        {notes}
        <div className="mt-3">
          <ActivityLicenseDocuments
            hasOther={hasOther}
            requiredFiles={requiredFiles}
            optionalFiles={[{fileObjectType: optionalFile}]}
            fileReferences={fileReferences}
            setSaveAllowed={setAreFilesPresent}
            objectId={applicationId}
            onUploadSuccess={onUploadSuccess}
            handleFileDeletion={onDeleteSuccess}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton id="backToApplication" onClick={onClose} />
        <PrimaryFormattedButton
          id="renewApplication"
          disabled={!areFilesPresent}
          onClick={renewApplication}
        />
      </ModalFooter>
    </Modal>
  );
};
