import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AxiosPromise } from "axios";

import {
  containsServiceId,
  convertServiceToActivityLicenseService,
  getLocationAddress
} from "../../../../Dto/ActivityLicense/ActivityLicense";
import { MinusIcon } from "../../../../Component/Icon/MinusIcon";
import { PlusIcon } from "../../../../Component/Icon/PlusIcon";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { renderAccordionHeaderRow } from "../../../../Component/Accordion/AccordionHeaderRowUtil";
import { ServiceRow } from "./ServiceRow";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { renderOverViewRow } from "../../Application/OverView/ApplicationOverViewUtil";
import { ActivityLocationSuspensionModal } from "../Suspension/ActivityLocationSuspensionModal";
import useModal from "../../../../Hook/useModal";
import { RootState } from "../../../../rootReducer";
import { activityLicenseTableActions } from "../../../../ActivityLicense/activityLicenseTableActions";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";
import { AddNewServiceModal } from "../Services/AddNewServiceModal";
import { API, getBaseUrl } from "../../../../api";
import { WarningBadge } from "../../../../Component/Badge/WarningBadge";
import {
  ActivityLicenseRestorationAcceptanceModal
} from "../../../Official/ActivityLicense/Restoration/ActivityLicenseRestorationAcceptanceModal";
import { Loader } from "../../../../Component/Loader/Loader";
import {
  ActivityLicense,
  ActivityLicenseApplicationProceedingTypeEnum,
  ActivityLicenseRowHeaderStatusEnum,
  ActivityLicenseService,
  ActivityLicenseServiceServiceStatusEnum,
  ActivityLicenseStatusEnum,
  ActivityLocation,
  AmetnikuTegevuskohtadeTaastamiseTeenusApiFactory as officialActivityPlaceAPI,
  BusinessAreaServiceRequirement,
  FileObjectType,
  HealthCareProfessional,
  Occupation,
  OfficialUserPrivilegesEnum, RemoveSpecialistServiceEmployee, Service, SpecialistData,
  TegevuslubadeTeenusApiFactory as activityPermitServiceAPI
} from "../../../../../api_client/medre_api";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import { BUSINESS_AREA_ID } from "../../../../Util/ActivityLicenseUtils";

interface Props {
  location: ActivityLocation;
  isEditable?: boolean;
  isOpen: boolean;
  index: number;
  handleRowClick: (index: number) => void;
  refresh?: () => void;
  isSuspendable: boolean;
  isHospital: boolean;
  isActivityApplicationLicense?: boolean;
  specialistBusinessAreaId?: string;
  showTorConfirmation?: boolean;
  addTHTEndpoint?: (
    id: string,
    healthCareProfessional: HealthCareProfessional[],
    options?: any
  ) => AxiosPromise<ActivityLicenseService>;
  addSpecialistEndpoint?: (
    id: string,
    specialistCodeId: string,
    specialistData: SpecialistData,
    options?: any
  ) => AxiosPromise<ActivityLicenseService>;
  removeThtServiceEmploymentEndpoint?: (
    id: string,
    employeeId: string,
    options?: any
  ) => AxiosPromise<string>;
  removeSpecialistServiceEmploymentEndpoint?: (
    id: string,
    employeeId: string,
    removeSpecialistServiceEmployee: RemoveSpecialistServiceEmployee,
    options?: any
  ) => AxiosPromise<string>;
  loadServiceEndpoint: (
    id: string,
    options?: any
  ) => AxiosPromise<ActivityLicenseService>;
  handleInvalidationButtonClick?: ((locationId: string) => void) | false;
  isDiscardServiceEnabled?: boolean;
  activityLicense?: ActivityLicense;
  isPharmacyLicense?: boolean;
  locationLoadingEndpoint?: (
    id: string,
    options?: any
  ) => AxiosPromise<ActivityLocation>;
  isApplicationView?: boolean;
}

export const ActivityLocationRow = ({
  location,
  isOpen,
  specialistBusinessAreaId,
  index,
  handleRowClick,
  isEditable,
  refresh,
  isSuspendable,
  isActivityApplicationLicense,
  handleInvalidationButtonClick,
  activityLicense,
  isPharmacyLicense = false,
  loadServiceEndpoint,
  locationLoadingEndpoint,
  isApplicationView = false,
  ...childrenProps
}: Props) => {
  const expandedRowNumber = useSelector(
    (state: RootState) => state.activityLicenseTable.expandedLocationRow
  );
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  const history = useHistory();
  const suspensionModal = useModal();
  const addNewServiceModal = useModal();
  const [optionalServices, setOptionalServices] = useState<Service[]>([]);
  const [businessAreaRequiredOccupations, setBusinessAreaRequiredOccupations] = useState<Occupation[]>([]);
  const [availableServices, setAvailableServices] = useState<Service[]>([]);
  const [
    isRestorationAcceptanceModalOpen,
    setIsRestorationAcceptanceModalOpen
  ] = useState(false);
  const [existingServices, setExistingServices] = useState(location.services);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoadedServices, setHasLoadedServices] = useState(false);
  const [hasLoadedRequiredSpecialistOccupations, setHasLoadedRequiredSpecialistOccupations] = useState(false);

  const className = isOpen ? "expanded" : "";
  const widthSize = useWindowWidthSize();
  const isMobileActivityLocation = useMemo(() => {
    return location.adsAdrID === "mobile";
  }, [location.adsAdrID]);

  const renderRow = (icon: JSX.Element) => {
    return renderAccordionHeaderRow(
      widthSize,
      icon,
      getLocationAddress(location, specialistBusinessAreaId)
    );
  };

  const handleServiceRowClick = (rowNumber: number): void => {
    if (expandedRowNumber !== rowNumber) {
      dispatch(activityLicenseTableActions.setExpandedLocationRow(rowNumber));
    } else {
      dispatch(activityLicenseTableActions.setExpandedLocationRow(null));
    }
  };

  const servicesToSuspend = (service: ActivityLicenseService) => {
    let servicesToSuspendList = [] as ActivityLicenseService[];
    const locationServices = existingServices
      ?.filter((s) => s.id !== service.id)
      .filter(
        (ser) =>
          ser.serviceStatus !==
          ActivityLicenseServiceServiceStatusEnum.Discarded
      );
    if (locationServices) {
      addLocationRequiredServices(
        service.service,
        locationServices,
        servicesToSuspendList
      );
    }
    return servicesToSuspendList;
  };

  const servicesToDiscard = (service: ActivityLicenseService): ActivityLicenseService[] => {
    let services = [service];
    if (existingServices) {
      addLocationRequiredServices(service.service, existingServices, services);
    }
    return services;
  };

  const addLocationRequiredServices = (
    service: Service | undefined,
    locationServices: ActivityLicenseService[],
    discardableServices: ActivityLicenseService[]
  ): void => {
    for (let s of locationServices) {
      if (
        containsServiceId(s.service!.requiredServices!, service!.id as string)
      ) {
        discardableServices.push(s);
      } else if (
        containsServiceId(
          service!.requiredServices!,
          s.service!.id as string
        ) &&
        !locationServices
          .map((ls) => ls.service?.requiredServices)
          .flat()
          .map((rs) => rs!.id as string)
          .includes(s?.service?.id!)
      ) {
        discardableServices.push(s);
      }
    }
  };

  useEffect(() => {
    if (isOpen && userInfo && specialistBusinessAreaId && !hasLoadedRequiredSpecialistOccupations) {
      activityPermitServiceAPI(undefined, getBaseUrl(), API)
        .getBusinessAreaRequirements(specialistBusinessAreaId, { withCredentials: true })
        .then(businessResponse => setBusinessAreaRequiredOccupations(
          businessResponse?.data
            ? getRequiredOccupationsForBusinessArea(businessResponse.data as BusinessAreaServiceRequirement)
            : []
        )).finally(() => setHasLoadedRequiredSpecialistOccupations(true));
    }
  }, [isOpen, specialistBusinessAreaId, hasLoadedRequiredSpecialistOccupations]);

  useEffect(() => {
    if (isOpen && (!existingServices || !existingServices.length) && !hasLoadedServices && !isApplicationView) {
      const apiRequests = [
        locationLoadingEndpoint
          ? locationLoadingEndpoint(location.id!, {
            withCredentials: true
          })
          : activityPermitServiceAPI(undefined, getBaseUrl(), API).getLocation1(
            location.id as string,
            { withCredentials: true }
          )
      ];
      if (userInfo) {
        apiRequests.push(
          activityPermitServiceAPI(
            undefined,
            getBaseUrl(),
            API
          ).getBusinessAreaRequirements(
            activityLicense?.businessArea?.id as string,
            {
              withCredentials: true
            }
          )
        );
      }

      setIsLoading(true);
      Promise.all(apiRequests)
        .then(([locationRes, servicesRes]) => {
          setExistingServices(
            (locationRes.data as ActivityLocation)?.services || []
          );
          setOptionalServices(
            servicesRes?.data
              ? (servicesRes.data as BusinessAreaServiceRequirement)
                .optionalServices
              : []
          );
        })
        .finally(() => {
          setIsLoading(false);
          setHasLoadedServices(true);
        });
    }
  }, [
    isOpen,
    existingServices,
    hasLoadedServices,
    location.id,
    activityLicense,
    optionalServices
  ]);

  const getRequiredOccupationsForBusinessArea = (serviceResponse: BusinessAreaServiceRequirement) => {
    return (serviceResponse.requiredServices || [])
      .flatMap(service => service.requiredOccupations || []);
  };

  useEffect(() => {
    const serviceIds = existingServices?.map((s) => s.service?.id);
    setAvailableServices(
      optionalServices.filter((os) => !serviceIds?.includes(os.id))
    );
  }, [existingServices, optionalServices]);

  const handleNewServiceModal = useCallback(() => {
    addNewServiceModal.handleOpen();
  }, [addNewServiceModal.handleOpen]);

  const handleNewService = (service: Service): void => {
    const userDetails = isPortal
      ? {
        submittingPersonFirstName: userInfo?.firstName,
        submittingPersonLastName: userInfo?.lastName,
        submittingPersonIdCode: userInfo?.idCode
      }
      : {};
    const newApplication = {
      proceedingType: ActivityLicenseApplicationProceedingTypeEnum.Service,
      locations: [{ ...location }],
      company: activityLicense?.company,
      activityLicense: activityLicense,
      businessArea: activityLicense?.businessArea,
      ...userDetails
    };
    dispatch(
      activityLicenseApplicationActions.setActivityLicense(newApplication)
    );
    dispatch(
      activityLicenseApplicationActions.updateLocationInfo(0, "services", [
        convertServiceToActivityLicenseService(service, true)
      ])
    );
    history.push("/new-activity-license-service");
  };
  const renderContent = (): ReactElement => {
    return (
      <>
        { existingServices?.map((service, i) => {
          return (
            <ServiceRow
              serviceId={service.id!}
              serviceName={service.service?.name || "-"}
              service={service}
              key={i}
              isOpen={expandedRowNumber === i}
              serviceWithRequiredInformation={service.service}
              specialistBusinessAreaId={specialistBusinessAreaId}
              requiredOccupationsBusinessArea={businessAreaRequiredOccupations}
              isActivityApplicationLicense={isActivityApplicationLicense}
              index={i}
              handleRowClick={handleServiceRowClick}
              servicesToSuspend={servicesToSuspend(service)}
              servicesToDiscard={servicesToDiscard(service)}
              isDiscardServiceEnabled={
                service.toBeDiscarded === false &&
                existingServices &&
                existingServices.length > 1
              }
              refresh={refresh}
              totalSuspendedDays={activityLicense?.totalSuspendedDays || 0}
              isServiceSuspendable={
                !location.suspended &&
                existingServices &&
                existingServices.length > 1 &&
                !containsServiceId(
                  service.service?.requiredServices!,
                  "" + service!.id
                )
              }
              isPharmacyLicense={isPharmacyLicense}
              isMobileActivityLocation={isMobileActivityLocation}
              loadServiceEndpoint={loadServiceEndpoint}
              isValidLicense={
                activityLicense?.status === ActivityLicenseStatusEnum.Valid
              }
              activityLicenseStatus={activityLicense?.status}
              {...childrenProps}
            />
          );
        }) }
        <ActivityLocationSuspensionModal
          isOpen={suspensionModal.isOpen}
          onClose={suspensionModal.handleClose}
          activityLocationId={location.id!}
          activityLocationFullAddress={location.fullAddress!}
          totalSuspendedDays={activityLicense?.totalSuspendedDays}
        />
        <AddNewServiceModal
          isOpen={addNewServiceModal.isOpen}
          services={availableServices}
          onClose={addNewServiceModal.handleClose}
          onSave={handleNewService}
        />
        <ActivityLicenseRestorationAcceptanceModal
          isOpen={isRestorationAcceptanceModalOpen}
          onClose={(): void => setIsRestorationAcceptanceModalOpen(false)}
          objectId={location.id!}
          type={FileObjectType.ActivityLicenseOnSiteInspectionProtocol}
          modalTitle="Taasta tegevuskoht"
          endpoint={
            officialActivityPlaceAPI(undefined, getBaseUrl(), API)
              .initiateActivityLocationRestoration
          }
        />
      </>
    );
  };

  const renderLinks = useCallback(() => {
    return (
      <>
        { ((!location.suspended &&
            location.status === ActivityLicenseRowHeaderStatusEnum.Valid &&
            !!availableServices.length &&
            activityLicense?.businessArea?.id !== BUSINESS_AREA_ID) ||
          (activityLicense?.businessArea?.id === BUSINESS_AREA_ID &&
            !existingServices?.length)) && (
          <ProtectedComponent
            directRepresentativeOnly
            officialsOnly
            allowedRoles={[
              OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate
            ]}
            matchAnyCondition
          >
            <LinkButton id="addService" onClick={handleNewServiceModal}/>
          </ProtectedComponent>
        ) }
        { isEditable ? (
          <>
            { isSuspendable &&
              activityLicense?.status !== ActivityLicenseStatusEnum.Paused &&
              activityLicense?.status !== ActivityLicenseStatusEnum.Invalid && (
              <ProtectedComponent
                directRepresentativeOnly
                officialsOnly
                allowedRoles={[OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate]}
                matchAnyCondition>
                <LinkButton
                  id="suspendLocation"
                  onClick={suspensionModal.handleOpen}
                />
              </ProtectedComponent>
            ) }
            { handleInvalidationButtonClick &&
              activityLicense?.status !== ActivityLicenseStatusEnum.Paused &&
              activityLicense?.status !== ActivityLicenseStatusEnum.Invalid && (
              <ProtectedComponent
                directRepresentativeOnly
                officialsOnly
                allowedRoles={[OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate]}
                matchAnyCondition>
                <LinkButton
                  id="invalidateLocation"
                  onClick={(): void => handleInvalidationButtonClick(location.id as string)}
                  className="danger"
                />
              </ProtectedComponent>
            ) }
            { location.status === ActivityLicenseRowHeaderStatusEnum.Paused && (
              <LinkButton
                id="acceptActivityLocationRestoration"
                onClick={(): void => setIsRestorationAcceptanceModalOpen(true)}
              />
            ) }
          </>
        ) : undefined }
      </>
    );
  }, [
    isEditable,
    location.status,
    location.suspended,
    handleInvalidationButtonClick,
    suspensionModal.handleOpen,
    isSuspendable,
    availableServices,
    existingServices,
    activityLicense
  ]);

  if (isLoading) {
    return <Loader backdrop />;
  }

  if (isOpen) {
    return (
      <>
        <div
          className={`${ className } cursor-pointer accordion-header location`}
          onClick={(): void => handleRowClick(index)}
        >
          { renderRow(<MinusIcon />) }
          { location.suspended && (
            <WarningBadge
              id={`paused-${ index }`}
              className="ml-4"
              message="paused"
            />
          ) }
        </div>

        <div className="detail-col pl-0 pr-0">
          { isEditable &&
            !isPharmacyLicense &&
            activityLicense?.status === ActivityLicenseStatusEnum.Valid &&
            renderOverViewRow("general", "activities", renderLinks()) }
          { renderContent() }
        </div>
      </>
    );
  }

  return (
    <div
      className={`${ className } cursor-pointer accordion-header location`}
      onClick={(): void => handleRowClick(index)}
    >
      { renderRow(<PlusIcon />) }
      { location.suspended && (
        <WarningBadge
          id={`paused-${ index }`}
          className="ml-4"
          message="paused"
        />
      ) }
    </div>
  );
};
