import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { AttachmentContainer } from "../../../Shared/Application/Education/Manual/Attachment/AttachmentContainer";
import {
  deleteFilesWithAlerts,
  saveFilesWithAlerts
} from "../../../../Component/FileDropzone/UploadFile";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { ApplicantChanges } from "../../../../Dto/Applicant/ApplicantChanges";
import { FormattedErrorMessage } from "../../../../Component/ErrorField/FormattedErrorMessage";
import { inputRegex } from "../../../../Util/inputRegexValidation";
import {
  FileObjectType,
  FileReference,
  OfficialUserPrivilegesEnum
} from "../../../../../api_client/medre_api";
import { PrescriptionPowerFormProps, FormValidity } from "./types";

export const PrescriptionPowerForm = ({
  applicantChanges,
  setApplicantChanges,
  forceValidation,
  person,
  applicantFormClassName,
  dateConfig,
  id
}: PrescriptionPowerFormProps) => {
  const dispatch = useDispatch();

  const prescriptionPower = applicantChanges.prescriptivePowerSpeciality;
  const [activeAttachmentId, setActiveAttachmentId] = useState<string>("");
  const [formValidity, setFormValidity] = useState<FormValidity>({});

  useEffect(() => {
    if (prescriptionPower) {
      const hasDateNumberValue =
        prescriptionPower.prescriptionPowerNumber ||
        prescriptionPower.prescriptionPowerDate;
      const hasDocument = prescriptionPower.prescriptionPowerDocument;
      if (!hasDateNumberValue && !hasDocument) {
        setApplicantChanges((prevState: ApplicantChanges) => {
          return { ...prevState, prescriptivePowerSpeciality: undefined };
        });
        setFormValidity({});
      }
    }
  }, [applicantChanges, prescriptionPower, setApplicantChanges]);

  useEffect(() => {
    if (
      !forceValidation ||
      prescriptionPower === undefined ||
      Object.keys(prescriptionPower).length === 0
    ) {
      return;
    }
    let {
      prescriptionPowerNumber,
      prescriptionPowerDate,
      prescriptionPowerDocument
    } = prescriptionPower;
    setFormValidity((prevState: FormValidity) => {
      return {
        ...prevState,
        prescriptionPowerNumberValid: !!prescriptionPowerNumber,
        prescriptionPowerDateValid: Boolean(
          prescriptionPowerDate && !isDateInFuture(prescriptionPowerDate)
        ),
        prescriptionPowerDocumentValid: !!prescriptionPowerDocument
      };
    });
  }, [forceValidation, prescriptionPower]);

  const handleSaveFile = (files: File[]) => {
    saveFilesWithAlerts(
      files,
      FileObjectType.PersonPrescriptionPowerDocument,
      person.id!,
      dispatch,
      onUploadSuccess
    );
  };

  const onUploadSuccess = (files: FileReference[]) => {
    setApplicantChanges((prevState: ApplicantChanges) => {
      return {
        ...prevState,
        prescriptivePowerSpeciality: {
          ...prevState.prescriptivePowerSpeciality,
          prescriptionPowerDocument: files[0]
        }
      };
    });
    setFormValidity((prevState: FormValidity) => {
      return { ...prevState, prescriptionPowerDocumentValid: true };
    });
  };

  const handlePrescriptionPowerDateChange = (newDate?: Date) => {
    setApplicantChanges((prevState: ApplicantChanges) => {
      return {
        ...prevState,
        prescriptivePowerSpeciality: {
          ...prevState.prescriptivePowerSpeciality,
          prescriptionPowerDate: newDate
        }
      };
    });
    setFormValidity((prevState: FormValidity) => {
      return {
        ...prevState,
        prescriptionPowerDateValid: newDate && !isDateInFuture(newDate)
      };
    });
  };

  const isDateInFuture = (date: Date) => {
    let todayMoment = moment(new Date()).startOf("day");
    let newDateMoment = moment(date).startOf("day");
    return newDateMoment.isAfter(todayMoment);
  };

  const handleFileDelete = () => {
    let toDelete = prescriptionPower?.prescriptionPowerDocument;
    if (toDelete && toDelete.minioFileName) {
      deleteFilesWithAlerts(
        toDelete.minioFileName,
        toDelete.fileObjectType!,
        dispatch,
        removeFileReference
      );
    }
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let fieldValue = e.target.value;
    setApplicantChanges((prevState: ApplicantChanges) => {
      return {
        ...prevState,
        prescriptivePowerSpeciality: {
          ...prevState.prescriptivePowerSpeciality,
          prescriptionPowerNumber: fieldValue
        }
      };
    });
    setFormValidity((prevState: FormValidity) => {
      return { ...prevState, prescriptionPowerNumberValid: true };
    });
  };

  const removeFileReference = (minioFileName: string) => {
    setApplicantChanges((prevState: ApplicantChanges) => {
      return {
        ...prevState,
        prescriptivePowerSpeciality: {
          ...prevState.prescriptivePowerSpeciality,
          prescriptionPowerDocument: undefined
        }
      };
    });
  };

  const togglePopOver = (value: boolean) => {
    value && setActiveAttachmentId("");
  };

  const prescriptionDocument = prescriptionPower?.prescriptionPowerDocument
    ? [prescriptionPower.prescriptionPowerDocument]
    : [];

  return (
    <>
      <div className={`applicant-changes-form ${applicantFormClassName}`}>
        <FormGroup className="form-inline mb-4">
          <Label>
            <FormattedMessage
              id="prescriptionPower.certificateNumber"
              defaultMessage="Tõendi nr"
            />
          </Label>
          <Input
            value={prescriptionPower?.prescriptionPowerNumber || ""}
            name="prescriptionPowerNumber"
            invalid={formValidity?.prescriptionPowerNumberValid === false}
            onChange={(e) => handleNumberChange(inputRegex(e))}
          />
        </FormGroup>
        <FormGroup className="form-inline mb-4">
          <Label className={dateConfig?.required ? "required" : ""}>
            <FormattedMessage
              id="prescriptionPower.dateOfProof"
              defaultMessage="Tõendi kuupäev"
            />
          </Label>
          <div className="w-auto">
            <DatePickerComponent
              selectedDate={prescriptionPower?.prescriptionPowerDate}
              onDateChange={handlePrescriptionPowerDateChange}
              placeholder="pp.kk.aaaa"
              invalid={formValidity?.prescriptionPowerDateValid === false}
              {...dateConfig}
            />
          </div>
        </FormGroup>
      </div>
      <AttachmentContainer
        onClick={() => setActiveAttachmentId(id)}
        isActive={activeAttachmentId === id}
        type={FileObjectType.PersonPrescriptionPowerDocument}
        fileReferences={prescriptionDocument}
        maxFilesCount={1}
        saveFiles={(files) => handleSaveFile(files)}
        handleFileDelete={() => handleFileDelete()}
        allowedRoles={[
          OfficialUserPrivilegesEnum.Sign,
          OfficialUserPrivilegesEnum.Proceede
        ]}
        handlePopOvertoggle={togglePopOver}
      />
      {formValidity.prescriptionPowerDocumentValid === false && (
        <div className="w-25">
          <FormattedErrorMessage id="mandatoryField" />
        </div>
      )}
    </>
  );
};
