import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {
  Education,
  EducationInfo,
  FileObjectType,
  FileReference,
  HariduseSalvestamineIsikuandmeteJaoksApiFactory as saveBlankEducationAPI
} from "../../../../../../api_client/medre_api";
import {EducationEditFields} from "./EducationEditFields";
import {SwitchToAutomaticEducationViewModal} from "../SwitchToAutomaticEducationViewModal";
import {API, getBaseUrl} from "../../../../../api";
import {deleteFilesWithAlerts, saveFilesWithAlerts} from "../../../../../Component/FileDropzone/UploadFile";
import {PersonAttachmentFields} from "./Attachment/PersonAttachmentFields";
import {AxiosResponse} from "axios";

interface Props {
  setIsSaveAllowed: (isAllowed: boolean) => void;
  onBlankEducationCreated: (blankEducationCreated: boolean) => void;
  education? : Education;
  setEducation: (education: Education) => void;
  personId?: string;
  toggleAutomaticEducationLoad: () => void;
}

export const ManualEducationModal = ({
                                      setIsSaveAllowed,
                                       onBlankEducationCreated,
                                      education,
                                       personId,
                                        setEducation,
                                       toggleAutomaticEducationLoad
                                    }: Props) => {
  const dispatch = useDispatch();
  const [showSwitchConfirmationModal, setShowSwitchConfirmationModal] =
    useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [areFilesPresent, setAreFilesPresent] = useState(false);
  const [isUpdate, setIsUpdate] = useState(true);
  const [refreshFlag, setRefreshFlag] = useState(false);

  useEffect(() => {
    setIsSaveAllowed(isFormValid && areFilesPresent);
  }, [setIsSaveAllowed, isFormValid, areFilesPresent]);

  const fileReferences = () => {
    return education?.fileReferences || []
  };

  const getBlankEducationFromResponse = (response: AxiosResponse<Education>) : Education => {
      return {id: response.data.id, source: "MANUALLY_ADDED"};
  }

  useEffect(() => {
    if (!(education && education?.id)) {
      saveBlankEducationAPI(undefined, getBaseUrl(), API).saveBlankEducation(personId!, {
        withCredentials: true
      }).then((response) => {
        const blankEducation = getBlankEducationFromResponse(response);
        setEducation(blankEducation);
        onBlankEducationCreated(true)
        education = blankEducation;
        setIsUpdate(false);
      });
    }
  }, []);

  const updateEducationInfo = useCallback(
    (info: EducationInfo) => {
      if (education && education?.id) {
      setEducation( {
          id: education?.id,
          source: education?.source,
          fileReferences: education?.fileReferences,
          acquiredDate: info.diplomaDate,
          facilityName: info.schoolName,
          curriculumName: info.curriculumName,
          countryCode: info.countryCode,
          curriculumCode: info.curriculumCode,
          documentNumber: info.diplomaNumber,
        });
      }
    },
    []
  );


  const handleConfirmation = () => {
    setShowSwitchConfirmationModal(false);
  };

  const handleFileSave = (files: File[], fileType: FileObjectType) => {
    saveFilesWithAlerts(
      files,
      fileType,
      education!.id!,
      dispatch,
      updateFiles
    );
  };

  const updateFiles = (updateFileReferences: FileReference[]) => {
    if (!education!.fileReferences) {
      education!.fileReferences = updateFileReferences;
    } else {
      education!.fileReferences!.push(...updateFileReferences);
    }
    setEducation(education!);
    setRefreshFlag(!refreshFlag);
  };

  const handleFileDelete = (fileName: string, fileType: FileObjectType) => {
    let toDelete: FileReference | undefined =
      education?.fileReferences?.filter((file) => {
        return file.fileName === fileName && file.fileObjectType === fileType;
      })[0];
    if (toDelete && toDelete?.minioFileName) {
      deleteFilesWithAlerts(
        toDelete.minioFileName,
        toDelete.fileObjectType!,
        dispatch,
        handleFileDeletion
      );
    }
  };
  const educationToEducationInfo =(educationToConvert: Education) : EducationInfo => {
    const educationInfoToReturn= {
      countryCode: educationToConvert?.countryCode,
      schoolName: educationToConvert?.facilityName,
      curriculumName: educationToConvert?.curriculumName,
      diplomaNumber: educationToConvert?.documentNumber,
      diplomaDate: educationToConvert?.acquiredDate,
      curriculumCode: educationToConvert?.curriculumCode,
      specialization: educationToConvert?.specialization
    } as EducationInfo;
    return educationInfoToReturn;
  }

  const handleFileDeletion = (minioFileName: string) => {
    education!.fileReferences = education!.fileReferences?.filter(fileRef => fileRef.minioFileName !== minioFileName);
    setEducation(education!);
    setRefreshFlag(!refreshFlag);
  };

  return (
    <div className="education-content">
      <EducationEditFields
        educationInfo={educationToEducationInfo(education!)}
        updateEducationInfo={updateEducationInfo}
        updateIsFormValid={setIsFormValid}
        isCountrySelectDisabled={isUpdate}
        isTHTDetailsPage={true}
      />
      <PersonAttachmentFields
        updateAreFilesValid={setAreFilesPresent}
        objectId={education?.id!}
        fileReferences={fileReferences}
        saveFiles={handleFileSave}
        handleFileDelete={handleFileDelete}
        refreshFlag={refreshFlag}
      />
      <SwitchToAutomaticEducationViewModal
        isOpen={showSwitchConfirmationModal}
        onClose={() => setShowSwitchConfirmationModal(!isUpdate)}
        onSave={() => handleConfirmation()}
      />
    </div>
  );
};
