import React from "react";
import { useSelector } from "react-redux";

import { ActivityLicenseServices } from "./ActivityLicenseServices";
import { RootState } from "../../../rootReducer";

interface Props {
  hideAddNewServiceButton?: boolean;
  isUpdateActivityLicenseService?: boolean;
}

export const NewActivityLicenseServices = ({
  hideAddNewServiceButton,
  isUpdateActivityLicenseService
                                           }: Props) => {
  const services = useSelector((state: RootState) => {
    if (
      state.activityLicenseApplication.locations &&
      state.activityLicenseApplication.locations.length
    ) {
      return state.activityLicenseApplication.locations[0].services || [];
    }
    return [];
  });

  return (
    <ActivityLicenseServices
      locationInfoIndex={0}
      services={services}
      hideAddNewServiceButton={hideAddNewServiceButton}
      isUpdateActivityLicenseService={isUpdateActivityLicenseService}
    />
  );
};
