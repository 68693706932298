import React, { useCallback, useState } from "react";
import { ActivityLicenseDocuments } from "./ActivityLicenseDocuments";
import { RootState } from "../../../../rootReducer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";
import {
  ActivityLicenseBusinessAreaBusinessAreaTypeEnum,
  FileObjectType,
  FileReference,
  FileTemplate
} from "../../../../../api_client/medre_api";
import { FormattedMessage } from "react-intl";
import { DocumentsInfoModal } from "./DocumentsInfoModal";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import question_mark from "../../../../../assets/images/question_mark_blue.svg";
import { ACTIVITY_LICENSE_DOCUMENTATION_REQUIREMENTS_LINK } from "../../../../Util/ActivityLicenseUtils";

const getRequiredBusinessAreaFileTypes = (state: RootState): Array<FileTemplate> => {
  return state.activityLicenseApplication.businessArea?.requiredFiles || [];
};

const getRequiredBusinessAreaFileTypesForMobile = (): Array<FileTemplate> => {
  const businessAreaRequirements = useSelector((state: RootState) =>
    state.activityLicenseApplication.businessArea?.businessAreaFileRequirements, shallowEqual);
  let filteredBusinessAreaRequirements = businessAreaRequirements
    ?.filter((businessAreaRequirement) => businessAreaRequirement.requiredForMobileLocation) ?? [];
  let fileTemplates = filteredBusinessAreaRequirements.map(requirement => requirement.fileTemplate);
  return fileTemplates || [];
};

const getRequiredServiceFileTypes = (state: RootState) =>
  (state.activityLicenseApplication
    .locations![0].services?.map((s) => s.service?.requiredFiles)
    .flat() || []) as FileTemplate[];

const isMobileLocation = () => {
  const adsAdrID = useSelector((state: RootState) => state.activityLicenseApplication.locations![0].adsAdrID);
  return adsAdrID === "mobile";
};

export const ActivityLicenseDocumentsContainer = () => {
  const isSpecialistActivityLicense: boolean = useSelector((state: RootState) => {
    return (
      state.activityLicenseApplication.businessArea?.businessAreaType ===
      ActivityLicenseBusinessAreaBusinessAreaTypeEnum.Specialist
    );
  });
  const dispatch = useDispatch();

  const fileReferences = useSelector(
    (state: RootState) => state.activityLicenseApplication.fileReferences || [],
    shallowEqual
  );
  const draftId = useSelector((state: RootState) => state.activityLicenseApplication.id);

  const getRequiredFiles = (): FileTemplate[] => {
    const businessAreaFiles: FileTemplate[] = isMobileLocation() ?
      getRequiredBusinessAreaFileTypesForMobile() :
      useSelector(getRequiredBusinessAreaFileTypes, shallowEqual);
    const serviceFiles: FileTemplate[] = useSelector(getRequiredServiceFileTypes, shallowEqual);

    if (isSpecialistActivityLicense && isMobileLocation()) {
      return businessAreaFiles;
    } else {
      return businessAreaFiles.concat(serviceFiles);
    }
  };

  const [requiredFiles] = useState(getRequiredFiles());

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const setSaveAllowed = useCallback((isAllowed: boolean) => {
    dispatch(activityLicenseApplicationActions.updateActivityLicense("saveAllowed", isAllowed));
  }, [dispatch]);

  const onUploadSuccess = (files: FileReference[]): void => {
    dispatch(activityLicenseApplicationActions.addActivityLicenseFileReference(files));
  };

  const handleFileDeletion = (minioFileName: string): void => {
    dispatch(activityLicenseApplicationActions.removeActivityLicenseFileReference(minioFileName));
  };

  const documents = (
    <FormattedMessage
      id="activityLicense.documents"
      defaultMessage="Dokumendid"
    />
  );

  return (
    <div className="activity-license-documents-wrapper">
      <h3 className="application-title fw-normal">{ documents }</h3>
      <p>
        <FormattedMessage
          id="activityLicense.addDocuments"
          defaultMessage="Lisa taotlusele dokumendid ja klõpsa “Edasi”."
        />
      </p>
      <h4 className="application-title fw-normal">{ documents }</h4>
      <div className="mb-2">
        <img src={question_mark} alt="info" />
        <LinkButton
          id="documentRequirements"
          onClick={(): void => setIsModalOpen(true)}
        />
      </div>
      <ActivityLicenseDocuments
        requiredFiles={requiredFiles}
        optionalFiles={!isSpecialistActivityLicense ? [
          { fileObjectType: FileObjectType.ActivityLicensePersonnelAgreement }
        ] : []}
        fileReferences={fileReferences}
        objectId={draftId!}
        setSaveAllowed={setSaveAllowed}
        onUploadSuccess={onUploadSuccess}
        handleFileDeletion={handleFileDeletion}
      />
      <DocumentsInfoModal
        isOpen={isModalOpen}
        type="ACTIVITY_LICENSE_DOCUMENTATION_REQUIREMENTS"
        fileUrl={ACTIVITY_LICENSE_DOCUMENTATION_REQUIREMENTS_LINK}
        onClose={(): void => setIsModalOpen(false)}
      />
    </div>
  );
};
