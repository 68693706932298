import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { Badge } from "reactstrap";

import "./Badge.scss";
import { LocaleMessage } from "../../Locale/LocaleMessage";

interface Props {
  id: string;
  message: BadgeName;
  icon: string;
  iconGhost: string;
  color?: string;
  className?: string;
  isGhost?: boolean;
  // tslint:disable-next-line:no-any
  values?: Record<string, any>;
}

export type BadgeName =
  | "saved"
  | "registering"
  | "stateFeeNotPaid"
  | "submitted"
  | "inProceeding"
  | "paused"
  | "directedToComplianceExam"
  | "directedToAptitudeTest"
  | "deleted"
  | "declined"
  | "accepted"
  | "waitingForSignature"
  | "signed"
  | "finished"
  | "onSiteInspectionPending"
  | "decisionPending"
  | "proceedingPending"
  | "protocolInPreparation"
  | "readyForDecision"
  | "invalid"
  | "gpListInLiquidation"
  | "gpListHasSubstitute"
  | "gpListLinksToNewLicense"
  | "gpListServiceAreaChanging"
  | "decisionAcceptedAfterComplianceExam"
  | "decisionAcceptedAfterAptitudeTest"
  | "waitingForComplianceDecisionSignature"
  | "accept"
  | "waitingForAptitudeDecisionSignature"
  | "manuallyAdded"
  | "ehisManuallyAdded"
  | "migrated"
  | "tortaCheckEnabled"
  | "tortaCheckDisabled"
  | "valid"
  | "expired"
  | "future"
  | "none"
  | "yes"
  | "no";

export const FormattedBadge = ({
  id,
  message,
  icon,
  iconGhost,
  color,
  className,
  isGhost,
  values
}: Props) => {
  const badges: Record<BadgeName, LocaleMessage> = defineMessages({
    saved: {
      id: "badge.saved",
      defaultMessage: "Salvestatud"
    },
    registering: {
      id: "badge.registering",
      defaultMessage: "Registreerimisel"
    },
    stateFeeNotPaid: {
      id: "badge.stateFeeNotPaid",
      defaultMessage: "Riigilõiv tasumata"
    },
    submitted: {
      id: "badge.submitted",
      defaultMessage: "Esitatud"
    },
    inProceeding: {
      id: "badge.inProceeding",
      defaultMessage: "Menetluses"
    },
    paused: {
      id: "badge.paused",
      defaultMessage: "Peatatud"
    },
    directedToComplianceExam: {
      id: "badge.directedToComplianceExam",
      defaultMessage: "Vastavuseksam"
    },
    directedToAptitudeTest: {
      id: "badge.directedToAptitudeTest",
      defaultMessage: "Sobivustest"
    },
    deleted: {
      id: "badge.deleted",
      defaultMessage: "Kustutatud"
    },
    declined: {
      id: "badge.declined",
      defaultMessage: "Keeldutud"
    },
    accepted: {
      id: "badge.accepted",
      defaultMessage: "Rahuldatud"
    },
    waitingForSignature: {
      id: "badge.waitingForSignature",
      defaultMessage: "Allkirjastamise ootel"
    },
    signed: {
      id: "badge.signed",
      defaultMessage: "Allkirjastatud"
    },
    finished: {
      id: "badge.finished",
      defaultMessage: "Lõpetatud"
    },
    onSiteInspectionPending: {
      id: "badge.onSiteInspectionPending",
      defaultMessage: "Paikvaatluse ootel"
    },
    decisionPending: {
      id: "badge.decisionPending",
      defaultMessage: "Otsuse ootel"
    },
    proceedingPending: {
      id: "badge.proceedingPending",
      defaultMessage: "Menetluse ootel"
    },
    protocolInPreparation: {
      id: "badge.protocolInPreparation",
      defaultMessage: "Protokoll koostamisel"
    },
    readyForDecision: {
      id: "badge.readyForDecision",
      defaultMessage: "Otsuse koostamiseks valmis"
    },
    invalid: {
      id: "badge.invalid",
      defaultMessage: "Kehtetu"
    },
    gpListInLiquidation: {
      id: "badge.gpListInLiquidation",
      defaultMessage: "Nimistu on likvideerimisel"
    },
    gpListHasSubstitute: {
      id: "badge.gpListHasSubstitute",
      defaultMessage: "Nimistul on asendaja"
    },
    gpListLinksToNewLicense: {
      id: "badge.gpListLinksToNewLicense",
      defaultMessage:
        "Uus tegevusluba {licenseNumber} määratakse alates {activationDate}"
    },
    gpListServiceAreaChanging: {
      id: "badge.gpListServiceAreaChanging",
      defaultMessage:
        "Uus teeninduspiirkonda {serviceArea} määratakse alates {activationDate}"
    },
    decisionAcceptedAfterComplianceExam: {
      id: "badge.decisionAcceptedAfterComplianceExam",
      defaultMessage: "Rahuldatud vastavuseksami järel"
    },
    decisionAcceptedAfterAptitudeTest: {
      id: "badge.decisionAcceptedAfterAptitudeTest",
      defaultMessage: "Rahuldatud sobivustesti järel"
    },
    waitingForComplianceDecisionSignature: {
      id: "badge.waitingForComplianceDecisionSignature",
      defaultMessage: "Vastavuseksamile suunatud ja allkirjastamise ootel"
    },
    accept: {
      id: "badge.accept",
      defaultMessage: "Rahulda"
    },
    waitingForAptitudeDecisionSignature: {
      id: "badge.waitingForAptitudeDecisionSignature",
      defaultMessage: "Sobivustestile suunatud ja allkirjastamise ootel"
    },
    manuallyAdded: {
      id: "badge.manuallyAdded",
      defaultMessage: "Käsitsi sisestatud"
    },
    ehisManuallyAdded: {
      id: "badge.ehisManuallyAdded",
      defaultMessage: "EHIS"
    },
    migrated: {
      id: "badge.migrated",
      defaultMessage: "Migreeritud"
    },
    tortaCheckEnabled: {
      id: "systemAdministration.tab.torta.tortaCheckEnabled",
      defaultMessage: "TORTA kontroll on sees"
    },
    tortaCheckDisabled: {
      id: "systemAdministration.tab.torta.tortaCheckDisabled",
      defaultMessage: "TORTA kontroll on väljas"
    },
    valid: {
      id: "badge.valid",
      defaultMessage: "Kehtiv"
    },
    expired: {
      id: "badge.expired",
      defaultMessage: "Lõppenud"
    },
    future: {
      id: "badge.future",
      defaultMessage: "Tulevikus"
    },
    none: {
      id: "badge.none",
      defaultMessage: "Puudub"
    },
    yes: {
      id: "badge.yes",
      defaultMessage: "Jah"
    },
    no: {
      id: "badge.no",
      defaultMessage: "Ei"
    }
  });

  return (
    <span className={"badge-container " + className}>
      <Badge color={color} id={id} className={isGhost ? "" : "filled"}>
        <FormattedMessage {...badges[message as BadgeName]} values={values} />
      </Badge>
      <img
        className="badge-icon"
        src={isGhost ? iconGhost : icon}
        alt="ikoon"
      />
    </span>
  );
};
