import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { AttachmentContainer } from "../../Application/Education/Manual/Attachment/AttachmentContainer";
import { deleteFilesWithAlerts, saveFilesWithAlerts } from "../../../../Component/FileDropzone/UploadFile";
import "../ActivityLicenseDocuments.scss";
import question_mark from "../../../../../assets/images/question_mark_blue.svg";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { DocumentsInfoModal } from "./DocumentsInfoModal";
import info_icon from "../../../../../assets/images/information_blue.svg";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { FileObjectType, FileReference, FileTemplate } from "../../../../../api_client/medre_api";
import { RootState } from '../../../../rootReducer';

interface Props {
  requiredFiles: FileTemplate[];
  optionalFiles?: FileTemplate[];
  fileReferences: FileReference[];
  objectId: string;
  onUploadSuccess: (files: FileReference[]) => void;
  handleFileDeletion: (minioFileName: string) => void;
  setSaveAllowed: (isAllowed: boolean) => void;
  hasOther?: boolean;
}

export const ActivityLicenseDocuments = ({
  requiredFiles,
  optionalFiles,
  fileReferences,
  objectId,
  handleFileDeletion,
  onUploadSuccess,
  setSaveAllowed,
  hasOther = true
}: Props) => {
  const dispatch = useDispatch();

  const licenseAcquisition = useSelector((state: RootState) => state.licenseAcquisitionApplication);

  const [activeAttachmentType, setActiveAttachmentType] =
    useState<FileObjectType>();
  const [openModal, setOpenModal] = useState<FileObjectType>();

  useEffect(() => {
    const saveAllowed = requiredFiles
      .map((template) => template.fileObjectType)
      .every(
        (type) =>
          filterFilesByType(type as FileObjectType, fileReferences).length > 0
      );
    setSaveAllowed(saveAllowed);
  }, [requiredFiles, fileReferences, setSaveAllowed]);

  const filterOutDuplicateFileTypes = (requiredFiles: FileTemplate[], optionalFiles: FileTemplate[] ) => {
    const requiredFileTypes = new Set(requiredFiles.map(file => file.fileObjectType));
    const filteredOptionalFiles = optionalFiles.filter(file => !requiredFileTypes.has(file.fileObjectType));
    return filteredOptionalFiles;
  };

  const filteredOptionalFiles = filterOutDuplicateFileTypes(requiredFiles, optionalFiles ?? []);

  const handleSaveFiles = (type: FileObjectType, files: File[]) => {
    const fileNames = fileReferences?.map((f) => f.fileName);
    if (files.some((f) => fileNames?.includes(f.name))) {
      const alertMessage = <AlertMessage id="fileNameNotUnique" />;
      const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      return dispatch(alertActions.addAlert(alert));
    }
    saveFilesWithAlerts(files, type, objectId, dispatch, onUploadSuccess);
  };

  const handleFileDelete = (type: FileObjectType, fileName: string) => {
    let toDelete = fileReferences?.find(
      (file) => file.fileName === fileName && file.fileObjectType === type
    );
    if (toDelete && toDelete.minioFileName) {
      deleteFilesWithAlerts(
        toDelete.minioFileName,
        type,
        dispatch,
        handleFileDeletion
      );
    }
  };

  const filterFilesByType = (
    fileType: FileObjectType,
    references?: FileReference[]
  ): FileReference[] =>
    references?.filter((ref) => ref.fileObjectType === fileType) || [];

  const renderAttachmentContainer = (template: FileTemplate) => {
    if (!template || !template.fileObjectType) {
      return  (<></>);
    }
    const { fileObjectType } = template;
    return (
      <Fragment key={template.fileObjectType}>
        {template.templateUrl && (
          <>
            <div>
              <img src={question_mark} alt="info" />
              <LinkButton
                id="instructions"
                onClick={() => setOpenModal(fileObjectType)}
              />
            </div>
            <DocumentsInfoModal
              isOpen={openModal === fileObjectType}
              type={fileObjectType as FileObjectType}
              fileUrl={template.templateUrl}
              onClose={() => setOpenModal(undefined)}
            />
          </>
        )}
        <AttachmentContainer
          key={fileObjectType}
          onClick={setActiveAttachmentType}
          isActive={activeAttachmentType === fileObjectType}
          type={fileObjectType as FileObjectType}
          fileReferences={filterFilesByType(
            fileObjectType as FileObjectType,
            fileReferences
          )}
          maxFilesCount={10}
          saveFiles={(files) =>
            handleSaveFiles(fileObjectType as FileObjectType, files)
          }
          handleFileDelete={(fileName) =>
            handleFileDelete(fileObjectType as FileObjectType, fileName)
          }
        />
      </Fragment>
    );
  };

  return (
    <div className="file-container">
      {requiredFiles.map(renderAttachmentContainer)}
      {filteredOptionalFiles && filteredOptionalFiles.map(renderAttachmentContainer)}
      {hasOther &&
        renderAttachmentContainer({
          fileObjectType: FileObjectType.ActivityLicenseOther
        })}
      <div>
        <img
          src={info_icon}
          alt="icon"
          width="20px"
          height="20px"
          className="mr-2"
        />
        <span className="align-middle">
          <FormattedMessage
            id="ActivityLicenseDocuments.archiveInfo"
            defaultMessage="Rohkema kui 10 faili üleslaadimiseks tuleb failid lisada .zip arhiivina"
          />
        </span>
      </div>
    </div>
  );
};
