import { useMemo } from "react";
import moment from "moment";

import { WorkingDay } from "../Dto/GeneralPractitionerList/GeneralPractitionerLicense";
import {
  LocalTime,
  WorkingDayWorkingDayEnum
} from "../../api_client/medre_api";

type Moment = typeof moment;

export const utc = (...args: Parameters<Moment>) =>
  moment.utc(...args).startOf("day");
export const utcToLocal = (...args: Parameters<Moment>) =>
  moment(moment.utc(...args).toDate());

export const getPreviousDate = (...args: Parameters<Moment>) =>
  utc(...args)
    .add(-1, "days")
    .toDate();
export const getNextDate = (...args: Parameters<Moment>) =>
  utc(...args)
    .add(1, "days")
    .toDate();

export const isDateInFuture = (...args: Parameters<Moment>): boolean =>
  moment(...args).isAfter(utc().toDate(), "day");

export const isDateInPast = (...args: Parameters<Moment>): boolean =>
  moment(...args).isBefore(utc().toDate(), "day");

export const isDateToday = (...args: Parameters<Moment>): boolean =>
  moment(...args).isSame(utc().toDate(), "day");

const getDay = (index: number): string => {
  switch (index) {
    case 0:
      return WorkingDayWorkingDayEnum.Monday;
    case 1:
      return WorkingDayWorkingDayEnum.Tuesday;
    case 2:
      return WorkingDayWorkingDayEnum.Wednesday;
    case 3:
      return WorkingDayWorkingDayEnum.Thursday;
    case 4:
      return WorkingDayWorkingDayEnum.Friday;
    case 5:
      return WorkingDayWorkingDayEnum.Saturday;
    default:
      return WorkingDayWorkingDayEnum.Sunday;
  }
};

export const useToday = () => useMemo(() => utc().toDate(), []);
export const useWorkingDays = () =>
  useMemo(() => {
    const workingDaysMin = moment.weekdaysMin().slice(1, 6);
    const workingDays = moment.weekdays().slice(1, 6);
    return workingDaysMin.reduce<
      Record<WorkingDay, { min: string; full: string }>
    >(
      (acc, dayMin, index) => ({
        ...acc,
        [getDay(index)]: {
          min: dayMin.toUpperCase(),
          full: workingDays[index]
        }
      }),
      {} as Record<WorkingDay, { min: string; full: string }>
    );
  }, []);

/**
 * parseTime, formatTime, isTimeInFuture
 * @param time: string -> 09:59:00 from <input type="time" />
 */

export const parseTime = (time: string | LocalTime) =>
  time.valueOf() === "object"
    ? [(time as LocalTime).hour, (time as LocalTime).minute]
    : (time as string).split(":").slice(0, 2);

export const formatTime = (time: string | LocalTime) => {
  const [hh, mm] = parseTime(time);
  return `${hh}:${mm}`;
};

export const timeRangeOverlaps = (
  timeFrom: string | LocalTime,
  timeTo: string | LocalTime
) => {
  const [timeFromHH, timeFromMM] = parseTime(timeFrom);
  const [timeToHH, timeToMM] = parseTime(timeTo);
  return moment()
    .set({ hours: Number(timeFromHH), minutes: Number(timeFromMM) })
    .isBefore(
      moment().set({ hours: Number(timeToHH), minutes: Number(timeToMM) })
    );
};

export const dateToString = (date?: Date) => moment(date).format("YYYY-MM-DD");

export const momentUTCtoString = (date?: Date) =>
  date && moment.utc(date).startOf("day").format("YYYY-MM-DD");

export { utc as momentUTC };

export const getDateWithTime = (date: string) => moment(date).format("DD.MM.YY LT");

export const getFormattedDate = (date: string | Date) =>
  moment(date).format("DD.MM.YYYY");
