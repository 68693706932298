/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivityLicense
 */
export interface ActivityLicense {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicense
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicense
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicense
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicense
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicense
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicense
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicense
     */
    'status'?: ActivityLicenseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicense
     */
    'validUntil'?: string;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof ActivityLicense
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {Company}
     * @memberof ActivityLicense
     */
    'company'?: Company;
    /**
     * 
     * @type {ActivityLicenseBusinessArea}
     * @memberof ActivityLicense
     */
    'businessArea'?: ActivityLicenseBusinessArea;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof ActivityLicense
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof ActivityLicense
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicense
     */
    'invalidationApplicationId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicense
     */
    'acquisitionApplicationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicense
     */
    'suspensionApplicationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicense
     */
    'restorationApplicationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicense
     */
    'gpListApplicationIds'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicense
     */
    'totalSuspendedDays'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicense
     */
    'suspended'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicense
     */
    'mainPharmacyName'?: string;
}

export const ActivityLicenseStatusEnum = {
    Valid: 'VALID',
    Paused: 'PAUSED',
    Invalid: 'INVALID'
} as const;

export type ActivityLicenseStatusEnum = typeof ActivityLicenseStatusEnum[keyof typeof ActivityLicenseStatusEnum];

/**
 * 
 * @export
 * @interface ActivityLicenseApplication
 */
export interface ActivityLicenseApplication {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplication
     */
    'id'?: string;
    /**
     * 
     * @type {Company}
     * @memberof ActivityLicenseApplication
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplication
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplication
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplication
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplication
     */
    'submittingPersonRole'?: string;
    /**
     * 
     * @type {ActivityLicenseBusinessArea}
     * @memberof ActivityLicenseApplication
     */
    'businessArea'?: ActivityLicenseBusinessArea;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseApplication
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {StateFee}
     * @memberof ActivityLicenseApplication
     */
    'stateFee'?: StateFee;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseApplication
     */
    'currentStep'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseApplication
     */
    'changeServicesRequest'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseApplication
     */
    'migrated'?: boolean;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof ActivityLicenseApplication
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplication
     */
    'renewalNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplication
     */
    'arrivalTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplication
     */
    'entryTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplication
     */
    'channelType'?: ActivityLicenseApplicationChannelTypeEnum;
    /**
     * 
     * @type {Array<ActivityLicenseApplicationProceedingStatus>}
     * @memberof ActivityLicenseApplication
     */
    'statuses'?: Array<ActivityLicenseApplicationProceedingStatus>;
    /**
     * 
     * @type {ActivityLicenseApplicationProceedingStatus}
     * @memberof ActivityLicenseApplication
     */
    'currentStatus'?: ActivityLicenseApplicationProceedingStatus;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof ActivityLicenseApplication
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseApplication
     */
    'shouldValidate'?: boolean;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof ActivityLicenseApplication
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {ActivityLicense}
     * @memberof ActivityLicenseApplication
     */
    'activityLicense'?: ActivityLicense;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplication
     */
    'proceedingType'?: ActivityLicenseApplicationProceedingTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseApplication
     */
    'saveAllowed'?: boolean;
}

export const ActivityLicenseApplicationChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type ActivityLicenseApplicationChannelTypeEnum = typeof ActivityLicenseApplicationChannelTypeEnum[keyof typeof ActivityLicenseApplicationChannelTypeEnum];
export const ActivityLicenseApplicationProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type ActivityLicenseApplicationProceedingTypeEnum = typeof ActivityLicenseApplicationProceedingTypeEnum[keyof typeof ActivityLicenseApplicationProceedingTypeEnum];

/**
 * 
 * @export
 * @interface ActivityLicenseApplicationDTO
 */
export interface ActivityLicenseApplicationDTO {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplicationDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplicationDTO
     */
    'arrivalTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseApplicationDTO
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplicationDTO
     */
    'proceedingType'?: ActivityLicenseApplicationDTOProceedingTypeEnum;
    /**
     * 
     * @type {ActivityLicenseApplicationStatus}
     * @memberof ActivityLicenseApplicationDTO
     */
    'status'?: ActivityLicenseApplicationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseApplicationDTO
     */
    'stateFeeIsPaid'?: boolean;
}

export const ActivityLicenseApplicationDTOProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type ActivityLicenseApplicationDTOProceedingTypeEnum = typeof ActivityLicenseApplicationDTOProceedingTypeEnum[keyof typeof ActivityLicenseApplicationDTOProceedingTypeEnum];

/**
 * 
 * @export
 * @interface ActivityLicenseApplicationProceedingStatus
 */
export interface ActivityLicenseApplicationProceedingStatus {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplicationProceedingStatus
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplicationProceedingStatus
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplicationProceedingStatus
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplicationProceedingStatus
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseApplicationProceedingStatus
     */
    'id'?: string;
    /**
     * 
     * @type {ActivityLicenseApplicationStatus}
     * @memberof ActivityLicenseApplicationProceedingStatus
     */
    'status'?: ActivityLicenseApplicationStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityLicenseApplicationStatus = {
    Saved: 'SAVED',
    Registering: 'REGISTERING',
    Submitted: 'SUBMITTED',
    SubmittedByOfficial: 'SUBMITTED_BY_OFFICIAL',
    ProceedingPending: 'PROCEEDING_PENDING',
    InProceeding: 'IN_PROCEEDING',
    ProceedingReopened: 'PROCEEDING_REOPENED',
    Paused: 'PAUSED',
    OnSiteInspectionPending: 'ON_SITE_INSPECTION_PENDING',
    ProtocolInPreparation: 'PROTOCOL_IN_PREPARATION',
    ReadyForDecision: 'READY_FOR_DECISION',
    UploadAcceptedDecision: 'UPLOAD_ACCEPTED_DECISION',
    UploadDeclinedDecision: 'UPLOAD_DECLINED_DECISION',
    SignAcceptedDecision: 'SIGN_ACCEPTED_DECISION',
    SignDeclinedDecision: 'SIGN_DECLINED_DECISION',
    SignReopened: 'SIGN_REOPENED',
    Accepted: 'ACCEPTED',
    Declined: 'DECLINED',
    Finished: 'FINISHED',
    Deleted: 'DELETED'
} as const;

export type ActivityLicenseApplicationStatus = typeof ActivityLicenseApplicationStatus[keyof typeof ActivityLicenseApplicationStatus];


/**
 * 
 * @export
 * @interface ActivityLicenseBusinessArea
 */
export interface ActivityLicenseBusinessArea {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseBusinessArea
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseBusinessArea
     */
    'name'?: string;
    /**
     * 
     * @type {ActivityLicenseBusinessArea}
     * @memberof ActivityLicenseBusinessArea
     */
    'actualBusinessArea'?: ActivityLicenseBusinessArea;
    /**
     * 
     * @type {Array<FileTemplate>}
     * @memberof ActivityLicenseBusinessArea
     */
    'requiredFiles'?: Array<FileTemplate>;
    /**
     * 
     * @type {Array<BusinessAreaFileRequirement>}
     * @memberof ActivityLicenseBusinessArea
     */
    'businessAreaFileRequirements'?: Array<BusinessAreaFileRequirement>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseBusinessArea
     */
    'status'?: ActivityLicenseBusinessAreaStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseBusinessArea
     */
    'isHospital'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseBusinessArea
     */
    'businessAreaType'?: ActivityLicenseBusinessAreaBusinessAreaTypeEnum;
}

export const ActivityLicenseBusinessAreaStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Virtual: 'VIRTUAL'
} as const;

export type ActivityLicenseBusinessAreaStatusEnum = typeof ActivityLicenseBusinessAreaStatusEnum[keyof typeof ActivityLicenseBusinessAreaStatusEnum];
export const ActivityLicenseBusinessAreaBusinessAreaTypeEnum = {
    Regular: 'REGULAR',
    Specialist: 'SPECIALIST'
} as const;

export type ActivityLicenseBusinessAreaBusinessAreaTypeEnum = typeof ActivityLicenseBusinessAreaBusinessAreaTypeEnum[keyof typeof ActivityLicenseBusinessAreaBusinessAreaTypeEnum];

/**
 * 
 * @export
 * @interface ActivityLicenseBusinessAreaClassifiers
 */
export interface ActivityLicenseBusinessAreaClassifiers {
    /**
     * 
     * @type {Array<ActivityLicenseBusinessArea>}
     * @memberof ActivityLicenseBusinessAreaClassifiers
     */
    'hospitalTypeAreas'?: Array<ActivityLicenseBusinessArea>;
    /**
     * 
     * @type {Array<ActivityLicenseBusinessArea>}
     * @memberof ActivityLicenseBusinessAreaClassifiers
     */
    'regularAreas'?: Array<ActivityLicenseBusinessArea>;
}
/**
 * 
 * @export
 * @interface ActivityLicenseGPListApplication
 */
export interface ActivityLicenseGPListApplication {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseGPListApplication
     */
    'licenseId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseGPListApplication
     */
    'numberOfGpLists'?: number;
}
/**
 * 
 * @export
 * @interface ActivityLicenseInvalidation
 */
export interface ActivityLicenseInvalidation {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseInvalidation
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseInvalidation
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseInvalidation
     */
    'licenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseInvalidation
     */
    'licenseId'?: string;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof ActivityLicenseInvalidation
     */
    'status'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {Company}
     * @memberof ActivityLicenseInvalidation
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseInvalidation
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseInvalidation
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseInvalidation
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseInvalidation
     */
    'businessAreaName'?: string;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof ActivityLicenseInvalidation
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseInvalidation
     */
    'isHospital'?: boolean;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof ActivityLicenseInvalidation
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof ActivityLicenseInvalidation
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseInvalidation
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseInvalidation
     */
    'proceedingType'?: ActivityLicenseInvalidationProceedingTypeEnum;
}

export const ActivityLicenseInvalidationProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type ActivityLicenseInvalidationProceedingTypeEnum = typeof ActivityLicenseInvalidationProceedingTypeEnum[keyof typeof ActivityLicenseInvalidationProceedingTypeEnum];

/**
 * 
 * @export
 * @interface ActivityLicenseLite
 */
export interface ActivityLicenseLite {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseLite
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseLite
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseLite
     */
    'status'?: ActivityLicenseLiteStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseLite
     */
    'validUntil'?: string;
}

export const ActivityLicenseLiteStatusEnum = {
    Valid: 'VALID',
    Paused: 'PAUSED',
    Invalid: 'INVALID'
} as const;

export type ActivityLicenseLiteStatusEnum = typeof ActivityLicenseLiteStatusEnum[keyof typeof ActivityLicenseLiteStatusEnum];

/**
 * 
 * @export
 * @interface ActivityLicenseProceeding
 */
export interface ActivityLicenseProceeding {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'arrivalTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'applicationNumber'?: string;
    /**
     * 
     * @type {ActivityLicenseApplicationStatus}
     * @memberof ActivityLicenseProceeding
     */
    'applicationStatus'?: ActivityLicenseApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'proceedingType'?: ActivityLicenseProceedingProceedingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'proceedingDeadline'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseProceeding
     */
    'proceedingPausedDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'businessName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'businessArea'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'actualBusinessAreaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'actualBusinessAreaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'hospitalTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'dateOfIssue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'proceedingOfficialId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'signingOfficialId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseProceeding
     */
    'stateFeeIsPaid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceeding
     */
    'stateFeeDeadline'?: string;
}

export const ActivityLicenseProceedingProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type ActivityLicenseProceedingProceedingTypeEnum = typeof ActivityLicenseProceedingProceedingTypeEnum[keyof typeof ActivityLicenseProceedingProceedingTypeEnum];

/**
 * 
 * @export
 * @interface ActivityLicenseProceedingSearchFilter
 */
export interface ActivityLicenseProceedingSearchFilter {
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseProceedingSearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseProceedingSearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicenseProceedingSearchFilter
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceedingSearchFilter
     */
    'businessName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicenseProceedingSearchFilter
     */
    'businessAreaIds'?: Array<string>;
    /**
     * 
     * @type {Array<ActivityLicenseApplicationStatus>}
     * @memberof ActivityLicenseProceedingSearchFilter
     */
    'applicationStatuses'?: Array<ActivityLicenseApplicationStatus>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceedingSearchFilter
     */
    'proceedingOfficialId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseProceedingSearchFilter
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicenseProceedingSearchFilter
     */
    'hospitalTypeIds'?: Array<string>;
    /**
     * 
     * @type {DateRange}
     * @memberof ActivityLicenseProceedingSearchFilter
     */
    'submitDate'?: DateRange;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceedingSearchFilter
     */
    'submitDateRangeType'?: ActivityLicenseProceedingSearchFilterSubmitDateRangeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseProceedingSearchFilter
     */
    'proceedingType'?: ActivityLicenseProceedingSearchFilterProceedingTypeEnum;
}

export const ActivityLicenseProceedingSearchFilterSubmitDateRangeTypeEnum = {
    Today: 'TODAY',
    Yesterday: 'YESTERDAY',
    ThisWeek: 'THIS_WEEK',
    ThisMonth: 'THIS_MONTH',
    ThisYear: 'THIS_YEAR'
} as const;

export type ActivityLicenseProceedingSearchFilterSubmitDateRangeTypeEnum = typeof ActivityLicenseProceedingSearchFilterSubmitDateRangeTypeEnum[keyof typeof ActivityLicenseProceedingSearchFilterSubmitDateRangeTypeEnum];
export const ActivityLicenseProceedingSearchFilterProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type ActivityLicenseProceedingSearchFilterProceedingTypeEnum = typeof ActivityLicenseProceedingSearchFilterProceedingTypeEnum[keyof typeof ActivityLicenseProceedingSearchFilterProceedingTypeEnum];

/**
 * 
 * @export
 * @interface ActivityLicenseProceedingSearchResponse
 */
export interface ActivityLicenseProceedingSearchResponse {
    /**
     * 
     * @type {Array<ActivityLicenseProceeding>}
     * @memberof ActivityLicenseProceedingSearchResponse
     */
    'content'?: Array<ActivityLicenseProceeding>;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseProceedingSearchResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseProceedingSearchResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseProceedingSearchResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseProceedingSearchResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface ActivityLicenseRestoration
 */
export interface ActivityLicenseRestoration {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRestoration
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseRestoration
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRestoration
     */
    'licenseNumber'?: string;
    /**
     * 
     * @type {Company}
     * @memberof ActivityLicenseRestoration
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRestoration
     */
    'businessAreaName'?: string;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof ActivityLicenseRestoration
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseRestoration
     */
    'isHospital'?: boolean;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof ActivityLicenseRestoration
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof ActivityLicenseRestoration
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRestoration
     */
    'createdAt'?: string;
    /**
     * 
     * @type {RestorationStatus}
     * @memberof ActivityLicenseRestoration
     */
    'status'?: RestorationStatus;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRestoration
     */
    'type'?: ActivityLicenseRestorationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRestoration
     */
    'proceedingType'?: ActivityLicenseRestorationProceedingTypeEnum;
}

export const ActivityLicenseRestorationTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLocation: 'ACTIVITY_LOCATION',
    Service: 'SERVICE'
} as const;

export type ActivityLicenseRestorationTypeEnum = typeof ActivityLicenseRestorationTypeEnum[keyof typeof ActivityLicenseRestorationTypeEnum];
export const ActivityLicenseRestorationProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type ActivityLicenseRestorationProceedingTypeEnum = typeof ActivityLicenseRestorationProceedingTypeEnum[keyof typeof ActivityLicenseRestorationProceedingTypeEnum];

/**
 * 
 * @export
 * @interface ActivityLicenseRowHeader
 */
export interface ActivityLicenseRowHeader {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRowHeader
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRowHeader
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRowHeader
     */
    'status': ActivityLicenseRowHeaderStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRowHeader
     */
    'businessAreaName': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRowHeader
     */
    'businessAreaId': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRowHeader
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseRowHeader
     */
    'validUntil'?: string;
}

export const ActivityLicenseRowHeaderStatusEnum = {
    Valid: 'VALID',
    Paused: 'PAUSED',
    Invalid: 'INVALID'
} as const;

export type ActivityLicenseRowHeaderStatusEnum = typeof ActivityLicenseRowHeaderStatusEnum[keyof typeof ActivityLicenseRowHeaderStatusEnum];

/**
 * 
 * @export
 * @interface ActivityLicenseSearchFilter
 */
export interface ActivityLicenseSearchFilter {
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseSearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseSearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicenseSearchFilter
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSearchFilter
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSearchFilter
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSearchFilter
     */
    'serviceId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicenseSearchFilter
     */
    'serviceIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSearchFilter
     */
    'hospitalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSearchFilter
     */
    'status'?: ActivityLicenseSearchFilterStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicenseSearchFilter
     */
    'statuses'?: Array<ActivityLicenseSearchFilterStatusesEnum>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSearchFilter
     */
    'businessAreaId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicenseSearchFilter
     */
    'businessAreaIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSearchFilter
     */
    'countyId'?: string;
    /**
     * 
     * @type {Array<InsuranceContractStatus>}
     * @memberof ActivityLicenseSearchFilter
     */
    'insuranceContractStatuses'?: Array<InsuranceContractStatus>;
}

export const ActivityLicenseSearchFilterStatusEnum = {
    Valid: 'VALID',
    Paused: 'PAUSED',
    Invalid: 'INVALID'
} as const;

export type ActivityLicenseSearchFilterStatusEnum = typeof ActivityLicenseSearchFilterStatusEnum[keyof typeof ActivityLicenseSearchFilterStatusEnum];
export const ActivityLicenseSearchFilterStatusesEnum = {
    Valid: 'VALID',
    Paused: 'PAUSED',
    Invalid: 'INVALID'
} as const;

export type ActivityLicenseSearchFilterStatusesEnum = typeof ActivityLicenseSearchFilterStatusesEnum[keyof typeof ActivityLicenseSearchFilterStatusesEnum];

/**
 * 
 * @export
 * @interface ActivityLicenseSearchResponse
 */
export interface ActivityLicenseSearchResponse {
    /**
     * 
     * @type {Array<ActivityLicense>}
     * @memberof ActivityLicenseSearchResponse
     */
    'content'?: Array<ActivityLicense>;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseSearchResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseSearchResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseSearchResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseSearchResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface ActivityLicenseService
 */
export interface ActivityLicenseService {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseService
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseService
     */
    'serviceId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicenseService
     */
    'employeeIds'?: Array<string>;
    /**
     * 
     * @type {Array<HealthCareProfessional>}
     * @memberof ActivityLicenseService
     */
    'employees'?: Array<HealthCareProfessional>;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseService
     */
    'beds'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseService
     */
    'ambulanceBrigades'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseService
     */
    'numberOfGpLists'?: number;
    /**
     * 
     * @type {Service}
     * @memberof ActivityLicenseService
     */
    'service'?: Service;
    /**
     * 
     * @type {Array<ActivityLicenseServiceRoomDTO>}
     * @memberof ActivityLicenseService
     */
    'roomDTOs'?: Array<ActivityLicenseServiceRoomDTO>;
    /**
     * 
     * @type {Array<ServiceRoom>}
     * @memberof ActivityLicenseService
     */
    'rooms'?: Array<ServiceRoom>;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseService
     */
    'required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseService
     */
    'toBeDiscarded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseService
     */
    'serviceStatus'?: ActivityLicenseServiceServiceStatusEnum;
    /**
     * 
     * @type {Array<ServiceEmployee>}
     * @memberof ActivityLicenseService
     */
    'serviceEmployees'?: Array<ServiceEmployee>;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseService
     */
    'suspended'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseService
     */
    'mobileActivityLocationRegistryNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicenseService
     */
    'suspensionApplicationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLicenseService
     */
    'restorationApplicationIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseService
     */
    'discardServiceApplicationId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseService
     */
    'totalServiceSuspendedDays'?: number;
    /**
     * 
     * @type {Array<ServiceBrigade>}
     * @memberof ActivityLicenseService
     */
    'brigades'?: Array<ServiceBrigade>;
}

export const ActivityLicenseServiceServiceStatusEnum = {
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED',
    Discarded: 'DISCARDED'
} as const;

export type ActivityLicenseServiceServiceStatusEnum = typeof ActivityLicenseServiceServiceStatusEnum[keyof typeof ActivityLicenseServiceServiceStatusEnum];

/**
 * 
 * @export
 * @interface ActivityLicenseServiceRoomDTO
 */
export interface ActivityLicenseServiceRoomDTO {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseServiceRoomDTO
     */
    'roomId': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseServiceRoomDTO
     */
    'number': string;
}
/**
 * 
 * @export
 * @interface ActivityLicenseSuspension
 */
export interface ActivityLicenseSuspension {
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseSuspension
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'suspensionReason'?: ActivityLicenseSuspensionSuspensionReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityLicenseSuspension
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'explanationNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'suspensionType'?: ActivityLicenseSuspensionSuspensionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'channelType'?: ActivityLicenseSuspensionChannelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'activityLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'activityLicenseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'submissionTime'?: string;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof ActivityLicenseSuspension
     */
    'currentStatus'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'businessArea'?: string;
    /**
     * 
     * @type {Company}
     * @memberof ActivityLicenseSuspension
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof ActivityLicenseSuspension
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLicenseSuspension
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof ActivityLicenseSuspension
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLicenseSuspension
     */
    'isHospital'?: boolean;
}

export const ActivityLicenseSuspensionSuspensionReasonEnum = {
    Personnel: 'PERSONNEL',
    Rooms: 'ROOMS'
} as const;

export type ActivityLicenseSuspensionSuspensionReasonEnum = typeof ActivityLicenseSuspensionSuspensionReasonEnum[keyof typeof ActivityLicenseSuspensionSuspensionReasonEnum];
export const ActivityLicenseSuspensionSuspensionTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type ActivityLicenseSuspensionSuspensionTypeEnum = typeof ActivityLicenseSuspensionSuspensionTypeEnum[keyof typeof ActivityLicenseSuspensionSuspensionTypeEnum];
export const ActivityLicenseSuspensionChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type ActivityLicenseSuspensionChannelTypeEnum = typeof ActivityLicenseSuspensionChannelTypeEnum[keyof typeof ActivityLicenseSuspensionChannelTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityLicenseSuspensionStatus = {
    Submitted: 'SUBMITTED',
    SubmittedByOfficial: 'SUBMITTED_BY_OFFICIAL',
    ProceedingPending: 'PROCEEDING_PENDING',
    InProceeding: 'IN_PROCEEDING',
    UploadAcceptedDecision: 'UPLOAD_ACCEPTED_DECISION',
    SignAcceptedDecision: 'SIGN_ACCEPTED_DECISION',
    SignReopened: 'SIGN_REOPENED',
    Accepted: 'ACCEPTED',
    Finished: 'FINISHED',
    Deleted: 'DELETED'
} as const;

export type ActivityLicenseSuspensionStatus = typeof ActivityLicenseSuspensionStatus[keyof typeof ActivityLicenseSuspensionStatus];


/**
 * 
 * @export
 * @interface ActivityLocation
 */
export interface ActivityLocation {
    /**
     * 
     * @type {string}
     * @memberof ActivityLocation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocation
     */
    'fullAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocation
     */
    'ehakmk'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocation
     */
    'ehakov'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocation
     */
    'ehak'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocation
     */
    'adsAdrID'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocation
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocation
     */
    'adrCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocation
     */
    'status'?: ActivityLocationStatusEnum;
    /**
     * 
     * @type {Array<ActivityLicenseService>}
     * @memberof ActivityLocation
     */
    'services'?: Array<ActivityLicenseService>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocation
     */
    'invalidationApplicationId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLocation
     */
    'suspensionApplicationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityLocation
     */
    'restorationApplicationIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLocation
     */
    'suspended'?: boolean;
}

export const ActivityLocationStatusEnum = {
    Valid: 'VALID',
    Paused: 'PAUSED',
    Invalid: 'INVALID'
} as const;

export type ActivityLocationStatusEnum = typeof ActivityLocationStatusEnum[keyof typeof ActivityLocationStatusEnum];

/**
 * 
 * @export
 * @interface ActivityLocationInvalidation
 */
export interface ActivityLocationInvalidation {
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationInvalidation
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityLocationInvalidation
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationInvalidation
     */
    'licenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationInvalidation
     */
    'licenseId'?: string;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof ActivityLocationInvalidation
     */
    'status'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {Company}
     * @memberof ActivityLocationInvalidation
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationInvalidation
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationInvalidation
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationInvalidation
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationInvalidation
     */
    'businessAreaName'?: string;
    /**
     * 
     * @type {ActivityLocation}
     * @memberof ActivityLocationInvalidation
     */
    'location'?: ActivityLocation;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLocationInvalidation
     */
    'isHospital'?: boolean;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof ActivityLocationInvalidation
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof ActivityLocationInvalidation
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationInvalidation
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof ActivityLocationInvalidation
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationInvalidation
     */
    'proceedingType'?: ActivityLocationInvalidationProceedingTypeEnum;
}

export const ActivityLocationInvalidationProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type ActivityLocationInvalidationProceedingTypeEnum = typeof ActivityLocationInvalidationProceedingTypeEnum[keyof typeof ActivityLocationInvalidationProceedingTypeEnum];

/**
 * 
 * @export
 * @interface ActivityLocationSuspension
 */
export interface ActivityLocationSuspension {
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityLocationSuspension
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'suspensionReason'?: ActivityLocationSuspensionSuspensionReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityLocationSuspension
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'explanationNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'suspensionType'?: ActivityLocationSuspensionSuspensionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'channelType'?: ActivityLocationSuspensionChannelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'activityLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'activityLicenseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'submissionTime'?: string;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof ActivityLocationSuspension
     */
    'currentStatus'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'businessArea'?: string;
    /**
     * 
     * @type {Company}
     * @memberof ActivityLocationSuspension
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof ActivityLocationSuspension
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof ActivityLocationSuspension
     */
    'createdAt'?: string;
    /**
     * 
     * @type {ActivityLocation}
     * @memberof ActivityLocationSuspension
     */
    'location'?: ActivityLocation;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof ActivityLocationSuspension
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityLocationSuspension
     */
    'isHospital'?: boolean;
}

export const ActivityLocationSuspensionSuspensionReasonEnum = {
    Personnel: 'PERSONNEL',
    Rooms: 'ROOMS'
} as const;

export type ActivityLocationSuspensionSuspensionReasonEnum = typeof ActivityLocationSuspensionSuspensionReasonEnum[keyof typeof ActivityLocationSuspensionSuspensionReasonEnum];
export const ActivityLocationSuspensionSuspensionTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type ActivityLocationSuspensionSuspensionTypeEnum = typeof ActivityLocationSuspensionSuspensionTypeEnum[keyof typeof ActivityLocationSuspensionSuspensionTypeEnum];
export const ActivityLocationSuspensionChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type ActivityLocationSuspensionChannelTypeEnum = typeof ActivityLocationSuspensionChannelTypeEnum[keyof typeof ActivityLocationSuspensionChannelTypeEnum];

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {Country}
     * @memberof Address
     */
    'country'?: Country;
    /**
     * 
     * @type {County}
     * @memberof Address
     */
    'county'?: County;
    /**
     * 
     * @type {Parish}
     * @memberof Address
     */
    'parish'?: Parish;
    /**
     * 
     * @type {Settlement}
     * @memberof Address
     */
    'settlement'?: Settlement;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'adsAdrID'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'adsOid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'fullAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'adrCode'?: string;
}
/**
 * 
 * @export
 * @interface AddressPublic
 */
export interface AddressPublic {
    /**
     * 
     * @type {CountryPublic}
     * @memberof AddressPublic
     */
    'country'?: CountryPublic;
    /**
     * 
     * @type {CountyPublic}
     * @memberof AddressPublic
     */
    'county'?: CountyPublic;
    /**
     * 
     * @type {ParishPublic}
     * @memberof AddressPublic
     */
    'parish'?: ParishPublic;
    /**
     * 
     * @type {SettlementPublic}
     * @memberof AddressPublic
     */
    'settlement'?: SettlementPublic;
    /**
     * 
     * @type {string}
     * @memberof AddressPublic
     */
    'adsAdrID'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPublic
     */
    'adsOid'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPublic
     */
    'fullAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPublic
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPublic
     */
    'adrCode'?: string;
}
/**
 * 
 * @export
 * @interface AllowedServiceArea
 */
export interface AllowedServiceArea {
    /**
     * 
     * @type {string}
     * @memberof AllowedServiceArea
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AllowedServiceArea
     */
    'value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AllowedServiceArea
     */
    'deleteOnly'?: boolean;
}
/**
 * 
 * @export
 * @interface Application
 */
export interface Application {
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'type'?: ApplicationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'personId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'personName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Application
     */
    'competenceExam'?: boolean;
    /**
     * 
     * @type {Occupation}
     * @memberof Application
     */
    'occupation'?: Occupation;
    /**
     * 
     * @type {Occupation}
     * @memberof Application
     */
    'additionalOccupation'?: Occupation;
    /**
     * 
     * @type {Speciality}
     * @memberof Application
     */
    'speciality'?: Speciality;
    /**
     * 
     * @type {Array<ProceedingStatus>}
     * @memberof Application
     */
    'statuses'?: Array<ProceedingStatus>;
    /**
     * 
     * @type {ProceedingStatus}
     * @memberof Application
     */
    'currentStatus'?: ProceedingStatus;
    /**
     * 
     * @type {EducationInfo}
     * @memberof Application
     */
    'educationInfo'?: EducationInfo;
    /**
     * 
     * @type {ExamInfo}
     * @memberof Application
     */
    'complianceExam'?: ExamInfo;
    /**
     * 
     * @type {ExamInfo}
     * @memberof Application
     */
    'aptitudeTest'?: ExamInfo;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'arrivalTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'entryTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'channelType'?: ApplicationChannelTypeEnum;
    /**
     * 
     * @type {StateFee}
     * @memberof Application
     */
    'stateFee'?: StateFee;
    /**
     * 
     * @type {boolean}
     * @memberof Application
     */
    'automaticProceeding'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'renewalNotes'?: string;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof Application
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'proceedingStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'proceedingEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'proceedingDeadline'?: string;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof Application
     */
    'decisions'?: Array<Decision>;
}

export const ApplicationTypeEnum = {
    Occupation: 'OCCUPATION',
    Specialization: 'SPECIALIZATION',
    ApplicationCertificate: 'APPLICATION_CERTIFICATE'
} as const;

export type ApplicationTypeEnum = typeof ApplicationTypeEnum[keyof typeof ApplicationTypeEnum];
export const ApplicationChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type ApplicationChannelTypeEnum = typeof ApplicationChannelTypeEnum[keyof typeof ApplicationChannelTypeEnum];

/**
 * 
 * @export
 * @interface ApplicationCertificate
 */
export interface ApplicationCertificate {
    /**
     * 
     * @type {string}
     * @memberof ApplicationCertificate
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationCertificate
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationCertificate
     */
    'currentStep'?: number;
    /**
     * 
     * @type {Array<Occupation>}
     * @memberof ApplicationCertificate
     */
    'occupations'?: Array<Occupation>;
    /**
     * 
     * @type {Speciality}
     * @memberof ApplicationCertificate
     */
    'speciality'?: Speciality;
    /**
     * 
     * @type {Array<Country>}
     * @memberof ApplicationCertificate
     */
    'countries'?: Array<Country>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationCertificate
     */
    'education'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationCertificate
     */
    'deliveryType'?: ApplicationCertificateDeliveryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationCertificate
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationCertificate
     */
    'personId'?: string;
    /**
     * 
     * @type {ApplicationStatusWrapper}
     * @memberof ApplicationCertificate
     */
    'currentStatus'?: ApplicationStatusWrapper;
    /**
     * 
     * @type {StateFee}
     * @memberof ApplicationCertificate
     */
    'stateFee'?: StateFee;
    /**
     * 
     * @type {string}
     * @memberof ApplicationCertificate
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationCertificate
     */
    'arrivalTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationCertificate
     */
    'deliveryAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationCertificate
     */
    'type'?: ApplicationCertificateTypeEnum;
}

export const ApplicationCertificateDeliveryTypeEnum = {
    TakeMyself: 'TAKE_MYSELF',
    Post: 'POST',
    Electronic: 'ELECTRONIC'
} as const;

export type ApplicationCertificateDeliveryTypeEnum = typeof ApplicationCertificateDeliveryTypeEnum[keyof typeof ApplicationCertificateDeliveryTypeEnum];
export const ApplicationCertificateTypeEnum = {
    Occupation: 'OCCUPATION',
    Specialization: 'SPECIALIZATION',
    ApplicationCertificate: 'APPLICATION_CERTIFICATE'
} as const;

export type ApplicationCertificateTypeEnum = typeof ApplicationCertificateTypeEnum[keyof typeof ApplicationCertificateTypeEnum];

/**
 * 
 * @export
 * @interface ApplicationDraft
 */
export interface ApplicationDraft {
    /**
     * 
     * @type {string}
     * @memberof ApplicationDraft
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationDraft
     */
    'currentStep'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDraft
     */
    'type'?: ApplicationDraftTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDraft
     */
    'personId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDraft
     */
    'channelType'?: ApplicationDraftChannelTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationDraft
     */
    'competenceExam'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDraft
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {Occupation}
     * @memberof ApplicationDraft
     */
    'occupation'?: Occupation;
    /**
     * 
     * @type {Occupation}
     * @memberof ApplicationDraft
     */
    'additionalOccupation'?: Occupation;
    /**
     * 
     * @type {Speciality}
     * @memberof ApplicationDraft
     */
    'speciality'?: Speciality;
    /**
     * 
     * @type {number}
     * @memberof ApplicationDraft
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {EducationInfo}
     * @memberof ApplicationDraft
     */
    'educationInfo'?: EducationInfo;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof ApplicationDraft
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationDraft
     */
    'saveAllowed'?: boolean;
}

export const ApplicationDraftTypeEnum = {
    Occupation: 'OCCUPATION',
    Specialization: 'SPECIALIZATION',
    ApplicationCertificate: 'APPLICATION_CERTIFICATE'
} as const;

export type ApplicationDraftTypeEnum = typeof ApplicationDraftTypeEnum[keyof typeof ApplicationDraftTypeEnum];
export const ApplicationDraftChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type ApplicationDraftChannelTypeEnum = typeof ApplicationDraftChannelTypeEnum[keyof typeof ApplicationDraftChannelTypeEnum];

/**
 * 
 * @export
 * @interface ApplicationNote
 */
export interface ApplicationNote {
    /**
     * 
     * @type {string}
     * @memberof ApplicationNote
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationNote
     */
    'noteText'?: string;
}
/**
 * 
 * @export
 * @interface ApplicationOfficialRelationDto
 */
export interface ApplicationOfficialRelationDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationOfficialRelationDto
     */
    'applicationId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOfficialRelationDto
     */
    'proceedingType': ApplicationOfficialRelationDtoProceedingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOfficialRelationDto
     */
    'relationType': ApplicationOfficialRelationDtoRelationTypeEnum;
}

export const ApplicationOfficialRelationDtoProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type ApplicationOfficialRelationDtoProceedingTypeEnum = typeof ApplicationOfficialRelationDtoProceedingTypeEnum[keyof typeof ApplicationOfficialRelationDtoProceedingTypeEnum];
export const ApplicationOfficialRelationDtoRelationTypeEnum = {
    Hidden: 'HIDDEN'
} as const;

export type ApplicationOfficialRelationDtoRelationTypeEnum = typeof ApplicationOfficialRelationDtoRelationTypeEnum[keyof typeof ApplicationOfficialRelationDtoRelationTypeEnum];

/**
 * 
 * @export
 * @interface ApplicationSearch
 */
export interface ApplicationSearch {
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearch
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearch
     */
    'personId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearch
     */
    'type'?: ApplicationSearchTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearch
     */
    'occupationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearch
     */
    'additionalOccupationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearch
     */
    'specialityName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationSearch
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearch
     */
    'currentStatus'?: ApplicationSearchCurrentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearch
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearch
     */
    'arrivalTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSearch
     */
    'stateFeePaid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearch
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearch
     */
    'lastName'?: string;
}

export const ApplicationSearchTypeEnum = {
    Occupation: 'OCCUPATION',
    Specialization: 'SPECIALIZATION',
    ApplicationCertificate: 'APPLICATION_CERTIFICATE'
} as const;

export type ApplicationSearchTypeEnum = typeof ApplicationSearchTypeEnum[keyof typeof ApplicationSearchTypeEnum];
export const ApplicationSearchCurrentStatusEnum = {
    Saved: 'SAVED',
    Registering: 'REGISTERING',
    Submitted: 'SUBMITTED',
    InProceeding: 'IN_PROCEEDING',
    Paused: 'PAUSED',
    PausedDueToComplianceExam: 'PAUSED_DUE_TO_COMPLIANCE_EXAM',
    PausedDueToAptitudeTest: 'PAUSED_DUE_TO_APTITUDE_TEST',
    UploadAcceptedDecision: 'UPLOAD_ACCEPTED_DECISION',
    UploadDeclinedDecision: 'UPLOAD_DECLINED_DECISION',
    UploadAptitudeTestDecision: 'UPLOAD_APTITUDE_TEST_DECISION',
    UploadAptitudeTestResult: 'UPLOAD_APTITUDE_TEST_RESULT',
    UploadComplianceExamDecision: 'UPLOAD_COMPLIANCE_EXAM_DECISION',
    UploadComplianceExamResult: 'UPLOAD_COMPLIANCE_EXAM_RESULT',
    SignAcceptedDecision: 'SIGN_ACCEPTED_DECISION',
    SignDeclinedDecision: 'SIGN_DECLINED_DECISION',
    SignComplianceExamDecision: 'SIGN_COMPLIANCE_EXAM_DECISION',
    SignAptitudeTestDecision: 'SIGN_APTITUDE_TEST_DECISION',
    SignComplianceExamResult: 'SIGN_COMPLIANCE_EXAM_RESULT',
    SignAptitudeTestResult: 'SIGN_APTITUDE_TEST_RESULT',
    Accepted: 'ACCEPTED',
    Declined: 'DECLINED',
    Finished: 'FINISHED',
    Deleted: 'DELETED'
} as const;

export type ApplicationSearchCurrentStatusEnum = typeof ApplicationSearchCurrentStatusEnum[keyof typeof ApplicationSearchCurrentStatusEnum];

/**
 * 
 * @export
 * @interface ApplicationSearchFilter
 */
export interface ApplicationSearchFilter {
    /**
     * 
     * @type {number}
     * @memberof ApplicationSearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationSearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationSearchFilter
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationSearchFilter
     */
    'occupationNames'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationSearchFilter
     */
    'specialityCodes'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ApplicationSearchFilter
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationSearchFilter
     */
    'currentStatuses'?: Array<ApplicationSearchFilterCurrentStatusesEnum>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchFilter
     */
    'educationCountryGroup'?: ApplicationSearchFilterEducationCountryGroupEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationSearchFilter
     */
    'educationCountries'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSearchFilter
     */
    'stateFeePaid'?: boolean;
    /**
     * 
     * @type {DateRange}
     * @memberof ApplicationSearchFilter
     */
    'submitDate'?: DateRange;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchFilter
     */
    'decisionNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSearchFilter
     */
    'acceptedAutomatically'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationSearchFilter
     */
    'decisionStatuses'?: Array<ApplicationSearchFilterDecisionStatusesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSearchFilter
     */
    'onlyApplicationCertificate'?: boolean;
}

export const ApplicationSearchFilterCurrentStatusesEnum = {
    Saved: 'SAVED',
    Registering: 'REGISTERING',
    Submitted: 'SUBMITTED',
    InProceeding: 'IN_PROCEEDING',
    Paused: 'PAUSED',
    PausedDueToComplianceExam: 'PAUSED_DUE_TO_COMPLIANCE_EXAM',
    PausedDueToAptitudeTest: 'PAUSED_DUE_TO_APTITUDE_TEST',
    UploadAcceptedDecision: 'UPLOAD_ACCEPTED_DECISION',
    UploadDeclinedDecision: 'UPLOAD_DECLINED_DECISION',
    UploadAptitudeTestDecision: 'UPLOAD_APTITUDE_TEST_DECISION',
    UploadAptitudeTestResult: 'UPLOAD_APTITUDE_TEST_RESULT',
    UploadComplianceExamDecision: 'UPLOAD_COMPLIANCE_EXAM_DECISION',
    UploadComplianceExamResult: 'UPLOAD_COMPLIANCE_EXAM_RESULT',
    SignAcceptedDecision: 'SIGN_ACCEPTED_DECISION',
    SignDeclinedDecision: 'SIGN_DECLINED_DECISION',
    SignComplianceExamDecision: 'SIGN_COMPLIANCE_EXAM_DECISION',
    SignAptitudeTestDecision: 'SIGN_APTITUDE_TEST_DECISION',
    SignComplianceExamResult: 'SIGN_COMPLIANCE_EXAM_RESULT',
    SignAptitudeTestResult: 'SIGN_APTITUDE_TEST_RESULT',
    Accepted: 'ACCEPTED',
    Declined: 'DECLINED',
    Finished: 'FINISHED',
    Deleted: 'DELETED'
} as const;

export type ApplicationSearchFilterCurrentStatusesEnum = typeof ApplicationSearchFilterCurrentStatusesEnum[keyof typeof ApplicationSearchFilterCurrentStatusesEnum];
export const ApplicationSearchFilterEducationCountryGroupEnum = {
    Estonia: 'ESTONIA',
    Eca: 'ECA',
    Third: 'THIRD'
} as const;

export type ApplicationSearchFilterEducationCountryGroupEnum = typeof ApplicationSearchFilterEducationCountryGroupEnum[keyof typeof ApplicationSearchFilterEducationCountryGroupEnum];
export const ApplicationSearchFilterDecisionStatusesEnum = {
    Accepted: 'ACCEPTED',
    Declined: 'DECLINED',
    ComplianceExam: 'COMPLIANCE_EXAM',
    AptitudeTest: 'APTITUDE_TEST',
    AcceptedAfterComplianceExam: 'ACCEPTED_AFTER_COMPLIANCE_EXAM',
    AcceptedAfterAptitudeTest: 'ACCEPTED_AFTER_APTITUDE_TEST',
    WaitingForSignature: 'WAITING_FOR_SIGNATURE'
} as const;

export type ApplicationSearchFilterDecisionStatusesEnum = typeof ApplicationSearchFilterDecisionStatusesEnum[keyof typeof ApplicationSearchFilterDecisionStatusesEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const ApplicationStatus = {
    Saved: 'SAVED',
    Registering: 'REGISTERING',
    Submitted: 'SUBMITTED',
    InProceeding: 'IN_PROCEEDING',
    Paused: 'PAUSED',
    PausedDueToComplianceExam: 'PAUSED_DUE_TO_COMPLIANCE_EXAM',
    PausedDueToAptitudeTest: 'PAUSED_DUE_TO_APTITUDE_TEST',
    UploadAcceptedDecision: 'UPLOAD_ACCEPTED_DECISION',
    UploadDeclinedDecision: 'UPLOAD_DECLINED_DECISION',
    UploadAptitudeTestDecision: 'UPLOAD_APTITUDE_TEST_DECISION',
    UploadAptitudeTestResult: 'UPLOAD_APTITUDE_TEST_RESULT',
    UploadComplianceExamDecision: 'UPLOAD_COMPLIANCE_EXAM_DECISION',
    UploadComplianceExamResult: 'UPLOAD_COMPLIANCE_EXAM_RESULT',
    SignAcceptedDecision: 'SIGN_ACCEPTED_DECISION',
    SignDeclinedDecision: 'SIGN_DECLINED_DECISION',
    SignComplianceExamDecision: 'SIGN_COMPLIANCE_EXAM_DECISION',
    SignAptitudeTestDecision: 'SIGN_APTITUDE_TEST_DECISION',
    SignComplianceExamResult: 'SIGN_COMPLIANCE_EXAM_RESULT',
    SignAptitudeTestResult: 'SIGN_APTITUDE_TEST_RESULT',
    Accepted: 'ACCEPTED',
    Declined: 'DECLINED',
    Finished: 'FINISHED',
    Deleted: 'DELETED'
} as const;

export type ApplicationStatus = typeof ApplicationStatus[keyof typeof ApplicationStatus];


/**
 * 
 * @export
 * @interface ApplicationStatusWrapper
 */
export interface ApplicationStatusWrapper {
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatusWrapper
     */
    'status'?: ApplicationStatusWrapperStatusEnum;
}

export const ApplicationStatusWrapperStatusEnum = {
    Saved: 'SAVED',
    Registering: 'REGISTERING',
    Submitted: 'SUBMITTED',
    InProceeding: 'IN_PROCEEDING',
    Paused: 'PAUSED',
    PausedDueToComplianceExam: 'PAUSED_DUE_TO_COMPLIANCE_EXAM',
    PausedDueToAptitudeTest: 'PAUSED_DUE_TO_APTITUDE_TEST',
    UploadAcceptedDecision: 'UPLOAD_ACCEPTED_DECISION',
    UploadDeclinedDecision: 'UPLOAD_DECLINED_DECISION',
    UploadAptitudeTestDecision: 'UPLOAD_APTITUDE_TEST_DECISION',
    UploadAptitudeTestResult: 'UPLOAD_APTITUDE_TEST_RESULT',
    UploadComplianceExamDecision: 'UPLOAD_COMPLIANCE_EXAM_DECISION',
    UploadComplianceExamResult: 'UPLOAD_COMPLIANCE_EXAM_RESULT',
    SignAcceptedDecision: 'SIGN_ACCEPTED_DECISION',
    SignDeclinedDecision: 'SIGN_DECLINED_DECISION',
    SignComplianceExamDecision: 'SIGN_COMPLIANCE_EXAM_DECISION',
    SignAptitudeTestDecision: 'SIGN_APTITUDE_TEST_DECISION',
    SignComplianceExamResult: 'SIGN_COMPLIANCE_EXAM_RESULT',
    SignAptitudeTestResult: 'SIGN_APTITUDE_TEST_RESULT',
    Accepted: 'ACCEPTED',
    Declined: 'DECLINED',
    Finished: 'FINISHED',
    Deleted: 'DELETED'
} as const;

export type ApplicationStatusWrapperStatusEnum = typeof ApplicationStatusWrapperStatusEnum[keyof typeof ApplicationStatusWrapperStatusEnum];

/**
 * 
 * @export
 * @interface AppliedApplications
 */
export interface AppliedApplications {
    /**
     * 
     * @type {Array<Occupation>}
     * @memberof AppliedApplications
     */
    'possessedOccupations'?: Array<Occupation>;
    /**
     * 
     * @type {Array<Occupation>}
     * @memberof AppliedApplications
     */
    'savedOccupations'?: Array<Occupation>;
    /**
     * 
     * @type {Array<Occupation>}
     * @memberof AppliedApplications
     */
    'appliedOccupations'?: Array<Occupation>;
    /**
     * 
     * @type {Array<Speciality>}
     * @memberof AppliedApplications
     */
    'possessedSpecialities'?: Array<Speciality>;
    /**
     * 
     * @type {Array<Speciality>}
     * @memberof AppliedApplications
     */
    'savedSpecialities'?: Array<Speciality>;
    /**
     * 
     * @type {Array<Speciality>}
     * @memberof AppliedApplications
     */
    'appliedSpecialities'?: Array<Speciality>;
}
/**
 * 
 * @export
 * @interface AssignDecisionToSignerRequest
 */
export interface AssignDecisionToSignerRequest {
    /**
     * 
     * @type {OfficialUser}
     * @memberof AssignDecisionToSignerRequest
     */
    'signer': OfficialUser;
    /**
     * 
     * @type {any}
     * @memberof AssignDecisionToSignerRequest
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface AuditLogItem
 */
export interface AuditLogItem {
    /**
     * 
     * @type {string}
     * @memberof AuditLogItem
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogItem
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogItem
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogItem
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogItem
     */
    'actorType'?: AuditLogItemActorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditLogItem
     */
    'actionType'?: AuditLogItemActionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditLogItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogItem
     */
    'dataObjectType'?: AuditLogItemDataObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditLogItem
     */
    'dataObjectValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogItem
     */
    'dataObjectId'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AuditLogItem
     */
    'metadata'?: { [key: string]: string; };
}

export const AuditLogItemActorTypeEnum = {
    Person: 'PERSON',
    Official: 'OFFICIAL',
    System: 'SYSTEM',
    Public: 'PUBLIC'
} as const;

export type AuditLogItemActorTypeEnum = typeof AuditLogItemActorTypeEnum[keyof typeof AuditLogItemActorTypeEnum];
export const AuditLogItemActionTypeEnum = {
    Create: 'CREATE',
    Update: 'UPDATE',
    Delete: 'DELETE',
    UpdateEducation: 'UPDATE_EDUCATION',
    ChangeStatus: 'CHANGE_STATUS',
    ForwardToOfficial: 'FORWARD_TO_OFFICIAL',
    UpdateGpListPatientsSize: 'UPDATE_GP_LIST_PATIENTS_SIZE',
    UpdateGpListServiceArea: 'UPDATE_GP_LIST_SERVICE_AREA',
    UpdateGpListGeneralPractitioner: 'UPDATE_GP_LIST_GENERAL_PRACTITIONER',
    UpdateGpListLicense: 'UPDATE_GP_LIST_LICENSE',
    UpdateGpListLicenseAddress: 'UPDATE_GP_LIST_LICENSE_ADDRESS',
    UpdateGpListSubstitute: 'UPDATE_GP_LIST_SUBSTITUTE',
    UpdateGpListEmployee: 'UPDATE_GP_LIST_EMPLOYEE',
    UpdateGpListSubstituteDoctor: 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR',
    UpdateGpListSubstituteDoctorPeriod: 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR_PERIOD',
    AddingSpeciality: 'ADDING_SPECIALITY',
    UpdateActivityLicensePharmacyOwner: 'UPDATE_ACTIVITY_LICENSE_PHARMACY_OWNER'
} as const;

export type AuditLogItemActionTypeEnum = typeof AuditLogItemActionTypeEnum[keyof typeof AuditLogItemActionTypeEnum];
export const AuditLogItemDataObjectTypeEnum = {
    Application: 'APPLICATION',
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    GeneralPractitionerList: 'GENERAL_PRACTITIONER_LIST',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    DiscardService: 'DISCARD_SERVICE',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    GeneralPractitionerListApplication: 'GENERAL_PRACTITIONER_LIST_APPLICATION',
    Speciality: 'SPECIALITY',
    ActivityLicenseOwner: 'ACTIVITY_LICENSE_OWNER'
} as const;

export type AuditLogItemDataObjectTypeEnum = typeof AuditLogItemDataObjectTypeEnum[keyof typeof AuditLogItemDataObjectTypeEnum];

/**
 * 
 * @export
 * @interface AuthConfig
 */
export interface AuthConfig {
    /**
     * 
     * @type {string}
     * @memberof AuthConfig
     */
    'issuer'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfig
     */
    'authorizationEndpoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfig
     */
    'tokenEndpoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfig
     */
    'logoutEndpoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfig
     */
    'portalClientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfig
     */
    'portalRedirectUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfig
     */
    'officeClientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfig
     */
    'officePortalRedirectUrl'?: string;
}
/**
 * 
 * @export
 * @interface Bank
 */
export interface Bank {
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    'bic': string;
}
/**
 * 
 * @export
 * @interface BusinessAreaFileRequirement
 */
export interface BusinessAreaFileRequirement {
    /**
     * 
     * @type {string}
     * @memberof BusinessAreaFileRequirement
     */
    'id': string;
    /**
     * 
     * @type {FileTemplate}
     * @memberof BusinessAreaFileRequirement
     */
    'fileTemplate': FileTemplate;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessAreaFileRequirement
     */
    'requiredForMobileLocation': boolean;
}
/**
 * 
 * @export
 * @interface BusinessAreaServiceRequirement
 */
export interface BusinessAreaServiceRequirement {
    /**
     * 
     * @type {string}
     * @memberof BusinessAreaServiceRequirement
     */
    'id': string;
    /**
     * 
     * @type {Array<Service>}
     * @memberof BusinessAreaServiceRequirement
     */
    'requiredServices': Array<Service>;
    /**
     * 
     * @type {Array<Service>}
     * @memberof BusinessAreaServiceRequirement
     */
    'optionalServices': Array<Service>;
}
/**
 * 
 * @export
 * @interface BusinessInfo
 */
export interface BusinessInfo {
    /**
     * 
     * @type {string}
     * @memberof BusinessInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessInfo
     */
    'registryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessInfo
     */
    'personRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessInfo
     */
    'status'?: BusinessInfoStatusEnum;
    /**
     * 
     * @type {PowerOfAttorney}
     * @memberof BusinessInfo
     */
    'powerOfAttorney'?: PowerOfAttorney;
}

export const BusinessInfoStatusEnum = {
    NotFound: 'NOT_FOUND',
    StatusNotOk: 'STATUS_NOT_OK',
    Deleted: 'DELETED',
    Ok: 'OK'
} as const;

export type BusinessInfoStatusEnum = typeof BusinessInfoStatusEnum[keyof typeof BusinessInfoStatusEnum];

/**
 * 
 * @export
 * @interface BusinessRegistryDetailResponse
 */
export interface BusinessRegistryDetailResponse {
    /**
     * 
     * @type {ErrorBody}
     * @memberof BusinessRegistryDetailResponse
     */
    'error'?: ErrorBody;
    /**
     * 
     * @type {Company}
     * @memberof BusinessRegistryDetailResponse
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof BusinessRegistryDetailResponse
     */
    'businessStatus'?: BusinessRegistryDetailResponseBusinessStatusEnum;
}

export const BusinessRegistryDetailResponseBusinessStatusEnum = {
    NotFound: 'NOT_FOUND',
    StatusNotOk: 'STATUS_NOT_OK',
    Deleted: 'DELETED',
    Ok: 'OK'
} as const;

export type BusinessRegistryDetailResponseBusinessStatusEnum = typeof BusinessRegistryDetailResponseBusinessStatusEnum[keyof typeof BusinessRegistryDetailResponseBusinessStatusEnum];

/**
 * 
 * @export
 * @interface CertificateMetadata
 */
export interface CertificateMetadata {
    /**
     * 
     * @type {Array<OccupationCode>}
     * @memberof CertificateMetadata
     */
    'allowedOccupationCodes'?: Array<OccupationCode>;
    /**
     * 
     * @type {Array<Speciality>}
     * @memberof CertificateMetadata
     */
    'allowedSpecialities'?: Array<Speciality>;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'registryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'status'?: CompanyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<Representative>}
     * @memberof Company
     */
    'representatives'?: Array<Representative>;
    /**
     * 
     * @type {InsuranceContractStatus}
     * @memberof Company
     */
    'insuranceContractStatus'?: InsuranceContractStatus;
}

export const CompanyStatusEnum = {
    NotFound: 'NOT_FOUND',
    StatusNotOk: 'STATUS_NOT_OK',
    Deleted: 'DELETED',
    Ok: 'OK'
} as const;

export type CompanyStatusEnum = typeof CompanyStatusEnum[keyof typeof CompanyStatusEnum];

/**
 * 
 * @export
 * @interface CompanyApplication
 */
export interface CompanyApplication {
    /**
     * 
     * @type {string}
     * @memberof CompanyApplication
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplication
     */
    'arrivalTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplication
     */
    'businessArea'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplication
     */
    'proceedingType'?: CompanyApplicationProceedingTypeEnum;
    /**
     * 
     * @type {ActivityLicenseApplicationStatus}
     * @memberof CompanyApplication
     */
    'applicationStatus'?: ActivityLicenseApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplication
     */
    'proceedingDeadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApplication
     */
    'stateFeeDeadline'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyApplication
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyApplication
     */
    'stateFeePaid'?: boolean;
}

export const CompanyApplicationProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type CompanyApplicationProceedingTypeEnum = typeof CompanyApplicationProceedingTypeEnum[keyof typeof CompanyApplicationProceedingTypeEnum];

/**
 * 
 * @export
 * @interface CompanySearch
 */
export interface CompanySearch {
    /**
     * 
     * @type {string}
     * @memberof CompanySearch
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySearch
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySearch
     */
    'registryCode'?: string;
    /**
     * 
     * @type {Array<Representative>}
     * @memberof CompanySearch
     */
    'representatives'?: Array<Representative>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanySearch
     */
    'activityLicenseNumbers'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CompanySearchFilter
 */
export interface CompanySearchFilter {
    /**
     * 
     * @type {number}
     * @memberof CompanySearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanySearchFilter
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CompanySearchFilter
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySearchFilter
     */
    'registryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySearchFilter
     */
    'representativeName'?: string;
}
/**
 * 
 * @export
 * @interface CompanySearchResponse
 */
export interface CompanySearchResponse {
    /**
     * 
     * @type {Array<CompanySearch>}
     * @memberof CompanySearchResponse
     */
    'content'?: Array<CompanySearch>;
    /**
     * 
     * @type {number}
     * @memberof CompanySearchResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySearchResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySearchResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySearchResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface Competence
 */
export interface Competence {
    /**
     * 
     * @type {string}
     * @memberof Competence
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Competence
     */
    'personId': string;
    /**
     * 
     * @type {string}
     * @memberof Competence
     */
    'nameAssociation': string;
    /**
     * 
     * @type {string}
     * @memberof Competence
     */
    'validationDate': string;
    /**
     * 
     * @type {string}
     * @memberof Competence
     */
    'validUntil'?: string;
    /**
     * 
     * @type {string}
     * @memberof Competence
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Competence
     */
    'specialtyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Competence
     */
    'specialtyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Competence
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Competence
     */
    'modifiedAt'?: string;
}
/**
 * 
 * @export
 * @interface ContactInfo
 */
export interface ContactInfo {
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    'dialCode': string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    'phone': string;
    /**
     * 
     * @type {Address}
     * @memberof ContactInfo
     */
    'address'?: Address;
}
/**
 * 
 * @export
 * @interface ContactInfoPublic
 */
export interface ContactInfoPublic {
    /**
     * 
     * @type {string}
     * @memberof ContactInfoPublic
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoPublic
     */
    'dialCode': string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoPublic
     */
    'phone': string;
    /**
     * 
     * @type {AddressPublic}
     * @memberof ContactInfoPublic
     */
    'address'?: AddressPublic;
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CountryPublic
 */
export interface CountryPublic {
    /**
     * 
     * @type {string}
     * @memberof CountryPublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryPublic
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryPublic
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface County
 */
export interface County {
    /**
     * 
     * @type {string}
     * @memberof County
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof County
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof County
     */
    'ehakmk'?: string;
}
/**
 * 
 * @export
 * @interface CountyPublic
 */
export interface CountyPublic {
    /**
     * 
     * @type {string}
     * @memberof CountyPublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountyPublic
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CountyPublic
     */
    'ehakmk'?: string;
}
/**
 * 
 * @export
 * @interface CreateActivityLicenseInvalidationRequest
 */
export interface CreateActivityLicenseInvalidationRequest {
    /**
     * 
     * @type {InvalidationCreation}
     * @memberof CreateActivityLicenseInvalidationRequest
     */
    'invalidation': InvalidationCreation;
    /**
     * 
     * @type {Array<any>}
     * @memberof CreateActivityLicenseInvalidationRequest
     */
    'file': Array<any>;
}
/**
 * 
 * @export
 * @interface CreateHashCodeContainerResponse
 */
export interface CreateHashCodeContainerResponse {
    /**
     * 
     * @type {ErrorBody}
     * @memberof CreateHashCodeContainerResponse
     */
    'error'?: ErrorBody;
    /**
     * 
     * @type {string}
     * @memberof CreateHashCodeContainerResponse
     */
    'decisionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHashCodeContainerResponse
     */
    'containerId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DateOperator = {
    Equals: 'EQUALS',
    LessThan: 'LESS_THAN',
    LessThanEqual: 'LESS_THAN_EQUAL',
    GreaterThan: 'GREATER_THAN',
    GreaterThanEqual: 'GREATER_THAN_EQUAL'
} as const;

export type DateOperator = typeof DateOperator[keyof typeof DateOperator];


/**
 * 
 * @export
 * @interface DateRange
 */
export interface DateRange {
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    'end'?: string;
}
/**
 * 
 * @export
 * @interface Decision
 */
export interface Decision {
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'decisionNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'minioFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'fileName'?: string;
    /**
     * 
     * @type {FileObjectType}
     * @memberof Decision
     */
    'fileObjectType'?: FileObjectType;
    /**
     * 
     * @type {boolean}
     * @memberof Decision
     */
    'signed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'signedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'signedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'minioContainerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'personName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'businessAreaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    'status'?: DecisionStatusEnum;
}

export const DecisionStatusEnum = {
    Accepted: 'ACCEPTED',
    Declined: 'DECLINED',
    ComplianceExam: 'COMPLIANCE_EXAM',
    AptitudeTest: 'APTITUDE_TEST',
    AcceptedAfterComplianceExam: 'ACCEPTED_AFTER_COMPLIANCE_EXAM',
    AcceptedAfterAptitudeTest: 'ACCEPTED_AFTER_APTITUDE_TEST',
    WaitingForSignature: 'WAITING_FOR_SIGNATURE'
} as const;

export type DecisionStatusEnum = typeof DecisionStatusEnum[keyof typeof DecisionStatusEnum];

/**
 * 
 * @export
 * @interface DecisionSearchFilter
 */
export interface DecisionSearchFilter {
    /**
     * 
     * @type {number}
     * @memberof DecisionSearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof DecisionSearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DecisionSearchFilter
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DecisionSearchFilter
     */
    'personName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DecisionSearchFilter
     */
    'decisionNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DecisionSearchFilter
     */
    'countryCode'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DecisionSearchFilter
     */
    'decisionSearchStatuses'?: Array<DecisionSearchFilterDecisionSearchStatusesEnum>;
    /**
     * 
     * @type {string}
     * @memberof DecisionSearchFilter
     */
    'personIdCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DecisionSearchFilter
     */
    'acceptedAutomatically'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DecisionSearchFilter
     */
    'companyName'?: string;
}

export const DecisionSearchFilterDecisionSearchStatusesEnum = {
    Accepted: 'ACCEPTED',
    Declined: 'DECLINED',
    ComplianceExam: 'COMPLIANCE_EXAM',
    AptitudeTest: 'APTITUDE_TEST',
    AcceptedAfterComplianceExam: 'ACCEPTED_AFTER_COMPLIANCE_EXAM',
    AcceptedAfterAptitudeTest: 'ACCEPTED_AFTER_APTITUDE_TEST',
    WaitingForSignature: 'WAITING_FOR_SIGNATURE'
} as const;

export type DecisionSearchFilterDecisionSearchStatusesEnum = typeof DecisionSearchFilterDecisionSearchStatusesEnum[keyof typeof DecisionSearchFilterDecisionSearchStatusesEnum];

/**
 * 
 * @export
 * @interface DecisionSearchResponse
 */
export interface DecisionSearchResponse {
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DecisionSearchResponse
     */
    'content'?: Array<Decision>;
    /**
     * 
     * @type {number}
     * @memberof DecisionSearchResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof DecisionSearchResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof DecisionSearchResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof DecisionSearchResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DecisionType = {
    Application: 'APPLICATION',
    PersonSuspension: 'PERSON_SUSPENSION',
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseApplication: 'ACTIVITY_LICENSE_APPLICATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    DiscardService: 'DISCARD_SERVICE',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    GeneralPractitionerListApplication: 'GENERAL_PRACTITIONER_LIST_APPLICATION',
    AddService: 'ADD_SERVICE',
    AddLocation: 'ADD_LOCATION'
} as const;

export type DecisionType = typeof DecisionType[keyof typeof DecisionType];


/**
 * 
 * @export
 * @interface DetailedActivityLicense
 */
export interface DetailedActivityLicense {
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicense
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicense
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicense
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicense
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicense
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicense
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicense
     */
    'status'?: DetailedActivityLicenseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicense
     */
    'validUntil'?: string;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof DetailedActivityLicense
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {Company}
     * @memberof DetailedActivityLicense
     */
    'company'?: Company;
    /**
     * 
     * @type {ActivityLicenseBusinessArea}
     * @memberof DetailedActivityLicense
     */
    'businessArea'?: ActivityLicenseBusinessArea;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof DetailedActivityLicense
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DetailedActivityLicense
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicense
     */
    'invalidationApplicationId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedActivityLicense
     */
    'acquisitionApplicationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedActivityLicense
     */
    'suspensionApplicationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedActivityLicense
     */
    'restorationApplicationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedActivityLicense
     */
    'gpListApplicationIds'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DetailedActivityLicense
     */
    'totalSuspendedDays'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedActivityLicense
     */
    'suspended'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicense
     */
    'mainPharmacyName'?: string;
    /**
     * 
     * @type {Array<ActivityLicenseApplication>}
     * @memberof DetailedActivityLicense
     */
    'applications'?: Array<ActivityLicenseApplication>;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof DetailedActivityLicense
     */
    'externalFiles'?: Array<FileReference>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedActivityLicense
     */
    'locationSuspensionIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedActivityLicense
     */
    'serviceSuspensionIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedActivityLicense
     */
    'locationRestorationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedActivityLicense
     */
    'serviceRestorationIds'?: Array<string>;
}

export const DetailedActivityLicenseStatusEnum = {
    Valid: 'VALID',
    Paused: 'PAUSED',
    Invalid: 'INVALID'
} as const;

export type DetailedActivityLicenseStatusEnum = typeof DetailedActivityLicenseStatusEnum[keyof typeof DetailedActivityLicenseStatusEnum];

/**
 * 
 * @export
 * @interface DetailedActivityLicenseApplication
 */
export interface DetailedActivityLicenseApplication {
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseApplication
     */
    'id'?: string;
    /**
     * 
     * @type {Company}
     * @memberof DetailedActivityLicenseApplication
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseApplication
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseApplication
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseApplication
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseApplication
     */
    'submittingPersonRole'?: string;
    /**
     * 
     * @type {ActivityLicenseBusinessArea}
     * @memberof DetailedActivityLicenseApplication
     */
    'businessArea'?: ActivityLicenseBusinessArea;
    /**
     * 
     * @type {number}
     * @memberof DetailedActivityLicenseApplication
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {StateFee}
     * @memberof DetailedActivityLicenseApplication
     */
    'stateFee'?: StateFee;
    /**
     * 
     * @type {number}
     * @memberof DetailedActivityLicenseApplication
     */
    'currentStep'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedActivityLicenseApplication
     */
    'migrated'?: boolean;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof DetailedActivityLicenseApplication
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseApplication
     */
    'renewalNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseApplication
     */
    'arrivalTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseApplication
     */
    'entryTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseApplication
     */
    'channelType'?: DetailedActivityLicenseApplicationChannelTypeEnum;
    /**
     * 
     * @type {Array<ActivityLicenseApplicationProceedingStatus>}
     * @memberof DetailedActivityLicenseApplication
     */
    'statuses'?: Array<ActivityLicenseApplicationProceedingStatus>;
    /**
     * 
     * @type {ActivityLicenseApplicationProceedingStatus}
     * @memberof DetailedActivityLicenseApplication
     */
    'currentStatus'?: ActivityLicenseApplicationProceedingStatus;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof DetailedActivityLicenseApplication
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedActivityLicenseApplication
     */
    'shouldValidate'?: boolean;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DetailedActivityLicenseApplication
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {ActivityLicense}
     * @memberof DetailedActivityLicenseApplication
     */
    'activityLicense'?: ActivityLicense;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseApplication
     */
    'proceedingType'?: DetailedActivityLicenseApplicationProceedingTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedActivityLicenseApplication
     */
    'saveAllowed'?: boolean;
    /**
     * 
     * @type {Array<AuditLogItem>}
     * @memberof DetailedActivityLicenseApplication
     */
    'auditLogItems'?: Array<AuditLogItem>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseApplication
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseApplication
     */
    'proceedingOfficialId'?: string;
}

export const DetailedActivityLicenseApplicationChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type DetailedActivityLicenseApplicationChannelTypeEnum = typeof DetailedActivityLicenseApplicationChannelTypeEnum[keyof typeof DetailedActivityLicenseApplicationChannelTypeEnum];
export const DetailedActivityLicenseApplicationProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type DetailedActivityLicenseApplicationProceedingTypeEnum = typeof DetailedActivityLicenseApplicationProceedingTypeEnum[keyof typeof DetailedActivityLicenseApplicationProceedingTypeEnum];

/**
 * 
 * @export
 * @interface DetailedActivityLicenseGPListApplication
 */
export interface DetailedActivityLicenseGPListApplication {
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {Company}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'licenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'businessAreaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'numberOfGpLists'?: number;
    /**
     * 
     * @type {GeneralPractitionerListApplicationStatus}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'status'?: GeneralPractitionerListApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'proceedingOfficialId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'signingOfficialId'?: string;
    /**
     * 
     * @type {Array<AuditLogItem>}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'auditLogItems'?: Array<AuditLogItem>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'licenseId'?: string;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DetailedActivityLicenseGPListApplication
     */
    'decisions'?: Array<Decision>;
}
/**
 * 
 * @export
 * @interface DetailedActivityLicenseInvalidation
 */
export interface DetailedActivityLicenseInvalidation {
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'licenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'licenseId'?: string;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'status'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {Company}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'businessAreaName'?: string;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'isHospital'?: boolean;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'proceedingOfficialId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'signingOfficialId'?: string;
    /**
     * 
     * @type {Array<AuditLogItem>}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'auditLogItems'?: Array<AuditLogItem>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseInvalidation
     */
    'proceedingType'?: DetailedActivityLicenseInvalidationProceedingTypeEnum;
}

export const DetailedActivityLicenseInvalidationProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type DetailedActivityLicenseInvalidationProceedingTypeEnum = typeof DetailedActivityLicenseInvalidationProceedingTypeEnum[keyof typeof DetailedActivityLicenseInvalidationProceedingTypeEnum];

/**
 * 
 * @export
 * @interface DetailedActivityLicenseRestoration
 */
export interface DetailedActivityLicenseRestoration {
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseRestoration
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedActivityLicenseRestoration
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseRestoration
     */
    'licenseNumber'?: string;
    /**
     * 
     * @type {Company}
     * @memberof DetailedActivityLicenseRestoration
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseRestoration
     */
    'businessAreaName'?: string;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof DetailedActivityLicenseRestoration
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedActivityLicenseRestoration
     */
    'isHospital'?: boolean;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof DetailedActivityLicenseRestoration
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DetailedActivityLicenseRestoration
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseRestoration
     */
    'createdAt'?: string;
    /**
     * 
     * @type {RestorationStatus}
     * @memberof DetailedActivityLicenseRestoration
     */
    'status'?: RestorationStatus;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseRestoration
     */
    'type'?: DetailedActivityLicenseRestorationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseRestoration
     */
    'notes'?: string;
    /**
     * 
     * @type {Array<AuditLogItem>}
     * @memberof DetailedActivityLicenseRestoration
     */
    'auditLogItems'?: Array<AuditLogItem>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseRestoration
     */
    'proceedingType'?: DetailedActivityLicenseRestorationProceedingTypeEnum;
}

export const DetailedActivityLicenseRestorationTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLocation: 'ACTIVITY_LOCATION',
    Service: 'SERVICE'
} as const;

export type DetailedActivityLicenseRestorationTypeEnum = typeof DetailedActivityLicenseRestorationTypeEnum[keyof typeof DetailedActivityLicenseRestorationTypeEnum];
export const DetailedActivityLicenseRestorationProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type DetailedActivityLicenseRestorationProceedingTypeEnum = typeof DetailedActivityLicenseRestorationProceedingTypeEnum[keyof typeof DetailedActivityLicenseRestorationProceedingTypeEnum];

/**
 * 
 * @export
 * @interface DetailedActivityLicenseSuspension
 */
export interface DetailedActivityLicenseSuspension {
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedActivityLicenseSuspension
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'suspensionReason'?: DetailedActivityLicenseSuspensionSuspensionReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedActivityLicenseSuspension
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'explanationNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'suspensionType'?: DetailedActivityLicenseSuspensionSuspensionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'channelType'?: DetailedActivityLicenseSuspensionChannelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'activityLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'activityLicenseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'submissionTime'?: string;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof DetailedActivityLicenseSuspension
     */
    'currentStatus'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'businessArea'?: string;
    /**
     * 
     * @type {Company}
     * @memberof DetailedActivityLicenseSuspension
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DetailedActivityLicenseSuspension
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof DetailedActivityLicenseSuspension
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLicenseSuspension
     */
    'proceedingOfficialId'?: string;
    /**
     * 
     * @type {Array<AuditLogItem>}
     * @memberof DetailedActivityLicenseSuspension
     */
    'auditLogItems'?: Array<AuditLogItem>;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedActivityLicenseSuspension
     */
    'isHospital'?: boolean;
}

export const DetailedActivityLicenseSuspensionSuspensionReasonEnum = {
    Personnel: 'PERSONNEL',
    Rooms: 'ROOMS'
} as const;

export type DetailedActivityLicenseSuspensionSuspensionReasonEnum = typeof DetailedActivityLicenseSuspensionSuspensionReasonEnum[keyof typeof DetailedActivityLicenseSuspensionSuspensionReasonEnum];
export const DetailedActivityLicenseSuspensionSuspensionTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type DetailedActivityLicenseSuspensionSuspensionTypeEnum = typeof DetailedActivityLicenseSuspensionSuspensionTypeEnum[keyof typeof DetailedActivityLicenseSuspensionSuspensionTypeEnum];
export const DetailedActivityLicenseSuspensionChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type DetailedActivityLicenseSuspensionChannelTypeEnum = typeof DetailedActivityLicenseSuspensionChannelTypeEnum[keyof typeof DetailedActivityLicenseSuspensionChannelTypeEnum];

/**
 * 
 * @export
 * @interface DetailedActivityLocationInvalidation
 */
export interface DetailedActivityLocationInvalidation {
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationInvalidation
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedActivityLocationInvalidation
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationInvalidation
     */
    'licenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationInvalidation
     */
    'licenseId'?: string;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof DetailedActivityLocationInvalidation
     */
    'status'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {Company}
     * @memberof DetailedActivityLocationInvalidation
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationInvalidation
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationInvalidation
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationInvalidation
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationInvalidation
     */
    'businessAreaName'?: string;
    /**
     * 
     * @type {ActivityLocation}
     * @memberof DetailedActivityLocationInvalidation
     */
    'location'?: ActivityLocation;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedActivityLocationInvalidation
     */
    'isHospital'?: boolean;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof DetailedActivityLocationInvalidation
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DetailedActivityLocationInvalidation
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationInvalidation
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationInvalidation
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationInvalidation
     */
    'proceedingOfficialId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationInvalidation
     */
    'signingOfficialId'?: string;
    /**
     * 
     * @type {Array<AuditLogItem>}
     * @memberof DetailedActivityLocationInvalidation
     */
    'auditLogItems'?: Array<AuditLogItem>;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof DetailedActivityLocationInvalidation
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationInvalidation
     */
    'proceedingType'?: DetailedActivityLocationInvalidationProceedingTypeEnum;
}

export const DetailedActivityLocationInvalidationProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type DetailedActivityLocationInvalidationProceedingTypeEnum = typeof DetailedActivityLocationInvalidationProceedingTypeEnum[keyof typeof DetailedActivityLocationInvalidationProceedingTypeEnum];

/**
 * 
 * @export
 * @interface DetailedActivityLocationSuspension
 */
export interface DetailedActivityLocationSuspension {
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedActivityLocationSuspension
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'suspensionReason'?: DetailedActivityLocationSuspensionSuspensionReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedActivityLocationSuspension
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'explanationNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'suspensionType'?: DetailedActivityLocationSuspensionSuspensionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'channelType'?: DetailedActivityLocationSuspensionChannelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'activityLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'activityLicenseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'submissionTime'?: string;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof DetailedActivityLocationSuspension
     */
    'currentStatus'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'businessArea'?: string;
    /**
     * 
     * @type {Company}
     * @memberof DetailedActivityLocationSuspension
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DetailedActivityLocationSuspension
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'createdAt'?: string;
    /**
     * 
     * @type {ActivityLocation}
     * @memberof DetailedActivityLocationSuspension
     */
    'location'?: ActivityLocation;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedActivityLocationSuspension
     */
    'proceedingOfficialId'?: string;
    /**
     * 
     * @type {Array<AuditLogItem>}
     * @memberof DetailedActivityLocationSuspension
     */
    'auditLogItems'?: Array<AuditLogItem>;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof DetailedActivityLocationSuspension
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedActivityLocationSuspension
     */
    'isHospital'?: boolean;
}

export const DetailedActivityLocationSuspensionSuspensionReasonEnum = {
    Personnel: 'PERSONNEL',
    Rooms: 'ROOMS'
} as const;

export type DetailedActivityLocationSuspensionSuspensionReasonEnum = typeof DetailedActivityLocationSuspensionSuspensionReasonEnum[keyof typeof DetailedActivityLocationSuspensionSuspensionReasonEnum];
export const DetailedActivityLocationSuspensionSuspensionTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type DetailedActivityLocationSuspensionSuspensionTypeEnum = typeof DetailedActivityLocationSuspensionSuspensionTypeEnum[keyof typeof DetailedActivityLocationSuspensionSuspensionTypeEnum];
export const DetailedActivityLocationSuspensionChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type DetailedActivityLocationSuspensionChannelTypeEnum = typeof DetailedActivityLocationSuspensionChannelTypeEnum[keyof typeof DetailedActivityLocationSuspensionChannelTypeEnum];

/**
 * 
 * @export
 * @interface DetailedApplication
 */
export interface DetailedApplication {
    /**
     * 
     * @type {string}
     * @memberof DetailedApplication
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedApplication
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplication
     */
    'type'?: DetailedApplicationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplication
     */
    'personId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplication
     */
    'personName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedApplication
     */
    'competenceExam'?: boolean;
    /**
     * 
     * @type {Occupation}
     * @memberof DetailedApplication
     */
    'occupation'?: Occupation;
    /**
     * 
     * @type {Occupation}
     * @memberof DetailedApplication
     */
    'additionalOccupation'?: Occupation;
    /**
     * 
     * @type {Speciality}
     * @memberof DetailedApplication
     */
    'speciality'?: Speciality;
    /**
     * 
     * @type {Array<ProceedingStatus>}
     * @memberof DetailedApplication
     */
    'statuses'?: Array<ProceedingStatus>;
    /**
     * 
     * @type {ProceedingStatus}
     * @memberof DetailedApplication
     */
    'currentStatus'?: ProceedingStatus;
    /**
     * 
     * @type {EducationInfo}
     * @memberof DetailedApplication
     */
    'educationInfo'?: EducationInfo;
    /**
     * 
     * @type {ExamInfo}
     * @memberof DetailedApplication
     */
    'complianceExam'?: ExamInfo;
    /**
     * 
     * @type {ExamInfo}
     * @memberof DetailedApplication
     */
    'aptitudeTest'?: ExamInfo;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplication
     */
    'arrivalTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplication
     */
    'entryTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplication
     */
    'channelType'?: DetailedApplicationChannelTypeEnum;
    /**
     * 
     * @type {StateFee}
     * @memberof DetailedApplication
     */
    'stateFee'?: StateFee;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedApplication
     */
    'automaticProceeding'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplication
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplication
     */
    'renewalNotes'?: string;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof DetailedApplication
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplication
     */
    'proceedingStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplication
     */
    'proceedingEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplication
     */
    'proceedingDeadline'?: string;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DetailedApplication
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {Array<AuditLogItem>}
     * @memberof DetailedApplication
     */
    'auditLogItems'?: Array<AuditLogItem>;
}

export const DetailedApplicationTypeEnum = {
    Occupation: 'OCCUPATION',
    Specialization: 'SPECIALIZATION',
    ApplicationCertificate: 'APPLICATION_CERTIFICATE'
} as const;

export type DetailedApplicationTypeEnum = typeof DetailedApplicationTypeEnum[keyof typeof DetailedApplicationTypeEnum];
export const DetailedApplicationChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type DetailedApplicationChannelTypeEnum = typeof DetailedApplicationChannelTypeEnum[keyof typeof DetailedApplicationChannelTypeEnum];

/**
 * 
 * @export
 * @interface DetailedApplicationCertificate
 */
export interface DetailedApplicationCertificate {
    /**
     * 
     * @type {string}
     * @memberof DetailedApplicationCertificate
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedApplicationCertificate
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetailedApplicationCertificate
     */
    'currentStep'?: number;
    /**
     * 
     * @type {Array<Occupation>}
     * @memberof DetailedApplicationCertificate
     */
    'occupations'?: Array<Occupation>;
    /**
     * 
     * @type {Speciality}
     * @memberof DetailedApplicationCertificate
     */
    'speciality'?: Speciality;
    /**
     * 
     * @type {Array<Country>}
     * @memberof DetailedApplicationCertificate
     */
    'countries'?: Array<Country>;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplicationCertificate
     */
    'education'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplicationCertificate
     */
    'deliveryType'?: DetailedApplicationCertificateDeliveryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplicationCertificate
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplicationCertificate
     */
    'personId'?: string;
    /**
     * 
     * @type {ApplicationStatusWrapper}
     * @memberof DetailedApplicationCertificate
     */
    'currentStatus'?: ApplicationStatusWrapper;
    /**
     * 
     * @type {StateFee}
     * @memberof DetailedApplicationCertificate
     */
    'stateFee'?: StateFee;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplicationCertificate
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplicationCertificate
     */
    'arrivalTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplicationCertificate
     */
    'deliveryAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedApplicationCertificate
     */
    'type'?: DetailedApplicationCertificateTypeEnum;
    /**
     * 
     * @type {Array<AuditLogItem>}
     * @memberof DetailedApplicationCertificate
     */
    'auditLogItems'?: Array<AuditLogItem>;
}

export const DetailedApplicationCertificateDeliveryTypeEnum = {
    TakeMyself: 'TAKE_MYSELF',
    Post: 'POST',
    Electronic: 'ELECTRONIC'
} as const;

export type DetailedApplicationCertificateDeliveryTypeEnum = typeof DetailedApplicationCertificateDeliveryTypeEnum[keyof typeof DetailedApplicationCertificateDeliveryTypeEnum];
export const DetailedApplicationCertificateTypeEnum = {
    Occupation: 'OCCUPATION',
    Specialization: 'SPECIALIZATION',
    ApplicationCertificate: 'APPLICATION_CERTIFICATE'
} as const;

export type DetailedApplicationCertificateTypeEnum = typeof DetailedApplicationCertificateTypeEnum[keyof typeof DetailedApplicationCertificateTypeEnum];

/**
 * 
 * @export
 * @interface DetailedCompany
 */
export interface DetailedCompany {
    /**
     * 
     * @type {string}
     * @memberof DetailedCompany
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedCompany
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedCompany
     */
    'registryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedCompany
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedCompany
     */
    'status'?: DetailedCompanyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedCompany
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedCompany
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<Representative>}
     * @memberof DetailedCompany
     */
    'representatives'?: Array<Representative>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedCompany
     */
    'activityLicenseLocations'?: Array<string>;
    /**
     * 
     * @type {Array<PowerOfAttorney>}
     * @memberof DetailedCompany
     */
    'powersOfAttorney'?: Array<PowerOfAttorney>;
}

export const DetailedCompanyStatusEnum = {
    NotFound: 'NOT_FOUND',
    StatusNotOk: 'STATUS_NOT_OK',
    Deleted: 'DELETED',
    Ok: 'OK'
} as const;

export type DetailedCompanyStatusEnum = typeof DetailedCompanyStatusEnum[keyof typeof DetailedCompanyStatusEnum];

/**
 * 
 * @export
 * @interface DetailedDiscardServiceApplication
 */
export interface DetailedDiscardServiceApplication {
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'id'?: string;
    /**
     * 
     * @type {Company}
     * @memberof DetailedDiscardServiceApplication
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'submittingPersonRole'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedDiscardServiceApplication
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {Array<ActivityLicenseService>}
     * @memberof DetailedDiscardServiceApplication
     */
    'services'?: Array<ActivityLicenseService>;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof DetailedDiscardServiceApplication
     */
    'currentStatus'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedDiscardServiceApplication
     */
    'shouldValidate'?: boolean;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DetailedDiscardServiceApplication
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'licenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'licenseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'activityLocationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'businessAreaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<AuditLogItem>}
     * @memberof DetailedDiscardServiceApplication
     */
    'auditLogItems'?: Array<AuditLogItem>;
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'proceedingOfficialId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDiscardServiceApplication
     */
    'proceedingType'?: DetailedDiscardServiceApplicationProceedingTypeEnum;
}

export const DetailedDiscardServiceApplicationProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type DetailedDiscardServiceApplicationProceedingTypeEnum = typeof DetailedDiscardServiceApplicationProceedingTypeEnum[keyof typeof DetailedDiscardServiceApplicationProceedingTypeEnum];

/**
 * 
 * @export
 * @interface DetailedLicenseAcquisition
 */
export interface DetailedLicenseAcquisition {
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'id'?: string;
    /**
     * 
     * @type {Company}
     * @memberof DetailedLicenseAcquisition
     */
    'company'?: Company;
    /**
     * 
     * @type {Company}
     * @memberof DetailedLicenseAcquisition
     */
    'transferor'?: Company;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'submittingPersonRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'submissionTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedLicenseAcquisition
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {StateFee}
     * @memberof DetailedLicenseAcquisition
     */
    'stateFee'?: StateFee;
    /**
     * 
     * @type {number}
     * @memberof DetailedLicenseAcquisition
     */
    'currentStep'?: number;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof DetailedLicenseAcquisition
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {ActivityLicense}
     * @memberof DetailedLicenseAcquisition
     */
    'license'?: ActivityLicense;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'channelType'?: DetailedLicenseAcquisitionChannelTypeEnum;
    /**
     * 
     * @type {ActivityLicenseApplicationStatus}
     * @memberof DetailedLicenseAcquisition
     */
    'status'?: ActivityLicenseApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'renewalNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'renewalDeadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'protocolNumber'?: string;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DetailedLicenseAcquisition
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedLicenseAcquisition
     */
    'saveAllowed'?: boolean;
    /**
     * 
     * @type {Array<AuditLogItem>}
     * @memberof DetailedLicenseAcquisition
     */
    'auditLogItems'?: Array<AuditLogItem>;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'proceedingOfficialId'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedLicenseAcquisition
     */
    'deadlineDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedLicenseAcquisition
     */
    'proceedingType'?: DetailedLicenseAcquisitionProceedingTypeEnum;
}

export const DetailedLicenseAcquisitionChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type DetailedLicenseAcquisitionChannelTypeEnum = typeof DetailedLicenseAcquisitionChannelTypeEnum[keyof typeof DetailedLicenseAcquisitionChannelTypeEnum];
export const DetailedLicenseAcquisitionProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type DetailedLicenseAcquisitionProceedingTypeEnum = typeof DetailedLicenseAcquisitionProceedingTypeEnum[keyof typeof DetailedLicenseAcquisitionProceedingTypeEnum];

/**
 * 
 * @export
 * @interface DetailedPersonCertificateSearch
 */
export interface DetailedPersonCertificateSearch {
    /**
     * 
     * @type {Array<Item>}
     * @memberof DetailedPersonCertificateSearch
     */
    'content'?: Array<Item>;
    /**
     * 
     * @type {number}
     * @memberof DetailedPersonCertificateSearch
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetailedPersonCertificateSearch
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetailedPersonCertificateSearch
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetailedPersonCertificateSearch
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface DetailedServiceSuspension
 */
export interface DetailedServiceSuspension {
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedServiceSuspension
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'suspensionReason'?: DetailedServiceSuspensionSuspensionReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailedServiceSuspension
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'explanationNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'suspensionType'?: DetailedServiceSuspensionSuspensionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'channelType'?: DetailedServiceSuspensionChannelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'activityLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'activityLicenseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'submissionTime'?: string;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof DetailedServiceSuspension
     */
    'currentStatus'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'businessArea'?: string;
    /**
     * 
     * @type {Company}
     * @memberof DetailedServiceSuspension
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DetailedServiceSuspension
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'createdAt'?: string;
    /**
     * 
     * @type {ActivityLocation}
     * @memberof DetailedServiceSuspension
     */
    'location'?: ActivityLocation;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedServiceSuspension
     */
    'serviceIdsToSuspend'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedServiceSuspension
     */
    'proceedingOfficialId'?: string;
    /**
     * 
     * @type {Array<AuditLogItem>}
     * @memberof DetailedServiceSuspension
     */
    'auditLogItems'?: Array<AuditLogItem>;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof DetailedServiceSuspension
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedServiceSuspension
     */
    'isHospital'?: boolean;
}

export const DetailedServiceSuspensionSuspensionReasonEnum = {
    Personnel: 'PERSONNEL',
    Rooms: 'ROOMS'
} as const;

export type DetailedServiceSuspensionSuspensionReasonEnum = typeof DetailedServiceSuspensionSuspensionReasonEnum[keyof typeof DetailedServiceSuspensionSuspensionReasonEnum];
export const DetailedServiceSuspensionSuspensionTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type DetailedServiceSuspensionSuspensionTypeEnum = typeof DetailedServiceSuspensionSuspensionTypeEnum[keyof typeof DetailedServiceSuspensionSuspensionTypeEnum];
export const DetailedServiceSuspensionChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type DetailedServiceSuspensionChannelTypeEnum = typeof DetailedServiceSuspensionChannelTypeEnum[keyof typeof DetailedServiceSuspensionChannelTypeEnum];

/**
 * 
 * @export
 * @interface DigitalPlatform
 */
export interface DigitalPlatform {
    /**
     * 
     * @type {string}
     * @memberof DigitalPlatform
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalPlatform
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalPlatform
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalPlatform
     */
    'url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DigitalPlatform
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface DigitalPlatformListResponse
 */
export interface DigitalPlatformListResponse {
    /**
     * 
     * @type {Array<DigitalPlatform>}
     * @memberof DigitalPlatformListResponse
     */
    'digitalPlatforms'?: Array<DigitalPlatform>;
}
/**
 * 
 * @export
 * @interface DiscardServiceApplication
 */
export interface DiscardServiceApplication {
    /**
     * 
     * @type {string}
     * @memberof DiscardServiceApplication
     */
    'id'?: string;
    /**
     * 
     * @type {Company}
     * @memberof DiscardServiceApplication
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof DiscardServiceApplication
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscardServiceApplication
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscardServiceApplication
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscardServiceApplication
     */
    'submittingPersonRole'?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscardServiceApplication
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {Array<ActivityLicenseService>}
     * @memberof DiscardServiceApplication
     */
    'services'?: Array<ActivityLicenseService>;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof DiscardServiceApplication
     */
    'currentStatus'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof DiscardServiceApplication
     */
    'shouldValidate'?: boolean;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof DiscardServiceApplication
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof DiscardServiceApplication
     */
    'licenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscardServiceApplication
     */
    'licenseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscardServiceApplication
     */
    'activityLocationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscardServiceApplication
     */
    'businessAreaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscardServiceApplication
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscardServiceApplication
     */
    'proceedingType'?: DiscardServiceApplicationProceedingTypeEnum;
}

export const DiscardServiceApplicationProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type DiscardServiceApplicationProceedingTypeEnum = typeof DiscardServiceApplicationProceedingTypeEnum[keyof typeof DiscardServiceApplicationProceedingTypeEnum];

/**
 * 
 * @export
 * @interface Education
 */
export interface Education {
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'facilityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'acquiredDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'documentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'curriculumName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Education
     */
    'migrated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'source'?: EducationSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'specialization'?: string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'curriculumCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'documentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof Education
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'prescriptionResult'?: EducationPrescriptionResultEnum;
}

export const EducationSourceEnum = {
    EhisAddedManually: 'EHIS_ADDED_MANUALLY',
    ManuallyAdded: 'MANUALLY_ADDED',
    Migrated: 'MIGRATED'
} as const;

export type EducationSourceEnum = typeof EducationSourceEnum[keyof typeof EducationSourceEnum];
export const EducationPrescriptionResultEnum = {
    PrescriptionRightsGranted: 'PRESCRIPTION_RIGHTS_GRANTED',
    PrescriptionRightsNotGranted: 'PRESCRIPTION_RIGHTS_NOT_GRANTED',
    FailedPrescriptionCheck: 'FAILED_PRESCRIPTION_CHECK'
} as const;

export type EducationPrescriptionResultEnum = typeof EducationPrescriptionResultEnum[keyof typeof EducationPrescriptionResultEnum];

/**
 * 
 * @export
 * @interface EducationInfo
 */
export interface EducationInfo {
    /**
     * 
     * @type {string}
     * @memberof EducationInfo
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationInfo
     */
    'schoolName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationInfo
     */
    'curriculumCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationInfo
     */
    'curriculumName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationInfo
     */
    'diplomaDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationInfo
     */
    'diplomaNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationInfo
     */
    'specialization'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EducationInfo
     */
    'fromEHIS'?: boolean;
}
/**
 * 
 * @export
 * @interface Employment
 */
export interface Employment {
    /**
     * 
     * @type {string}
     * @memberof Employment
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employment
     */
    'registryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employment
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employment
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employment
     */
    'position'?: string;
    /**
     * 
     * @type {number}
     * @memberof Employment
     */
    'professionCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof Employment
     */
    'activityLicenseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employment
     */
    'specialistId'?: string;
}
/**
 * 
 * @export
 * @interface EmploymentPublic
 */
export interface EmploymentPublic {
    /**
     * 
     * @type {string}
     * @memberof EmploymentPublic
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentPublic
     */
    'registryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentPublic
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentPublic
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentPublic
     */
    'position'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmploymentPublic
     */
    'professionCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmploymentPublic
     */
    'activityLicenseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentPublic
     */
    'specialistId'?: string;
}
/**
 * 
 * @export
 * @interface ErrorBody
 */
export interface ErrorBody {
    /**
     * 
     * @type {string}
     * @memberof ErrorBody
     */
    'className'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorBody
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ExamInfo
 */
export interface ExamInfo {
    /**
     * 
     * @type {string}
     * @memberof ExamInfo
     */
    'diplomaNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExamInfo
     */
    'diplomaPlace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExamInfo
     */
    'diplomaDate'?: string;
    /**
     * 
     * @type {FileObjectType}
     * @memberof ExamInfo
     */
    'type'?: FileObjectType;
}
/**
 * 
 * @export
 * @interface ExternalDecision
 */
export interface ExternalDecision {
    /**
     * 
     * @type {string}
     * @memberof ExternalDecision
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalDecision
     */
    'createdAt'?: string;
    /**
     * 
     * @type {FileReference}
     * @memberof ExternalDecision
     */
    'fileReference'?: FileReference;
}
/**
 * 
 * @export
 * @interface ExternalDecisionPublic
 */
export interface ExternalDecisionPublic {
    /**
     * 
     * @type {string}
     * @memberof ExternalDecisionPublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalDecisionPublic
     */
    'createdAt'?: string;
    /**
     * 
     * @type {FileReferencePublic}
     * @memberof ExternalDecisionPublic
     */
    'fileReference'?: FileReferencePublic;
}
/**
 * 
 * @export
 * @interface FeatureFlag
 */
export interface FeatureFlag {
    /**
     * 
     * @type {string}
     * @memberof FeatureFlag
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlag
     */
    'isEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface FeatureFlagRequest
 */
export interface FeatureFlagRequest {
    /**
     * 
     * @type {string}
     * @memberof FeatureFlagRequest
     */
    'id': FeatureFlagRequestIdEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlagRequest
     */
    'isEnabled': boolean;
}

export const FeatureFlagRequestIdEnum = {
    TortaCheckEnabled: 'TORTA_CHECK_ENABLED',
    InsuranceV1: 'INSURANCE_V1'
} as const;

export type FeatureFlagRequestIdEnum = typeof FeatureFlagRequestIdEnum[keyof typeof FeatureFlagRequestIdEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const FileObjectType = {
    ProceedingDiploma: 'PROCEEDING_DIPLOMA',
    ProceedingAcademicReport: 'PROCEEDING_ACADEMIC_REPORT',
    ProceedingAdditionalDocument: 'PROCEEDING_ADDITIONAL_DOCUMENT',
    ProceedingNameChangeDocument: 'PROCEEDING_NAME_CHANGE_DOCUMENT',
    ProceedingComplianceExamCert: 'PROCEEDING_COMPLIANCE_EXAM_CERT',
    ProceedingAptitudeTestCert: 'PROCEEDING_APTITUDE_TEST_CERT',
    ApplicationAcceptanceDecision: 'APPLICATION_ACCEPTANCE_DECISION',
    ApplicationDeclinationDecision: 'APPLICATION_DECLINATION_DECISION',
    ApplicationSendToComplianceExamDecision: 'APPLICATION_SEND_TO_COMPLIANCE_EXAM_DECISION',
    ApplicationSendToAptitudeTestDecision: 'APPLICATION_SEND_TO_APTITUDE_TEST_DECISION',
    ApplicationComplianceExamResultDecision: 'APPLICATION_COMPLIANCE_EXAM_RESULT_DECISION',
    ApplicationAptitudeTestResultDecision: 'APPLICATION_APTITUDE_TEST_RESULT_DECISION',
    PersonSuspensionDecision: 'PERSON_SUSPENSION_DECISION',
    PersonPreviousNameDocument: 'PERSON_PREVIOUS_NAME_DOCUMENT',
    PersonExternalDecisionDocument: 'PERSON_EXTERNAL_DECISION_DOCUMENT',
    PersonPrescriptionPowerDocument: 'PERSON_PRESCRIPTION_POWER_DOCUMENT',
    PersonMigratedDocument: 'PERSON_MIGRATED_DOCUMENT',
    ActivityLicenseMedicalTechnologyProject: 'ACTIVITY_LICENSE_MEDICAL_TECHNOLOGY_PROJECT',
    ActivityLicenseRightOfUseForRooms: 'ACTIVITY_LICENSE_RIGHT_OF_USE_FOR_ROOMS',
    ActivityLicenseListOfDevices: 'ACTIVITY_LICENSE_LIST_OF_DEVICES',
    ActivityLicensePrimaryCarePhysicianInfo: 'ACTIVITY_LICENSE_PRIMARY_CARE_PHYSICIAN_INFO',
    ActivityLicenseSchoolNurseImmunisation: 'ACTIVITY_LICENSE_SCHOOL_NURSE_IMMUNISATION',
    ActivityLicenseNurseTrainings: 'ACTIVITY_LICENSE_NURSE_TRAININGS',
    ActivityLicenseMidwifeHomeBirth: 'ACTIVITY_LICENSE_MIDWIFE_HOME_BIRTH',
    ActivityLicenseOther: 'ACTIVITY_LICENSE_OTHER',
    ActivityLicensePersonnelAgreement: 'ACTIVITY_LICENSE_PERSONNEL_AGREEMENT',
    ActivityLicenseAmbulance: 'ACTIVITY_LICENSE_AMBULANCE',
    ActivityLicenseOnSiteInspectionProtocol: 'ACTIVITY_LICENSE_ON_SITE_INSPECTION_PROTOCOL',
    ActivityLicenseApplication: 'ACTIVITY_LICENSE_APPLICATION',
    ActivityLicenseDecision: 'ACTIVITY_LICENSE_DECISION',
    LicenseAcquisitionEconomicActivityConfirmation: 'LICENSE_ACQUISITION_ECONOMIC_ACTIVITY_CONFIRMATION',
    LicenseAcquisitionPropertyTransitionConfirmation: 'LICENSE_ACQUISITION_PROPERTY_TRANSITION_CONFIRMATION',
    LicenseAcquisitionHealthcareProvidingConfirmation: 'LICENSE_ACQUISITION_HEALTHCARE_PROVIDING_CONFIRMATION',
    LicenseAcquisitionPreviousOwnerAgreement: 'LICENSE_ACQUISITION_PREVIOUS_OWNER_AGREEMENT',
    LicenseAcquisitionPersonnelList: 'LICENSE_ACQUISITION_PERSONNEL_LIST',
    LicenseAcquisitionOnSiteInspectionProtocol: 'LICENSE_ACQUISITION_ON_SITE_INSPECTION_PROTOCOL',
    ActivityLicenseInvalidationDocument: 'ACTIVITY_LICENSE_INVALIDATION_DOCUMENT',
    ActivityLocationInvalidationDocument: 'ACTIVITY_LOCATION_INVALIDATION_DOCUMENT',
    LicenseExternalFile: 'LICENSE_EXTERNAL_FILE',
    OpenDataGeneralPartitionerListFile: 'OPEN_DATA_GENERAL_PARTITIONER_LIST_FILE',
    OpenDataCompaniesFile: 'OPEN_DATA_COMPANIES_FILE',
    LicenseRestorationOnSiteInspectionProtocol: 'LICENSE_RESTORATION_ON_SITE_INSPECTION_PROTOCOL',
    OpenDataPharmacistsFile: 'OPEN_DATA_PHARMACISTS_FILE',
    OpenDataSpecialitiesFile: 'OPEN_DATA_SPECIALITIES_FILE',
    OpenDataOccupationsFile: 'OPEN_DATA_OCCUPATIONS_FILE',
    MigratedLicenseDecision: 'MIGRATED_LICENSE_DECISION',
    OpenDataServicesFile: 'OPEN_DATA_SERVICES_FILE',
    LocationRestorationOnSiteInspectionProtocol: 'LOCATION_RESTORATION_ON_SITE_INSPECTION_PROTOCOL',
    ServiceRestorationOnSiteInspectionProtocol: 'SERVICE_RESTORATION_ON_SITE_INSPECTION_PROTOCOL',
    PowersOfAttornery: 'POWERS_OF_ATTORNERY',
    ActivityLicenseFilledApplication: 'ACTIVITY_LICENSE_FILLED_APPLICATION',
    ActivityLicenseRequirementsTable: 'ACTIVITY_LICENSE_REQUIREMENTS_TABLE',
    ActivityLicenseEmployeeQualifications: 'ACTIVITY_LICENSE_EMPLOYEE_QUALIFICATIONS',
    PersonDiploma: 'PERSON_DIPLOMA',
    PersonAcademicReport: 'PERSON_ACADEMIC_REPORT',
    PersonAdditionalDocument: 'PERSON_ADDITIONAL_DOCUMENT',
    PersonNameChangeDocument: 'PERSON_NAME_CHANGE_DOCUMENT'
} as const;

export type FileObjectType = typeof FileObjectType[keyof typeof FileObjectType];


/**
 * 
 * @export
 * @enum {string}
 */

export const FileObjectTypePublic = {
    ProceedingDiploma: 'PROCEEDING_DIPLOMA',
    ProceedingAcademicReport: 'PROCEEDING_ACADEMIC_REPORT',
    ProceedingAdditionalDocument: 'PROCEEDING_ADDITIONAL_DOCUMENT',
    ProceedingNameChangeDocument: 'PROCEEDING_NAME_CHANGE_DOCUMENT',
    ProceedingComplianceExamCert: 'PROCEEDING_COMPLIANCE_EXAM_CERT',
    ProceedingAptitudeTestCert: 'PROCEEDING_APTITUDE_TEST_CERT',
    ApplicationAcceptanceDecision: 'APPLICATION_ACCEPTANCE_DECISION',
    ApplicationDeclinationDecision: 'APPLICATION_DECLINATION_DECISION',
    ApplicationSendToComplianceExamDecision: 'APPLICATION_SEND_TO_COMPLIANCE_EXAM_DECISION',
    ApplicationSendToAptitudeTestDecision: 'APPLICATION_SEND_TO_APTITUDE_TEST_DECISION',
    ApplicationComplianceExamResultDecision: 'APPLICATION_COMPLIANCE_EXAM_RESULT_DECISION',
    ApplicationAptitudeTestResultDecision: 'APPLICATION_APTITUDE_TEST_RESULT_DECISION',
    PersonSuspensionDecision: 'PERSON_SUSPENSION_DECISION',
    PersonPreviousNameDocument: 'PERSON_PREVIOUS_NAME_DOCUMENT',
    PersonExternalDecisionDocument: 'PERSON_EXTERNAL_DECISION_DOCUMENT',
    PersonPrescriptionPowerDocument: 'PERSON_PRESCRIPTION_POWER_DOCUMENT',
    PersonMigratedDocument: 'PERSON_MIGRATED_DOCUMENT',
    ActivityLicenseMedicalTechnologyProject: 'ACTIVITY_LICENSE_MEDICAL_TECHNOLOGY_PROJECT',
    ActivityLicenseRightOfUseForRooms: 'ACTIVITY_LICENSE_RIGHT_OF_USE_FOR_ROOMS',
    ActivityLicenseListOfDevices: 'ACTIVITY_LICENSE_LIST_OF_DEVICES',
    ActivityLicensePrimaryCarePhysicianInfo: 'ACTIVITY_LICENSE_PRIMARY_CARE_PHYSICIAN_INFO',
    ActivityLicenseSchoolNurseImmunisation: 'ACTIVITY_LICENSE_SCHOOL_NURSE_IMMUNISATION',
    ActivityLicenseNurseTrainings: 'ACTIVITY_LICENSE_NURSE_TRAININGS',
    ActivityLicenseMidwifeHomeBirth: 'ACTIVITY_LICENSE_MIDWIFE_HOME_BIRTH',
    ActivityLicenseOther: 'ACTIVITY_LICENSE_OTHER',
    ActivityLicensePersonnelAgreement: 'ACTIVITY_LICENSE_PERSONNEL_AGREEMENT',
    ActivityLicenseAmbulance: 'ACTIVITY_LICENSE_AMBULANCE',
    ActivityLicenseOnSiteInspectionProtocol: 'ACTIVITY_LICENSE_ON_SITE_INSPECTION_PROTOCOL',
    ActivityLicenseApplication: 'ACTIVITY_LICENSE_APPLICATION',
    ActivityLicenseDecision: 'ACTIVITY_LICENSE_DECISION',
    LicenseAcquisitionEconomicActivityConfirmation: 'LICENSE_ACQUISITION_ECONOMIC_ACTIVITY_CONFIRMATION',
    LicenseAcquisitionPropertyTransitionConfirmation: 'LICENSE_ACQUISITION_PROPERTY_TRANSITION_CONFIRMATION',
    LicenseAcquisitionHealthcareProvidingConfirmation: 'LICENSE_ACQUISITION_HEALTHCARE_PROVIDING_CONFIRMATION',
    LicenseAcquisitionPreviousOwnerAgreement: 'LICENSE_ACQUISITION_PREVIOUS_OWNER_AGREEMENT',
    LicenseAcquisitionPersonnelList: 'LICENSE_ACQUISITION_PERSONNEL_LIST',
    LicenseAcquisitionOnSiteInspectionProtocol: 'LICENSE_ACQUISITION_ON_SITE_INSPECTION_PROTOCOL',
    ActivityLicenseInvalidationDocument: 'ACTIVITY_LICENSE_INVALIDATION_DOCUMENT',
    ActivityLocationInvalidationDocument: 'ACTIVITY_LOCATION_INVALIDATION_DOCUMENT',
    LicenseExternalFile: 'LICENSE_EXTERNAL_FILE',
    OpenDataGeneralPartitionerListFile: 'OPEN_DATA_GENERAL_PARTITIONER_LIST_FILE',
    OpenDataCompaniesFile: 'OPEN_DATA_COMPANIES_FILE',
    LicenseRestorationOnSiteInspectionProtocol: 'LICENSE_RESTORATION_ON_SITE_INSPECTION_PROTOCOL',
    OpenDataPharmacistsFile: 'OPEN_DATA_PHARMACISTS_FILE',
    OpenDataSpecialitiesFile: 'OPEN_DATA_SPECIALITIES_FILE',
    OpenDataOccupationsFile: 'OPEN_DATA_OCCUPATIONS_FILE',
    MigratedLicenseDecision: 'MIGRATED_LICENSE_DECISION',
    OpenDataServicesFile: 'OPEN_DATA_SERVICES_FILE',
    LocationRestorationOnSiteInspectionProtocol: 'LOCATION_RESTORATION_ON_SITE_INSPECTION_PROTOCOL',
    ServiceRestorationOnSiteInspectionProtocol: 'SERVICE_RESTORATION_ON_SITE_INSPECTION_PROTOCOL',
    PowersOfAttornery: 'POWERS_OF_ATTORNERY',
    ActivityLicenseFilledApplication: 'ACTIVITY_LICENSE_FILLED_APPLICATION',
    ActivityLicenseRequirementsTable: 'ACTIVITY_LICENSE_REQUIREMENTS_TABLE',
    ActivityLicenseEmployeeQualifications: 'ACTIVITY_LICENSE_EMPLOYEE_QUALIFICATIONS'
} as const;

export type FileObjectTypePublic = typeof FileObjectTypePublic[keyof typeof FileObjectTypePublic];


/**
 * 
 * @export
 * @interface FileReference
 */
export interface FileReference {
    /**
     * 
     * @type {string}
     * @memberof FileReference
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileReference
     */
    'minioFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileReference
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileReference
     */
    'objectId'?: string;
    /**
     * 
     * @type {FileObjectType}
     * @memberof FileReference
     */
    'fileObjectType'?: FileObjectType;
}
/**
 * 
 * @export
 * @interface FileReferencePublic
 */
export interface FileReferencePublic {
    /**
     * 
     * @type {string}
     * @memberof FileReferencePublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileReferencePublic
     */
    'minioFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileReferencePublic
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileReferencePublic
     */
    'objectId'?: string;
    /**
     * 
     * @type {FileObjectTypePublic}
     * @memberof FileReferencePublic
     */
    'fileObjectType'?: FileObjectTypePublic;
}
/**
 * 
 * @export
 * @interface FileTemplate
 */
export interface FileTemplate {
    /**
     * 
     * @type {FileObjectType}
     * @memberof FileTemplate
     */
    'fileObjectType'?: FileObjectType;
    /**
     * 
     * @type {string}
     * @memberof FileTemplate
     */
    'templateUrl'?: string;
}
/**
 * 
 * @export
 * @interface GeneralPractitioner
 */
export interface GeneralPractitioner {
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitioner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitioner
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitioner
     */
    'deactivationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitioner
     */
    'occupationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitioner
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitioner
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface GeneralPractitionerList
 */
export interface GeneralPractitionerList {
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerList
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerList
     */
    'status': GeneralPractitionerListStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerList
     */
    'maxPatientsSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerList
     */
    'actualPatientsSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerList
     */
    'actualPatientsSizeDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerList
     */
    'insuredPersonsSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerList
     */
    'uninsuredPersonsSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerList
     */
    'vacanciesSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerList
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerList
     */
    'liquidationDate'?: string;
    /**
     * 
     * @type {ServiceArea}
     * @memberof GeneralPractitionerList
     */
    'serviceArea': ServiceArea;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralPractitionerList
     */
    'specificServiceAreas': Array<string>;
    /**
     * 
     * @type {GeneralPractitioner}
     * @memberof GeneralPractitionerList
     */
    'generalPractitioner': GeneralPractitioner;
    /**
     * 
     * @type {Substitute}
     * @memberof GeneralPractitionerList
     */
    'substitute': Substitute;
    /**
     * 
     * @type {Array<GeneralPractitionerListLicense>}
     * @memberof GeneralPractitionerList
     */
    'licenses': Array<GeneralPractitionerListLicense>;
    /**
     * 
     * @type {Array<GeneralPractitionerListEmployee>}
     * @memberof GeneralPractitionerList
     */
    'employees': Array<GeneralPractitionerListEmployee>;
    /**
     * 
     * @type {Array<GeneralPractitionerListAppointment>}
     * @memberof GeneralPractitionerList
     */
    'appointments': Array<GeneralPractitionerListAppointment>;
    /**
     * 
     * @type {Array<GeneralPractitionerListConsultation>}
     * @memberof GeneralPractitionerList
     */
    'consultations': Array<GeneralPractitionerListConsultation>;
    /**
     * 
     * @type {Array<GeneralPractitionerListSubstituteDoctor>}
     * @memberof GeneralPractitionerList
     */
    'substituteDoctors': Array<GeneralPractitionerListSubstituteDoctor>;
    /**
     * 
     * @type {Array<GeneralPractitionerListSubstituteDoctorPeriod>}
     * @memberof GeneralPractitionerList
     */
    'substituteDoctorsPeriod': Array<GeneralPractitionerListSubstituteDoctorPeriod>;
    /**
     * 
     * @type {Array<GeneralPractitionerListContact>}
     * @memberof GeneralPractitionerList
     */
    'contacts': Array<GeneralPractitionerListContact>;
}

export const GeneralPractitionerListStatusEnum = {
    Proceeding: 'IN_PROCEEDING',
    Register: 'IN_REGISTER',
    Archives: 'IN_ARCHIVES'
} as const;

export type GeneralPractitionerListStatusEnum = typeof GeneralPractitionerListStatusEnum[keyof typeof GeneralPractitionerListStatusEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralPractitionerListApplicationStatus = {
    SubmittedByOfficial: 'SUBMITTED_BY_OFFICIAL',
    UploadAcceptedDecision: 'UPLOAD_ACCEPTED_DECISION',
    SignAcceptedDecision: 'SIGN_ACCEPTED_DECISION',
    Accepted: 'ACCEPTED',
    Finished: 'FINISHED'
} as const;

export type GeneralPractitionerListApplicationStatus = typeof GeneralPractitionerListApplicationStatus[keyof typeof GeneralPractitionerListApplicationStatus];


/**
 * 
 * @export
 * @interface GeneralPractitionerListAppointment
 */
export interface GeneralPractitionerListAppointment {
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListAppointment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListAppointment
     */
    'employeeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListAppointment
     */
    'role': GeneralPractitionerListAppointmentRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListAppointment
     */
    'personId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralPractitionerListAppointment
     */
    'workingDays': Array<GeneralPractitionerListAppointmentWorkingDaysEnum>;
    /**
     * 
     * @type {LocalTime}
     * @memberof GeneralPractitionerListAppointment
     */
    'workingFrom': LocalTime;
    /**
     * 
     * @type {LocalTime}
     * @memberof GeneralPractitionerListAppointment
     */
    'workingTo': LocalTime;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListAppointment
     */
    'locationId': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListAppointment
     */
    'comment': string;
}

export const GeneralPractitionerListAppointmentRoleEnum = {
    GeneralPractitioner: 'GENERAL_PRACTITIONER',
    SubstituteDoctor: 'SUBSTITUTE_DOCTOR',
    FamilyNurse: 'FAMILY_NURSE',
    Doctor: 'DOCTOR'
} as const;

export type GeneralPractitionerListAppointmentRoleEnum = typeof GeneralPractitionerListAppointmentRoleEnum[keyof typeof GeneralPractitionerListAppointmentRoleEnum];
export const GeneralPractitionerListAppointmentWorkingDaysEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type GeneralPractitionerListAppointmentWorkingDaysEnum = typeof GeneralPractitionerListAppointmentWorkingDaysEnum[keyof typeof GeneralPractitionerListAppointmentWorkingDaysEnum];

/**
 * 
 * @export
 * @interface GeneralPractitionerListConsultation
 */
export interface GeneralPractitionerListConsultation {
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListConsultation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListConsultation
     */
    'employeeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListConsultation
     */
    'role': GeneralPractitionerListConsultationRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListConsultation
     */
    'personId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralPractitionerListConsultation
     */
    'workingDays': Array<GeneralPractitionerListConsultationWorkingDaysEnum>;
    /**
     * 
     * @type {LocalTime}
     * @memberof GeneralPractitionerListConsultation
     */
    'workingFrom': LocalTime;
    /**
     * 
     * @type {LocalTime}
     * @memberof GeneralPractitionerListConsultation
     */
    'workingTo': LocalTime;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListConsultation
     */
    'phone'?: string;
}

export const GeneralPractitionerListConsultationRoleEnum = {
    GeneralPractitioner: 'GENERAL_PRACTITIONER',
    SubstituteDoctor: 'SUBSTITUTE_DOCTOR',
    FamilyNurse: 'FAMILY_NURSE',
    Doctor: 'DOCTOR'
} as const;

export type GeneralPractitionerListConsultationRoleEnum = typeof GeneralPractitionerListConsultationRoleEnum[keyof typeof GeneralPractitionerListConsultationRoleEnum];
export const GeneralPractitionerListConsultationWorkingDaysEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type GeneralPractitionerListConsultationWorkingDaysEnum = typeof GeneralPractitionerListConsultationWorkingDaysEnum[keyof typeof GeneralPractitionerListConsultationWorkingDaysEnum];

/**
 * 
 * @export
 * @interface GeneralPractitionerListContact
 */
export interface GeneralPractitionerListContact {
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListContact
     */
    'locationId': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListContact
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListContact
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListContact
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListContact
     */
    'dialCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListContact
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListContact
     */
    'homepage'?: string;
    /**
     * 
     * @type {Array<WorkingDay>}
     * @memberof GeneralPractitionerListContact
     */
    'workingDays'?: Array<WorkingDay>;
    /**
     * 
     * @type {DigitalPlatform}
     * @memberof GeneralPractitionerListContact
     */
    'digitalPlatform'?: DigitalPlatform;
}
/**
 * 
 * @export
 * @interface GeneralPractitionerListEmployee
 */
export interface GeneralPractitionerListEmployee {
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListEmployee
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListEmployee
     */
    'personId': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListEmployee
     */
    'idCode': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListEmployee
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListEmployee
     */
    'lastName': string;
    /**
     * 
     * @type {OccupationCode}
     * @memberof GeneralPractitionerListEmployee
     */
    'occupationCode': OccupationCode;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListEmployee
     */
    'role': GeneralPractitionerListEmployeeRoleEnum;
    /**
     * 
     * @type {Array<Speciality>}
     * @memberof GeneralPractitionerListEmployee
     */
    'specialities': Array<Speciality>;
}

export const GeneralPractitionerListEmployeeRoleEnum = {
    GeneralPractitioner: 'GENERAL_PRACTITIONER',
    SubstituteDoctor: 'SUBSTITUTE_DOCTOR',
    FamilyNurse: 'FAMILY_NURSE',
    Doctor: 'DOCTOR'
} as const;

export type GeneralPractitionerListEmployeeRoleEnum = typeof GeneralPractitionerListEmployeeRoleEnum[keyof typeof GeneralPractitionerListEmployeeRoleEnum];

/**
 * 
 * @export
 * @interface GeneralPractitionerListLicense
 */
export interface GeneralPractitionerListLicense {
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListLicense
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListLicense
     */
    'companyRegistryCode': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListLicense
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListLicense
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListLicense
     */
    'licenseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListLicense
     */
    'licenseValidUntil'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListLicense
     */
    'licenseStartDate'?: string;
}
/**
 * 
 * @export
 * @interface GeneralPractitionerListLite
 */
export interface GeneralPractitionerListLite {
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListLite
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListLite
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListLite
     */
    'status': GeneralPractitionerListLiteStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerListLite
     */
    'maxPatientsSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerListLite
     */
    'vacanciesSize'?: number;
    /**
     * 
     * @type {ServiceArea}
     * @memberof GeneralPractitionerListLite
     */
    'serviceArea': ServiceArea;
    /**
     * 
     * @type {GeneralPractitioner}
     * @memberof GeneralPractitionerListLite
     */
    'generalPractitioner': GeneralPractitioner;
    /**
     * 
     * @type {Substitute}
     * @memberof GeneralPractitionerListLite
     */
    'substitute': Substitute;
}

export const GeneralPractitionerListLiteStatusEnum = {
    Proceeding: 'IN_PROCEEDING',
    Register: 'IN_REGISTER',
    Archives: 'IN_ARCHIVES'
} as const;

export type GeneralPractitionerListLiteStatusEnum = typeof GeneralPractitionerListLiteStatusEnum[keyof typeof GeneralPractitionerListLiteStatusEnum];

/**
 * 
 * @export
 * @interface GeneralPractitionerListSearch
 */
export interface GeneralPractitionerListSearch {
    /**
     * 
     * @type {Array<GeneralPractitionerList>}
     * @memberof GeneralPractitionerListSearch
     */
    'content'?: Array<GeneralPractitionerList>;
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerListSearch
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerListSearch
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerListSearch
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerListSearch
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface GeneralPractitionerListSearchFilter
 */
export interface GeneralPractitionerListSearchFilter {
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'registryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'gpOccupationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'substituteOccupationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'status'?: GeneralPractitionerListSearchFilterStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'gpSearchCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'companyName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'onlyWithVacancies'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'serviceAreaValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'employeeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'employeeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'licenseCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'locationId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralPractitionerListSearchFilter
     */
    'employeeIds'?: Array<string>;
}

export const GeneralPractitionerListSearchFilterStatusEnum = {
    Proceeding: 'IN_PROCEEDING',
    Register: 'IN_REGISTER',
    Archives: 'IN_ARCHIVES'
} as const;

export type GeneralPractitionerListSearchFilterStatusEnum = typeof GeneralPractitionerListSearchFilterStatusEnum[keyof typeof GeneralPractitionerListSearchFilterStatusEnum];

/**
 * 
 * @export
 * @interface GeneralPractitionerListSubstituteDoctor
 */
export interface GeneralPractitionerListSubstituteDoctor {
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSubstituteDoctor
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSubstituteDoctor
     */
    'personId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSubstituteDoctor
     */
    'idCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSubstituteDoctor
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSubstituteDoctor
     */
    'lastName'?: string;
    /**
     * 
     * @type {OccupationCode}
     * @memberof GeneralPractitionerListSubstituteDoctor
     */
    'occupationCode'?: OccupationCode;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSubstituteDoctor
     */
    'role'?: GeneralPractitionerListSubstituteDoctorRoleEnum;
    /**
     * 
     * @type {Array<Speciality>}
     * @memberof GeneralPractitionerListSubstituteDoctor
     */
    'specialities'?: Array<Speciality>;
}

export const GeneralPractitionerListSubstituteDoctorRoleEnum = {
    GeneralPractitioner: 'GENERAL_PRACTITIONER',
    SubstituteDoctor: 'SUBSTITUTE_DOCTOR',
    FamilyNurse: 'FAMILY_NURSE',
    Doctor: 'DOCTOR'
} as const;

export type GeneralPractitionerListSubstituteDoctorRoleEnum = typeof GeneralPractitionerListSubstituteDoctorRoleEnum[keyof typeof GeneralPractitionerListSubstituteDoctorRoleEnum];

/**
 * 
 * @export
 * @interface GeneralPractitionerListSubstituteDoctorPeriod
 */
export interface GeneralPractitionerListSubstituteDoctorPeriod {
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSubstituteDoctorPeriod
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSubstituteDoctorPeriod
     */
    'substituteDoctorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSubstituteDoctorPeriod
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerListSubstituteDoctorPeriod
     */
    'dateTo'?: string;
}
/**
 * 
 * @export
 * @interface HealthCareProfessional
 */
export interface HealthCareProfessional {
    /**
     * 
     * @type {string}
     * @memberof HealthCareProfessional
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthCareProfessional
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthCareProfessional
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthCareProfessional
     */
    'idCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthCareProfessional
     */
    'foreignIdCode'?: string;
    /**
     * 
     * @type {Array<OccupationCode>}
     * @memberof HealthCareProfessional
     */
    'occupationCodes'?: Array<OccupationCode>;
    /**
     * 
     * @type {Array<Speciality>}
     * @memberof HealthCareProfessional
     */
    'specialities'?: Array<Speciality>;
    /**
     * 
     * @type {Array<SpecialistCode>}
     * @memberof HealthCareProfessional
     */
    'specialistCodes'?: Array<SpecialistCode>;
}
/**
 * 
 * @export
 * @interface HealthCareProfessionalPublicSearchFilter
 */
export interface HealthCareProfessionalPublicSearchFilter {
    /**
     * 
     * @type {number}
     * @memberof HealthCareProfessionalPublicSearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof HealthCareProfessionalPublicSearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof HealthCareProfessionalPublicSearchFilter
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof HealthCareProfessionalPublicSearchFilter
     */
    'occupationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthCareProfessionalPublicSearchFilter
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthCareProfessionalPublicSearchFilter
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthCareProfessionalPublicSearchFilter
     */
    'occupation'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthCareProfessionalPublicSearchFilter
     */
    'speciality'?: string;
}
/**
 * 
 * @export
 * @interface HealthCareProfessionalPublicSearchResponse
 */
export interface HealthCareProfessionalPublicSearchResponse {
    /**
     * 
     * @type {Array<HealthCareProfessional>}
     * @memberof HealthCareProfessionalPublicSearchResponse
     */
    'content'?: Array<HealthCareProfessional>;
    /**
     * 
     * @type {number}
     * @memberof HealthCareProfessionalPublicSearchResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof HealthCareProfessionalPublicSearchResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof HealthCareProfessionalPublicSearchResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof HealthCareProfessionalPublicSearchResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface InitiateActivityLocationRestorationRequest
 */
export interface InitiateActivityLocationRestorationRequest {
    /**
     * 
     * @type {RestorationCreation}
     * @memberof InitiateActivityLocationRestorationRequest
     */
    'restoration': RestorationCreation;
    /**
     * 
     * @type {Array<any>}
     * @memberof InitiateActivityLocationRestorationRequest
     */
    'file': Array<any>;
}
/**
 * 
 * @export
 * @interface InsuranceContract
 */
export interface InsuranceContract {
    /**
     * 
     * @type {string}
     * @memberof InsuranceContract
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContract
     */
    'insurerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContract
     */
    'insurerRegCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContract
     */
    'policyHolderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContract
     */
    'policyHolderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContract
     */
    'policyHolderRegCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContract
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContract
     */
    'versionNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContract
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContract
     */
    'periodStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContract
     */
    'periodEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContract
     */
    'periodNewEndDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsuranceContract
     */
    'insuranceSum'?: number;
    /**
     * 
     * @type {InsuranceContractStatus}
     * @memberof InsuranceContract
     */
    'status'?: InsuranceContractStatus;
}
/**
 * 
 * @export
 * @interface InsuranceContractResponse
 */
export interface InsuranceContractResponse {
    /**
     * 
     * @type {Array<InsuranceContract>}
     * @memberof InsuranceContractResponse
     */
    'content'?: Array<InsuranceContract>;
    /**
     * 
     * @type {number}
     * @memberof InsuranceContractResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof InsuranceContractResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof InsuranceContractResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof InsuranceContractResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface InsuranceContractSearchFilter
 */
export interface InsuranceContractSearchFilter {
    /**
     * 
     * @type {number}
     * @memberof InsuranceContractSearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof InsuranceContractSearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsuranceContractSearchFilter
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContractSearchFilter
     */
    'policyHolderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContractSearchFilter
     */
    'policyHolder'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContractSearchFilter
     */
    'insurer'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContractSearchFilter
     */
    'number'?: string;
    /**
     * 
     * @type {Array<InsuranceContractStatus>}
     * @memberof InsuranceContractSearchFilter
     */
    'status'?: Array<InsuranceContractStatus>;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContractSearchFilter
     */
    'periodStartDate'?: string;
    /**
     * 
     * @type {DateOperator}
     * @memberof InsuranceContractSearchFilter
     */
    'periodStartDateOperator'?: DateOperator;
    /**
     * 
     * @type {string}
     * @memberof InsuranceContractSearchFilter
     */
    'periodEndDate'?: string;
    /**
     * 
     * @type {DateOperator}
     * @memberof InsuranceContractSearchFilter
     */
    'periodEndDateOperator'?: DateOperator;
    /**
     * 
     * @type {number}
     * @memberof InsuranceContractSearchFilter
     */
    'insuranceSumFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof InsuranceContractSearchFilter
     */
    'insuranceSumTo'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InsuranceContractStatus = {
    Valid: 'VALID',
    None: 'NONE',
    Expired: 'EXPIRED',
    Future: 'FUTURE'
} as const;

export type InsuranceContractStatus = typeof InsuranceContractStatus[keyof typeof InsuranceContractStatus];


/**
 * 
 * @export
 * @interface InvalidationCreation
 */
export interface InvalidationCreation {
    /**
     * 
     * @type {string}
     * @memberof InvalidationCreation
     */
    'reason'?: InvalidationCreationReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof InvalidationCreation
     */
    'courtName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidationCreation
     */
    'courtDecisionNumber'?: string;
}

export const InvalidationCreationReasonEnum = {
    Application: 'APPLICATION',
    CourtDecision: 'COURT_DECISION',
    BusinessRegistry: 'BUSINESS_REGISTRY'
} as const;

export type InvalidationCreationReasonEnum = typeof InvalidationCreationReasonEnum[keyof typeof InvalidationCreationReasonEnum];

/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'personId'?: string;
    /**
     * 
     * @type {Array<OccupationCode>}
     * @memberof Item
     */
    'occupationCodes'?: Array<OccupationCode>;
    /**
     * 
     * @type {Speciality}
     * @memberof Item
     */
    'speciality'?: Speciality;
    /**
     * 
     * @type {Array<Country>}
     * @memberof Item
     */
    'countries'?: Array<Country>;
    /**
     * 
     * @type {Array<Country>}
     * @memberof Item
     */
    'educationCountries'?: Array<Country>;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'applicationCertificateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface LicenseAcquisition
 */
export interface LicenseAcquisition {
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'id'?: string;
    /**
     * 
     * @type {Company}
     * @memberof LicenseAcquisition
     */
    'company'?: Company;
    /**
     * 
     * @type {Company}
     * @memberof LicenseAcquisition
     */
    'transferor'?: Company;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'submittingPersonRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'submissionTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof LicenseAcquisition
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {StateFee}
     * @memberof LicenseAcquisition
     */
    'stateFee'?: StateFee;
    /**
     * 
     * @type {number}
     * @memberof LicenseAcquisition
     */
    'currentStep'?: number;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof LicenseAcquisition
     */
    'fileReferences'?: Array<FileReference>;
    /**
     * 
     * @type {ActivityLicense}
     * @memberof LicenseAcquisition
     */
    'license'?: ActivityLicense;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'channelType'?: LicenseAcquisitionChannelTypeEnum;
    /**
     * 
     * @type {ActivityLicenseApplicationStatus}
     * @memberof LicenseAcquisition
     */
    'status'?: ActivityLicenseApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'renewalNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'renewalDeadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'protocolNumber'?: string;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof LicenseAcquisition
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseAcquisition
     */
    'saveAllowed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenseAcquisition
     */
    'proceedingType'?: LicenseAcquisitionProceedingTypeEnum;
}

export const LicenseAcquisitionChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type LicenseAcquisitionChannelTypeEnum = typeof LicenseAcquisitionChannelTypeEnum[keyof typeof LicenseAcquisitionChannelTypeEnum];
export const LicenseAcquisitionProceedingTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type LicenseAcquisitionProceedingTypeEnum = typeof LicenseAcquisitionProceedingTypeEnum[keyof typeof LicenseAcquisitionProceedingTypeEnum];

/**
 * 
 * @export
 * @interface LocalTime
 */
export interface LocalTime {
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'minute'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'second'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'nano'?: number;
}
/**
 * 
 * @export
 * @interface LogoutClientIdRequest
 */
export interface LogoutClientIdRequest {
    /**
     * 
     * @type {string}
     * @memberof LogoutClientIdRequest
     */
    'clientId'?: string;
}
/**
 * 
 * @export
 * @interface MigratedDecree
 */
export interface MigratedDecree {
    /**
     * 
     * @type {string}
     * @memberof MigratedDecree
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MigratedDecree
     */
    'fileName'?: string;
}
/**
 * 
 * @export
 * @interface MigratedDecreePublic
 */
export interface MigratedDecreePublic {
    /**
     * 
     * @type {string}
     * @memberof MigratedDecreePublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MigratedDecreePublic
     */
    'fileName'?: string;
}
/**
 * 
 * @export
 * @interface MigratedEducationPublic
 */
export interface MigratedEducationPublic {
    /**
     * 
     * @type {string}
     * @memberof MigratedEducationPublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MigratedEducationPublic
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof MigratedEducationPublic
     */
    'establishmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MigratedEducationPublic
     */
    'educationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MigratedEducationPublic
     */
    'documentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MigratedEducationPublic
     */
    'educationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MigratedEducationPublic
     */
    'documentNumber'?: string;
}
/**
 * 
 * @export
 * @interface MobileSignFinalizationDTO
 */
export interface MobileSignFinalizationDTO {
    /**
     * 
     * @type {string}
     * @memberof MobileSignFinalizationDTO
     */
    'containerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileSignFinalizationDTO
     */
    'signatureId'?: string;
}
/**
 * 
 * @export
 * @interface MobileSignInitResponse
 */
export interface MobileSignInitResponse {
    /**
     * 
     * @type {ErrorBody}
     * @memberof MobileSignInitResponse
     */
    'error'?: ErrorBody;
    /**
     * 
     * @type {string}
     * @memberof MobileSignInitResponse
     */
    'signatureId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileSignInitResponse
     */
    'challengeId'?: string;
}
/**
 * 
 * @export
 * @interface MobileSigningDTO
 */
export interface MobileSigningDTO {
    /**
     * 
     * @type {string}
     * @memberof MobileSigningDTO
     */
    'containerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileSigningDTO
     */
    'personIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileSigningDTO
     */
    'phoneNr'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileSigningDTO
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface Occupation
 */
export interface Occupation {
    /**
     * 
     * @type {string}
     * @memberof Occupation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Occupation
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Occupation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Occupation
     */
    'prefix': string;
    /**
     * 
     * @type {Array<Speciality>}
     * @memberof Occupation
     */
    'specialities': Array<Speciality>;
    /**
     * 
     * @type {string}
     * @memberof Occupation
     */
    'applicationDraftId'?: string;
}
/**
 * 
 * @export
 * @interface OccupationCode
 */
export interface OccupationCode {
    /**
     * 
     * @type {string}
     * @memberof OccupationCode
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OccupationCode
     */
    'prefix': string;
    /**
     * 
     * @type {string}
     * @memberof OccupationCode
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OccupationCode
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OccupationCode
     */
    'applicationId': string;
    /**
     * 
     * @type {string}
     * @memberof OccupationCode
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof OccupationCode
     */
    'occupationId': string;
}
/**
 * 
 * @export
 * @interface OccupationCodePublic
 */
export interface OccupationCodePublic {
    /**
     * 
     * @type {string}
     * @memberof OccupationCodePublic
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OccupationCodePublic
     */
    'prefix': string;
    /**
     * 
     * @type {string}
     * @memberof OccupationCodePublic
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OccupationCodePublic
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OccupationCodePublic
     */
    'applicationId': string;
    /**
     * 
     * @type {string}
     * @memberof OccupationCodePublic
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof OccupationCodePublic
     */
    'occupationId': string;
}
/**
 * 
 * @export
 * @interface OfficialApplication
 */
export interface OfficialApplication {
    /**
     * 
     * @type {string}
     * @memberof OfficialApplication
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficialApplication
     */
    'arrivalTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficialApplication
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficialApplication
     */
    'type'?: OfficialApplicationTypeEnum;
    /**
     * 
     * @type {ApplicationStatus}
     * @memberof OfficialApplication
     */
    'status'?: ApplicationStatus;
    /**
     * 
     * @type {number}
     * @memberof OfficialApplication
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfficialApplication
     */
    'personName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficialApplication
     */
    'proceedingDeadline'?: string;
    /**
     * 
     * @type {StateFee}
     * @memberof OfficialApplication
     */
    'stateFee'?: StateFee;
}

export const OfficialApplicationTypeEnum = {
    Occupation: 'OCCUPATION',
    Specialization: 'SPECIALIZATION',
    ApplicationCertificate: 'APPLICATION_CERTIFICATE'
} as const;

export type OfficialApplicationTypeEnum = typeof OfficialApplicationTypeEnum[keyof typeof OfficialApplicationTypeEnum];

/**
 * 
 * @export
 * @interface OfficialUser
 */
export interface OfficialUser {
    /**
     * 
     * @type {string}
     * @memberof OfficialUser
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficialUser
     */
    'idCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficialUser
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficialUser
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficialUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficialUser
     */
    'profession'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficialUser
     */
    'departmentName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficialUser
     */
    'isDefaultSigner'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfficialUser
     */
    'privileges'?: Array<OfficialUserPrivilegesEnum>;
    /**
     * 
     * @type {string}
     * @memberof OfficialUser
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficialUser
     */
    'officialType'?: OfficialUserOfficialTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OfficialUser
     */
    'visible'?: boolean;
}

export const OfficialUserPrivilegesEnum = {
    Admin: 'ADMIN',
    Proceede: 'PROCEEDE',
    Sign: 'SIGN',
    Official: 'OFFICIAL',
    Observe: 'OBSERVE',
    Person: 'PERSON',
    PowerOfAttorneyTypeUltimate: 'POWER_OF_ATTORNEY_TYPE_ULTIMATE',
    PowerOfAttorneyTypeFull: 'POWER_OF_ATTORNEY_TYPE_FULL',
    PowerOfAttorneyTypeLimited: 'POWER_OF_ATTORNEY_TYPE_LIMITED',
    PowerOfAttorneyTypeGpl: 'POWER_OF_ATTORNEY_TYPE_GPL',
    LicenseTypeFull: 'LICENSE_TYPE_FULL'
} as const;

export type OfficialUserPrivilegesEnum = typeof OfficialUserPrivilegesEnum[keyof typeof OfficialUserPrivilegesEnum];
export const OfficialUserOfficialTypeEnum = {
    Tht: 'THT'
} as const;

export type OfficialUserOfficialTypeEnum = typeof OfficialUserOfficialTypeEnum[keyof typeof OfficialUserOfficialTypeEnum];

/**
 * 
 * @export
 * @interface Parish
 */
export interface Parish {
    /**
     * 
     * @type {string}
     * @memberof Parish
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parish
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parish
     */
    'ehakov'?: string;
}
/**
 * 
 * @export
 * @interface ParishPublic
 */
export interface ParishPublic {
    /**
     * 
     * @type {string}
     * @memberof ParishPublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParishPublic
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParishPublic
     */
    'ehakov'?: string;
}
/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'idCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'foreignIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'foreignIdCodeCountryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'lastName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Person
     */
    'foreigner'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'citizenship'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'gender'?: PersonGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {ContactInfo}
     * @memberof Person
     */
    'contactInfo'?: ContactInfo;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'dateOfDeath'?: string;
    /**
     * 
     * @type {Array<PreviousName>}
     * @memberof Person
     */
    'previousNames'?: Array<PreviousName>;
    /**
     * 
     * @type {Array<OccupationCode>}
     * @memberof Person
     */
    'occupationCodes'?: Array<OccupationCode>;
    /**
     * 
     * @type {Array<Speciality>}
     * @memberof Person
     */
    'specialities'?: Array<Speciality>;
    /**
     * 
     * @type {Array<PersonCertificate>}
     * @memberof Person
     */
    'certificates'?: Array<PersonCertificate>;
    /**
     * 
     * @type {Array<ExternalDecision>}
     * @memberof Person
     */
    'externalDecisions'?: Array<ExternalDecision>;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'status'?: PersonStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'dateAddedToRegistry'?: string;
    /**
     * 
     * @type {Array<PersonSuspension>}
     * @memberof Person
     */
    'suspensions'?: Array<PersonSuspension>;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof Person
     */
    'migratedDocuments'?: Array<FileReference>;
    /**
     * 
     * @type {Array<Education>}
     * @memberof Person
     */
    'educations'?: Array<Education>;
    /**
     * 
     * @type {Array<MigratedDecree>}
     * @memberof Person
     */
    'migratedDecrees'?: Array<MigratedDecree>;
    /**
     * 
     * @type {Array<Employment>}
     * @memberof Person
     */
    'employments'?: Array<Employment>;
    /**
     * 
     * @type {ResidencyInfo}
     * @memberof Person
     */
    'residencyInfo'?: ResidencyInfo;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'fullName'?: string;
}

export const PersonGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type PersonGenderEnum = typeof PersonGenderEnum[keyof typeof PersonGenderEnum];
export const PersonStatusEnum = {
    Registered: 'REGISTERED',
    Applied: 'APPLIED',
    Paused: 'PAUSED',
    Archived: 'ARCHIVED'
} as const;

export type PersonStatusEnum = typeof PersonStatusEnum[keyof typeof PersonStatusEnum];

/**
 * 
 * @export
 * @interface PersonCertificate
 */
export interface PersonCertificate {
    /**
     * 
     * @type {string}
     * @memberof PersonCertificate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonCertificate
     */
    'personId'?: string;
    /**
     * 
     * @type {Array<OccupationCode>}
     * @memberof PersonCertificate
     */
    'occupationCodes'?: Array<OccupationCode>;
    /**
     * 
     * @type {Speciality}
     * @memberof PersonCertificate
     */
    'speciality'?: Speciality;
    /**
     * 
     * @type {Array<Country>}
     * @memberof PersonCertificate
     */
    'countries'?: Array<Country>;
    /**
     * 
     * @type {Array<Country>}
     * @memberof PersonCertificate
     */
    'educationCountries'?: Array<Country>;
    /**
     * 
     * @type {string}
     * @memberof PersonCertificate
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonCertificate
     */
    'applicationCertificateId'?: string;
}
/**
 * 
 * @export
 * @interface PersonCertificatePublic
 */
export interface PersonCertificatePublic {
    /**
     * 
     * @type {string}
     * @memberof PersonCertificatePublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonCertificatePublic
     */
    'personId'?: string;
    /**
     * 
     * @type {Array<OccupationCodePublic>}
     * @memberof PersonCertificatePublic
     */
    'occupationCodes'?: Array<OccupationCodePublic>;
    /**
     * 
     * @type {SpecialityPublic}
     * @memberof PersonCertificatePublic
     */
    'speciality'?: SpecialityPublic;
    /**
     * 
     * @type {Array<CountryPublic>}
     * @memberof PersonCertificatePublic
     */
    'countries'?: Array<CountryPublic>;
    /**
     * 
     * @type {Array<CountryPublic>}
     * @memberof PersonCertificatePublic
     */
    'educationCountries'?: Array<CountryPublic>;
    /**
     * 
     * @type {string}
     * @memberof PersonCertificatePublic
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonCertificatePublic
     */
    'applicationCertificateId'?: string;
}
/**
 * 
 * @export
 * @interface PersonCertificateSearchFilter
 */
export interface PersonCertificateSearchFilter {
    /**
     * 
     * @type {number}
     * @memberof PersonCertificateSearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonCertificateSearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonCertificateSearchFilter
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {DateRange}
     * @memberof PersonCertificateSearchFilter
     */
    'issueDate'?: DateRange;
}
/**
 * 
 * @export
 * @interface PersonCreation
 */
export interface PersonCreation {
    /**
     * 
     * @type {Person}
     * @memberof PersonCreation
     */
    'person'?: Person;
    /**
     * 
     * @type {boolean}
     * @memberof PersonCreation
     */
    'isGenerateIdCode'?: boolean;
}
/**
 * 
 * @export
 * @interface PersonDataResponse
 */
export interface PersonDataResponse {
    /**
     * 
     * @type {ErrorBody}
     * @memberof PersonDataResponse
     */
    'error'?: ErrorBody;
    /**
     * 
     * @type {Person}
     * @memberof PersonDataResponse
     */
    'person'?: Person;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonDataResponse
     */
    'notificationTypes'?: Array<PersonDataResponseNotificationTypesEnum>;
}

export const PersonDataResponseNotificationTypesEnum = {
    IsResidentOfEstoniaBasedOnRegistry: 'IS_RESIDENT_OF_ESTONIA_BASED_ON_REGISTRY',
    HasForeignPersonalCode: 'HAS_FOREIGN_PERSONAL_CODE',
    ExistsInSystemBasedOnForeignPersonalCode: 'EXISTS_IN_SYSTEM_BASED_ON_FOREIGN_PERSONAL_CODE',
    NewEstonianPersonalCodeAndResidencyWillBeUpdated: 'NEW_ESTONIAN_PERSONAL_CODE_AND_RESIDENCY_WILL_BE_UPDATED',
    PersonalDataWillBeUpdated: 'PERSONAL_DATA_WILL_BE_UPDATED',
    ApplicantDoesNotExistInSystem: 'APPLICANT_DOES_NOT_EXIST_IN_SYSTEM',
    ExistsInRegistryButNotResidentOfEstonia: 'EXISTS_IN_REGISTRY_BUT_NOT_RESIDENT_OF_ESTONIA',
    EstonianPersonalCodeWillBeAdded: 'ESTONIAN_PERSONAL_CODE_WILL_BE_ADDED',
    RegistryDoesNotContainPerson: 'REGISTRY_DOES_NOT_CONTAIN_PERSON',
    ApplicantCannotBeAdded: 'APPLICANT_CANNOT_BE_ADDED',
    ApplicantIsDead: 'APPLICANT_IS_DEAD'
} as const;

export type PersonDataResponseNotificationTypesEnum = typeof PersonDataResponseNotificationTypesEnum[keyof typeof PersonDataResponseNotificationTypesEnum];

/**
 * 
 * @export
 * @interface PersonPublic
 */
export interface PersonPublic {
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'idCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'foreignIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'foreignIdCodeCountryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'lastName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PersonPublic
     */
    'foreigner'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'citizenship'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'gender'?: PersonPublicGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {ContactInfoPublic}
     * @memberof PersonPublic
     */
    'contactInfo'?: ContactInfoPublic;
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'dateOfDeath'?: string;
    /**
     * 
     * @type {Array<PreviousNamePublic>}
     * @memberof PersonPublic
     */
    'previousNames'?: Array<PreviousNamePublic>;
    /**
     * 
     * @type {Array<OccupationCodePublic>}
     * @memberof PersonPublic
     */
    'occupationCodes'?: Array<OccupationCodePublic>;
    /**
     * 
     * @type {Array<SpecialityPublic>}
     * @memberof PersonPublic
     */
    'specialities'?: Array<SpecialityPublic>;
    /**
     * 
     * @type {Array<PersonCertificatePublic>}
     * @memberof PersonPublic
     */
    'certificates'?: Array<PersonCertificatePublic>;
    /**
     * 
     * @type {Array<ExternalDecisionPublic>}
     * @memberof PersonPublic
     */
    'externalDecisions'?: Array<ExternalDecisionPublic>;
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'status'?: PersonPublicStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'dateAddedToRegistry'?: string;
    /**
     * 
     * @type {Array<PersonSuspensionPublic>}
     * @memberof PersonPublic
     */
    'suspensions'?: Array<PersonSuspensionPublic>;
    /**
     * 
     * @type {Array<FileReferencePublic>}
     * @memberof PersonPublic
     */
    'migratedDocuments'?: Array<FileReferencePublic>;
    /**
     * 
     * @type {Array<MigratedEducationPublic>}
     * @memberof PersonPublic
     */
    'migratedEducations'?: Array<MigratedEducationPublic>;
    /**
     * 
     * @type {Array<MigratedDecreePublic>}
     * @memberof PersonPublic
     */
    'migratedDecrees'?: Array<MigratedDecreePublic>;
    /**
     * 
     * @type {Array<EmploymentPublic>}
     * @memberof PersonPublic
     */
    'employments'?: Array<EmploymentPublic>;
    /**
     * 
     * @type {ResidencyInfoPublic}
     * @memberof PersonPublic
     */
    'residencyInfo'?: ResidencyInfoPublic;
    /**
     * 
     * @type {string}
     * @memberof PersonPublic
     */
    'fullName'?: string;
}

export const PersonPublicGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type PersonPublicGenderEnum = typeof PersonPublicGenderEnum[keyof typeof PersonPublicGenderEnum];
export const PersonPublicStatusEnum = {
    Registered: 'REGISTERED',
    Applied: 'APPLIED',
    Paused: 'PAUSED',
    Archived: 'ARCHIVED'
} as const;

export type PersonPublicStatusEnum = typeof PersonPublicStatusEnum[keyof typeof PersonPublicStatusEnum];

/**
 * 
 * @export
 * @interface PersonResponse
 */
export interface PersonResponse {
    /**
     * 
     * @type {ErrorBody}
     * @memberof PersonResponse
     */
    'error'?: ErrorBody;
    /**
     * 
     * @type {Person}
     * @memberof PersonResponse
     */
    'person'?: Person;
}
/**
 * 
 * @export
 * @interface PersonSearch
 */
export interface PersonSearch {
    /**
     * 
     * @type {string}
     * @memberof PersonSearch
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSearch
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSearch
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSearch
     */
    'status'?: PersonSearchStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonSearch
     */
    'idCodeCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSearch
     */
    'idCode'?: string;
    /**
     * 
     * @type {Array<OccupationCode>}
     * @memberof PersonSearch
     */
    'occupationCodes'?: Array<OccupationCode>;
    /**
     * 
     * @type {Array<Speciality>}
     * @memberof PersonSearch
     */
    'specialities'?: Array<Speciality>;
}

export const PersonSearchStatusEnum = {
    Registered: 'REGISTERED',
    Applied: 'APPLIED',
    Paused: 'PAUSED',
    Archived: 'ARCHIVED'
} as const;

export type PersonSearchStatusEnum = typeof PersonSearchStatusEnum[keyof typeof PersonSearchStatusEnum];

/**
 * 
 * @export
 * @interface PersonSearchFilter
 */
export interface PersonSearchFilter {
    /**
     * 
     * @type {number}
     * @memberof PersonSearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonSearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonSearchFilter
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PersonSearchFilter
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSearchFilter
     */
    'idCodeCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSearchFilter
     */
    'idCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSearchFilter
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSearchFilter
     */
    'sex'?: PersonSearchFilterSexEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonSearchFilter
     */
    'citizenship'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSearchFilter
     */
    'status'?: PersonSearchFilterStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonSearchFilter
     */
    'occupationCode'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonSearchFilter
     */
    'occupationNames'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonSearchFilter
     */
    'specialityCodes'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PersonSearchFilter
     */
    'educationCountryGroup'?: PersonSearchFilterEducationCountryGroupEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonSearchFilter
     */
    'educationCountries'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PersonSearchFilter
     */
    'fullNameOrOccupationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSearchFilter
     */
    'fullNameOrOccupationCodeOrIdCode'?: string;
}

export const PersonSearchFilterSexEnum = {
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type PersonSearchFilterSexEnum = typeof PersonSearchFilterSexEnum[keyof typeof PersonSearchFilterSexEnum];
export const PersonSearchFilterStatusEnum = {
    Registered: 'REGISTERED',
    Applied: 'APPLIED',
    Paused: 'PAUSED',
    Archived: 'ARCHIVED'
} as const;

export type PersonSearchFilterStatusEnum = typeof PersonSearchFilterStatusEnum[keyof typeof PersonSearchFilterStatusEnum];
export const PersonSearchFilterEducationCountryGroupEnum = {
    Estonia: 'ESTONIA',
    Eca: 'ECA',
    Third: 'THIRD'
} as const;

export type PersonSearchFilterEducationCountryGroupEnum = typeof PersonSearchFilterEducationCountryGroupEnum[keyof typeof PersonSearchFilterEducationCountryGroupEnum];

/**
 * 
 * @export
 * @interface PersonSearchResponse
 */
export interface PersonSearchResponse {
    /**
     * 
     * @type {Array<PersonSearch>}
     * @memberof PersonSearchResponse
     */
    'content'?: Array<PersonSearch>;
    /**
     * 
     * @type {number}
     * @memberof PersonSearchResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonSearchResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonSearchResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonSearchResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PersonSuspension
 */
export interface PersonSuspension {
    /**
     * 
     * @type {string}
     * @memberof PersonSuspension
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspension
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspension
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspension
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspension
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspension
     */
    'courtName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspension
     */
    'courtDecisionNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspension
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspension
     */
    'endDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PersonSuspension
     */
    'waitingForSignature'?: boolean;
    /**
     * 
     * @type {FileReference}
     * @memberof PersonSuspension
     */
    'fileReference'?: FileReference;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspension
     */
    'decisionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspension
     */
    'decisionNumber'?: string;
}
/**
 * 
 * @export
 * @interface PersonSuspensionPublic
 */
export interface PersonSuspensionPublic {
    /**
     * 
     * @type {string}
     * @memberof PersonSuspensionPublic
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspensionPublic
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspensionPublic
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspensionPublic
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspensionPublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspensionPublic
     */
    'courtName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspensionPublic
     */
    'courtDecisionNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspensionPublic
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspensionPublic
     */
    'endDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PersonSuspensionPublic
     */
    'waitingForSignature'?: boolean;
    /**
     * 
     * @type {FileReferencePublic}
     * @memberof PersonSuspensionPublic
     */
    'fileReference'?: FileReferencePublic;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspensionPublic
     */
    'decisionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonSuspensionPublic
     */
    'decisionNumber'?: string;
}
/**
 * 
 * @export
 * @interface PersonalData
 */
export interface PersonalData {
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'idCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'foreignIdCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'foreignIdCodeCountryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'lastName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PersonalData
     */
    'foreigner'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'citizenship'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'gender'?: PersonalDataGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'status'?: PersonalDataStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'dateOfDeath'?: string;
}

export const PersonalDataGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type PersonalDataGenderEnum = typeof PersonalDataGenderEnum[keyof typeof PersonalDataGenderEnum];
export const PersonalDataStatusEnum = {
    Registered: 'REGISTERED',
    Applied: 'APPLIED',
    Paused: 'PAUSED',
    Archived: 'ARCHIVED'
} as const;

export type PersonalDataStatusEnum = typeof PersonalDataStatusEnum[keyof typeof PersonalDataStatusEnum];

/**
 * 
 * @export
 * @interface PowerOfAttorney
 */
export interface PowerOfAttorney {
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'personCode': string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'personFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'personLastName': string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'personEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'companyRegNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'type': PowerOfAttorneyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PowerOfAttorney
     */
    'status'?: PowerOfAttorneyStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PowerOfAttorney
     */
    'daysLeft'?: number;
}

export const PowerOfAttorneyTypeEnum = {
    Ultimate: 'TYPE_ULTIMATE',
    Full: 'TYPE_FULL',
    Limited: 'TYPE_LIMITED',
    Gpl: 'TYPE_GPL'
} as const;

export type PowerOfAttorneyTypeEnum = typeof PowerOfAttorneyTypeEnum[keyof typeof PowerOfAttorneyTypeEnum];
export const PowerOfAttorneyStatusEnum = {
    Valid: 'VALID',
    Scheduled: 'SCHEDULED',
    Expired: 'EXPIRED'
} as const;

export type PowerOfAttorneyStatusEnum = typeof PowerOfAttorneyStatusEnum[keyof typeof PowerOfAttorneyStatusEnum];

/**
 * 
 * @export
 * @interface PowersOfAttorneySearchFilter
 */
export interface PowersOfAttorneySearchFilter {
    /**
     * 
     * @type {number}
     * @memberof PowersOfAttorneySearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PowersOfAttorneySearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowersOfAttorneySearchFilter
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PowersOfAttorneySearchFilter
     */
    'personCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowersOfAttorneySearchFilter
     */
    'personFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowersOfAttorneySearchFilter
     */
    'personLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowersOfAttorneySearchFilter
     */
    'personEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowersOfAttorneySearchFilter
     */
    'companyRegNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowersOfAttorneySearchFilter
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowersOfAttorneySearchFilter
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowersOfAttorneySearchFilter
     */
    'startDateOperator'?: PowersOfAttorneySearchFilterStartDateOperatorEnum;
    /**
     * 
     * @type {string}
     * @memberof PowersOfAttorneySearchFilter
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowersOfAttorneySearchFilter
     */
    'endDateOperator'?: PowersOfAttorneySearchFilterEndDateOperatorEnum;
    /**
     * 
     * @type {string}
     * @memberof PowersOfAttorneySearchFilter
     */
    'type'?: PowersOfAttorneySearchFilterTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowersOfAttorneySearchFilter
     */
    'status'?: Array<PowersOfAttorneySearchFilterStatusEnum>;
}

export const PowersOfAttorneySearchFilterStartDateOperatorEnum = {
    Equals: 'EQUALS',
    LessThan: 'LESS_THAN',
    LessThanEqual: 'LESS_THAN_EQUAL',
    GreaterThan: 'GREATER_THAN',
    GreaterThanEqual: 'GREATER_THAN_EQUAL'
} as const;

export type PowersOfAttorneySearchFilterStartDateOperatorEnum = typeof PowersOfAttorneySearchFilterStartDateOperatorEnum[keyof typeof PowersOfAttorneySearchFilterStartDateOperatorEnum];
export const PowersOfAttorneySearchFilterEndDateOperatorEnum = {
    Equals: 'EQUALS',
    LessThan: 'LESS_THAN',
    LessThanEqual: 'LESS_THAN_EQUAL',
    GreaterThan: 'GREATER_THAN',
    GreaterThanEqual: 'GREATER_THAN_EQUAL'
} as const;

export type PowersOfAttorneySearchFilterEndDateOperatorEnum = typeof PowersOfAttorneySearchFilterEndDateOperatorEnum[keyof typeof PowersOfAttorneySearchFilterEndDateOperatorEnum];
export const PowersOfAttorneySearchFilterTypeEnum = {
    Ultimate: 'TYPE_ULTIMATE',
    Full: 'TYPE_FULL',
    Limited: 'TYPE_LIMITED',
    Gpl: 'TYPE_GPL'
} as const;

export type PowersOfAttorneySearchFilterTypeEnum = typeof PowersOfAttorneySearchFilterTypeEnum[keyof typeof PowersOfAttorneySearchFilterTypeEnum];
export const PowersOfAttorneySearchFilterStatusEnum = {
    Valid: 'VALID',
    Scheduled: 'SCHEDULED',
    Expired: 'EXPIRED'
} as const;

export type PowersOfAttorneySearchFilterStatusEnum = typeof PowersOfAttorneySearchFilterStatusEnum[keyof typeof PowersOfAttorneySearchFilterStatusEnum];

/**
 * 
 * @export
 * @interface PowersOfAttorneySearchResponse
 */
export interface PowersOfAttorneySearchResponse {
    /**
     * 
     * @type {Array<PowerOfAttorney>}
     * @memberof PowersOfAttorneySearchResponse
     */
    'content'?: Array<PowerOfAttorney>;
    /**
     * 
     * @type {number}
     * @memberof PowersOfAttorneySearchResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PowersOfAttorneySearchResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PowersOfAttorneySearchResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PowersOfAttorneySearchResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PreviousName
 */
export interface PreviousName {
    /**
     * 
     * @type {string}
     * @memberof PreviousName
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviousName
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviousName
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviousName
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof PreviousName
     */
    'fileReferences'?: Array<FileReference>;
}
/**
 * 
 * @export
 * @interface PreviousNamePublic
 */
export interface PreviousNamePublic {
    /**
     * 
     * @type {string}
     * @memberof PreviousNamePublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviousNamePublic
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviousNamePublic
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviousNamePublic
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<FileReferencePublic>}
     * @memberof PreviousNamePublic
     */
    'fileReferences'?: Array<FileReferencePublic>;
}
/**
 * 
 * @export
 * @interface ProceedingSearchResponse
 */
export interface ProceedingSearchResponse {
    /**
     * 
     * @type {Array<ApplicationSearch>}
     * @memberof ProceedingSearchResponse
     */
    'content'?: Array<ApplicationSearch>;
    /**
     * 
     * @type {number}
     * @memberof ProceedingSearchResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProceedingSearchResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProceedingSearchResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProceedingSearchResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface ProceedingStatus
 */
export interface ProceedingStatus {
    /**
     * 
     * @type {string}
     * @memberof ProceedingStatus
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingStatus
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingStatus
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingStatus
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingStatus
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingStatus
     */
    'status'?: ProceedingStatusStatusEnum;
}

export const ProceedingStatusStatusEnum = {
    Saved: 'SAVED',
    Registering: 'REGISTERING',
    Submitted: 'SUBMITTED',
    InProceeding: 'IN_PROCEEDING',
    Paused: 'PAUSED',
    PausedDueToComplianceExam: 'PAUSED_DUE_TO_COMPLIANCE_EXAM',
    PausedDueToAptitudeTest: 'PAUSED_DUE_TO_APTITUDE_TEST',
    UploadAcceptedDecision: 'UPLOAD_ACCEPTED_DECISION',
    UploadDeclinedDecision: 'UPLOAD_DECLINED_DECISION',
    UploadAptitudeTestDecision: 'UPLOAD_APTITUDE_TEST_DECISION',
    UploadAptitudeTestResult: 'UPLOAD_APTITUDE_TEST_RESULT',
    UploadComplianceExamDecision: 'UPLOAD_COMPLIANCE_EXAM_DECISION',
    UploadComplianceExamResult: 'UPLOAD_COMPLIANCE_EXAM_RESULT',
    SignAcceptedDecision: 'SIGN_ACCEPTED_DECISION',
    SignDeclinedDecision: 'SIGN_DECLINED_DECISION',
    SignComplianceExamDecision: 'SIGN_COMPLIANCE_EXAM_DECISION',
    SignAptitudeTestDecision: 'SIGN_APTITUDE_TEST_DECISION',
    SignComplianceExamResult: 'SIGN_COMPLIANCE_EXAM_RESULT',
    SignAptitudeTestResult: 'SIGN_APTITUDE_TEST_RESULT',
    Accepted: 'ACCEPTED',
    Declined: 'DECLINED',
    Finished: 'FINISHED',
    Deleted: 'DELETED'
} as const;

export type ProceedingStatusStatusEnum = typeof ProceedingStatusStatusEnum[keyof typeof ProceedingStatusStatusEnum];

/**
 * 
 * @export
 * @interface RemoteSigningDTO
 */
export interface RemoteSigningDTO {
    /**
     * 
     * @type {string}
     * @memberof RemoteSigningDTO
     */
    'containerId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoteSigningDTO
     */
    'certificate'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RemoteSigningFinalizationDTO
 */
export interface RemoteSigningFinalizationDTO {
    /**
     * 
     * @type {string}
     * @memberof RemoteSigningFinalizationDTO
     */
    'containerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteSigningFinalizationDTO
     */
    'signatureId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoteSigningFinalizationDTO
     */
    'signature'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RemoteSigningInitResponse
 */
export interface RemoteSigningInitResponse {
    /**
     * 
     * @type {ErrorBody}
     * @memberof RemoteSigningInitResponse
     */
    'error'?: ErrorBody;
    /**
     * 
     * @type {string}
     * @memberof RemoteSigningInitResponse
     */
    'generatedSignatureId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoteSigningInitResponse
     */
    'dataToSignHash'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RemoteSigningInitResponse
     */
    'digestAlgorithm'?: string;
}
/**
 * 
 * @export
 * @interface RemoveSpecialistServiceEmployee
 */
export interface RemoveSpecialistServiceEmployee {
    /**
     * 
     * @type {string}
     * @memberof RemoveSpecialistServiceEmployee
     */
    'specialistCode'?: string;
}
/**
 * 
 * @export
 * @interface Representative
 */
export interface Representative {
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'idCode': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'personRole': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    'fullName'?: string;
}
/**
 * 
 * @export
 * @interface RequiredRoom
 */
export interface RequiredRoom {
    /**
     * 
     * @type {string}
     * @memberof RequiredRoom
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RequiredRoom
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RequiredRoom
     */
    'roomType'?: RequiredRoomRoomTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredRoom
     */
    'isOptional'?: boolean;
}

export const RequiredRoomRoomTypeEnum = {
    Reception: 'RECEPTION',
    Examination: 'EXAMINATION',
    ExaminationAndReception: 'EXAMINATION_AND_RECEPTION'
} as const;

export type RequiredRoomRoomTypeEnum = typeof RequiredRoomRoomTypeEnum[keyof typeof RequiredRoomRoomTypeEnum];

/**
 * 
 * @export
 * @interface ResidencyInfo
 */
export interface ResidencyInfo {
    /**
     * 
     * @type {string}
     * @memberof ResidencyInfo
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResidencyInfo
     */
    'studyYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResidencyInfo
     */
    'curriculumCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidencyInfo
     */
    'curriculumName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidencyInfo
     */
    'schoolName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidencyInfo
     */
    'occupationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidencyInfo
     */
    'specialityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidencyInfo
     */
    'specialityCode'?: string;
}
/**
 * 
 * @export
 * @interface ResidencyInfoPublic
 */
export interface ResidencyInfoPublic {
    /**
     * 
     * @type {number}
     * @memberof ResidencyInfoPublic
     */
    'studyYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResidencyInfoPublic
     */
    'curriculumCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidencyInfoPublic
     */
    'curriculumName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidencyInfoPublic
     */
    'schoolName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidencyInfoPublic
     */
    'occupationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidencyInfoPublic
     */
    'specialityName'?: string;
}
/**
 * 
 * @export
 * @interface RestorationCreation
 */
export interface RestorationCreation {
    /**
     * 
     * @type {string}
     * @memberof RestorationCreation
     */
    'restorationDate'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RestorationStatus = {
    SubmittedByOfficial: 'SUBMITTED_BY_OFFICIAL',
    UploadAcceptedDecision: 'UPLOAD_ACCEPTED_DECISION',
    SignAcceptedDecision: 'SIGN_ACCEPTED_DECISION',
    Accepted: 'ACCEPTED',
    Deleted: 'DELETED'
} as const;

export type RestorationStatus = typeof RestorationStatus[keyof typeof RestorationStatus];


/**
 * 
 * @export
 * @interface Room
 */
export interface Room {
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    'roomType'?: RoomRoomTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Room
     */
    'isOptional'?: boolean;
    /**
     * 
     * @type {Array<RequiredRoom>}
     * @memberof Room
     */
    'requiredRooms'?: Array<RequiredRoom>;
}

export const RoomRoomTypeEnum = {
    Reception: 'RECEPTION',
    Examination: 'EXAMINATION',
    ExaminationAndReception: 'EXAMINATION_AND_RECEPTION'
} as const;

export type RoomRoomTypeEnum = typeof RoomRoomTypeEnum[keyof typeof RoomRoomTypeEnum];

/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'type'?: ServiceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'status'?: ServiceStatusEnum;
    /**
     * 
     * @type {Array<ServiceRoom>}
     * @memberof Service
     */
    'serviceRooms'?: Array<ServiceRoom>;
    /**
     * 
     * @type {Array<Occupation>}
     * @memberof Service
     */
    'requiredOccupations'?: Array<Occupation>;
    /**
     * 
     * @type {Array<Speciality>}
     * @memberof Service
     */
    'requiredSpecialities'?: Array<Speciality>;
    /**
     * 
     * @type {Array<Occupation>}
     * @memberof Service
     */
    'optionalOccupations'?: Array<Occupation>;
    /**
     * 
     * @type {Array<Speciality>}
     * @memberof Service
     */
    'optionalSpecialities'?: Array<Speciality>;
    /**
     * 
     * @type {Array<Service>}
     * @memberof Service
     */
    'requiredServices'?: Array<Service>;
    /**
     * 
     * @type {Array<FileTemplate>}
     * @memberof Service
     */
    'requiredFiles'?: Array<FileTemplate>;
}

export const ServiceTypeEnum = {
    Ambulatory: 'AMBULATORY',
    Inpatient: 'INPATIENT',
    Both: 'BOTH',
    NotApplicable: 'NOT_APPLICABLE'
} as const;

export type ServiceTypeEnum = typeof ServiceTypeEnum[keyof typeof ServiceTypeEnum];
export const ServiceStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Virtual: 'VIRTUAL'
} as const;

export type ServiceStatusEnum = typeof ServiceStatusEnum[keyof typeof ServiceStatusEnum];

/**
 * 
 * @export
 * @interface ServiceArea
 */
export interface ServiceArea {
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceArea
     */
    'ids': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceArea
     */
    'values': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceArea
     */
    'activationDate': string;
}
/**
 * 
 * @export
 * @interface ServiceBrigade
 */
export interface ServiceBrigade {
    /**
     * 
     * @type {string}
     * @memberof ServiceBrigade
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBrigade
     */
    'brigadeType'?: ServiceBrigadeBrigadeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ServiceBrigade
     */
    'amount'?: number;
}

export const ServiceBrigadeBrigadeTypeEnum = {
    NursingBrigade: 'NURSING_BRIGADE',
    MedicalBrigade: 'MEDICAL_BRIGADE',
    ReanimobileBrigade: 'REANIMOBILE_BRIGADE',
    FirstAidBrigade: 'FIRST_AID_BRIGADE'
} as const;

export type ServiceBrigadeBrigadeTypeEnum = typeof ServiceBrigadeBrigadeTypeEnum[keyof typeof ServiceBrigadeBrigadeTypeEnum];

/**
 * 
 * @export
 * @interface ServiceEmployee
 */
export interface ServiceEmployee {
    /**
     * 
     * @type {string}
     * @memberof ServiceEmployee
     */
    'employeeId'?: string;
    /**
     * 
     * @type {SpecialistCode}
     * @memberof ServiceEmployee
     */
    'specialistCode'?: SpecialistCode;
}
/**
 * 
 * @export
 * @interface ServiceRoom
 */
export interface ServiceRoom {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceRoom
     */
    'isShareable': boolean;
    /**
     * 
     * @type {Room}
     * @memberof ServiceRoom
     */
    'room': Room;
    /**
     * 
     * @type {string}
     * @memberof ServiceRoom
     */
    'number'?: string;
}
/**
 * 
 * @export
 * @interface ServiceSuspension
 */
export interface ServiceSuspension {
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceSuspension
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'suspensionReason'?: ServiceSuspensionSuspensionReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceSuspension
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'explanationNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'suspensionType'?: ServiceSuspensionSuspensionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'channelType'?: ServiceSuspensionChannelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'activityLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'activityLicenseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'submissionTime'?: string;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof ServiceSuspension
     */
    'currentStatus'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'businessArea'?: string;
    /**
     * 
     * @type {Company}
     * @memberof ServiceSuspension
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof ServiceSuspension
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof ServiceSuspension
     */
    'createdAt'?: string;
    /**
     * 
     * @type {ActivityLocation}
     * @memberof ServiceSuspension
     */
    'location'?: ActivityLocation;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceSuspension
     */
    'serviceIdsToSuspend'?: Array<string>;
    /**
     * 
     * @type {Array<ActivityLocation>}
     * @memberof ServiceSuspension
     */
    'locations'?: Array<ActivityLocation>;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceSuspension
     */
    'isHospital'?: boolean;
}

export const ServiceSuspensionSuspensionReasonEnum = {
    Personnel: 'PERSONNEL',
    Rooms: 'ROOMS'
} as const;

export type ServiceSuspensionSuspensionReasonEnum = typeof ServiceSuspensionSuspensionReasonEnum[keyof typeof ServiceSuspensionSuspensionReasonEnum];
export const ServiceSuspensionSuspensionTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type ServiceSuspensionSuspensionTypeEnum = typeof ServiceSuspensionSuspensionTypeEnum[keyof typeof ServiceSuspensionSuspensionTypeEnum];
export const ServiceSuspensionChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type ServiceSuspensionChannelTypeEnum = typeof ServiceSuspensionChannelTypeEnum[keyof typeof ServiceSuspensionChannelTypeEnum];

/**
 * 
 * @export
 * @interface Settlement
 */
export interface Settlement {
    /**
     * 
     * @type {string}
     * @memberof Settlement
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Settlement
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Settlement
     */
    'ehak'?: string;
}
/**
 * 
 * @export
 * @interface SettlementPublic
 */
export interface SettlementPublic {
    /**
     * 
     * @type {string}
     * @memberof SettlementPublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementPublic
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementPublic
     */
    'ehak'?: string;
}
/**
 * 
 * @export
 * @interface SpecialistCode
 */
export interface SpecialistCode {
    /**
     * 
     * @type {string}
     * @memberof SpecialistCode
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialistCode
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialistCode
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialistCode
     */
    'status'?: SpecialistCodeStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SpecialistCode
     */
    'isTortaCheckRequired': boolean;
}

export const SpecialistCodeStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Virtual: 'VIRTUAL'
} as const;

export type SpecialistCodeStatusEnum = typeof SpecialistCodeStatusEnum[keyof typeof SpecialistCodeStatusEnum];

/**
 * 
 * @export
 * @interface SpecialistData
 */
export interface SpecialistData {
    /**
     * 
     * @type {PersonalData}
     * @memberof SpecialistData
     */
    'personalData'?: PersonalData;
    /**
     * 
     * @type {boolean}
     * @memberof SpecialistData
     */
    'isSpecialistRequired'?: boolean;
}
/**
 * 
 * @export
 * @interface Speciality
 */
export interface Speciality {
    /**
     * 
     * @type {string}
     * @memberof Speciality
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Speciality
     */
    'occupationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Speciality
     */
    'specialityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Speciality
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Speciality
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Speciality
     */
    'status'?: SpecialityStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Speciality
     */
    'applicationDraftId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Speciality
     */
    'createdAt'?: string;
}

export const SpecialityStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Virtual: 'VIRTUAL'
} as const;

export type SpecialityStatusEnum = typeof SpecialityStatusEnum[keyof typeof SpecialityStatusEnum];

/**
 * 
 * @export
 * @interface SpecialityPublic
 */
export interface SpecialityPublic {
    /**
     * 
     * @type {string}
     * @memberof SpecialityPublic
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialityPublic
     */
    'occupationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialityPublic
     */
    'specialityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialityPublic
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialityPublic
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialityPublic
     */
    'status'?: SpecialityPublicStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SpecialityPublic
     */
    'applicationDraftId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialityPublic
     */
    'createdAt'?: string;
}

export const SpecialityPublicStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Virtual: 'VIRTUAL'
} as const;

export type SpecialityPublicStatusEnum = typeof SpecialityPublicStatusEnum[keyof typeof SpecialityPublicStatusEnum];

/**
 * 
 * @export
 * @interface StateFee
 */
export interface StateFee {
    /**
     * 
     * @type {string}
     * @memberof StateFee
     */
    'referenceNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof StateFee
     */
    'stateFeeValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof StateFee
     */
    'stateFeeDeadline'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StateFee
     */
    'paid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StateFee
     */
    'paidManually'?: boolean;
}
/**
 * 
 * @export
 * @interface Substitute
 */
export interface Substitute {
    /**
     * 
     * @type {string}
     * @memberof Substitute
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Substitute
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Substitute
     */
    'deactivationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Substitute
     */
    'occupationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Substitute
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Substitute
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Substitute
     */
    'idCode'?: string;
}
/**
 * 
 * @export
 * @interface Suspension
 */
export interface Suspension {
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Suspension
     */
    'applicationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'suspensionReason'?: SuspensionSuspensionReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Suspension
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'explanationNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'suspensionType'?: SuspensionSuspensionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'channelType'?: SuspensionChannelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'activityLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'activityLicenseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'submissionTime'?: string;
    /**
     * 
     * @type {ActivityLicenseSuspensionStatus}
     * @memberof Suspension
     */
    'currentStatus'?: ActivityLicenseSuspensionStatus;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'businessArea'?: string;
    /**
     * 
     * @type {Company}
     * @memberof Suspension
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'submittingPersonFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'submittingPersonLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'submittingPersonIdCode'?: string;
    /**
     * 
     * @type {Array<Decision>}
     * @memberof Suspension
     */
    'decisions'?: Array<Decision>;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    'createdAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Suspension
     */
    'isHospital'?: boolean;
}

export const SuspensionSuspensionReasonEnum = {
    Personnel: 'PERSONNEL',
    Rooms: 'ROOMS'
} as const;

export type SuspensionSuspensionReasonEnum = typeof SuspensionSuspensionReasonEnum[keyof typeof SuspensionSuspensionReasonEnum];
export const SuspensionSuspensionTypeEnum = {
    ActivityLicense: 'ACTIVITY_LICENSE',
    ActivityLicenseSuspension: 'ACTIVITY_LICENSE_SUSPENSION',
    ActivityLicenseRestoration: 'ACTIVITY_LICENSE_RESTORATION',
    ActivityLicenseInvalidation: 'ACTIVITY_LICENSE_INVALIDATION',
    ActivityLocation: 'ACTIVITY_LOCATION',
    ActivityLocationSuspension: 'ACTIVITY_LOCATION_SUSPENSION',
    ActivityLocationRestoration: 'ACTIVITY_LOCATION_RESTORATION',
    ActivityLocationInvalidation: 'ACTIVITY_LOCATION_INVALIDATION',
    Service: 'SERVICE',
    ServiceSuspension: 'SERVICE_SUSPENSION',
    ServiceRestoration: 'SERVICE_RESTORATION',
    ServiceDiscard: 'SERVICE_DISCARD',
    LicenseAcquisition: 'LICENSE_ACQUISITION',
    GeneralPractitionerApplication: 'GENERAL_PRACTITIONER_APPLICATION'
} as const;

export type SuspensionSuspensionTypeEnum = typeof SuspensionSuspensionTypeEnum[keyof typeof SuspensionSuspensionTypeEnum];
export const SuspensionChannelTypeEnum = {
    SelfService: 'SELF_SERVICE',
    EMail: 'E_MAIL',
    Mail: 'MAIL',
    Delivered: 'DELIVERED'
} as const;

export type SuspensionChannelTypeEnum = typeof SuspensionChannelTypeEnum[keyof typeof SuspensionChannelTypeEnum];

/**
 * 
 * @export
 * @interface TortaCheckInfo
 */
export interface TortaCheckInfo {
    /**
     * 
     * @type {string}
     * @memberof TortaCheckInfo
     */
    'downloadRequestId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TortaCheckInfo
     */
    'requestTime'?: string;
}
/**
 * 
 * @export
 * @interface TortaCheckJob
 */
export interface TortaCheckJob {
    /**
     * 
     * @type {string}
     * @memberof TortaCheckJob
     */
    'responseCode'?: TortaCheckJobResponseCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof TortaCheckJob
     */
    'responseMessage'?: string;
    /**
     * 
     * @type {TortaCheckInfo}
     * @memberof TortaCheckJob
     */
    'responseBody'?: TortaCheckInfo;
}

export const TortaCheckJobResponseCodeEnum = {
    Failed: 'FAILED',
    Success: 'SUCCESS',
    Timeout: 'TIMEOUT',
    Waiting: 'WAITING'
} as const;

export type TortaCheckJobResponseCodeEnum = typeof TortaCheckJobResponseCodeEnum[keyof typeof TortaCheckJobResponseCodeEnum];

/**
 * 
 * @export
 * @interface TortaCheckJobRequest
 */
export interface TortaCheckJobRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof TortaCheckJobRequest
     */
    'registryCodes'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TortaCheckJobRequest
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof TortaCheckJobRequest
     */
    'endTime'?: string;
}
/**
 * 
 * @export
 * @interface TortaDownloadJob
 */
export interface TortaDownloadJob {
    /**
     * 
     * @type {string}
     * @memberof TortaDownloadJob
     */
    'responseCode'?: TortaDownloadJobResponseCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof TortaDownloadJob
     */
    'responseMessage'?: string;
}

export const TortaDownloadJobResponseCodeEnum = {
    Failed: 'FAILED',
    Success: 'SUCCESS',
    Timeout: 'TIMEOUT',
    Waiting: 'WAITING'
} as const;

export type TortaDownloadJobResponseCodeEnum = typeof TortaDownloadJobResponseCodeEnum[keyof typeof TortaDownloadJobResponseCodeEnum];

/**
 * 
 * @export
 * @interface TortaDownloadJobRequest
 */
export interface TortaDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof TortaDownloadJobRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface TortaExcludeCompaniesRequest
 */
export interface TortaExcludeCompaniesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof TortaExcludeCompaniesRequest
     */
    'registryCodes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TortaManualProcessingJob
 */
export interface TortaManualProcessingJob {
    /**
     * 
     * @type {string}
     * @memberof TortaManualProcessingJob
     */
    'responseCode'?: TortaManualProcessingJobResponseCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof TortaManualProcessingJob
     */
    'responseMessage'?: string;
}

export const TortaManualProcessingJobResponseCodeEnum = {
    Failed: 'FAILED',
    Success: 'SUCCESS',
    Timeout: 'TIMEOUT',
    Waiting: 'WAITING'
} as const;

export type TortaManualProcessingJobResponseCodeEnum = typeof TortaManualProcessingJobResponseCodeEnum[keyof typeof TortaManualProcessingJobResponseCodeEnum];

/**
 * 
 * @export
 * @interface TortaWorkInfo
 */
export interface TortaWorkInfo {
    /**
     * 
     * @type {string}
     * @memberof TortaWorkInfo
     */
    'personCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof TortaWorkInfo
     */
    'workingStart'?: string;
    /**
     * 
     * @type {number}
     * @memberof TortaWorkInfo
     */
    'professionCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof TortaWorkInfo
     */
    'workingStatus'?: number;
    /**
     * 
     * @type {string}
     * @memberof TortaWorkInfo
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TortaWorkInfo
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface UiConfig
 */
export interface UiConfig {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UiConfig
     */
    'l18nLinks'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface UploadFileRequest
 */
export interface UploadFileRequest {
    /**
     * 
     * @type {Array<any>}
     * @memberof UploadFileRequest
     */
    'file': Array<any>;
}
/**
 * 
 * @export
 * @interface UserApplications
 */
export interface UserApplications {
    /**
     * 
     * @type {Array<ApplicationDraft>}
     * @memberof UserApplications
     */
    'savedApplications'?: Array<ApplicationDraft>;
    /**
     * 
     * @type {Array<Application>}
     * @memberof UserApplications
     */
    'submittedApplications'?: Array<Application>;
    /**
     * 
     * @type {Array<ApplicationCertificate>}
     * @memberof UserApplications
     */
    'applicationCertificates'?: Array<ApplicationCertificate>;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'idCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'tokenIssuedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'tokenExpiresAt'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfo
     */
    'roles'?: Array<string>;
}
/**
 * 
 * @export
 * @interface WorkingDay
 */
export interface WorkingDay {
    /**
     * 
     * @type {string}
     * @memberof WorkingDay
     */
    'workingDay': WorkingDayWorkingDayEnum;
    /**
     * 
     * @type {LocalTime}
     * @memberof WorkingDay
     */
    'workingFrom': LocalTime;
    /**
     * 
     * @type {LocalTime}
     * @memberof WorkingDay
     */
    'workingTo': LocalTime;
}

export const WorkingDayWorkingDayEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type WorkingDayWorkingDayEnum = typeof WorkingDayWorkingDayEnum[keyof typeof WorkingDayWorkingDayEnum];


/**
 * AadressideAndmeteTeenusApi - axios parameter creator
 * @export
 */
export const AadressideAndmeteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab maakondade nimed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCounties1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/addresses/counties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab riikide nimed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/addresses/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab valdade nimed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParishes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/addresses/parishes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab valdade nimed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParishes1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getParishes1', 'id', id)
            const localVarPath = `/addresses/counties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab külade nimed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettlements: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSettlements', 'id', id)
            const localVarPath = `/addresses/parishes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AadressideAndmeteTeenusApi - functional programming interface
 * @export
 */
export const AadressideAndmeteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AadressideAndmeteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab maakondade nimed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCounties1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<County>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCounties1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab riikide nimed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Country>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab valdade nimed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParishes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parish>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParishes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab valdade nimed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParishes1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parish>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParishes1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab külade nimed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettlements(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Settlement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettlements(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AadressideAndmeteTeenusApi - factory interface
 * @export
 */
export const AadressideAndmeteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AadressideAndmeteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab maakondade nimed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCounties1(options?: any): AxiosPromise<Array<County>> {
            return localVarFp.getCounties1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab riikide nimed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(options?: any): AxiosPromise<Array<Country>> {
            return localVarFp.getCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab valdade nimed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParishes(options?: any): AxiosPromise<Array<Parish>> {
            return localVarFp.getParishes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab valdade nimed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParishes1(id: string, options?: any): AxiosPromise<Array<Parish>> {
            return localVarFp.getParishes1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab külade nimed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettlements(id: string, options?: any): AxiosPromise<Array<Settlement>> {
            return localVarFp.getSettlements(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AadressideAndmeteTeenusApi - object-oriented interface
 * @export
 * @class AadressideAndmeteTeenusApi
 * @extends {BaseAPI}
 */
export class AadressideAndmeteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab maakondade nimed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AadressideAndmeteTeenusApi
     */
    public getCounties1(options?: AxiosRequestConfig) {
        return AadressideAndmeteTeenusApiFp(this.configuration).getCounties1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab riikide nimed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AadressideAndmeteTeenusApi
     */
    public getCountries(options?: AxiosRequestConfig) {
        return AadressideAndmeteTeenusApiFp(this.configuration).getCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab valdade nimed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AadressideAndmeteTeenusApi
     */
    public getParishes(options?: AxiosRequestConfig) {
        return AadressideAndmeteTeenusApiFp(this.configuration).getParishes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab valdade nimed.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AadressideAndmeteTeenusApi
     */
    public getParishes1(id: string, options?: AxiosRequestConfig) {
        return AadressideAndmeteTeenusApiFp(this.configuration).getParishes1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab külade nimed.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AadressideAndmeteTeenusApi
     */
    public getSettlements(id: string, options?: AxiosRequestConfig) {
        return AadressideAndmeteTeenusApiFp(this.configuration).getSettlements(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminTeenusApi - axios parameter creator
 * @export
 */
export const AdminTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary TORTA kontrollist välistatavad ettevõtted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTortaExcludedCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/torta2-exclude-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab funktsioonaalsuste staatused (sees/väljas).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureFlags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/admin/feature-flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Töötleb käsitsi sisestatud torta lõpetatud töötamisi. Sisend andembaasi tabelist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performManualTorta2EmploymentInfoProcessing: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/torta2-employment-info-manual-processing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Töötleb TORTA2 päringutulemusi.
         * @param {TortaDownloadJobRequest} tortaDownloadJobRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        torta2Download: async (tortaDownloadJobRequest: TortaDownloadJobRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tortaDownloadJobRequest' is not null or undefined
            assertParamExists('torta2Download', 'tortaDownloadJobRequest', tortaDownloadJobRequest)
            const localVarPath = `/admin/torta2-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tortaDownloadJobRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab TORTA2 downloadRequest id.
         * @param {TortaCheckJobRequest} tortaCheckJobRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        torta2check: async (tortaCheckJobRequest: TortaCheckJobRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tortaCheckJobRequest' is not null or undefined
            assertParamExists('torta2check', 'tortaCheckJobRequest', tortaCheckJobRequest)
            const localVarPath = `/admin/torta2-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tortaCheckJobRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TORTA kontrollist välistatavad ettevõtted.
         * @param {TortaExcludeCompaniesRequest} tortaExcludeCompaniesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tortaExcludeCompanies: async (tortaExcludeCompaniesRequest: TortaExcludeCompaniesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tortaExcludeCompaniesRequest' is not null or undefined
            assertParamExists('tortaExcludeCompanies', 'tortaExcludeCompaniesRequest', tortaExcludeCompaniesRequest)
            const localVarPath = `/admin/torta2-exclude-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tortaExcludeCompaniesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab funktsioonaalsust sisse/välja.
         * @param {FeatureFlagRequest} featureFlagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureFlag: async (featureFlagRequest: FeatureFlagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureFlagRequest' is not null or undefined
            assertParamExists('updateFeatureFlag', 'featureFlagRequest', featureFlagRequest)
            const localVarPath = `/admin/feature-flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureFlagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTeenusApi - functional programming interface
 * @export
 */
export const AdminTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary TORTA kontrollist välistatavad ettevõtted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchTortaExcludedCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TortaExcludeCompaniesRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchTortaExcludedCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab funktsioonaalsuste staatused (sees/väljas).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureFlags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureFlags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Töötleb käsitsi sisestatud torta lõpetatud töötamisi. Sisend andembaasi tabelist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performManualTorta2EmploymentInfoProcessing(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TortaManualProcessingJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performManualTorta2EmploymentInfoProcessing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Töötleb TORTA2 päringutulemusi.
         * @param {TortaDownloadJobRequest} tortaDownloadJobRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async torta2Download(tortaDownloadJobRequest: TortaDownloadJobRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TortaDownloadJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.torta2Download(tortaDownloadJobRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab TORTA2 downloadRequest id.
         * @param {TortaCheckJobRequest} tortaCheckJobRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async torta2check(tortaCheckJobRequest: TortaCheckJobRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TortaCheckJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.torta2check(tortaCheckJobRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TORTA kontrollist välistatavad ettevõtted.
         * @param {TortaExcludeCompaniesRequest} tortaExcludeCompaniesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tortaExcludeCompanies(tortaExcludeCompaniesRequest: TortaExcludeCompaniesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TortaCheckJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tortaExcludeCompanies(tortaExcludeCompaniesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab funktsioonaalsust sisse/välja.
         * @param {FeatureFlagRequest} featureFlagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeatureFlag(featureFlagRequest: FeatureFlagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureFlag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeatureFlag(featureFlagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminTeenusApi - factory interface
 * @export
 */
export const AdminTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary TORTA kontrollist välistatavad ettevõtted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTortaExcludedCompanies(options?: any): AxiosPromise<TortaExcludeCompaniesRequest> {
            return localVarFp.fetchTortaExcludedCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab funktsioonaalsuste staatused (sees/väljas).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureFlags(options?: any): AxiosPromise<Array<FeatureFlag>> {
            return localVarFp.getFeatureFlags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Töötleb käsitsi sisestatud torta lõpetatud töötamisi. Sisend andembaasi tabelist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performManualTorta2EmploymentInfoProcessing(options?: any): AxiosPromise<TortaManualProcessingJob> {
            return localVarFp.performManualTorta2EmploymentInfoProcessing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Töötleb TORTA2 päringutulemusi.
         * @param {TortaDownloadJobRequest} tortaDownloadJobRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        torta2Download(tortaDownloadJobRequest: TortaDownloadJobRequest, options?: any): AxiosPromise<TortaDownloadJob> {
            return localVarFp.torta2Download(tortaDownloadJobRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab TORTA2 downloadRequest id.
         * @param {TortaCheckJobRequest} tortaCheckJobRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        torta2check(tortaCheckJobRequest: TortaCheckJobRequest, options?: any): AxiosPromise<TortaCheckJob> {
            return localVarFp.torta2check(tortaCheckJobRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TORTA kontrollist välistatavad ettevõtted.
         * @param {TortaExcludeCompaniesRequest} tortaExcludeCompaniesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tortaExcludeCompanies(tortaExcludeCompaniesRequest: TortaExcludeCompaniesRequest, options?: any): AxiosPromise<TortaCheckJob> {
            return localVarFp.tortaExcludeCompanies(tortaExcludeCompaniesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab funktsioonaalsust sisse/välja.
         * @param {FeatureFlagRequest} featureFlagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureFlag(featureFlagRequest: FeatureFlagRequest, options?: any): AxiosPromise<FeatureFlag> {
            return localVarFp.updateFeatureFlag(featureFlagRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTeenusApi - object-oriented interface
 * @export
 * @class AdminTeenusApi
 * @extends {BaseAPI}
 */
export class AdminTeenusApi extends BaseAPI {
    /**
     * 
     * @summary TORTA kontrollist välistatavad ettevõtted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeenusApi
     */
    public fetchTortaExcludedCompanies(options?: AxiosRequestConfig) {
        return AdminTeenusApiFp(this.configuration).fetchTortaExcludedCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab funktsioonaalsuste staatused (sees/väljas).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeenusApi
     */
    public getFeatureFlags(options?: AxiosRequestConfig) {
        return AdminTeenusApiFp(this.configuration).getFeatureFlags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Töötleb käsitsi sisestatud torta lõpetatud töötamisi. Sisend andembaasi tabelist.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeenusApi
     */
    public performManualTorta2EmploymentInfoProcessing(options?: AxiosRequestConfig) {
        return AdminTeenusApiFp(this.configuration).performManualTorta2EmploymentInfoProcessing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Töötleb TORTA2 päringutulemusi.
     * @param {TortaDownloadJobRequest} tortaDownloadJobRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeenusApi
     */
    public torta2Download(tortaDownloadJobRequest: TortaDownloadJobRequest, options?: AxiosRequestConfig) {
        return AdminTeenusApiFp(this.configuration).torta2Download(tortaDownloadJobRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab TORTA2 downloadRequest id.
     * @param {TortaCheckJobRequest} tortaCheckJobRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeenusApi
     */
    public torta2check(tortaCheckJobRequest: TortaCheckJobRequest, options?: AxiosRequestConfig) {
        return AdminTeenusApiFp(this.configuration).torta2check(tortaCheckJobRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TORTA kontrollist välistatavad ettevõtted.
     * @param {TortaExcludeCompaniesRequest} tortaExcludeCompaniesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeenusApi
     */
    public tortaExcludeCompanies(tortaExcludeCompaniesRequest: TortaExcludeCompaniesRequest, options?: AxiosRequestConfig) {
        return AdminTeenusApiFp(this.configuration).tortaExcludeCompanies(tortaExcludeCompaniesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab funktsioonaalsust sisse/välja.
     * @param {FeatureFlagRequest} featureFlagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeenusApi
     */
    public updateFeatureFlag(featureFlagRequest: FeatureFlagRequest, options?: AxiosRequestConfig) {
        return AdminTeenusApiFp(this.configuration).updateFeatureFlag(featureFlagRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikeTeenusAmetnikeleApi - axios parameter creator
 * @export
 */
export const AmetnikeTeenusAmetnikeleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ${medre.api.messages.officials.with-privilege.description
         * @param {'ADMIN' | 'PROCEEDE' | 'SIGN' | 'OFFICIAL' | 'OBSERVE' | 'PERSON' | 'POWER_OF_ATTORNEY_TYPE_ULTIMATE' | 'POWER_OF_ATTORNEY_TYPE_FULL' | 'POWER_OF_ATTORNEY_TYPE_LIMITED' | 'POWER_OF_ATTORNEY_TYPE_GPL' | 'LICENSE_TYPE_FULL'} privilege 
         * @param {boolean} visible 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficialsWithPrivilege: async (privilege: 'ADMIN' | 'PROCEEDE' | 'SIGN' | 'OFFICIAL' | 'OBSERVE' | 'PERSON' | 'POWER_OF_ATTORNEY_TYPE_ULTIMATE' | 'POWER_OF_ATTORNEY_TYPE_FULL' | 'POWER_OF_ATTORNEY_TYPE_LIMITED' | 'POWER_OF_ATTORNEY_TYPE_GPL' | 'LICENSE_TYPE_FULL', visible: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'privilege' is not null or undefined
            assertParamExists('getOfficialsWithPrivilege', 'privilege', privilege)
            // verify required parameter 'visible' is not null or undefined
            assertParamExists('getOfficialsWithPrivilege', 'visible', visible)
            const localVarPath = `/official/officials/with-privilege/{privilege}`
                .replace(`{${"privilege"}}`, encodeURIComponent(String(privilege)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (visible !== undefined) {
                localVarQueryParameter['visible'] = visible;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikeTeenusAmetnikeleApi - functional programming interface
 * @export
 */
export const AmetnikeTeenusAmetnikeleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikeTeenusAmetnikeleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ${medre.api.messages.officials.with-privilege.description
         * @param {'ADMIN' | 'PROCEEDE' | 'SIGN' | 'OFFICIAL' | 'OBSERVE' | 'PERSON' | 'POWER_OF_ATTORNEY_TYPE_ULTIMATE' | 'POWER_OF_ATTORNEY_TYPE_FULL' | 'POWER_OF_ATTORNEY_TYPE_LIMITED' | 'POWER_OF_ATTORNEY_TYPE_GPL' | 'LICENSE_TYPE_FULL'} privilege 
         * @param {boolean} visible 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfficialsWithPrivilege(privilege: 'ADMIN' | 'PROCEEDE' | 'SIGN' | 'OFFICIAL' | 'OBSERVE' | 'PERSON' | 'POWER_OF_ATTORNEY_TYPE_ULTIMATE' | 'POWER_OF_ATTORNEY_TYPE_FULL' | 'POWER_OF_ATTORNEY_TYPE_LIMITED' | 'POWER_OF_ATTORNEY_TYPE_GPL' | 'LICENSE_TYPE_FULL', visible: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfficialUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfficialsWithPrivilege(privilege, visible, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikeTeenusAmetnikeleApi - factory interface
 * @export
 */
export const AmetnikeTeenusAmetnikeleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikeTeenusAmetnikeleApiFp(configuration)
    return {
        /**
         * 
         * @summary ${medre.api.messages.officials.with-privilege.description
         * @param {'ADMIN' | 'PROCEEDE' | 'SIGN' | 'OFFICIAL' | 'OBSERVE' | 'PERSON' | 'POWER_OF_ATTORNEY_TYPE_ULTIMATE' | 'POWER_OF_ATTORNEY_TYPE_FULL' | 'POWER_OF_ATTORNEY_TYPE_LIMITED' | 'POWER_OF_ATTORNEY_TYPE_GPL' | 'LICENSE_TYPE_FULL'} privilege 
         * @param {boolean} visible 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficialsWithPrivilege(privilege: 'ADMIN' | 'PROCEEDE' | 'SIGN' | 'OFFICIAL' | 'OBSERVE' | 'PERSON' | 'POWER_OF_ATTORNEY_TYPE_ULTIMATE' | 'POWER_OF_ATTORNEY_TYPE_FULL' | 'POWER_OF_ATTORNEY_TYPE_LIMITED' | 'POWER_OF_ATTORNEY_TYPE_GPL' | 'LICENSE_TYPE_FULL', visible: boolean, options?: any): AxiosPromise<Array<OfficialUser>> {
            return localVarFp.getOfficialsWithPrivilege(privilege, visible, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikeTeenusAmetnikeleApi - object-oriented interface
 * @export
 * @class AmetnikeTeenusAmetnikeleApi
 * @extends {BaseAPI}
 */
export class AmetnikeTeenusAmetnikeleApi extends BaseAPI {
    /**
     * 
     * @summary ${medre.api.messages.officials.with-privilege.description
     * @param {'ADMIN' | 'PROCEEDE' | 'SIGN' | 'OFFICIAL' | 'OBSERVE' | 'PERSON' | 'POWER_OF_ATTORNEY_TYPE_ULTIMATE' | 'POWER_OF_ATTORNEY_TYPE_FULL' | 'POWER_OF_ATTORNEY_TYPE_LIMITED' | 'POWER_OF_ATTORNEY_TYPE_GPL' | 'LICENSE_TYPE_FULL'} privilege 
     * @param {boolean} visible 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikeTeenusAmetnikeleApi
     */
    public getOfficialsWithPrivilege(privilege: 'ADMIN' | 'PROCEEDE' | 'SIGN' | 'OFFICIAL' | 'OBSERVE' | 'PERSON' | 'POWER_OF_ATTORNEY_TYPE_ULTIMATE' | 'POWER_OF_ATTORNEY_TYPE_FULL' | 'POWER_OF_ATTORNEY_TYPE_LIMITED' | 'POWER_OF_ATTORNEY_TYPE_GPL' | 'LICENSE_TYPE_FULL', visible: boolean, options?: AxiosRequestConfig) {
        return AmetnikeTeenusAmetnikeleApiFp(this.configuration).getOfficialsWithPrivilege(privilege, visible, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuPerearstiNimistudTeenusApi - axios parameter creator
 * @export
 */
export const AmetnikuPerearstiNimistudTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Koostage perearstide loend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralPractitionerList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/official/general-practitioner-lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ekspordib filtreeritud perearstide nimistud CSV-failina
         * @param {GeneralPractitionerListSearchFilter} generalPractitionerListSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportGeneralPractitionerList: async (generalPractitionerListSearchFilter: GeneralPractitionerListSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generalPractitionerListSearchFilter' is not null or undefined
            assertParamExists('exportGeneralPractitionerList', 'generalPractitionerListSearchFilter', generalPractitionerListSearchFilter)
            const localVarPath = `/official/general-practitioner-lists/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.general-practitioner-list-activity-license.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicense1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicense1', 'id', id)
            const localVarPath = `/official/general-practitioner-lists/{id}/activity-licenses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {string} licenseCode 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByLicenseCode: async (licenseCode: string, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseCode' is not null or undefined
            assertParamExists('getAllByLicenseCode', 'licenseCode', licenseCode)
            const localVarPath = `/official/general-practitioner-lists/get-all/{licenseCode}`
                .replace(`{${"licenseCode"}}`, encodeURIComponent(String(licenseCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saadaval teeninduspiirkonnad
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedServiceAreas: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllowedServiceAreas', 'id', id)
            const localVarPath = `/official/general-practitioner-lists/{id}/allowed-service-areas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hankige logid pärast perearstide loendi värskendamist
         * @param {string} id 
         * @param {'CREATE' | 'UPDATE' | 'DELETE' | 'UPDATE_EDUCATION' | 'CHANGE_STATUS' | 'FORWARD_TO_OFFICIAL' | 'UPDATE_GP_LIST_PATIENTS_SIZE' | 'UPDATE_GP_LIST_SERVICE_AREA' | 'UPDATE_GP_LIST_GENERAL_PRACTITIONER' | 'UPDATE_GP_LIST_LICENSE' | 'UPDATE_GP_LIST_LICENSE_ADDRESS' | 'UPDATE_GP_LIST_SUBSTITUTE' | 'UPDATE_GP_LIST_EMPLOYEE' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR_PERIOD' | 'ADDING_SPECIALITY' | 'UPDATE_ACTIVITY_LICENSE_PHARMACY_OWNER'} actionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLogItems: async (id: string, actionType: 'CREATE' | 'UPDATE' | 'DELETE' | 'UPDATE_EDUCATION' | 'CHANGE_STATUS' | 'FORWARD_TO_OFFICIAL' | 'UPDATE_GP_LIST_PATIENTS_SIZE' | 'UPDATE_GP_LIST_SERVICE_AREA' | 'UPDATE_GP_LIST_GENERAL_PRACTITIONER' | 'UPDATE_GP_LIST_LICENSE' | 'UPDATE_GP_LIST_LICENSE_ADDRESS' | 'UPDATE_GP_LIST_SUBSTITUTE' | 'UPDATE_GP_LIST_EMPLOYEE' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR_PERIOD' | 'ADDING_SPECIALITY' | 'UPDATE_ACTIVITY_LICENSE_PHARMACY_OWNER', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAuditLogItems', 'id', id)
            // verify required parameter 'actionType' is not null or undefined
            assertParamExists('getAuditLogItems', 'actionType', actionType)
            const localVarPath = `/official/general-practitioner-lists/{id}/audit-logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (actionType !== undefined) {
                localVarQueryParameter['actionType'] = actionType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {string} id 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPractitionerList1: async (id: string, context?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGeneralPractitionerList1', 'id', id)
            const localVarPath = `/official/general-practitioner-lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saada üldarst
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPractitionerModification: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGeneralPractitionerModification', 'id', id)
            const localVarPath = `/official/general-practitioner-lists/{id}/modifications/general-practitioner`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab Nimistu tegevusloaga
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicensesModification: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLicensesModification', 'id', id)
            const localVarPath = `/official/general-practitioner-lists/{id}/modifications/licenses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab teeninduspiirkonna
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceAreaModification: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getServiceAreaModification', 'id', id)
            const localVarPath = `/official/general-practitioner-lists/{id}/modifications/service-area`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saada asendaja
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubstituteModification: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSubstituteModification', 'id', id)
            const localVarPath = `/official/general-practitioner-lists/{id}/modifications/substitute`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary V�rskendada perearsti nimistu tegelikku suurust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshActualSizeInGeneralPractitionerList: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refreshActualSizeInGeneralPractitionerList', 'id', id)
            const localVarPath = `/official/general-practitioner-lists/{id}/refresh-actual-size`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab filtreeritud perearstide loendi.
         * @param {GeneralPractitionerListSearchFilter} generalPractitionerListSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGeneralPractitionerList1: async (generalPractitionerListSearchFilter: GeneralPractitionerListSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generalPractitionerListSearchFilter' is not null or undefined
            assertParamExists('searchGeneralPractitionerList1', 'generalPractitionerListSearchFilter', generalPractitionerListSearchFilter)
            const localVarPath = `/official/general-practitioner-lists/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ajakohastada üldarstide nimekirja
         * @param {string} id 
         * @param {GeneralPractitionerList} generalPractitionerList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralPractitionerList: async (id: string, generalPractitionerList: GeneralPractitionerList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGeneralPractitionerList', 'id', id)
            // verify required parameter 'generalPractitionerList' is not null or undefined
            assertParamExists('updateGeneralPractitionerList', 'generalPractitionerList', generalPractitionerList)
            const localVarPath = `/official/general-practitioner-lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendada üldarsti
         * @param {string} id 
         * @param {GeneralPractitioner} generalPractitioner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralPractitionerModification: async (id: string, generalPractitioner: GeneralPractitioner, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGeneralPractitionerModification', 'id', id)
            // verify required parameter 'generalPractitioner' is not null or undefined
            assertParamExists('updateGeneralPractitionerModification', 'generalPractitioner', generalPractitioner)
            const localVarPath = `/official/general-practitioner-lists/{id}/modifications/general-practitioner`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitioner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab Nimistu tegevusloaga
         * @param {string} id 
         * @param {Array<GeneralPractitionerListLicense>} generalPractitionerListLicense 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicensesModification: async (id: string, generalPractitionerListLicense: Array<GeneralPractitionerListLicense>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLicensesModification', 'id', id)
            // verify required parameter 'generalPractitionerListLicense' is not null or undefined
            assertParamExists('updateLicensesModification', 'generalPractitionerListLicense', generalPractitionerListLicense)
            const localVarPath = `/official/general-practitioner-lists/{id}/modifications/licenses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListLicense, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab teeninduspiirkonna
         * @param {string} id 
         * @param {ServiceArea} serviceArea 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceAreaModification: async (id: string, serviceArea: ServiceArea, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateServiceAreaModification', 'id', id)
            // verify required parameter 'serviceArea' is not null or undefined
            assertParamExists('updateServiceAreaModification', 'serviceArea', serviceArea)
            const localVarPath = `/official/general-practitioner-lists/{id}/modifications/service-area`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceArea, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Värskenduse asendaja
         * @param {string} id 
         * @param {Substitute} substitute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubstituteModification: async (id: string, substitute: Substitute, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSubstituteModification', 'id', id)
            // verify required parameter 'substitute' is not null or undefined
            assertParamExists('updateSubstituteModification', 'substitute', substitute)
            const localVarPath = `/official/general-practitioner-lists/{id}/modifications/substitute`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(substitute, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuPerearstiNimistudTeenusApi - functional programming interface
 * @export
 */
export const AmetnikuPerearstiNimistudTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuPerearstiNimistudTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Koostage perearstide loend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGeneralPractitionerList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGeneralPractitionerList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ekspordib filtreeritud perearstide nimistud CSV-failina
         * @param {GeneralPractitionerListSearchFilter} generalPractitionerListSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportGeneralPractitionerList(generalPractitionerListSearchFilter: GeneralPractitionerListSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportGeneralPractitionerList(generalPractitionerListSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.general-practitioner-list-activity-license.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicense1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityLicense>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicense1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {string} licenseCode 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllByLicenseCode(licenseCode: string, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralPractitionerListLite>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllByLicenseCode(licenseCode, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saadaval teeninduspiirkonnad
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllowedServiceAreas(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AllowedServiceArea>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllowedServiceAreas(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Hankige logid pärast perearstide loendi värskendamist
         * @param {string} id 
         * @param {'CREATE' | 'UPDATE' | 'DELETE' | 'UPDATE_EDUCATION' | 'CHANGE_STATUS' | 'FORWARD_TO_OFFICIAL' | 'UPDATE_GP_LIST_PATIENTS_SIZE' | 'UPDATE_GP_LIST_SERVICE_AREA' | 'UPDATE_GP_LIST_GENERAL_PRACTITIONER' | 'UPDATE_GP_LIST_LICENSE' | 'UPDATE_GP_LIST_LICENSE_ADDRESS' | 'UPDATE_GP_LIST_SUBSTITUTE' | 'UPDATE_GP_LIST_EMPLOYEE' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR_PERIOD' | 'ADDING_SPECIALITY' | 'UPDATE_ACTIVITY_LICENSE_PHARMACY_OWNER'} actionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditLogItems(id: string, actionType: 'CREATE' | 'UPDATE' | 'DELETE' | 'UPDATE_EDUCATION' | 'CHANGE_STATUS' | 'FORWARD_TO_OFFICIAL' | 'UPDATE_GP_LIST_PATIENTS_SIZE' | 'UPDATE_GP_LIST_SERVICE_AREA' | 'UPDATE_GP_LIST_GENERAL_PRACTITIONER' | 'UPDATE_GP_LIST_LICENSE' | 'UPDATE_GP_LIST_LICENSE_ADDRESS' | 'UPDATE_GP_LIST_SUBSTITUTE' | 'UPDATE_GP_LIST_EMPLOYEE' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR_PERIOD' | 'ADDING_SPECIALITY' | 'UPDATE_ACTIVITY_LICENSE_PHARMACY_OWNER', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLogItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditLogItems(id, actionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {string} id 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralPractitionerList1(id: string, context?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralPractitionerList1(id, context, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saada üldarst
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralPractitionerModification(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitioner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralPractitionerModification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab Nimistu tegevusloaga
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicensesModification(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralPractitionerListLicense>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicensesModification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab teeninduspiirkonna
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceAreaModification(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceAreaModification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saada asendaja
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubstituteModification(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Substitute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubstituteModification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary V�rskendada perearsti nimistu tegelikku suurust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshActualSizeInGeneralPractitionerList(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshActualSizeInGeneralPractitionerList(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab filtreeritud perearstide loendi.
         * @param {GeneralPractitionerListSearchFilter} generalPractitionerListSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchGeneralPractitionerList1(generalPractitionerListSearchFilter: GeneralPractitionerListSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerListSearch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchGeneralPractitionerList1(generalPractitionerListSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ajakohastada üldarstide nimekirja
         * @param {string} id 
         * @param {GeneralPractitionerList} generalPractitionerList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGeneralPractitionerList(id: string, generalPractitionerList: GeneralPractitionerList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGeneralPractitionerList(id, generalPractitionerList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendada üldarsti
         * @param {string} id 
         * @param {GeneralPractitioner} generalPractitioner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGeneralPractitionerModification(id: string, generalPractitioner: GeneralPractitioner, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitioner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGeneralPractitionerModification(id, generalPractitioner, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab Nimistu tegevusloaga
         * @param {string} id 
         * @param {Array<GeneralPractitionerListLicense>} generalPractitionerListLicense 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLicensesModification(id: string, generalPractitionerListLicense: Array<GeneralPractitionerListLicense>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralPractitionerListLicense>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLicensesModification(id, generalPractitionerListLicense, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab teeninduspiirkonna
         * @param {string} id 
         * @param {ServiceArea} serviceArea 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceAreaModification(id: string, serviceArea: ServiceArea, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceAreaModification(id, serviceArea, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Värskenduse asendaja
         * @param {string} id 
         * @param {Substitute} substitute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubstituteModification(id: string, substitute: Substitute, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Substitute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubstituteModification(id, substitute, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuPerearstiNimistudTeenusApi - factory interface
 * @export
 */
export const AmetnikuPerearstiNimistudTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuPerearstiNimistudTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Koostage perearstide loend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralPractitionerList(options?: any): AxiosPromise<GeneralPractitionerList> {
            return localVarFp.createGeneralPractitionerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ekspordib filtreeritud perearstide nimistud CSV-failina
         * @param {GeneralPractitionerListSearchFilter} generalPractitionerListSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportGeneralPractitionerList(generalPractitionerListSearchFilter: GeneralPractitionerListSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.exportGeneralPractitionerList(generalPractitionerListSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.general-practitioner-list-activity-license.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicense1(id: string, options?: any): AxiosPromise<Array<ActivityLicense>> {
            return localVarFp.getActivityLicense1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {string} licenseCode 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByLicenseCode(licenseCode: string, status?: string, options?: any): AxiosPromise<Array<GeneralPractitionerListLite>> {
            return localVarFp.getAllByLicenseCode(licenseCode, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saadaval teeninduspiirkonnad
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedServiceAreas(id: string, options?: any): AxiosPromise<Array<AllowedServiceArea>> {
            return localVarFp.getAllowedServiceAreas(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hankige logid pärast perearstide loendi värskendamist
         * @param {string} id 
         * @param {'CREATE' | 'UPDATE' | 'DELETE' | 'UPDATE_EDUCATION' | 'CHANGE_STATUS' | 'FORWARD_TO_OFFICIAL' | 'UPDATE_GP_LIST_PATIENTS_SIZE' | 'UPDATE_GP_LIST_SERVICE_AREA' | 'UPDATE_GP_LIST_GENERAL_PRACTITIONER' | 'UPDATE_GP_LIST_LICENSE' | 'UPDATE_GP_LIST_LICENSE_ADDRESS' | 'UPDATE_GP_LIST_SUBSTITUTE' | 'UPDATE_GP_LIST_EMPLOYEE' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR_PERIOD' | 'ADDING_SPECIALITY' | 'UPDATE_ACTIVITY_LICENSE_PHARMACY_OWNER'} actionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLogItems(id: string, actionType: 'CREATE' | 'UPDATE' | 'DELETE' | 'UPDATE_EDUCATION' | 'CHANGE_STATUS' | 'FORWARD_TO_OFFICIAL' | 'UPDATE_GP_LIST_PATIENTS_SIZE' | 'UPDATE_GP_LIST_SERVICE_AREA' | 'UPDATE_GP_LIST_GENERAL_PRACTITIONER' | 'UPDATE_GP_LIST_LICENSE' | 'UPDATE_GP_LIST_LICENSE_ADDRESS' | 'UPDATE_GP_LIST_SUBSTITUTE' | 'UPDATE_GP_LIST_EMPLOYEE' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR_PERIOD' | 'ADDING_SPECIALITY' | 'UPDATE_ACTIVITY_LICENSE_PHARMACY_OWNER', options?: any): AxiosPromise<Array<AuditLogItem>> {
            return localVarFp.getAuditLogItems(id, actionType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {string} id 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPractitionerList1(id: string, context?: string, options?: any): AxiosPromise<GeneralPractitionerList> {
            return localVarFp.getGeneralPractitionerList1(id, context, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saada üldarst
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPractitionerModification(id: string, options?: any): AxiosPromise<GeneralPractitioner> {
            return localVarFp.getGeneralPractitionerModification(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab Nimistu tegevusloaga
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicensesModification(id: string, options?: any): AxiosPromise<Array<GeneralPractitionerListLicense>> {
            return localVarFp.getLicensesModification(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab teeninduspiirkonna
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceAreaModification(id: string, options?: any): AxiosPromise<ServiceArea> {
            return localVarFp.getServiceAreaModification(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saada asendaja
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubstituteModification(id: string, options?: any): AxiosPromise<Substitute> {
            return localVarFp.getSubstituteModification(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary V�rskendada perearsti nimistu tegelikku suurust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshActualSizeInGeneralPractitionerList(id: string, options?: any): AxiosPromise<GeneralPractitionerList> {
            return localVarFp.refreshActualSizeInGeneralPractitionerList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab filtreeritud perearstide loendi.
         * @param {GeneralPractitionerListSearchFilter} generalPractitionerListSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGeneralPractitionerList1(generalPractitionerListSearchFilter: GeneralPractitionerListSearchFilter, options?: any): AxiosPromise<GeneralPractitionerListSearch> {
            return localVarFp.searchGeneralPractitionerList1(generalPractitionerListSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ajakohastada üldarstide nimekirja
         * @param {string} id 
         * @param {GeneralPractitionerList} generalPractitionerList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralPractitionerList(id: string, generalPractitionerList: GeneralPractitionerList, options?: any): AxiosPromise<GeneralPractitionerList> {
            return localVarFp.updateGeneralPractitionerList(id, generalPractitionerList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendada üldarsti
         * @param {string} id 
         * @param {GeneralPractitioner} generalPractitioner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralPractitionerModification(id: string, generalPractitioner: GeneralPractitioner, options?: any): AxiosPromise<GeneralPractitioner> {
            return localVarFp.updateGeneralPractitionerModification(id, generalPractitioner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab Nimistu tegevusloaga
         * @param {string} id 
         * @param {Array<GeneralPractitionerListLicense>} generalPractitionerListLicense 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicensesModification(id: string, generalPractitionerListLicense: Array<GeneralPractitionerListLicense>, options?: any): AxiosPromise<Array<GeneralPractitionerListLicense>> {
            return localVarFp.updateLicensesModification(id, generalPractitionerListLicense, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab teeninduspiirkonna
         * @param {string} id 
         * @param {ServiceArea} serviceArea 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceAreaModification(id: string, serviceArea: ServiceArea, options?: any): AxiosPromise<ServiceArea> {
            return localVarFp.updateServiceAreaModification(id, serviceArea, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Värskenduse asendaja
         * @param {string} id 
         * @param {Substitute} substitute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubstituteModification(id: string, substitute: Substitute, options?: any): AxiosPromise<Substitute> {
            return localVarFp.updateSubstituteModification(id, substitute, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuPerearstiNimistudTeenusApi - object-oriented interface
 * @export
 * @class AmetnikuPerearstiNimistudTeenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuPerearstiNimistudTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Koostage perearstide loend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public createGeneralPractitionerList(options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).createGeneralPractitionerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ekspordib filtreeritud perearstide nimistud CSV-failina
     * @param {GeneralPractitionerListSearchFilter} generalPractitionerListSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public exportGeneralPractitionerList(generalPractitionerListSearchFilter: GeneralPractitionerListSearchFilter, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).exportGeneralPractitionerList(generalPractitionerListSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.general-practitioner-list-activity-license.description}
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public getActivityLicense1(id: string, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).getActivityLicense1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hankige perearstide nimekiri
     * @param {string} licenseCode 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public getAllByLicenseCode(licenseCode: string, status?: string, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).getAllByLicenseCode(licenseCode, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saadaval teeninduspiirkonnad
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public getAllowedServiceAreas(id: string, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).getAllowedServiceAreas(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hankige logid pärast perearstide loendi värskendamist
     * @param {string} id 
     * @param {'CREATE' | 'UPDATE' | 'DELETE' | 'UPDATE_EDUCATION' | 'CHANGE_STATUS' | 'FORWARD_TO_OFFICIAL' | 'UPDATE_GP_LIST_PATIENTS_SIZE' | 'UPDATE_GP_LIST_SERVICE_AREA' | 'UPDATE_GP_LIST_GENERAL_PRACTITIONER' | 'UPDATE_GP_LIST_LICENSE' | 'UPDATE_GP_LIST_LICENSE_ADDRESS' | 'UPDATE_GP_LIST_SUBSTITUTE' | 'UPDATE_GP_LIST_EMPLOYEE' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR_PERIOD' | 'ADDING_SPECIALITY' | 'UPDATE_ACTIVITY_LICENSE_PHARMACY_OWNER'} actionType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public getAuditLogItems(id: string, actionType: 'CREATE' | 'UPDATE' | 'DELETE' | 'UPDATE_EDUCATION' | 'CHANGE_STATUS' | 'FORWARD_TO_OFFICIAL' | 'UPDATE_GP_LIST_PATIENTS_SIZE' | 'UPDATE_GP_LIST_SERVICE_AREA' | 'UPDATE_GP_LIST_GENERAL_PRACTITIONER' | 'UPDATE_GP_LIST_LICENSE' | 'UPDATE_GP_LIST_LICENSE_ADDRESS' | 'UPDATE_GP_LIST_SUBSTITUTE' | 'UPDATE_GP_LIST_EMPLOYEE' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR' | 'UPDATE_GP_LIST_SUBSTITUTE_DOCTOR_PERIOD' | 'ADDING_SPECIALITY' | 'UPDATE_ACTIVITY_LICENSE_PHARMACY_OWNER', options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).getAuditLogItems(id, actionType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hankige perearstide nimekiri
     * @param {string} id 
     * @param {string} [context] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public getGeneralPractitionerList1(id: string, context?: string, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).getGeneralPractitionerList1(id, context, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saada üldarst
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public getGeneralPractitionerModification(id: string, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).getGeneralPractitionerModification(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab Nimistu tegevusloaga
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public getLicensesModification(id: string, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).getLicensesModification(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab teeninduspiirkonna
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public getServiceAreaModification(id: string, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).getServiceAreaModification(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saada asendaja
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public getSubstituteModification(id: string, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).getSubstituteModification(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary V�rskendada perearsti nimistu tegelikku suurust.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public refreshActualSizeInGeneralPractitionerList(id: string, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).refreshActualSizeInGeneralPractitionerList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab filtreeritud perearstide loendi.
     * @param {GeneralPractitionerListSearchFilter} generalPractitionerListSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public searchGeneralPractitionerList1(generalPractitionerListSearchFilter: GeneralPractitionerListSearchFilter, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).searchGeneralPractitionerList1(generalPractitionerListSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ajakohastada üldarstide nimekirja
     * @param {string} id 
     * @param {GeneralPractitionerList} generalPractitionerList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public updateGeneralPractitionerList(id: string, generalPractitionerList: GeneralPractitionerList, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).updateGeneralPractitionerList(id, generalPractitionerList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendada üldarsti
     * @param {string} id 
     * @param {GeneralPractitioner} generalPractitioner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public updateGeneralPractitionerModification(id: string, generalPractitioner: GeneralPractitioner, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).updateGeneralPractitionerModification(id, generalPractitioner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab Nimistu tegevusloaga
     * @param {string} id 
     * @param {Array<GeneralPractitionerListLicense>} generalPractitionerListLicense 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public updateLicensesModification(id: string, generalPractitionerListLicense: Array<GeneralPractitionerListLicense>, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).updateLicensesModification(id, generalPractitionerListLicense, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab teeninduspiirkonna
     * @param {string} id 
     * @param {ServiceArea} serviceArea 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public updateServiceAreaModification(id: string, serviceArea: ServiceArea, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).updateServiceAreaModification(id, serviceArea, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Värskenduse asendaja
     * @param {string} id 
     * @param {Substitute} substitute 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuPerearstiNimistudTeenusApi
     */
    public updateSubstituteModification(id: string, substitute: Substitute, options?: AxiosRequestConfig) {
        return AmetnikuPerearstiNimistudTeenusApiFp(this.configuration).updateSubstituteModification(id, substitute, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuRakendussertifikaadiAndmeteenusApi - axios parameter creator
 * @export
 */
export const AmetnikuRakendussertifikaadiAndmeteenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Nõustuge rakenduse sertifikaadiga
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptApplicationCertificate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('acceptApplicationCertificate', 'id', id)
            const localVarPath = `/official/applications/certificates/{id}/accept`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kustutab rakenduse sertifikaadi
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCertificateApplication: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCertificateApplication', 'id', id)
            const localVarPath = `/official/applications/certificates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lõpetage rakenduse sertifikaat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishApplicationCertificate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('finishApplicationCertificate', 'id', id)
            const localVarPath = `/official/applications/certificates/{id}/finish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab rakenduse sertifikaadi detailse informatsiooni
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplication1', 'id', id)
            const localVarPath = `/official/applications/certificates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Märgib rakenduse sertifikaadi käsitsi tasutuks
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paidManuallyApplicationCertificate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paidManuallyApplicationCertificate', 'id', id)
            const localVarPath = `/official/applications/certificates/{id}/paid-manually`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Jätkake rakenduse sertifikaadiga
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proceedApplicationCertificate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proceedApplicationCertificate', 'id', id)
            const localVarPath = `/official/applications/certificates/{id}/proceed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab rakenduse sertifikaat märkmeid
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationCertificateNote: async (id: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApplicationCertificateNote', 'id', id)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('updateApplicationCertificateNote', 'applicationNote', applicationNote)
            const localVarPath = `/official/applications/certificates/{id}/note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuRakendussertifikaadiAndmeteenusApi - functional programming interface
 * @export
 */
export const AmetnikuRakendussertifikaadiAndmeteenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuRakendussertifikaadiAndmeteenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Nõustuge rakenduse sertifikaadiga
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptApplicationCertificate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptApplicationCertificate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kustutab rakenduse sertifikaadi
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCertificateApplication(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCertificateApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lõpetage rakenduse sertifikaat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishApplicationCertificate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishApplicationCertificate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab rakenduse sertifikaadi detailse informatsiooni
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplication1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedApplicationCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplication1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Märgib rakenduse sertifikaadi käsitsi tasutuks
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paidManuallyApplicationCertificate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paidManuallyApplicationCertificate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Jätkake rakenduse sertifikaadiga
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proceedApplicationCertificate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proceedApplicationCertificate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab rakenduse sertifikaat märkmeid
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationCertificateNote(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationCertificateNote(id, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuRakendussertifikaadiAndmeteenusApi - factory interface
 * @export
 */
export const AmetnikuRakendussertifikaadiAndmeteenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuRakendussertifikaadiAndmeteenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Nõustuge rakenduse sertifikaadiga
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptApplicationCertificate(id: string, options?: any): AxiosPromise<ApplicationCertificate> {
            return localVarFp.acceptApplicationCertificate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kustutab rakenduse sertifikaadi
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCertificateApplication(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteCertificateApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lõpetage rakenduse sertifikaat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishApplicationCertificate(id: string, options?: any): AxiosPromise<ApplicationCertificate> {
            return localVarFp.finishApplicationCertificate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab rakenduse sertifikaadi detailse informatsiooni
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication1(id: string, options?: any): AxiosPromise<DetailedApplicationCertificate> {
            return localVarFp.getApplication1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Märgib rakenduse sertifikaadi käsitsi tasutuks
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paidManuallyApplicationCertificate(id: string, options?: any): AxiosPromise<ApplicationCertificate> {
            return localVarFp.paidManuallyApplicationCertificate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Jätkake rakenduse sertifikaadiga
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proceedApplicationCertificate(id: string, options?: any): AxiosPromise<ApplicationCertificate> {
            return localVarFp.proceedApplicationCertificate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab rakenduse sertifikaat märkmeid
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationCertificateNote(id: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<ApplicationCertificate> {
            return localVarFp.updateApplicationCertificateNote(id, applicationNote, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuRakendussertifikaadiAndmeteenusApi - object-oriented interface
 * @export
 * @class AmetnikuRakendussertifikaadiAndmeteenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuRakendussertifikaadiAndmeteenusApi extends BaseAPI {
    /**
     * 
     * @summary Nõustuge rakenduse sertifikaadiga
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuRakendussertifikaadiAndmeteenusApi
     */
    public acceptApplicationCertificate(id: string, options?: AxiosRequestConfig) {
        return AmetnikuRakendussertifikaadiAndmeteenusApiFp(this.configuration).acceptApplicationCertificate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kustutab rakenduse sertifikaadi
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuRakendussertifikaadiAndmeteenusApi
     */
    public deleteCertificateApplication(id: string, options?: AxiosRequestConfig) {
        return AmetnikuRakendussertifikaadiAndmeteenusApiFp(this.configuration).deleteCertificateApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lõpetage rakenduse sertifikaat
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuRakendussertifikaadiAndmeteenusApi
     */
    public finishApplicationCertificate(id: string, options?: AxiosRequestConfig) {
        return AmetnikuRakendussertifikaadiAndmeteenusApiFp(this.configuration).finishApplicationCertificate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab rakenduse sertifikaadi detailse informatsiooni
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuRakendussertifikaadiAndmeteenusApi
     */
    public getApplication1(id: string, options?: AxiosRequestConfig) {
        return AmetnikuRakendussertifikaadiAndmeteenusApiFp(this.configuration).getApplication1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Märgib rakenduse sertifikaadi käsitsi tasutuks
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuRakendussertifikaadiAndmeteenusApi
     */
    public paidManuallyApplicationCertificate(id: string, options?: AxiosRequestConfig) {
        return AmetnikuRakendussertifikaadiAndmeteenusApiFp(this.configuration).paidManuallyApplicationCertificate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Jätkake rakenduse sertifikaadiga
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuRakendussertifikaadiAndmeteenusApi
     */
    public proceedApplicationCertificate(id: string, options?: AxiosRequestConfig) {
        return AmetnikuRakendussertifikaadiAndmeteenusApiFp(this.configuration).proceedApplicationCertificate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab rakenduse sertifikaat märkmeid
     * @param {string} id 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuRakendussertifikaadiAndmeteenusApi
     */
    public updateApplicationCertificateNote(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuRakendussertifikaadiAndmeteenusApiFp(this.configuration).updateApplicationCertificateNote(id, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuSertifikaadiAndmeteenusApi - axios parameter creator
 * @export
 */
export const AmetnikuSertifikaadiAndmeteenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lisab uue sertifikaadi
         * @param {string} personId 
         * @param {PersonCertificate} personCertificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonCertificate: async (personId: string, personCertificate: PersonCertificate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('createPersonCertificate', 'personId', personId)
            // verify required parameter 'personCertificate' is not null or undefined
            assertParamExists('createPersonCertificate', 'personCertificate', personCertificate)
            const localVarPath = `/official/persons/{personId}/certificates`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personCertificate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kustutab sertifikaadi
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePersonCertificate: async (id: string, personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePersonCertificate', 'id', id)
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('deletePersonCertificate', 'personId', personId)
            const localVarPath = `/official/persons/{personId}/certificates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab sertifikaadi metaandmed
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationCertificateMetadata: async (personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getApplicationCertificateMetadata', 'personId', personId)
            const localVarPath = `/official/persons/{personId}/certificates/metadata`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.get-person-certificate-filter.description}
         * @param {PersonCertificateSearchFilter} personCertificateSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCertificates: async (personCertificateSearchFilter: PersonCertificateSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personCertificateSearchFilter' is not null or undefined
            assertParamExists('searchCertificates', 'personCertificateSearchFilter', personCertificateSearchFilter)
            const localVarPath = `/official/certificates/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personCertificateSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Värskendab sertifikaadi
         * @param {string} personId 
         * @param {string} id 
         * @param {PersonCertificate} personCertificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonCertificate: async (personId: string, id: string, personCertificate: PersonCertificate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('updatePersonCertificate', 'personId', personId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePersonCertificate', 'id', id)
            // verify required parameter 'personCertificate' is not null or undefined
            assertParamExists('updatePersonCertificate', 'personCertificate', personCertificate)
            const localVarPath = `/official/persons/{personId}/certificates/{id}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personCertificate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuSertifikaadiAndmeteenusApi - functional programming interface
 * @export
 */
export const AmetnikuSertifikaadiAndmeteenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuSertifikaadiAndmeteenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lisab uue sertifikaadi
         * @param {string} personId 
         * @param {PersonCertificate} personCertificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPersonCertificate(personId: string, personCertificate: PersonCertificate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPersonCertificate(personId, personCertificate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kustutab sertifikaadi
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePersonCertificate(id: string, personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePersonCertificate(id, personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab sertifikaadi metaandmed
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationCertificateMetadata(personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertificateMetadata>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationCertificateMetadata(personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.get-person-certificate-filter.description}
         * @param {PersonCertificateSearchFilter} personCertificateSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCertificates(personCertificateSearchFilter: PersonCertificateSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedPersonCertificateSearch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCertificates(personCertificateSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Värskendab sertifikaadi
         * @param {string} personId 
         * @param {string} id 
         * @param {PersonCertificate} personCertificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePersonCertificate(personId: string, id: string, personCertificate: PersonCertificate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonCertificate(personId, id, personCertificate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuSertifikaadiAndmeteenusApi - factory interface
 * @export
 */
export const AmetnikuSertifikaadiAndmeteenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuSertifikaadiAndmeteenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Lisab uue sertifikaadi
         * @param {string} personId 
         * @param {PersonCertificate} personCertificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonCertificate(personId: string, personCertificate: PersonCertificate, options?: any): AxiosPromise<PersonCertificate> {
            return localVarFp.createPersonCertificate(personId, personCertificate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kustutab sertifikaadi
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePersonCertificate(id: string, personId: string, options?: any): AxiosPromise<string> {
            return localVarFp.deletePersonCertificate(id, personId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab sertifikaadi metaandmed
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationCertificateMetadata(personId: string, options?: any): AxiosPromise<CertificateMetadata> {
            return localVarFp.getApplicationCertificateMetadata(personId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.get-person-certificate-filter.description}
         * @param {PersonCertificateSearchFilter} personCertificateSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCertificates(personCertificateSearchFilter: PersonCertificateSearchFilter, options?: any): AxiosPromise<DetailedPersonCertificateSearch> {
            return localVarFp.searchCertificates(personCertificateSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Värskendab sertifikaadi
         * @param {string} personId 
         * @param {string} id 
         * @param {PersonCertificate} personCertificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonCertificate(personId: string, id: string, personCertificate: PersonCertificate, options?: any): AxiosPromise<PersonCertificate> {
            return localVarFp.updatePersonCertificate(personId, id, personCertificate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuSertifikaadiAndmeteenusApi - object-oriented interface
 * @export
 * @class AmetnikuSertifikaadiAndmeteenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuSertifikaadiAndmeteenusApi extends BaseAPI {
    /**
     * 
     * @summary Lisab uue sertifikaadi
     * @param {string} personId 
     * @param {PersonCertificate} personCertificate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuSertifikaadiAndmeteenusApi
     */
    public createPersonCertificate(personId: string, personCertificate: PersonCertificate, options?: AxiosRequestConfig) {
        return AmetnikuSertifikaadiAndmeteenusApiFp(this.configuration).createPersonCertificate(personId, personCertificate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kustutab sertifikaadi
     * @param {string} id 
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuSertifikaadiAndmeteenusApi
     */
    public deletePersonCertificate(id: string, personId: string, options?: AxiosRequestConfig) {
        return AmetnikuSertifikaadiAndmeteenusApiFp(this.configuration).deletePersonCertificate(id, personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab sertifikaadi metaandmed
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuSertifikaadiAndmeteenusApi
     */
    public getApplicationCertificateMetadata(personId: string, options?: AxiosRequestConfig) {
        return AmetnikuSertifikaadiAndmeteenusApiFp(this.configuration).getApplicationCertificateMetadata(personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.get-person-certificate-filter.description}
     * @param {PersonCertificateSearchFilter} personCertificateSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuSertifikaadiAndmeteenusApi
     */
    public searchCertificates(personCertificateSearchFilter: PersonCertificateSearchFilter, options?: AxiosRequestConfig) {
        return AmetnikuSertifikaadiAndmeteenusApiFp(this.configuration).searchCertificates(personCertificateSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Värskendab sertifikaadi
     * @param {string} personId 
     * @param {string} id 
     * @param {PersonCertificate} personCertificate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuSertifikaadiAndmeteenusApi
     */
    public updatePersonCertificate(personId: string, id: string, personCertificate: PersonCertificate, options?: AxiosRequestConfig) {
        return AmetnikuSertifikaadiAndmeteenusApiFp(this.configuration).updatePersonCertificate(personId, id, personCertificate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuTaotluseAndmeteTeenusApi - axios parameter creator
 * @export
 */
export const AmetnikuTaotluseAndmeteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Märgib taotluse automaatselt menetletuks.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptApplicationAutomatically: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('acceptApplicationAutomatically', 'applicationId', applicationId)
            const localVarPath = `/official/applications/accept-automatically/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Muuta rakenduse ametlikku suhet.
         * @param {string} applicationId 
         * @param {ApplicationOfficialRelationDto} applicationOfficialRelationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplicationOfficialRelation: async (applicationId: string, applicationOfficialRelationDto: ApplicationOfficialRelationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('addApplicationOfficialRelation', 'applicationId', applicationId)
            // verify required parameter 'applicationOfficialRelationDto' is not null or undefined
            assertParamExists('addApplicationOfficialRelation', 'applicationOfficialRelationDto', applicationOfficialRelationDto)
            const localVarPath = `/official/applications/{applicationId}/official-relation`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationOfficialRelationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lisab taotlusele vastavuseksami info.
         * @param {string} applicationId 
         * @param {ExamInfo} examInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExamInfo: async (applicationId: string, examInfo: ExamInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('addExamInfo', 'applicationId', applicationId)
            // verify required parameter 'examInfo' is not null or undefined
            assertParamExists('addExamInfo', 'examInfo', examInfo)
            const localVarPath = `/official/applications/{applicationId}/exam-cert`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Muudab taotluse staatust.
         * @param {string} id 
         * @param {ProceedingStatus} proceedingStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeApplicationStatus: async (id: string, proceedingStatus: ProceedingStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeApplicationStatus', 'id', id)
            // verify required parameter 'proceedingStatus' is not null or undefined
            assertParamExists('changeApplicationStatus', 'proceedingStatus', proceedingStatus)
            const localVarPath = `/official/applications/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proceedingStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueApplicationProceeding: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('continueApplicationProceeding', 'applicationId', applicationId)
            const localVarPath = `/official/applications/{applicationId}/continue-proceeding`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi ametniku portaalist.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationDraft: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApplicationDraft', 'id', id)
            const localVarPath = `/official/applications/draft/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.get-official-application-filter-applications.description}
         * @param {ApplicationSearchFilter} applicationSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterApplications: async (applicationSearchFilter: ApplicationSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationSearchFilter' is not null or undefined
            assertParamExists('filterApplications', 'applicationSearchFilter', applicationSearchFilter)
            const localVarPath = `/official/applications/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab taotluse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplication', 'id', id)
            const localVarPath = `/official/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab salvestatud taotluse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationDraft: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplicationDraft', 'id', id)
            const localVarPath = `/official/applications/draft/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kasutaja taotlused.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonApplications: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPersonApplications', 'id', id)
            const localVarPath = `/official/applications/person/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOccupationApplications: async (personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getUserOccupationApplications', 'personId', personId)
            const localVarPath = `/official/applications/occupations/{personId}/applied`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSpecializationApplications: async (personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getUserSpecializationApplications', 'personId', personId)
            const localVarPath = `/official/applications/specializations/{personId}/applied`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Alustab taotluse menetlust.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateApplicationProceeding: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('initiateApplicationProceeding', 'applicationId', applicationId)
            const localVarPath = `/official/applications/start-proceeding/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Märgib taotluse käsitsi makstuks.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsManuallyPaid1: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('markAsManuallyPaid1', 'applicationId', applicationId)
            const localVarPath = `/official/applications/{applicationId}/paid-manually`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Peatab taotluse menetluse.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseApplicationProceeding1: async (applicationId: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('pauseApplicationProceeding1', 'applicationId', applicationId)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('pauseApplicationProceeding1', 'applicationNote', applicationNote)
            const localVarPath = `/official/applications/{applicationId}/pause`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab ametniku poolt sisestatud pooliku avalduse.
         * @param {string} id 
         * @param {ApplicationDraft} applicationDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOfficialApplicationDraft: async (id: string, applicationDraft: ApplicationDraft, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveOfficialApplicationDraft', 'id', id)
            // verify required parameter 'applicationDraft' is not null or undefined
            assertParamExists('saveOfficialApplicationDraft', 'applicationDraft', applicationDraft)
            const localVarPath = `/official/applications/draft/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationDraft, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Loob ametniku poolt pooliku avalduse.
         * @param {ApplicationDraft} applicationDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOfficialApplicationDraft1: async (applicationDraft: ApplicationDraft, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationDraft' is not null or undefined
            assertParamExists('saveOfficialApplicationDraft1', 'applicationDraft', applicationDraft)
            const localVarPath = `/official/applications/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationDraft, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab avalduse märkmeid.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationNote1: async (id: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApplicationNote1', 'id', id)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('updateApplicationNote1', 'applicationNote', applicationNote)
            const localVarPath = `/official/applications/note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab taotluse hariduse info.
         * @param {string} id 
         * @param {EducationInfo} educationInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEducationInfo: async (id: string, educationInfo: EducationInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEducationInfo', 'id', id)
            // verify required parameter 'educationInfo' is not null or undefined
            assertParamExists('updateEducationInfo', 'educationInfo', educationInfo)
            const localVarPath = `/official/applications/{id}/education-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuTaotluseAndmeteTeenusApi - functional programming interface
 * @export
 */
export const AmetnikuTaotluseAndmeteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuTaotluseAndmeteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Märgib taotluse automaatselt menetletuks.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptApplicationAutomatically(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptApplicationAutomatically(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Muuta rakenduse ametlikku suhet.
         * @param {string} applicationId 
         * @param {ApplicationOfficialRelationDto} applicationOfficialRelationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addApplicationOfficialRelation(applicationId: string, applicationOfficialRelationDto: ApplicationOfficialRelationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addApplicationOfficialRelation(applicationId, applicationOfficialRelationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lisab taotlusele vastavuseksami info.
         * @param {string} applicationId 
         * @param {ExamInfo} examInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addExamInfo(applicationId: string, examInfo: ExamInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addExamInfo(applicationId, examInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Muudab taotluse staatust.
         * @param {string} id 
         * @param {ProceedingStatus} proceedingStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeApplicationStatus(id: string, proceedingStatus: ProceedingStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeApplicationStatus(id, proceedingStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continueApplicationProceeding(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.continueApplicationProceeding(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi ametniku portaalist.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationDraft(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicationDraft(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.get-official-application-filter-applications.description}
         * @param {ApplicationSearchFilter} applicationSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterApplications(applicationSearchFilter: ApplicationSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProceedingSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterApplications(applicationSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab taotluse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplication(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab salvestatud taotluse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationDraft(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDraft>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationDraft(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kasutaja taotlused.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonApplications(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserApplications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonApplications(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserOccupationApplications(personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppliedApplications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserOccupationApplications(personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSpecializationApplications(personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppliedApplications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSpecializationApplications(personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Alustab taotluse menetlust.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateApplicationProceeding(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateApplicationProceeding(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Märgib taotluse käsitsi makstuks.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAsManuallyPaid1(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAsManuallyPaid1(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Peatab taotluse menetluse.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pauseApplicationProceeding1(applicationId: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pauseApplicationProceeding1(applicationId, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab ametniku poolt sisestatud pooliku avalduse.
         * @param {string} id 
         * @param {ApplicationDraft} applicationDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveOfficialApplicationDraft(id: string, applicationDraft: ApplicationDraft, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDraft>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveOfficialApplicationDraft(id, applicationDraft, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Loob ametniku poolt pooliku avalduse.
         * @param {ApplicationDraft} applicationDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveOfficialApplicationDraft1(applicationDraft: ApplicationDraft, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDraft>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveOfficialApplicationDraft1(applicationDraft, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab avalduse märkmeid.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationNote1(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationNote1(id, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab taotluse hariduse info.
         * @param {string} id 
         * @param {EducationInfo} educationInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEducationInfo(id: string, educationInfo: EducationInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEducationInfo(id, educationInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuTaotluseAndmeteTeenusApi - factory interface
 * @export
 */
export const AmetnikuTaotluseAndmeteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuTaotluseAndmeteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Märgib taotluse automaatselt menetletuks.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptApplicationAutomatically(applicationId: string, options?: any): AxiosPromise<DetailedApplication> {
            return localVarFp.acceptApplicationAutomatically(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Muuta rakenduse ametlikku suhet.
         * @param {string} applicationId 
         * @param {ApplicationOfficialRelationDto} applicationOfficialRelationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplicationOfficialRelation(applicationId: string, applicationOfficialRelationDto: ApplicationOfficialRelationDto, options?: any): AxiosPromise<string> {
            return localVarFp.addApplicationOfficialRelation(applicationId, applicationOfficialRelationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lisab taotlusele vastavuseksami info.
         * @param {string} applicationId 
         * @param {ExamInfo} examInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExamInfo(applicationId: string, examInfo: ExamInfo, options?: any): AxiosPromise<DetailedApplication> {
            return localVarFp.addExamInfo(applicationId, examInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Muudab taotluse staatust.
         * @param {string} id 
         * @param {ProceedingStatus} proceedingStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeApplicationStatus(id: string, proceedingStatus: ProceedingStatus, options?: any): AxiosPromise<DetailedApplication> {
            return localVarFp.changeApplicationStatus(id, proceedingStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueApplicationProceeding(applicationId: string, options?: any): AxiosPromise<DetailedApplication> {
            return localVarFp.continueApplicationProceeding(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi ametniku portaalist.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationDraft(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteApplicationDraft(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.get-official-application-filter-applications.description}
         * @param {ApplicationSearchFilter} applicationSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterApplications(applicationSearchFilter: ApplicationSearchFilter, options?: any): AxiosPromise<ProceedingSearchResponse> {
            return localVarFp.filterApplications(applicationSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab taotluse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication(id: string, options?: any): AxiosPromise<DetailedApplication> {
            return localVarFp.getApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab salvestatud taotluse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationDraft(id: string, options?: any): AxiosPromise<ApplicationDraft> {
            return localVarFp.getApplicationDraft(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kasutaja taotlused.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonApplications(id: string, options?: any): AxiosPromise<UserApplications> {
            return localVarFp.getPersonApplications(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOccupationApplications(personId: string, options?: any): AxiosPromise<AppliedApplications> {
            return localVarFp.getUserOccupationApplications(personId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSpecializationApplications(personId: string, options?: any): AxiosPromise<AppliedApplications> {
            return localVarFp.getUserSpecializationApplications(personId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Alustab taotluse menetlust.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateApplicationProceeding(applicationId: string, options?: any): AxiosPromise<DetailedApplication> {
            return localVarFp.initiateApplicationProceeding(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Märgib taotluse käsitsi makstuks.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsManuallyPaid1(applicationId: string, options?: any): AxiosPromise<DetailedApplication> {
            return localVarFp.markAsManuallyPaid1(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Peatab taotluse menetluse.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseApplicationProceeding1(applicationId: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedApplication> {
            return localVarFp.pauseApplicationProceeding1(applicationId, applicationNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab ametniku poolt sisestatud pooliku avalduse.
         * @param {string} id 
         * @param {ApplicationDraft} applicationDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOfficialApplicationDraft(id: string, applicationDraft: ApplicationDraft, options?: any): AxiosPromise<ApplicationDraft> {
            return localVarFp.saveOfficialApplicationDraft(id, applicationDraft, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Loob ametniku poolt pooliku avalduse.
         * @param {ApplicationDraft} applicationDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOfficialApplicationDraft1(applicationDraft: ApplicationDraft, options?: any): AxiosPromise<ApplicationDraft> {
            return localVarFp.saveOfficialApplicationDraft1(applicationDraft, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab avalduse märkmeid.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationNote1(id: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<Application> {
            return localVarFp.updateApplicationNote1(id, applicationNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab taotluse hariduse info.
         * @param {string} id 
         * @param {EducationInfo} educationInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEducationInfo(id: string, educationInfo: EducationInfo, options?: any): AxiosPromise<DetailedApplication> {
            return localVarFp.updateEducationInfo(id, educationInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuTaotluseAndmeteTeenusApi - object-oriented interface
 * @export
 * @class AmetnikuTaotluseAndmeteTeenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuTaotluseAndmeteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Märgib taotluse automaatselt menetletuks.
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public acceptApplicationAutomatically(applicationId: string, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).acceptApplicationAutomatically(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Muuta rakenduse ametlikku suhet.
     * @param {string} applicationId 
     * @param {ApplicationOfficialRelationDto} applicationOfficialRelationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public addApplicationOfficialRelation(applicationId: string, applicationOfficialRelationDto: ApplicationOfficialRelationDto, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).addApplicationOfficialRelation(applicationId, applicationOfficialRelationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lisab taotlusele vastavuseksami info.
     * @param {string} applicationId 
     * @param {ExamInfo} examInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public addExamInfo(applicationId: string, examInfo: ExamInfo, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).addExamInfo(applicationId, examInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Muudab taotluse staatust.
     * @param {string} id 
     * @param {ProceedingStatus} proceedingStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public changeApplicationStatus(id: string, proceedingStatus: ProceedingStatus, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).changeApplicationStatus(id, proceedingStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Jätkab taotluse menetlust.
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public continueApplicationProceeding(applicationId: string, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).continueApplicationProceeding(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kustutab taotluse mustandi ametniku portaalist.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public deleteApplicationDraft(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).deleteApplicationDraft(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.get-official-application-filter-applications.description}
     * @param {ApplicationSearchFilter} applicationSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public filterApplications(applicationSearchFilter: ApplicationSearchFilter, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).filterApplications(applicationSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab taotluse info.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public getApplication(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).getApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab salvestatud taotluse info.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public getApplicationDraft(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).getApplicationDraft(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kasutaja taotlused.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public getPersonApplications(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).getPersonApplications(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public getUserOccupationApplications(personId: string, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).getUserOccupationApplications(personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public getUserSpecializationApplications(personId: string, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).getUserSpecializationApplications(personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Alustab taotluse menetlust.
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public initiateApplicationProceeding(applicationId: string, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).initiateApplicationProceeding(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Märgib taotluse käsitsi makstuks.
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public markAsManuallyPaid1(applicationId: string, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).markAsManuallyPaid1(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Peatab taotluse menetluse.
     * @param {string} applicationId 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public pauseApplicationProceeding1(applicationId: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).pauseApplicationProceeding1(applicationId, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab ametniku poolt sisestatud pooliku avalduse.
     * @param {string} id 
     * @param {ApplicationDraft} applicationDraft 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public saveOfficialApplicationDraft(id: string, applicationDraft: ApplicationDraft, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).saveOfficialApplicationDraft(id, applicationDraft, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Loob ametniku poolt pooliku avalduse.
     * @param {ApplicationDraft} applicationDraft 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public saveOfficialApplicationDraft1(applicationDraft: ApplicationDraft, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).saveOfficialApplicationDraft1(applicationDraft, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab avalduse märkmeid.
     * @param {string} id 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public updateApplicationNote1(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).updateApplicationNote1(id, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab taotluse hariduse info.
     * @param {string} id 
     * @param {EducationInfo} educationInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTaotluseAndmeteTeenusApi
     */
    public updateEducationInfo(id: string, educationInfo: EducationInfo, options?: AxiosRequestConfig) {
        return AmetnikuTaotluseAndmeteTeenusApiFp(this.configuration).updateEducationInfo(id, educationInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApi - axios parameter creator
 * @export
 */
export const AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tegevuskoha peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeInvalidationStatus: async (id: string, status: ActivityLicenseSuspensionStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeInvalidationStatus', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeInvalidationStatus', 'status', status)
            const localVarPath = `/official/activity-licenses/locations/invalidations/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.create-activity-location-invalidation.description}
         * @param {string} id 
         * @param {InvalidationCreation} invalidation 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityLicenseInvalidation: async (id: string, invalidation: InvalidationCreation, file: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createActivityLicenseInvalidation', 'id', id)
            // verify required parameter 'invalidation' is not null or undefined
            assertParamExists('createActivityLicenseInvalidation', 'invalidation', invalidation)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createActivityLicenseInvalidation', 'file', file)
            const localVarPath = `/official/activity-licenses/locations/invalidations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication api-key required


            if (invalidation !== undefined) { 
                localVarFormParams.append('invalidation', new Blob([JSON.stringify(invalidation)], { type: "application/json", }));
            }
                if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardToOfficial: async (id: string, officialUser: OfficialUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forwardToOfficial', 'id', id)
            // verify required parameter 'officialUser' is not null or undefined
            assertParamExists('forwardToOfficial', 'officialUser', officialUser)
            const localVarPath = `/official/activity-licenses/locations/invalidations/{id}/forward-official`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officialUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseInvalidation2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicenseInvalidation2', 'id', id)
            const localVarPath = `/official/activity-licenses/locations/invalidations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateProceeding1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('initiateProceeding1', 'id', id)
            const localVarPath = `/official/activity-licenses/locations/invalidations/{id}/initiate-proceeding`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Taotluse märkused uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityLocationSuspensionNote: async (id: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateActivityLocationSuspensionNote', 'id', id)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('updateActivityLocationSuspensionNote', 'applicationNote', applicationNote)
            const localVarPath = `/official/activity-licenses/locations/invalidations/{id}/note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApi - functional programming interface
 * @export
 */
export const AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tegevuskoha peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeInvalidationStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLocationInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeInvalidationStatus(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.create-activity-location-invalidation.description}
         * @param {string} id 
         * @param {InvalidationCreation} invalidation 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActivityLicenseInvalidation(id: string, invalidation: InvalidationCreation, file: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActivityLicenseInvalidation(id, invalidation, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forwardToOfficial(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLocationInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forwardToOfficial(id, officialUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicenseInvalidation2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLocationInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicenseInvalidation2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateProceeding1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLocationInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateProceeding1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Taotluse märkused uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivityLocationSuspensionNote(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLocationInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivityLocationSuspensionNote(id, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApi - factory interface
 * @export
 */
export const AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tegevuskoha peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeInvalidationStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: any): AxiosPromise<DetailedActivityLocationInvalidation> {
            return localVarFp.changeInvalidationStatus(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.create-activity-location-invalidation.description}
         * @param {string} id 
         * @param {InvalidationCreation} invalidation 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityLicenseInvalidation(id: string, invalidation: InvalidationCreation, file: Array<any>, options?: any): AxiosPromise<string> {
            return localVarFp.createActivityLicenseInvalidation(id, invalidation, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardToOfficial(id: string, officialUser: OfficialUser, options?: any): AxiosPromise<DetailedActivityLocationInvalidation> {
            return localVarFp.forwardToOfficial(id, officialUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseInvalidation2(id: string, options?: any): AxiosPromise<DetailedActivityLocationInvalidation> {
            return localVarFp.getActivityLicenseInvalidation2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateProceeding1(id: string, options?: any): AxiosPromise<DetailedActivityLocationInvalidation> {
            return localVarFp.initiateProceeding1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Taotluse märkused uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityLocationSuspensionNote(id: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedActivityLocationInvalidation> {
            return localVarFp.updateActivityLocationSuspensionNote(id, applicationNote, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApi - object-oriented interface
 * @export
 * @class AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tegevuskoha peatamise taotluse staatus muudeti.
     * @param {string} id 
     * @param {ActivityLicenseSuspensionStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApi
     */
    public changeInvalidationStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: AxiosRequestConfig) {
        return AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiFp(this.configuration).changeInvalidationStatus(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.create-activity-location-invalidation.description}
     * @param {string} id 
     * @param {InvalidationCreation} invalidation 
     * @param {Array<any>} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApi
     */
    public createActivityLicenseInvalidation(id: string, invalidation: InvalidationCreation, file: Array<any>, options?: AxiosRequestConfig) {
        return AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiFp(this.configuration).createActivityLicenseInvalidation(id, invalidation, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.forward-location-suspension-official.description
     * @param {string} id 
     * @param {OfficialUser} officialUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApi
     */
    public forwardToOfficial(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig) {
        return AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiFp(this.configuration).forwardToOfficial(id, officialUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApi
     */
    public getActivityLicenseInvalidation2(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiFp(this.configuration).getActivityLicenseInvalidation2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.forward-location-suspension-official.description
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApi
     */
    public initiateProceeding1(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiFp(this.configuration).initiateProceeding1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Taotluse märkused uuendati.
     * @param {string} id 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApi
     */
    public updateActivityLocationSuspensionNote(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiFp(this.configuration).updateActivityLocationSuspensionNote(id, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuTegevuskohtadeTaastamiseTeenusApi - axios parameter creator
 * @export
 */
export const AmetnikuTegevuskohtadeTaastamiseTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tegevuskoha taastamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {RestorationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLocationRestorationStatus: async (id: string, status: RestorationStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeLocationRestorationStatus', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeLocationRestorationStatus', 'status', status)
            const localVarPath = `/official/activity-locations/restoration/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevuskoha taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLocationRestoration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLocationRestoration', 'id', id)
            const localVarPath = `/official/activity-locations/restoration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tegevuskoha taastamine.
         * @param {string} id 
         * @param {RestorationCreation} restoration 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateActivityLocationRestoration: async (id: string, restoration: RestorationCreation, file: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('initiateActivityLocationRestoration', 'id', id)
            // verify required parameter 'restoration' is not null or undefined
            assertParamExists('initiateActivityLocationRestoration', 'restoration', restoration)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('initiateActivityLocationRestoration', 'file', file)
            const localVarPath = `/official/activity-locations/restoration/acceptance/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication api-key required


            if (restoration !== undefined) { 
                localVarFormParams.append('restoration', new Blob([JSON.stringify(restoration)], { type: "application/json", }));
            }
                if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuTegevuskohtadeTaastamiseTeenusApi - functional programming interface
 * @export
 */
export const AmetnikuTegevuskohtadeTaastamiseTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuTegevuskohtadeTaastamiseTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tegevuskoha taastamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {RestorationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeLocationRestorationStatus(id: string, status: RestorationStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseRestoration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeLocationRestorationStatus(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevuskoha taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLocationRestoration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseRestoration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLocationRestoration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tegevuskoha taastamine.
         * @param {string} id 
         * @param {RestorationCreation} restoration 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateActivityLocationRestoration(id: string, restoration: RestorationCreation, file: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseRestoration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateActivityLocationRestoration(id, restoration, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuTegevuskohtadeTaastamiseTeenusApi - factory interface
 * @export
 */
export const AmetnikuTegevuskohtadeTaastamiseTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuTegevuskohtadeTaastamiseTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tegevuskoha taastamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {RestorationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLocationRestorationStatus(id: string, status: RestorationStatus, options?: any): AxiosPromise<DetailedActivityLicenseRestoration> {
            return localVarFp.changeLocationRestorationStatus(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevuskoha taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLocationRestoration(id: string, options?: any): AxiosPromise<DetailedActivityLicenseRestoration> {
            return localVarFp.getActivityLocationRestoration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tegevuskoha taastamine.
         * @param {string} id 
         * @param {RestorationCreation} restoration 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateActivityLocationRestoration(id: string, restoration: RestorationCreation, file: Array<any>, options?: any): AxiosPromise<DetailedActivityLicenseRestoration> {
            return localVarFp.initiateActivityLocationRestoration(id, restoration, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuTegevuskohtadeTaastamiseTeenusApi - object-oriented interface
 * @export
 * @class AmetnikuTegevuskohtadeTaastamiseTeenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuTegevuskohtadeTaastamiseTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tegevuskoha taastamise taotluse staatus muudeti.
     * @param {string} id 
     * @param {RestorationStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuskohtadeTaastamiseTeenusApi
     */
    public changeLocationRestorationStatus(id: string, status: RestorationStatus, options?: AxiosRequestConfig) {
        return AmetnikuTegevuskohtadeTaastamiseTeenusApiFp(this.configuration).changeLocationRestorationStatus(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevuskoha taastamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuskohtadeTaastamiseTeenusApi
     */
    public getActivityLocationRestoration(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuskohtadeTaastamiseTeenusApiFp(this.configuration).getActivityLocationRestoration(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tegevuskoha taastamine.
     * @param {string} id 
     * @param {RestorationCreation} restoration 
     * @param {Array<any>} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuskohtadeTaastamiseTeenusApi
     */
    public initiateActivityLocationRestoration(id: string, restoration: RestorationCreation, file: Array<any>, options?: AxiosRequestConfig) {
        return AmetnikuTegevuskohtadeTaastamiseTeenusApiFp(this.configuration).initiateActivityLocationRestoration(id, restoration, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi - axios parameter creator
 * @export
 */
export const AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lisab tegevusloa ülevõtmise taotlusele protokolli.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProtocol: async (applicationId: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('addProtocol', 'applicationId', applicationId)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('addProtocol', 'applicationNote', applicationNote)
            const localVarPath = `/official/license-acquisitions/{applicationId}/add-protocol`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Muudab tegevusloa ülevõtmise taotluse staatust.
         * @param {string} id 
         * @param {ActivityLicenseApplicationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatus: async (id: string, status: ActivityLicenseApplicationStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeStatus', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeStatus', 'status', status)
            const localVarPath = `/official/license-acquisitions/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueProceeding: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('continueProceeding', 'id', id)
            const localVarPath = `/official/license-acquisitions/{id}/continue-proceeding`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Suunab taotluse ametnikule menetlusse.
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardToOfficial2: async (id: string, officialUser: OfficialUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forwardToOfficial2', 'id', id)
            // verify required parameter 'officialUser' is not null or undefined
            assertParamExists('forwardToOfficial2', 'officialUser', officialUser)
            const localVarPath = `/official/license-acquisitions/{id}/forward-official`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officialUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab taotluse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailed: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDetailed', 'id', id)
            const localVarPath = `/official/license-acquisitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Alustab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateProceeding: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('initiateProceeding', 'id', id)
            const localVarPath = `/official/license-acquisitions/start-proceeding/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Märgib taotluse käsitsi makstuks.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsManuallyPaid: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markAsManuallyPaid', 'id', id)
            const localVarPath = `/official/license-acquisitions/{id}/paid-manually`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Peatab taotluse menetluse.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseApplicationProceeding: async (applicationId: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('pauseApplicationProceeding', 'applicationId', applicationId)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('pauseApplicationProceeding', 'applicationNote', applicationNote)
            const localVarPath = `/official/license-acquisitions/{applicationId}/pause`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab avalduse märkmeid.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationNote: async (id: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApplicationNote', 'id', id)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('updateApplicationNote', 'applicationNote', applicationNote)
            const localVarPath = `/official/license-acquisitions/note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi - functional programming interface
 * @export
 */
export const AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lisab tegevusloa ülevõtmise taotlusele protokolli.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProtocol(applicationId: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedLicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProtocol(applicationId, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Muudab tegevusloa ülevõtmise taotluse staatust.
         * @param {string} id 
         * @param {ActivityLicenseApplicationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStatus(id: string, status: ActivityLicenseApplicationStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedLicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStatus(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continueProceeding(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedLicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.continueProceeding(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Suunab taotluse ametnikule menetlusse.
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forwardToOfficial2(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedLicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forwardToOfficial2(id, officialUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab taotluse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailed(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedLicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailed(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Alustab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateProceeding(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedLicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateProceeding(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Märgib taotluse käsitsi makstuks.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAsManuallyPaid(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedLicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAsManuallyPaid(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Peatab taotluse menetluse.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pauseApplicationProceeding(applicationId: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedLicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pauseApplicationProceeding(applicationId, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab avalduse märkmeid.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationNote(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedLicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationNote(id, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi - factory interface
 * @export
 */
export const AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Lisab tegevusloa ülevõtmise taotlusele protokolli.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProtocol(applicationId: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedLicenseAcquisition> {
            return localVarFp.addProtocol(applicationId, applicationNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Muudab tegevusloa ülevõtmise taotluse staatust.
         * @param {string} id 
         * @param {ActivityLicenseApplicationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatus(id: string, status: ActivityLicenseApplicationStatus, options?: any): AxiosPromise<DetailedLicenseAcquisition> {
            return localVarFp.changeStatus(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueProceeding(id: string, options?: any): AxiosPromise<DetailedLicenseAcquisition> {
            return localVarFp.continueProceeding(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Suunab taotluse ametnikule menetlusse.
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardToOfficial2(id: string, officialUser: OfficialUser, options?: any): AxiosPromise<DetailedLicenseAcquisition> {
            return localVarFp.forwardToOfficial2(id, officialUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab taotluse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailed(id: string, options?: any): AxiosPromise<DetailedLicenseAcquisition> {
            return localVarFp.getDetailed(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Alustab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateProceeding(id: string, options?: any): AxiosPromise<DetailedLicenseAcquisition> {
            return localVarFp.initiateProceeding(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Märgib taotluse käsitsi makstuks.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsManuallyPaid(id: string, options?: any): AxiosPromise<DetailedLicenseAcquisition> {
            return localVarFp.markAsManuallyPaid(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Peatab taotluse menetluse.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseApplicationProceeding(applicationId: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedLicenseAcquisition> {
            return localVarFp.pauseApplicationProceeding(applicationId, applicationNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab avalduse märkmeid.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationNote(id: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedLicenseAcquisition> {
            return localVarFp.updateApplicationNote(id, applicationNote, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi - object-oriented interface
 * @export
 * @class AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Lisab tegevusloa ülevõtmise taotlusele protokolli.
     * @param {string} applicationId 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi
     */
    public addProtocol(applicationId: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFp(this.configuration).addProtocol(applicationId, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Muudab tegevusloa ülevõtmise taotluse staatust.
     * @param {string} id 
     * @param {ActivityLicenseApplicationStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi
     */
    public changeStatus(id: string, status: ActivityLicenseApplicationStatus, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFp(this.configuration).changeStatus(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Jätkab taotluse menetlust.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi
     */
    public continueProceeding(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFp(this.configuration).continueProceeding(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Suunab taotluse ametnikule menetlusse.
     * @param {string} id 
     * @param {OfficialUser} officialUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi
     */
    public forwardToOfficial2(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFp(this.configuration).forwardToOfficial2(id, officialUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab taotluse info.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi
     */
    public getDetailed(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFp(this.configuration).getDetailed(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Alustab taotluse menetlust.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi
     */
    public initiateProceeding(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFp(this.configuration).initiateProceeding(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Märgib taotluse käsitsi makstuks.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi
     */
    public markAsManuallyPaid(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFp(this.configuration).markAsManuallyPaid(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Peatab taotluse menetluse.
     * @param {string} applicationId 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi
     */
    public pauseApplicationProceeding(applicationId: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFp(this.configuration).pauseApplicationProceeding(applicationId, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab avalduse märkmeid.
     * @param {string} id 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApi
     */
    public updateApplicationNote(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFp(this.configuration).updateApplicationNote(id, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuTegevuslubadePeatamiseTeenusApi - axios parameter creator
 * @export
 */
export const AmetnikuTegevuslubadePeatamiseTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tegevusloa peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLicenseSuspensionStatus: async (id: string, status: ActivityLicenseSuspensionStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeLicenseSuspensionStatus', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeLicenseSuspensionStatus', 'status', status)
            const localVarPath = `/official/activity-licenses/suspensions/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-license-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardLicenseSuspensionToOfficial: async (id: string, officialUser: OfficialUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forwardLicenseSuspensionToOfficial', 'id', id)
            // verify required parameter 'officialUser' is not null or undefined
            assertParamExists('forwardLicenseSuspensionToOfficial', 'officialUser', officialUser)
            const localVarPath = `/official/activity-licenses/suspensions/{id}/forward-official`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officialUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedActivityLicenseSuspension: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDetailedActivityLicenseSuspension', 'id', id)
            const localVarPath = `/official/activity-licenses/suspension/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.post-official-activity-license-suspension.description}
         * @param {string} id 
         * @param {Suspension} suspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateActivityLicenseSuspension: async (id: string, suspension: Suspension, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('initiateActivityLicenseSuspension', 'id', id)
            // verify required parameter 'suspension' is not null or undefined
            assertParamExists('initiateActivityLicenseSuspension', 'suspension', suspension)
            const localVarPath = `/official/activity-licenses/suspend/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suspension, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tegevusloa peatamise taotluse märkus uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityLicenseSuspensionNote: async (id: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateActivityLicenseSuspensionNote', 'id', id)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('updateActivityLicenseSuspensionNote', 'applicationNote', applicationNote)
            const localVarPath = `/official/activity-licenses/suspensions/note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuTegevuslubadePeatamiseTeenusApi - functional programming interface
 * @export
 */
export const AmetnikuTegevuslubadePeatamiseTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuTegevuslubadePeatamiseTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tegevusloa peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeLicenseSuspensionStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeLicenseSuspensionStatus(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-license-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forwardLicenseSuspensionToOfficial(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forwardLicenseSuspensionToOfficial(id, officialUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedActivityLicenseSuspension(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailedActivityLicenseSuspension(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.post-official-activity-license-suspension.description}
         * @param {string} id 
         * @param {Suspension} suspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateActivityLicenseSuspension(id: string, suspension: Suspension, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Suspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateActivityLicenseSuspension(id, suspension, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tegevusloa peatamise taotluse märkus uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivityLicenseSuspensionNote(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivityLicenseSuspensionNote(id, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuTegevuslubadePeatamiseTeenusApi - factory interface
 * @export
 */
export const AmetnikuTegevuslubadePeatamiseTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuTegevuslubadePeatamiseTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tegevusloa peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLicenseSuspensionStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: any): AxiosPromise<DetailedActivityLicenseSuspension> {
            return localVarFp.changeLicenseSuspensionStatus(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-license-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardLicenseSuspensionToOfficial(id: string, officialUser: OfficialUser, options?: any): AxiosPromise<DetailedActivityLicenseSuspension> {
            return localVarFp.forwardLicenseSuspensionToOfficial(id, officialUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedActivityLicenseSuspension(id: string, options?: any): AxiosPromise<DetailedActivityLicenseSuspension> {
            return localVarFp.getDetailedActivityLicenseSuspension(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.post-official-activity-license-suspension.description}
         * @param {string} id 
         * @param {Suspension} suspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateActivityLicenseSuspension(id: string, suspension: Suspension, options?: any): AxiosPromise<Suspension> {
            return localVarFp.initiateActivityLicenseSuspension(id, suspension, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tegevusloa peatamise taotluse märkus uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityLicenseSuspensionNote(id: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedActivityLicenseSuspension> {
            return localVarFp.updateActivityLicenseSuspensionNote(id, applicationNote, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuTegevuslubadePeatamiseTeenusApi - object-oriented interface
 * @export
 * @class AmetnikuTegevuslubadePeatamiseTeenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuTegevuslubadePeatamiseTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tegevusloa peatamise taotluse staatus muudeti.
     * @param {string} id 
     * @param {ActivityLicenseSuspensionStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadePeatamiseTeenusApi
     */
    public changeLicenseSuspensionStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadePeatamiseTeenusApiFp(this.configuration).changeLicenseSuspensionStatus(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.forward-license-suspension-official.description
     * @param {string} id 
     * @param {OfficialUser} officialUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadePeatamiseTeenusApi
     */
    public forwardLicenseSuspensionToOfficial(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadePeatamiseTeenusApiFp(this.configuration).forwardLicenseSuspensionToOfficial(id, officialUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa peatamise.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadePeatamiseTeenusApi
     */
    public getDetailedActivityLicenseSuspension(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadePeatamiseTeenusApiFp(this.configuration).getDetailedActivityLicenseSuspension(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.post-official-activity-license-suspension.description}
     * @param {string} id 
     * @param {Suspension} suspension 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadePeatamiseTeenusApi
     */
    public initiateActivityLicenseSuspension(id: string, suspension: Suspension, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadePeatamiseTeenusApiFp(this.configuration).initiateActivityLicenseSuspension(id, suspension, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tegevusloa peatamise taotluse märkus uuendati.
     * @param {string} id 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadePeatamiseTeenusApi
     */
    public updateActivityLicenseSuspensionNote(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadePeatamiseTeenusApiFp(this.configuration).updateActivityLicenseSuspensionNote(id, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuTegevuslubadeTaastamiseTeenusApi - axios parameter creator
 * @export
 */
export const AmetnikuTegevuslubadeTaastamiseTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tegevusloa taastamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {RestorationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLicenseRestorationStatus: async (id: string, status: RestorationStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeLicenseRestorationStatus', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeLicenseRestorationStatus', 'status', status)
            const localVarPath = `/official/activity-licenses/restoration/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseRestoration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicenseRestoration', 'id', id)
            const localVarPath = `/official/activity-licenses/restoration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tegevusloa taastamine.
         * @param {string} id 
         * @param {RestorationCreation} restoration 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateActivityLicenseRestoration: async (id: string, restoration: RestorationCreation, file: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('initiateActivityLicenseRestoration', 'id', id)
            // verify required parameter 'restoration' is not null or undefined
            assertParamExists('initiateActivityLicenseRestoration', 'restoration', restoration)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('initiateActivityLicenseRestoration', 'file', file)
            const localVarPath = `/official/activity-licenses/restoration/acceptance/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication api-key required


            if (restoration !== undefined) { 
                localVarFormParams.append('restoration', new Blob([JSON.stringify(restoration)], { type: "application/json", }));
            }
                if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuTegevuslubadeTaastamiseTeenusApi - functional programming interface
 * @export
 */
export const AmetnikuTegevuslubadeTaastamiseTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuTegevuslubadeTaastamiseTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tegevusloa taastamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {RestorationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeLicenseRestorationStatus(id: string, status: RestorationStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseRestoration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeLicenseRestorationStatus(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicenseRestoration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseRestoration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicenseRestoration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tegevusloa taastamine.
         * @param {string} id 
         * @param {RestorationCreation} restoration 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateActivityLicenseRestoration(id: string, restoration: RestorationCreation, file: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseRestoration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateActivityLicenseRestoration(id, restoration, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuTegevuslubadeTaastamiseTeenusApi - factory interface
 * @export
 */
export const AmetnikuTegevuslubadeTaastamiseTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuTegevuslubadeTaastamiseTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tegevusloa taastamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {RestorationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLicenseRestorationStatus(id: string, status: RestorationStatus, options?: any): AxiosPromise<DetailedActivityLicenseRestoration> {
            return localVarFp.changeLicenseRestorationStatus(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseRestoration(id: string, options?: any): AxiosPromise<DetailedActivityLicenseRestoration> {
            return localVarFp.getActivityLicenseRestoration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tegevusloa taastamine.
         * @param {string} id 
         * @param {RestorationCreation} restoration 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateActivityLicenseRestoration(id: string, restoration: RestorationCreation, file: Array<any>, options?: any): AxiosPromise<DetailedActivityLicenseRestoration> {
            return localVarFp.initiateActivityLicenseRestoration(id, restoration, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuTegevuslubadeTaastamiseTeenusApi - object-oriented interface
 * @export
 * @class AmetnikuTegevuslubadeTaastamiseTeenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuTegevuslubadeTaastamiseTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tegevusloa taastamise taotluse staatus muudeti.
     * @param {string} id 
     * @param {RestorationStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaastamiseTeenusApi
     */
    public changeLicenseRestorationStatus(id: string, status: RestorationStatus, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaastamiseTeenusApiFp(this.configuration).changeLicenseRestorationStatus(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa taastamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaastamiseTeenusApi
     */
    public getActivityLicenseRestoration(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaastamiseTeenusApiFp(this.configuration).getActivityLicenseRestoration(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tegevusloa taastamine.
     * @param {string} id 
     * @param {RestorationCreation} restoration 
     * @param {Array<any>} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaastamiseTeenusApi
     */
    public initiateActivityLicenseRestoration(id: string, restoration: RestorationCreation, file: Array<any>, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaastamiseTeenusApiFp(this.configuration).initiateActivityLicenseRestoration(id, restoration, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuTegevuslubadeTaotlusteTeenusApi - axios parameter creator
 * @export
 */
export const AmetnikuTegevuslubadeTaotlusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lisab tegevusõiguse taotlusele protokolli.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProtocol1: async (applicationId: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('addProtocol1', 'applicationId', applicationId)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('addProtocol1', 'applicationNote', applicationNote)
            const localVarPath = `/official/activity-license-applications/{applicationId}/add-protocol`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Taotluse staatus muudetud.
         * @param {string} id 
         * @param {GeneralPractitionerListApplicationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeApplicationStatus1: async (id: string, status: GeneralPractitionerListApplicationStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeApplicationStatus1', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeApplicationStatus1', 'status', status)
            const localVarPath = `/official/activity-licenses/general-practitioners/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.patch-official-activity-license-application-status.description}
         * @param {string} id 
         * @param {ActivityLicenseApplicationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeApplicationStatus2: async (id: string, status: ActivityLicenseApplicationStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeApplicationStatus2', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeApplicationStatus2', 'status', status)
            const localVarPath = `/official/activity-license-applications/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueApplicationProceeding1: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('continueApplicationProceeding1', 'applicationId', applicationId)
            const localVarPath = `/official/activity-license-applications/{applicationId}/continue-proceeding`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Loob uue perearsti nimistu arvu muutmise taotluse.
         * @param {ActivityLicenseGPListApplication} activityLicenseGPListApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralPractitionerListApplication: async (activityLicenseGPListApplication: ActivityLicenseGPListApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityLicenseGPListApplication' is not null or undefined
            assertParamExists('createGeneralPractitionerListApplication', 'activityLicenseGPListApplication', activityLicenseGPListApplication)
            const localVarPath = `/official/activity-licenses/general-practitioners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseGPListApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi ametniku portaalist.
         * @param {string} id 
         * @param {'ACTIVITY_LICENSE' | 'ACTIVITY_LICENSE_SUSPENSION' | 'ACTIVITY_LICENSE_RESTORATION' | 'ACTIVITY_LICENSE_INVALIDATION' | 'ACTIVITY_LOCATION' | 'ACTIVITY_LOCATION_SUSPENSION' | 'ACTIVITY_LOCATION_RESTORATION' | 'ACTIVITY_LOCATION_INVALIDATION' | 'SERVICE' | 'SERVICE_SUSPENSION' | 'SERVICE_RESTORATION' | 'SERVICE_DISCARD' | 'LICENSE_ACQUISITION' | 'GENERAL_PRACTITIONER_APPLICATION'} proceedingType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationByProceedingType: async (id: string, proceedingType: 'ACTIVITY_LICENSE' | 'ACTIVITY_LICENSE_SUSPENSION' | 'ACTIVITY_LICENSE_RESTORATION' | 'ACTIVITY_LICENSE_INVALIDATION' | 'ACTIVITY_LOCATION' | 'ACTIVITY_LOCATION_SUSPENSION' | 'ACTIVITY_LOCATION_RESTORATION' | 'ACTIVITY_LOCATION_INVALIDATION' | 'SERVICE' | 'SERVICE_SUSPENSION' | 'SERVICE_RESTORATION' | 'SERVICE_DISCARD' | 'LICENSE_ACQUISITION' | 'GENERAL_PRACTITIONER_APPLICATION', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApplicationByProceedingType', 'id', id)
            // verify required parameter 'proceedingType' is not null or undefined
            assertParamExists('deleteApplicationByProceedingType', 'proceedingType', proceedingType)
            const localVarPath = `/official/activity-license-applications/{proceedingType}/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"proceedingType"}}`, encodeURIComponent(String(proceedingType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi ametniku portaalist.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationDraft1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApplicationDraft1', 'id', id)
            const localVarPath = `/official/activity-license-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardToOfficial3: async (id: string, officialUser: OfficialUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forwardToOfficial3', 'id', id)
            // verify required parameter 'officialUser' is not null or undefined
            assertParamExists('forwardToOfficial3', 'officialUser', officialUser)
            const localVarPath = `/official/activity-license-applications/{id}/forward-official`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officialUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kasutaja menetlused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseProceedings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/official/activity-license-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab taotluse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedActivityLicenseApplication: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDetailedActivityLicenseApplication', 'id', id)
            const localVarPath = `/official/activity-license-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab perearsti nimistu arvu muutmise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedActivityLicenseGpListApplication: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDetailedActivityLicenseGpListApplication', 'id', id)
            const localVarPath = `/official/activity-licenses/general-practitioners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary THT taotlused
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficialApplicationProceedings: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getOfficialApplicationProceedings', 'requestBody', requestBody)
            const localVarPath = `/official/applications/tht`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Alustab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateProceeding3: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('initiateProceeding3', 'id', id)
            const localVarPath = `/official/activity-license-applications/start-proceeding/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Märgib taotluse käsitsi makstuks.
         * @param {string} activityLicenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsManuallyPaid2: async (activityLicenseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityLicenseId' is not null or undefined
            assertParamExists('markAsManuallyPaid2', 'activityLicenseId', activityLicenseId)
            const localVarPath = `/official/activity-license-applications/{activityLicenseId}/paid-manually`
                .replace(`{${"activityLicenseId"}}`, encodeURIComponent(String(activityLicenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Peatab taotluse menetluse.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseApplicationProceeding2: async (applicationId: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('pauseApplicationProceeding2', 'applicationId', applicationId)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('pauseApplicationProceeding2', 'applicationNote', applicationNote)
            const localVarPath = `/official/activity-license-applications/{applicationId}/pause`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.post-official-activity-license.description}
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveActivityLicense: async (activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('saveActivityLicense', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/official/activity-license-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.put-official-activity-license-without-validations.description}
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveActivityLicenseWithoutValidations: async (id: string, activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveActivityLicenseWithoutValidations', 'id', id)
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('saveActivityLicenseWithoutValidations', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/official/activity-license-applications/backward/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab filtreeritud kasutaja menetlused loendi.
         * @param {ActivityLicenseProceedingSearchFilter} activityLicenseProceedingSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivityLicenseProceedings: async (activityLicenseProceedingSearchFilter: ActivityLicenseProceedingSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityLicenseProceedingSearchFilter' is not null or undefined
            assertParamExists('searchActivityLicenseProceedings', 'activityLicenseProceedingSearchFilter', activityLicenseProceedingSearchFilter)
            const localVarPath = `/official/activity-license-applications/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseProceedingSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.put-official-activity-license.description}
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityLicense: async (id: string, activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateActivityLicense', 'id', id)
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('updateActivityLicense', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/official/activity-license-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab avalduse märkmeid.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationNote2: async (id: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApplicationNote2', 'id', id)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('updateApplicationNote2', 'applicationNote', applicationNote)
            const localVarPath = `/official/activity-licenses/general-practitioners/{id}/note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab avalduse märkmeid.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationNote3: async (id: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApplicationNote3', 'id', id)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('updateApplicationNote3', 'applicationNote', applicationNote)
            const localVarPath = `/official/activity-license-applications/note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuTegevuslubadeTaotlusteTeenusApi - functional programming interface
 * @export
 */
export const AmetnikuTegevuslubadeTaotlusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuTegevuslubadeTaotlusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lisab tegevusõiguse taotlusele protokolli.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProtocol1(applicationId: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProtocol1(applicationId, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Taotluse staatus muudetud.
         * @param {string} id 
         * @param {GeneralPractitionerListApplicationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeApplicationStatus1(id: string, status: GeneralPractitionerListApplicationStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseGPListApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeApplicationStatus1(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.patch-official-activity-license-application-status.description}
         * @param {string} id 
         * @param {ActivityLicenseApplicationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeApplicationStatus2(id: string, status: ActivityLicenseApplicationStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeApplicationStatus2(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continueApplicationProceeding1(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.continueApplicationProceeding1(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Loob uue perearsti nimistu arvu muutmise taotluse.
         * @param {ActivityLicenseGPListApplication} activityLicenseGPListApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGeneralPractitionerListApplication(activityLicenseGPListApplication: ActivityLicenseGPListApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseGPListApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGeneralPractitionerListApplication(activityLicenseGPListApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi ametniku portaalist.
         * @param {string} id 
         * @param {'ACTIVITY_LICENSE' | 'ACTIVITY_LICENSE_SUSPENSION' | 'ACTIVITY_LICENSE_RESTORATION' | 'ACTIVITY_LICENSE_INVALIDATION' | 'ACTIVITY_LOCATION' | 'ACTIVITY_LOCATION_SUSPENSION' | 'ACTIVITY_LOCATION_RESTORATION' | 'ACTIVITY_LOCATION_INVALIDATION' | 'SERVICE' | 'SERVICE_SUSPENSION' | 'SERVICE_RESTORATION' | 'SERVICE_DISCARD' | 'LICENSE_ACQUISITION' | 'GENERAL_PRACTITIONER_APPLICATION'} proceedingType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationByProceedingType(id: string, proceedingType: 'ACTIVITY_LICENSE' | 'ACTIVITY_LICENSE_SUSPENSION' | 'ACTIVITY_LICENSE_RESTORATION' | 'ACTIVITY_LICENSE_INVALIDATION' | 'ACTIVITY_LOCATION' | 'ACTIVITY_LOCATION_SUSPENSION' | 'ACTIVITY_LOCATION_RESTORATION' | 'ACTIVITY_LOCATION_INVALIDATION' | 'SERVICE' | 'SERVICE_SUSPENSION' | 'SERVICE_RESTORATION' | 'SERVICE_DISCARD' | 'LICENSE_ACQUISITION' | 'GENERAL_PRACTITIONER_APPLICATION', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicationByProceedingType(id, proceedingType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi ametniku portaalist.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationDraft1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicationDraft1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forwardToOfficial3(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forwardToOfficial3(id, officialUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kasutaja menetlused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicenseProceedings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityLicenseProceeding>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicenseProceedings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab taotluse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedActivityLicenseApplication(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailedActivityLicenseApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab perearsti nimistu arvu muutmise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedActivityLicenseGpListApplication(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseGPListApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailedActivityLicenseGpListApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary THT taotlused
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfficialApplicationProceedings(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfficialApplication>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfficialApplicationProceedings(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Alustab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateProceeding3(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateProceeding3(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Märgib taotluse käsitsi makstuks.
         * @param {string} activityLicenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAsManuallyPaid2(activityLicenseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAsManuallyPaid2(activityLicenseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Peatab taotluse menetluse.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pauseApplicationProceeding2(applicationId: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pauseApplicationProceeding2(applicationId, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.post-official-activity-license.description}
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveActivityLicense(activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveActivityLicense(activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.put-official-activity-license-without-validations.description}
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveActivityLicenseWithoutValidations(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveActivityLicenseWithoutValidations(id, activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab filtreeritud kasutaja menetlused loendi.
         * @param {ActivityLicenseProceedingSearchFilter} activityLicenseProceedingSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchActivityLicenseProceedings(activityLicenseProceedingSearchFilter: ActivityLicenseProceedingSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseProceedingSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchActivityLicenseProceedings(activityLicenseProceedingSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.put-official-activity-license.description}
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivityLicense(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivityLicense(id, activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab avalduse märkmeid.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationNote2(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseGPListApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationNote2(id, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab avalduse märkmeid.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationNote3(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationNote3(id, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuTegevuslubadeTaotlusteTeenusApi - factory interface
 * @export
 */
export const AmetnikuTegevuslubadeTaotlusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuTegevuslubadeTaotlusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Lisab tegevusõiguse taotlusele protokolli.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProtocol1(applicationId: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedActivityLicenseApplication> {
            return localVarFp.addProtocol1(applicationId, applicationNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Taotluse staatus muudetud.
         * @param {string} id 
         * @param {GeneralPractitionerListApplicationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeApplicationStatus1(id: string, status: GeneralPractitionerListApplicationStatus, options?: any): AxiosPromise<DetailedActivityLicenseGPListApplication> {
            return localVarFp.changeApplicationStatus1(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.patch-official-activity-license-application-status.description}
         * @param {string} id 
         * @param {ActivityLicenseApplicationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeApplicationStatus2(id: string, status: ActivityLicenseApplicationStatus, options?: any): AxiosPromise<DetailedActivityLicenseApplication> {
            return localVarFp.changeApplicationStatus2(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueApplicationProceeding1(applicationId: string, options?: any): AxiosPromise<DetailedActivityLicenseApplication> {
            return localVarFp.continueApplicationProceeding1(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Loob uue perearsti nimistu arvu muutmise taotluse.
         * @param {ActivityLicenseGPListApplication} activityLicenseGPListApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralPractitionerListApplication(activityLicenseGPListApplication: ActivityLicenseGPListApplication, options?: any): AxiosPromise<DetailedActivityLicenseGPListApplication> {
            return localVarFp.createGeneralPractitionerListApplication(activityLicenseGPListApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi ametniku portaalist.
         * @param {string} id 
         * @param {'ACTIVITY_LICENSE' | 'ACTIVITY_LICENSE_SUSPENSION' | 'ACTIVITY_LICENSE_RESTORATION' | 'ACTIVITY_LICENSE_INVALIDATION' | 'ACTIVITY_LOCATION' | 'ACTIVITY_LOCATION_SUSPENSION' | 'ACTIVITY_LOCATION_RESTORATION' | 'ACTIVITY_LOCATION_INVALIDATION' | 'SERVICE' | 'SERVICE_SUSPENSION' | 'SERVICE_RESTORATION' | 'SERVICE_DISCARD' | 'LICENSE_ACQUISITION' | 'GENERAL_PRACTITIONER_APPLICATION'} proceedingType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationByProceedingType(id: string, proceedingType: 'ACTIVITY_LICENSE' | 'ACTIVITY_LICENSE_SUSPENSION' | 'ACTIVITY_LICENSE_RESTORATION' | 'ACTIVITY_LICENSE_INVALIDATION' | 'ACTIVITY_LOCATION' | 'ACTIVITY_LOCATION_SUSPENSION' | 'ACTIVITY_LOCATION_RESTORATION' | 'ACTIVITY_LOCATION_INVALIDATION' | 'SERVICE' | 'SERVICE_SUSPENSION' | 'SERVICE_RESTORATION' | 'SERVICE_DISCARD' | 'LICENSE_ACQUISITION' | 'GENERAL_PRACTITIONER_APPLICATION', options?: any): AxiosPromise<string> {
            return localVarFp.deleteApplicationByProceedingType(id, proceedingType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi ametniku portaalist.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationDraft1(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteApplicationDraft1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardToOfficial3(id: string, officialUser: OfficialUser, options?: any): AxiosPromise<DetailedActivityLicenseApplication> {
            return localVarFp.forwardToOfficial3(id, officialUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kasutaja menetlused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseProceedings(options?: any): AxiosPromise<Array<ActivityLicenseProceeding>> {
            return localVarFp.getActivityLicenseProceedings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab taotluse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedActivityLicenseApplication(id: string, options?: any): AxiosPromise<DetailedActivityLicenseApplication> {
            return localVarFp.getDetailedActivityLicenseApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab perearsti nimistu arvu muutmise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedActivityLicenseGpListApplication(id: string, options?: any): AxiosPromise<DetailedActivityLicenseGPListApplication> {
            return localVarFp.getDetailedActivityLicenseGpListApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary THT taotlused
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficialApplicationProceedings(requestBody: Array<string>, options?: any): AxiosPromise<Array<OfficialApplication>> {
            return localVarFp.getOfficialApplicationProceedings(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Alustab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateProceeding3(id: string, options?: any): AxiosPromise<DetailedActivityLicenseApplication> {
            return localVarFp.initiateProceeding3(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Märgib taotluse käsitsi makstuks.
         * @param {string} activityLicenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsManuallyPaid2(activityLicenseId: string, options?: any): AxiosPromise<DetailedActivityLicenseApplication> {
            return localVarFp.markAsManuallyPaid2(activityLicenseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Peatab taotluse menetluse.
         * @param {string} applicationId 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseApplicationProceeding2(applicationId: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedActivityLicenseApplication> {
            return localVarFp.pauseApplicationProceeding2(applicationId, applicationNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.post-official-activity-license.description}
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveActivityLicense(activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.saveActivityLicense(activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.put-official-activity-license-without-validations.description}
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveActivityLicenseWithoutValidations(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.saveActivityLicenseWithoutValidations(id, activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab filtreeritud kasutaja menetlused loendi.
         * @param {ActivityLicenseProceedingSearchFilter} activityLicenseProceedingSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivityLicenseProceedings(activityLicenseProceedingSearchFilter: ActivityLicenseProceedingSearchFilter, options?: any): AxiosPromise<ActivityLicenseProceedingSearchResponse> {
            return localVarFp.searchActivityLicenseProceedings(activityLicenseProceedingSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.put-official-activity-license.description}
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityLicense(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.updateActivityLicense(id, activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab avalduse märkmeid.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationNote2(id: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedActivityLicenseGPListApplication> {
            return localVarFp.updateApplicationNote2(id, applicationNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab avalduse märkmeid.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationNote3(id: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedActivityLicenseApplication> {
            return localVarFp.updateApplicationNote3(id, applicationNote, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuTegevuslubadeTaotlusteTeenusApi - object-oriented interface
 * @export
 * @class AmetnikuTegevuslubadeTaotlusteTeenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuTegevuslubadeTaotlusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Lisab tegevusõiguse taotlusele protokolli.
     * @param {string} applicationId 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public addProtocol1(applicationId: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).addProtocol1(applicationId, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Taotluse staatus muudetud.
     * @param {string} id 
     * @param {GeneralPractitionerListApplicationStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public changeApplicationStatus1(id: string, status: GeneralPractitionerListApplicationStatus, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).changeApplicationStatus1(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.patch-official-activity-license-application-status.description}
     * @param {string} id 
     * @param {ActivityLicenseApplicationStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public changeApplicationStatus2(id: string, status: ActivityLicenseApplicationStatus, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).changeApplicationStatus2(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Jätkab taotluse menetlust.
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public continueApplicationProceeding1(applicationId: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).continueApplicationProceeding1(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Loob uue perearsti nimistu arvu muutmise taotluse.
     * @param {ActivityLicenseGPListApplication} activityLicenseGPListApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public createGeneralPractitionerListApplication(activityLicenseGPListApplication: ActivityLicenseGPListApplication, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).createGeneralPractitionerListApplication(activityLicenseGPListApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kustutab taotluse mustandi ametniku portaalist.
     * @param {string} id 
     * @param {'ACTIVITY_LICENSE' | 'ACTIVITY_LICENSE_SUSPENSION' | 'ACTIVITY_LICENSE_RESTORATION' | 'ACTIVITY_LICENSE_INVALIDATION' | 'ACTIVITY_LOCATION' | 'ACTIVITY_LOCATION_SUSPENSION' | 'ACTIVITY_LOCATION_RESTORATION' | 'ACTIVITY_LOCATION_INVALIDATION' | 'SERVICE' | 'SERVICE_SUSPENSION' | 'SERVICE_RESTORATION' | 'SERVICE_DISCARD' | 'LICENSE_ACQUISITION' | 'GENERAL_PRACTITIONER_APPLICATION'} proceedingType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public deleteApplicationByProceedingType(id: string, proceedingType: 'ACTIVITY_LICENSE' | 'ACTIVITY_LICENSE_SUSPENSION' | 'ACTIVITY_LICENSE_RESTORATION' | 'ACTIVITY_LICENSE_INVALIDATION' | 'ACTIVITY_LOCATION' | 'ACTIVITY_LOCATION_SUSPENSION' | 'ACTIVITY_LOCATION_RESTORATION' | 'ACTIVITY_LOCATION_INVALIDATION' | 'SERVICE' | 'SERVICE_SUSPENSION' | 'SERVICE_RESTORATION' | 'SERVICE_DISCARD' | 'LICENSE_ACQUISITION' | 'GENERAL_PRACTITIONER_APPLICATION', options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).deleteApplicationByProceedingType(id, proceedingType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kustutab taotluse mustandi ametniku portaalist.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public deleteApplicationDraft1(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).deleteApplicationDraft1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.forward-official.description
     * @param {string} id 
     * @param {OfficialUser} officialUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public forwardToOfficial3(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).forwardToOfficial3(id, officialUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kasutaja menetlused.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public getActivityLicenseProceedings(options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).getActivityLicenseProceedings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab taotluse info.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public getDetailedActivityLicenseApplication(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).getDetailedActivityLicenseApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab perearsti nimistu arvu muutmise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public getDetailedActivityLicenseGpListApplication(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).getDetailedActivityLicenseGpListApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary THT taotlused
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public getOfficialApplicationProceedings(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).getOfficialApplicationProceedings(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Alustab taotluse menetlust.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public initiateProceeding3(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).initiateProceeding3(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Märgib taotluse käsitsi makstuks.
     * @param {string} activityLicenseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public markAsManuallyPaid2(activityLicenseId: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).markAsManuallyPaid2(activityLicenseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Peatab taotluse menetluse.
     * @param {string} applicationId 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public pauseApplicationProceeding2(applicationId: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).pauseApplicationProceeding2(applicationId, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.post-official-activity-license.description}
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public saveActivityLicense(activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).saveActivityLicense(activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.put-official-activity-license-without-validations.description}
     * @param {string} id 
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public saveActivityLicenseWithoutValidations(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).saveActivityLicenseWithoutValidations(id, activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab filtreeritud kasutaja menetlused loendi.
     * @param {ActivityLicenseProceedingSearchFilter} activityLicenseProceedingSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public searchActivityLicenseProceedings(activityLicenseProceedingSearchFilter: ActivityLicenseProceedingSearchFilter, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).searchActivityLicenseProceedings(activityLicenseProceedingSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.put-official-activity-license.description}
     * @param {string} id 
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public updateActivityLicense(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).updateActivityLicense(id, activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab avalduse märkmeid.
     * @param {string} id 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public updateApplicationNote2(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).updateApplicationNote2(id, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab avalduse märkmeid.
     * @param {string} id 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTaotlusteTeenusApi
     */
    public updateApplicationNote3(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTaotlusteTeenusApiFp(this.configuration).updateApplicationNote3(id, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuTegevuslubadeTeenusApi - axios parameter creator
 * @export
 */
export const AmetnikuTegevuslubadeTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Muudab taotluse staatust.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDiscardServiceApplicationStatus: async (id: string, status: ActivityLicenseSuspensionStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeDiscardServiceApplicationStatus', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeDiscardServiceApplicationStatus', 'status', status)
            const localVarPath = `/official/activity-licenses/service/discard/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tegevuskoha peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeInvalidationStatus1: async (id: string, status: ActivityLicenseSuspensionStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeInvalidationStatus1', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeInvalidationStatus1', 'status', status)
            const localVarPath = `/official/activity-licenses/invalidations/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tegevuskoha peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLocationSuspensionStatus: async (id: string, status: ActivityLicenseSuspensionStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeLocationSuspensionStatus', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeLocationSuspensionStatus', 'status', status)
            const localVarPath = `/official/activity-licenses/activity-location/suspension/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Teenuse peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeServiceSuspensionStatus: async (id: string, status: ActivityLicenseSuspensionStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeServiceSuspensionStatus', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeServiceSuspensionStatus', 'status', status)
            const localVarPath = `/official/activity-licenses/service/suspension/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.create-activity-license-invalidation.description}
         * @param {string} id 
         * @param {InvalidationCreation} invalidation 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityLicenseInvalidation1: async (id: string, invalidation: InvalidationCreation, file: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createActivityLicenseInvalidation1', 'id', id)
            // verify required parameter 'invalidation' is not null or undefined
            assertParamExists('createActivityLicenseInvalidation1', 'invalidation', invalidation)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createActivityLicenseInvalidation1', 'file', file)
            const localVarPath = `/official/activity-licenses/invalidations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication api-key required


            if (invalidation !== undefined) { 
                localVarFormParams.append('invalidation', new Blob([JSON.stringify(invalidation)], { type: "application/json", }));
            }
                if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ekspordib filtreeritud Tegevuslubade otsing CSV-failina
         * @param {ActivityLicenseSearchFilter} activityLicenseSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportActivityLicenses: async (activityLicenseSearchFilter: ActivityLicenseSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityLicenseSearchFilter' is not null or undefined
            assertParamExists('exportActivityLicenses', 'activityLicenseSearchFilter', activityLicenseSearchFilter)
            const localVarPath = `/official/activity-licenses/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Suunab taotluse ametnikule menetlusse.
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardDiscardServiceApplicationToOfficial: async (id: string, officialUser: OfficialUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forwardDiscardServiceApplicationToOfficial', 'id', id)
            // verify required parameter 'officialUser' is not null or undefined
            assertParamExists('forwardDiscardServiceApplicationToOfficial', 'officialUser', officialUser)
            const localVarPath = `/official/activity-licenses/service/discard/{id}/forward-official`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officialUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardLocationSuspensionToOfficial: async (id: string, officialUser: OfficialUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forwardLocationSuspensionToOfficial', 'id', id)
            // verify required parameter 'officialUser' is not null or undefined
            assertParamExists('forwardLocationSuspensionToOfficial', 'officialUser', officialUser)
            const localVarPath = `/official/activity-licenses/activity-location/suspension/{id}/forward-official`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officialUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-service-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardServiceSuspensionToOfficial: async (id: string, officialUser: OfficialUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forwardServiceSuspensionToOfficial', 'id', id)
            // verify required parameter 'officialUser' is not null or undefined
            assertParamExists('forwardServiceSuspensionToOfficial', 'officialUser', officialUser)
            const localVarPath = `/official/activity-licenses/service/suspension/{id}/forward-official`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officialUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardToOfficial1: async (id: string, officialUser: OfficialUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forwardToOfficial1', 'id', id)
            // verify required parameter 'officialUser' is not null or undefined
            assertParamExists('forwardToOfficial1', 'officialUser', officialUser)
            const localVarPath = `/official/activity-licenses/invalidations/{id}/forward-official`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officialUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa id järgi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicense2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicense2', 'id', id)
            const localVarPath = `/official/activity-licenses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloaga seotud taotlused.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseApplicationDTOs: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicenseApplicationDTOs', 'id', id)
            const localVarPath = `/official/activity-licenses/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseInvalidation3: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicenseInvalidation3', 'id', id)
            const localVarPath = `/official/activity-licenses/invalidations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa tegevuskoha peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedActivityLicenseActivityLocationSuspension: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDetailedActivityLicenseActivityLocationSuspension', 'id', id)
            const localVarPath = `/official/activity-licenses/activity-location/suspension/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa teenuse peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedActivityLicenseServiceSuspension: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDetailedActivityLicenseServiceSuspension', 'id', id)
            const localVarPath = `/official/activity-licenses/service/suspension/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.submit-discard-activity-license-service-application.description}
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedDiscardServicesApplication: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getDetailedDiscardServicesApplication', 'applicationId', applicationId)
            const localVarPath = `/official/activity-licenses/service/discard/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevuskoha andmed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocation', 'id', id)
            const localVarPath = `/official/activity-licenses/activity-location/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.initiate-application-proceeding.description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateDiscardServiceApplicationProceeding: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('initiateDiscardServiceApplicationProceeding', 'id', id)
            const localVarPath = `/official/activity-licenses/service/discard/{id}/initiate-proceeding`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateProceeding2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('initiateProceeding2', 'id', id)
            const localVarPath = `/official/activity-licenses/invalidations/{id}/initiate-proceeding`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab filtreeritud tegevuslubade loendi.
         * @param {ActivityLicenseSearchFilter} activityLicenseSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivityLicenseList1: async (activityLicenseSearchFilter: ActivityLicenseSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityLicenseSearchFilter' is not null or undefined
            assertParamExists('searchActivityLicenseList1', 'activityLicenseSearchFilter', activityLicenseSearchFilter)
            const localVarPath = `/official/activity-licenses/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.post-official-activity-license-location-suspension.description}
         * @param {string} id 
         * @param {Suspension} suspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendActivityLicenseLocation: async (id: string, suspension: Suspension, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('suspendActivityLicenseLocation', 'id', id)
            // verify required parameter 'suspension' is not null or undefined
            assertParamExists('suspendActivityLicenseLocation', 'suspension', suspension)
            const localVarPath = `/official/activity-licenses/activity-location/suspend/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suspension, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.post-official-activity-license-service-suspension.description}
         * @param {string} id 
         * @param {ServiceSuspension} serviceSuspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendActivityLicenseService: async (id: string, serviceSuspension: ServiceSuspension, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('suspendActivityLicenseService', 'id', id)
            // verify required parameter 'serviceSuspension' is not null or undefined
            assertParamExists('suspendActivityLicenseService', 'serviceSuspension', serviceSuspension)
            const localVarPath = `/official/activity-licenses/service/suspend/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceSuspension, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Taotluse märkused uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityLocationSuspensionNote1: async (id: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateActivityLocationSuspensionNote1', 'id', id)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('updateActivityLocationSuspensionNote1', 'applicationNote', applicationNote)
            const localVarPath = `/official/activity-licenses/invalidations/{id}/note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Taotluse märkused uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityLocationSuspensionNote2: async (id: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateActivityLocationSuspensionNote2', 'id', id)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('updateActivityLocationSuspensionNote2', 'applicationNote', applicationNote)
            const localVarPath = `/official/activity-licenses/activity-location/suspension/note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Taotluse märkused uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDiscardServiceApplicationNote: async (id: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDiscardServiceApplicationNote', 'id', id)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('updateDiscardServiceApplicationNote', 'applicationNote', applicationNote)
            const localVarPath = `/official/activity-licenses/service/discard/note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Teenuse peatamise taotluse märkus uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceSuspensionNote: async (id: string, applicationNote: ApplicationNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateServiceSuspensionNote', 'id', id)
            // verify required parameter 'applicationNote' is not null or undefined
            assertParamExists('updateServiceSuspensionNote', 'applicationNote', applicationNote)
            const localVarPath = `/official/activity-licenses/service/suspension/note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuTegevuslubadeTeenusApi - functional programming interface
 * @export
 */
export const AmetnikuTegevuslubadeTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuTegevuslubadeTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Muudab taotluse staatust.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeDiscardServiceApplicationStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedDiscardServiceApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeDiscardServiceApplicationStatus(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tegevuskoha peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeInvalidationStatus1(id: string, status: ActivityLicenseSuspensionStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeInvalidationStatus1(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tegevuskoha peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeLocationSuspensionStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLocationSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeLocationSuspensionStatus(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Teenuse peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeServiceSuspensionStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedServiceSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeServiceSuspensionStatus(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.create-activity-license-invalidation.description}
         * @param {string} id 
         * @param {InvalidationCreation} invalidation 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActivityLicenseInvalidation1(id: string, invalidation: InvalidationCreation, file: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActivityLicenseInvalidation1(id, invalidation, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ekspordib filtreeritud Tegevuslubade otsing CSV-failina
         * @param {ActivityLicenseSearchFilter} activityLicenseSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportActivityLicenses(activityLicenseSearchFilter: ActivityLicenseSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportActivityLicenses(activityLicenseSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Suunab taotluse ametnikule menetlusse.
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forwardDiscardServiceApplicationToOfficial(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedDiscardServiceApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forwardDiscardServiceApplicationToOfficial(id, officialUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forwardLocationSuspensionToOfficial(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLocationSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forwardLocationSuspensionToOfficial(id, officialUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-service-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forwardServiceSuspensionToOfficial(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedServiceSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forwardServiceSuspensionToOfficial(id, officialUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forwardToOfficial1(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forwardToOfficial1(id, officialUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa id järgi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicense2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicense2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloaga seotud taotlused.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicenseApplicationDTOs(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityLicenseApplicationDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicenseApplicationDTOs(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicenseInvalidation3(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicenseInvalidation3(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa tegevuskoha peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedActivityLicenseActivityLocationSuspension(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLocationSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailedActivityLicenseActivityLocationSuspension(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa teenuse peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedActivityLicenseServiceSuspension(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedServiceSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailedActivityLicenseServiceSuspension(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.submit-discard-activity-license-service-application.description}
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedDiscardServicesApplication(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedDiscardServiceApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailedDiscardServicesApplication(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevuskoha andmed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLocation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.initiate-application-proceeding.description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateDiscardServiceApplicationProceeding(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedDiscardServiceApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateDiscardServiceApplicationProceeding(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateProceeding2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateProceeding2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab filtreeritud tegevuslubade loendi.
         * @param {ActivityLicenseSearchFilter} activityLicenseSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchActivityLicenseList1(activityLicenseSearchFilter: ActivityLicenseSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchActivityLicenseList1(activityLicenseSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.post-official-activity-license-location-suspension.description}
         * @param {string} id 
         * @param {Suspension} suspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suspendActivityLicenseLocation(id: string, suspension: Suspension, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Suspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suspendActivityLicenseLocation(id, suspension, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.post-official-activity-license-service-suspension.description}
         * @param {string} id 
         * @param {ServiceSuspension} serviceSuspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suspendActivityLicenseService(id: string, serviceSuspension: ServiceSuspension, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Suspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suspendActivityLicenseService(id, serviceSuspension, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Taotluse märkused uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivityLocationSuspensionNote1(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivityLocationSuspensionNote1(id, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Taotluse märkused uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivityLocationSuspensionNote2(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLocationSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivityLocationSuspensionNote2(id, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Taotluse märkused uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDiscardServiceApplicationNote(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedDiscardServiceApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDiscardServiceApplicationNote(id, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Teenuse peatamise taotluse märkus uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceSuspensionNote(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedServiceSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceSuspensionNote(id, applicationNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuTegevuslubadeTeenusApi - factory interface
 * @export
 */
export const AmetnikuTegevuslubadeTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuTegevuslubadeTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Muudab taotluse staatust.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDiscardServiceApplicationStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: any): AxiosPromise<DetailedDiscardServiceApplication> {
            return localVarFp.changeDiscardServiceApplicationStatus(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tegevuskoha peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeInvalidationStatus1(id: string, status: ActivityLicenseSuspensionStatus, options?: any): AxiosPromise<DetailedActivityLicenseInvalidation> {
            return localVarFp.changeInvalidationStatus1(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tegevuskoha peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLocationSuspensionStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: any): AxiosPromise<DetailedActivityLocationSuspension> {
            return localVarFp.changeLocationSuspensionStatus(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Teenuse peatamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {ActivityLicenseSuspensionStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeServiceSuspensionStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: any): AxiosPromise<DetailedServiceSuspension> {
            return localVarFp.changeServiceSuspensionStatus(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.create-activity-license-invalidation.description}
         * @param {string} id 
         * @param {InvalidationCreation} invalidation 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityLicenseInvalidation1(id: string, invalidation: InvalidationCreation, file: Array<any>, options?: any): AxiosPromise<string> {
            return localVarFp.createActivityLicenseInvalidation1(id, invalidation, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ekspordib filtreeritud Tegevuslubade otsing CSV-failina
         * @param {ActivityLicenseSearchFilter} activityLicenseSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportActivityLicenses(activityLicenseSearchFilter: ActivityLicenseSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.exportActivityLicenses(activityLicenseSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Suunab taotluse ametnikule menetlusse.
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardDiscardServiceApplicationToOfficial(id: string, officialUser: OfficialUser, options?: any): AxiosPromise<DetailedDiscardServiceApplication> {
            return localVarFp.forwardDiscardServiceApplicationToOfficial(id, officialUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardLocationSuspensionToOfficial(id: string, officialUser: OfficialUser, options?: any): AxiosPromise<DetailedActivityLocationSuspension> {
            return localVarFp.forwardLocationSuspensionToOfficial(id, officialUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-service-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardServiceSuspensionToOfficial(id: string, officialUser: OfficialUser, options?: any): AxiosPromise<DetailedServiceSuspension> {
            return localVarFp.forwardServiceSuspensionToOfficial(id, officialUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {OfficialUser} officialUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardToOfficial1(id: string, officialUser: OfficialUser, options?: any): AxiosPromise<DetailedActivityLicenseInvalidation> {
            return localVarFp.forwardToOfficial1(id, officialUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa id järgi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicense2(id: string, options?: any): AxiosPromise<DetailedActivityLicense> {
            return localVarFp.getActivityLicense2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloaga seotud taotlused.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseApplicationDTOs(id: string, options?: any): AxiosPromise<Array<ActivityLicenseApplicationDTO>> {
            return localVarFp.getActivityLicenseApplicationDTOs(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseInvalidation3(id: string, options?: any): AxiosPromise<DetailedActivityLicenseInvalidation> {
            return localVarFp.getActivityLicenseInvalidation3(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa tegevuskoha peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedActivityLicenseActivityLocationSuspension(id: string, options?: any): AxiosPromise<DetailedActivityLocationSuspension> {
            return localVarFp.getDetailedActivityLicenseActivityLocationSuspension(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa teenuse peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedActivityLicenseServiceSuspension(id: string, options?: any): AxiosPromise<DetailedServiceSuspension> {
            return localVarFp.getDetailedActivityLicenseServiceSuspension(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.submit-discard-activity-license-service-application.description}
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedDiscardServicesApplication(applicationId: string, options?: any): AxiosPromise<DetailedDiscardServiceApplication> {
            return localVarFp.getDetailedDiscardServicesApplication(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevuskoha andmed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation(id: string, options?: any): AxiosPromise<ActivityLocation> {
            return localVarFp.getLocation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.initiate-application-proceeding.description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateDiscardServiceApplicationProceeding(id: string, options?: any): AxiosPromise<DetailedDiscardServiceApplication> {
            return localVarFp.initiateDiscardServiceApplicationProceeding(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.forward-location-suspension-official.description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateProceeding2(id: string, options?: any): AxiosPromise<DetailedActivityLicenseInvalidation> {
            return localVarFp.initiateProceeding2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab filtreeritud tegevuslubade loendi.
         * @param {ActivityLicenseSearchFilter} activityLicenseSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivityLicenseList1(activityLicenseSearchFilter: ActivityLicenseSearchFilter, options?: any): AxiosPromise<ActivityLicenseSearchResponse> {
            return localVarFp.searchActivityLicenseList1(activityLicenseSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.post-official-activity-license-location-suspension.description}
         * @param {string} id 
         * @param {Suspension} suspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendActivityLicenseLocation(id: string, suspension: Suspension, options?: any): AxiosPromise<Suspension> {
            return localVarFp.suspendActivityLicenseLocation(id, suspension, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.post-official-activity-license-service-suspension.description}
         * @param {string} id 
         * @param {ServiceSuspension} serviceSuspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendActivityLicenseService(id: string, serviceSuspension: ServiceSuspension, options?: any): AxiosPromise<Suspension> {
            return localVarFp.suspendActivityLicenseService(id, serviceSuspension, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Taotluse märkused uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityLocationSuspensionNote1(id: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedActivityLicenseInvalidation> {
            return localVarFp.updateActivityLocationSuspensionNote1(id, applicationNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Taotluse märkused uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityLocationSuspensionNote2(id: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedActivityLocationSuspension> {
            return localVarFp.updateActivityLocationSuspensionNote2(id, applicationNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Taotluse märkused uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDiscardServiceApplicationNote(id: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedDiscardServiceApplication> {
            return localVarFp.updateDiscardServiceApplicationNote(id, applicationNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Teenuse peatamise taotluse märkus uuendati.
         * @param {string} id 
         * @param {ApplicationNote} applicationNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceSuspensionNote(id: string, applicationNote: ApplicationNote, options?: any): AxiosPromise<DetailedServiceSuspension> {
            return localVarFp.updateServiceSuspensionNote(id, applicationNote, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuTegevuslubadeTeenusApi - object-oriented interface
 * @export
 * @class AmetnikuTegevuslubadeTeenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuTegevuslubadeTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Muudab taotluse staatust.
     * @param {string} id 
     * @param {ActivityLicenseSuspensionStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public changeDiscardServiceApplicationStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).changeDiscardServiceApplicationStatus(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tegevuskoha peatamise taotluse staatus muudeti.
     * @param {string} id 
     * @param {ActivityLicenseSuspensionStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public changeInvalidationStatus1(id: string, status: ActivityLicenseSuspensionStatus, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).changeInvalidationStatus1(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tegevuskoha peatamise taotluse staatus muudeti.
     * @param {string} id 
     * @param {ActivityLicenseSuspensionStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public changeLocationSuspensionStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).changeLocationSuspensionStatus(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Teenuse peatamise taotluse staatus muudeti.
     * @param {string} id 
     * @param {ActivityLicenseSuspensionStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public changeServiceSuspensionStatus(id: string, status: ActivityLicenseSuspensionStatus, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).changeServiceSuspensionStatus(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.create-activity-license-invalidation.description}
     * @param {string} id 
     * @param {InvalidationCreation} invalidation 
     * @param {Array<any>} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public createActivityLicenseInvalidation1(id: string, invalidation: InvalidationCreation, file: Array<any>, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).createActivityLicenseInvalidation1(id, invalidation, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ekspordib filtreeritud Tegevuslubade otsing CSV-failina
     * @param {ActivityLicenseSearchFilter} activityLicenseSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public exportActivityLicenses(activityLicenseSearchFilter: ActivityLicenseSearchFilter, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).exportActivityLicenses(activityLicenseSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Suunab taotluse ametnikule menetlusse.
     * @param {string} id 
     * @param {OfficialUser} officialUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public forwardDiscardServiceApplicationToOfficial(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).forwardDiscardServiceApplicationToOfficial(id, officialUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.forward-location-suspension-official.description
     * @param {string} id 
     * @param {OfficialUser} officialUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public forwardLocationSuspensionToOfficial(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).forwardLocationSuspensionToOfficial(id, officialUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.forward-service-suspension-official.description
     * @param {string} id 
     * @param {OfficialUser} officialUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public forwardServiceSuspensionToOfficial(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).forwardServiceSuspensionToOfficial(id, officialUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.forward-location-suspension-official.description
     * @param {string} id 
     * @param {OfficialUser} officialUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public forwardToOfficial1(id: string, officialUser: OfficialUser, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).forwardToOfficial1(id, officialUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa id järgi.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public getActivityLicense2(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).getActivityLicense2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloaga seotud taotlused.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public getActivityLicenseApplicationDTOs(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).getActivityLicenseApplicationDTOs(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public getActivityLicenseInvalidation3(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).getActivityLicenseInvalidation3(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa tegevuskoha peatamise.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public getDetailedActivityLicenseActivityLocationSuspension(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).getDetailedActivityLicenseActivityLocationSuspension(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa teenuse peatamise.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public getDetailedActivityLicenseServiceSuspension(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).getDetailedActivityLicenseServiceSuspension(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.submit-discard-activity-license-service-application.description}
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public getDetailedDiscardServicesApplication(applicationId: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).getDetailedDiscardServicesApplication(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevuskoha andmed.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public getLocation(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).getLocation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.initiate-application-proceeding.description
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public initiateDiscardServiceApplicationProceeding(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).initiateDiscardServiceApplicationProceeding(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.forward-location-suspension-official.description
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public initiateProceeding2(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).initiateProceeding2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab filtreeritud tegevuslubade loendi.
     * @param {ActivityLicenseSearchFilter} activityLicenseSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public searchActivityLicenseList1(activityLicenseSearchFilter: ActivityLicenseSearchFilter, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).searchActivityLicenseList1(activityLicenseSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.post-official-activity-license-location-suspension.description}
     * @param {string} id 
     * @param {Suspension} suspension 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public suspendActivityLicenseLocation(id: string, suspension: Suspension, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).suspendActivityLicenseLocation(id, suspension, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.post-official-activity-license-service-suspension.description}
     * @param {string} id 
     * @param {ServiceSuspension} serviceSuspension 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public suspendActivityLicenseService(id: string, serviceSuspension: ServiceSuspension, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).suspendActivityLicenseService(id, serviceSuspension, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Taotluse märkused uuendati.
     * @param {string} id 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public updateActivityLocationSuspensionNote1(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).updateActivityLocationSuspensionNote1(id, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Taotluse märkused uuendati.
     * @param {string} id 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public updateActivityLocationSuspensionNote2(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).updateActivityLocationSuspensionNote2(id, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Taotluse märkused uuendati.
     * @param {string} id 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public updateDiscardServiceApplicationNote(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).updateDiscardServiceApplicationNote(id, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Teenuse peatamise taotluse märkus uuendati.
     * @param {string} id 
     * @param {ApplicationNote} applicationNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusApi
     */
    public updateServiceSuspensionNote(id: string, applicationNote: ApplicationNote, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusApiFp(this.configuration).updateServiceSuspensionNote(id, applicationNote, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApi - axios parameter creator
 * @export
 */
export const AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Salvestab teenuse lisamise taotluse.
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveActivityLicenseServiceApplication: async (activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('saveActivityLicenseServiceApplication', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/official/activity-licenses/service-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Esitab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPortalActivityLicenseServiceApplication: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submitPortalActivityLicenseServiceApplication', 'id', id)
            const localVarPath = `/official/activity-licenses/service-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortalActivityLicenseApplication: async (id: string, activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePortalActivityLicenseApplication', 'id', id)
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('updatePortalActivityLicenseApplication', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/official/activity-licenses/service-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithoutValidation: async (id: string, activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithoutValidation', 'id', id)
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('updateWithoutValidation', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/official/activity-licenses/service-applications/backward/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApi - functional programming interface
 * @export
 */
export const AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Salvestab teenuse lisamise taotluse.
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveActivityLicenseServiceApplication(activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveActivityLicenseServiceApplication(activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Esitab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitPortalActivityLicenseServiceApplication(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitPortalActivityLicenseServiceApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePortalActivityLicenseApplication(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePortalActivityLicenseApplication(id, activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithoutValidation(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithoutValidation(id, activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApi - factory interface
 * @export
 */
export const AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Salvestab teenuse lisamise taotluse.
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveActivityLicenseServiceApplication(activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.saveActivityLicenseServiceApplication(activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Esitab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPortalActivityLicenseServiceApplication(id: string, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.submitPortalActivityLicenseServiceApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortalActivityLicenseApplication(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<void> {
            return localVarFp.updatePortalActivityLicenseApplication(id, activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithoutValidation(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.updateWithoutValidation(id, activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApi - object-oriented interface
 * @export
 * @class AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Salvestab teenuse lisamise taotluse.
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApi
     */
    public saveActivityLicenseServiceApplication(activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApiFp(this.configuration).saveActivityLicenseServiceApplication(activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Esitab teenuse lisamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApi
     */
    public submitPortalActivityLicenseServiceApplication(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApiFp(this.configuration).submitPortalActivityLicenseServiceApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab teenuse lisamise taotluse.
     * @param {string} id 
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApi
     */
    public updatePortalActivityLicenseApplication(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApiFp(this.configuration).updatePortalActivityLicenseApplication(id, activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab teenuse lisamise taotluse.
     * @param {string} id 
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApi
     */
    public updateWithoutValidation(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApiFp(this.configuration).updateWithoutValidation(id, activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuTegevuslubadeTeenusteTeenusApi - axios parameter creator
 * @export
 */
export const AmetnikuTegevuslubadeTeenusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tegevusloa teenuse taastamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {RestorationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLicenseRestorationStatus1: async (id: string, status: RestorationStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeLicenseRestorationStatus1', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeLicenseRestorationStatus1', 'status', status)
            const localVarPath = `/official/activity-license-services/restoration/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa teenuse taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseRestoration1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicenseRestoration1', 'id', id)
            const localVarPath = `/official/activity-license-services/restoration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tegevusloa teenuse taastamine.
         * @param {string} id 
         * @param {RestorationCreation} restoration 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateActivityLicenseRestoration1: async (id: string, restoration: RestorationCreation, file: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('initiateActivityLicenseRestoration1', 'id', id)
            // verify required parameter 'restoration' is not null or undefined
            assertParamExists('initiateActivityLicenseRestoration1', 'restoration', restoration)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('initiateActivityLicenseRestoration1', 'file', file)
            const localVarPath = `/official/activity-license-services/restoration/acceptance/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication api-key required


            if (restoration !== undefined) { 
                localVarFormParams.append('restoration', new Blob([JSON.stringify(restoration)], { type: "application/json", }));
            }
                if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuTegevuslubadeTeenusteTeenusApi - functional programming interface
 * @export
 */
export const AmetnikuTegevuslubadeTeenusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuTegevuslubadeTeenusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tegevusloa teenuse taastamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {RestorationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeLicenseRestorationStatus1(id: string, status: RestorationStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseRestoration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeLicenseRestorationStatus1(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa teenuse taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicenseRestoration1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseRestoration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicenseRestoration1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tegevusloa teenuse taastamine.
         * @param {string} id 
         * @param {RestorationCreation} restoration 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateActivityLicenseRestoration1(id: string, restoration: RestorationCreation, file: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseRestoration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateActivityLicenseRestoration1(id, restoration, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuTegevuslubadeTeenusteTeenusApi - factory interface
 * @export
 */
export const AmetnikuTegevuslubadeTeenusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuTegevuslubadeTeenusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tegevusloa teenuse taastamise taotluse staatus muudeti.
         * @param {string} id 
         * @param {RestorationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLicenseRestorationStatus1(id: string, status: RestorationStatus, options?: any): AxiosPromise<DetailedActivityLicenseRestoration> {
            return localVarFp.changeLicenseRestorationStatus1(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa teenuse taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseRestoration1(id: string, options?: any): AxiosPromise<DetailedActivityLicenseRestoration> {
            return localVarFp.getActivityLicenseRestoration1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tegevusloa teenuse taastamine.
         * @param {string} id 
         * @param {RestorationCreation} restoration 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateActivityLicenseRestoration1(id: string, restoration: RestorationCreation, file: Array<any>, options?: any): AxiosPromise<DetailedActivityLicenseRestoration> {
            return localVarFp.initiateActivityLicenseRestoration1(id, restoration, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuTegevuslubadeTeenusteTeenusApi - object-oriented interface
 * @export
 * @class AmetnikuTegevuslubadeTeenusteTeenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuTegevuslubadeTeenusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tegevusloa teenuse taastamise taotluse staatus muudeti.
     * @param {string} id 
     * @param {RestorationStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusteTeenusApi
     */
    public changeLicenseRestorationStatus1(id: string, status: RestorationStatus, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusteTeenusApiFp(this.configuration).changeLicenseRestorationStatus1(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa teenuse taastamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusteTeenusApi
     */
    public getActivityLicenseRestoration1(id: string, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusteTeenusApiFp(this.configuration).getActivityLicenseRestoration1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tegevusloa teenuse taastamine.
     * @param {string} id 
     * @param {RestorationCreation} restoration 
     * @param {Array<any>} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTeenusteTeenusApi
     */
    public initiateActivityLicenseRestoration1(id: string, restoration: RestorationCreation, file: Array<any>, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTeenusteTeenusApiFp(this.configuration).initiateActivityLicenseRestoration1(id, restoration, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApi - axios parameter creator
 * @export
 */
export const AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Salvestab tegevuskoha lisamise taotluse.
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveActivityLocationApplication: async (activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('saveActivityLocationApplication', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/official/activity-location-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salvestab tegevuskoha taotluse ilma validatsioonideta.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveApplicationWithoutValidations: async (id: string, activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveApplicationWithoutValidations', 'id', id)
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('saveApplicationWithoutValidations', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/official/activity-location-applications/backward/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab tegevuskoha lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityLocationApplication: async (id: string, activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateActivityLocationApplication', 'id', id)
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('updateActivityLocationApplication', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/official/activity-location-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApi - functional programming interface
 * @export
 */
export const AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Salvestab tegevuskoha lisamise taotluse.
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveActivityLocationApplication(activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveActivityLocationApplication(activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Salvestab tegevuskoha taotluse ilma validatsioonideta.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveApplicationWithoutValidations(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveApplicationWithoutValidations(id, activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab tegevuskoha lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivityLocationApplication(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivityLocationApplication(id, activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApi - factory interface
 * @export
 */
export const AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Salvestab tegevuskoha lisamise taotluse.
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveActivityLocationApplication(activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.saveActivityLocationApplication(activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Salvestab tegevuskoha taotluse ilma validatsioonideta.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveApplicationWithoutValidations(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.saveApplicationWithoutValidations(id, activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab tegevuskoha lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityLocationApplication(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.updateActivityLocationApplication(id, activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApi - object-oriented interface
 * @export
 * @class AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApi
 * @extends {BaseAPI}
 */
export class AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Salvestab tegevuskoha lisamise taotluse.
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApi
     */
    public saveActivityLocationApplication(activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApiFp(this.configuration).saveActivityLocationApplication(activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Salvestab tegevuskoha taotluse ilma validatsioonideta.
     * @param {string} id 
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApi
     */
    public saveApplicationWithoutValidations(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApiFp(this.configuration).saveApplicationWithoutValidations(id, activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab tegevuskoha lisamise taotluse.
     * @param {string} id 
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApi
     */
    public updateActivityLocationApplication(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApiFp(this.configuration).updateActivityLocationApplication(id, activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AvaandmeteKirjeldusteTeenusApi - axios parameter creator
 * @export
 */
export const AvaandmeteKirjeldusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Avaandmed kirjeldus
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenDataDescription: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getOpenDataDescription', 'type', type)
            const localVarPath = `/public/open-data/{type}/description`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvaandmeteKirjeldusteTeenusApi - functional programming interface
 * @export
 */
export const AvaandmeteKirjeldusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvaandmeteKirjeldusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Avaandmed kirjeldus
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenDataDescription(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenDataDescription(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AvaandmeteKirjeldusteTeenusApi - factory interface
 * @export
 */
export const AvaandmeteKirjeldusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvaandmeteKirjeldusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Avaandmed kirjeldus
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenDataDescription(type: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getOpenDataDescription(type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvaandmeteKirjeldusteTeenusApi - object-oriented interface
 * @export
 * @class AvaandmeteKirjeldusteTeenusApi
 * @extends {BaseAPI}
 */
export class AvaandmeteKirjeldusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Avaandmed kirjeldus
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvaandmeteKirjeldusteTeenusApi
     */
    public getOpenDataDescription(type: string, options?: AxiosRequestConfig) {
        return AvaandmeteKirjeldusteTeenusApiFp(this.configuration).getOpenDataDescription(type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AvalikAadressideAndmeteTeenusApi - axios parameter creator
 * @export
 */
export const AvalikAadressideAndmeteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab maakondade nimed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCounties: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/addresses/counties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvalikAadressideAndmeteTeenusApi - functional programming interface
 * @export
 */
export const AvalikAadressideAndmeteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvalikAadressideAndmeteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab maakondade nimed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCounties(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<County>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCounties(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AvalikAadressideAndmeteTeenusApi - factory interface
 * @export
 */
export const AvalikAadressideAndmeteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvalikAadressideAndmeteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab maakondade nimed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCounties(options?: any): AxiosPromise<Array<County>> {
            return localVarFp.getCounties(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvalikAadressideAndmeteTeenusApi - object-oriented interface
 * @export
 * @class AvalikAadressideAndmeteTeenusApi
 * @extends {BaseAPI}
 */
export class AvalikAadressideAndmeteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab maakondade nimed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikAadressideAndmeteTeenusApi
     */
    public getCounties(options?: AxiosRequestConfig) {
        return AvalikAadressideAndmeteTeenusApiFp(this.configuration).getCounties(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AvalikPerearstiNimistudTeenusApi - axios parameter creator
 * @export
 */
export const AvalikPerearstiNimistudTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {string} id 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPractitionerList: async (id: string, context?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGeneralPractitionerList', 'id', id)
            const localVarPath = `/public/general-practitioner-lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Avaandmed Nimistud.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPractitionerListsOpenData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/general-practitioner-lists/open-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab filtreeritud perearstide loendi.
         * @param {GeneralPractitionerListSearchFilter} generalPractitionerListSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGeneralPractitionerList: async (generalPractitionerListSearchFilter: GeneralPractitionerListSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generalPractitionerListSearchFilter' is not null or undefined
            assertParamExists('searchGeneralPractitionerList', 'generalPractitionerListSearchFilter', generalPractitionerListSearchFilter)
            const localVarPath = `/public/general-practitioner-lists/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvalikPerearstiNimistudTeenusApi - functional programming interface
 * @export
 */
export const AvalikPerearstiNimistudTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvalikPerearstiNimistudTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {string} id 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralPractitionerList(id: string, context?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralPractitionerList(id, context, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Avaandmed Nimistud.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralPractitionerListsOpenData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralPractitionerListsOpenData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab filtreeritud perearstide loendi.
         * @param {GeneralPractitionerListSearchFilter} generalPractitionerListSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchGeneralPractitionerList(generalPractitionerListSearchFilter: GeneralPractitionerListSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerListSearch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchGeneralPractitionerList(generalPractitionerListSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AvalikPerearstiNimistudTeenusApi - factory interface
 * @export
 */
export const AvalikPerearstiNimistudTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvalikPerearstiNimistudTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {string} id 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPractitionerList(id: string, context?: string, options?: any): AxiosPromise<GeneralPractitionerList> {
            return localVarFp.getGeneralPractitionerList(id, context, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Avaandmed Nimistud.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPractitionerListsOpenData(options?: any): AxiosPromise<any> {
            return localVarFp.getGeneralPractitionerListsOpenData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab filtreeritud perearstide loendi.
         * @param {GeneralPractitionerListSearchFilter} generalPractitionerListSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGeneralPractitionerList(generalPractitionerListSearchFilter: GeneralPractitionerListSearchFilter, options?: any): AxiosPromise<GeneralPractitionerListSearch> {
            return localVarFp.searchGeneralPractitionerList(generalPractitionerListSearchFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvalikPerearstiNimistudTeenusApi - object-oriented interface
 * @export
 * @class AvalikPerearstiNimistudTeenusApi
 * @extends {BaseAPI}
 */
export class AvalikPerearstiNimistudTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Hankige perearstide nimekiri
     * @param {string} id 
     * @param {string} [context] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikPerearstiNimistudTeenusApi
     */
    public getGeneralPractitionerList(id: string, context?: string, options?: AxiosRequestConfig) {
        return AvalikPerearstiNimistudTeenusApiFp(this.configuration).getGeneralPractitionerList(id, context, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Avaandmed Nimistud.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikPerearstiNimistudTeenusApi
     */
    public getGeneralPractitionerListsOpenData(options?: AxiosRequestConfig) {
        return AvalikPerearstiNimistudTeenusApiFp(this.configuration).getGeneralPractitionerListsOpenData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab filtreeritud perearstide loendi.
     * @param {GeneralPractitionerListSearchFilter} generalPractitionerListSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikPerearstiNimistudTeenusApi
     */
    public searchGeneralPractitionerList(generalPractitionerListSearchFilter: GeneralPractitionerListSearchFilter, options?: AxiosRequestConfig) {
        return AvalikPerearstiNimistudTeenusApiFp(this.configuration).searchGeneralPractitionerList(generalPractitionerListSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AvalikTegevuslubadeTeenusApi - axios parameter creator
 * @export
 */
export const AvalikTegevuslubadeTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab tegevusloa PDF faili.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('download', 'id', id)
            const localVarPath = `/public/activity-licenses/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicense: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicense', 'id', id)
            const localVarPath = `/public/activity-licenses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.get-location.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseLocation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicenseLocation', 'id', id)
            const localVarPath = `/public/activity-licenses/location/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa tegevusliigid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAreaClassifiers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/activity-licenses/business-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusliikidega seotud teenused
         * @param {Array<string>} businessAreaIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAreaClassifiersServices: async (businessAreaIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessAreaIds' is not null or undefined
            assertParamExists('getBusinessAreaClassifiersServices', 'businessAreaIds', businessAreaIds)
            const localVarPath = `/public/activity-licenses/business-areas/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (businessAreaIds) {
                localVarQueryParameter['businessAreaIds'] = businessAreaIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevuskoha andmed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getService: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getService', 'id', id)
            const localVarPath = `/public/activity-licenses/service/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab listi teenustest kindla staatuse ja tüübiga/tüüpidega.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/activity-licenses/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Avaandmed Teenused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServicesOpenData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/activity-licenses/services/open-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab filtreeritud tegevuslubade loendi.
         * @param {ActivityLicenseSearchFilter} activityLicenseSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivityLicenseList: async (activityLicenseSearchFilter: ActivityLicenseSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityLicenseSearchFilter' is not null or undefined
            assertParamExists('searchActivityLicenseList', 'activityLicenseSearchFilter', activityLicenseSearchFilter)
            const localVarPath = `/public/activity-licenses/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvalikTegevuslubadeTeenusApi - functional programming interface
 * @export
 */
export const AvalikTegevuslubadeTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvalikTegevuslubadeTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa PDF faili.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicense(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicense(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.get-location.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicenseLocation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLocation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicenseLocation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa tegevusliigid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessAreaClassifiers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseBusinessAreaClassifiers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessAreaClassifiers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusliikidega seotud teenused
         * @param {Array<string>} businessAreaIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessAreaClassifiersServices(businessAreaIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Service>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessAreaClassifiersServices(businessAreaIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevuskoha andmed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getService(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getService(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab listi teenustest kindla staatuse ja tüübiga/tüüpidega.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Service>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Avaandmed Teenused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServicesOpenData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServicesOpenData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab filtreeritud tegevuslubade loendi.
         * @param {ActivityLicenseSearchFilter} activityLicenseSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchActivityLicenseList(activityLicenseSearchFilter: ActivityLicenseSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchActivityLicenseList(activityLicenseSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AvalikTegevuslubadeTeenusApi - factory interface
 * @export
 */
export const AvalikTegevuslubadeTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvalikTegevuslubadeTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa PDF faili.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.download(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicense(id: string, options?: any): AxiosPromise<ActivityLicense> {
            return localVarFp.getActivityLicense(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.get-location.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseLocation(id: string, options?: any): AxiosPromise<ActivityLocation> {
            return localVarFp.getActivityLicenseLocation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa tegevusliigid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAreaClassifiers(options?: any): AxiosPromise<ActivityLicenseBusinessAreaClassifiers> {
            return localVarFp.getBusinessAreaClassifiers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusliikidega seotud teenused
         * @param {Array<string>} businessAreaIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAreaClassifiersServices(businessAreaIds: Array<string>, options?: any): AxiosPromise<Array<Service>> {
            return localVarFp.getBusinessAreaClassifiersServices(businessAreaIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevuskoha andmed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getService(id: string, options?: any): AxiosPromise<ActivityLicenseService> {
            return localVarFp.getService(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab listi teenustest kindla staatuse ja tüübiga/tüüpidega.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServices(options?: any): AxiosPromise<Array<Service>> {
            return localVarFp.getServices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Avaandmed Teenused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServicesOpenData(options?: any): AxiosPromise<any> {
            return localVarFp.getServicesOpenData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab filtreeritud tegevuslubade loendi.
         * @param {ActivityLicenseSearchFilter} activityLicenseSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivityLicenseList(activityLicenseSearchFilter: ActivityLicenseSearchFilter, options?: any): AxiosPromise<ActivityLicenseSearchResponse> {
            return localVarFp.searchActivityLicenseList(activityLicenseSearchFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvalikTegevuslubadeTeenusApi - object-oriented interface
 * @export
 * @class AvalikTegevuslubadeTeenusApi
 * @extends {BaseAPI}
 */
export class AvalikTegevuslubadeTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab tegevusloa PDF faili.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikTegevuslubadeTeenusApi
     */
    public download(id: string, options?: AxiosRequestConfig) {
        return AvalikTegevuslubadeTeenusApiFp(this.configuration).download(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikTegevuslubadeTeenusApi
     */
    public getActivityLicense(id: string, options?: AxiosRequestConfig) {
        return AvalikTegevuslubadeTeenusApiFp(this.configuration).getActivityLicense(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.get-location.description}
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikTegevuslubadeTeenusApi
     */
    public getActivityLicenseLocation(id: string, options?: AxiosRequestConfig) {
        return AvalikTegevuslubadeTeenusApiFp(this.configuration).getActivityLicenseLocation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa tegevusliigid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikTegevuslubadeTeenusApi
     */
    public getBusinessAreaClassifiers(options?: AxiosRequestConfig) {
        return AvalikTegevuslubadeTeenusApiFp(this.configuration).getBusinessAreaClassifiers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusliikidega seotud teenused
     * @param {Array<string>} businessAreaIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikTegevuslubadeTeenusApi
     */
    public getBusinessAreaClassifiersServices(businessAreaIds: Array<string>, options?: AxiosRequestConfig) {
        return AvalikTegevuslubadeTeenusApiFp(this.configuration).getBusinessAreaClassifiersServices(businessAreaIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevuskoha andmed.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikTegevuslubadeTeenusApi
     */
    public getService(id: string, options?: AxiosRequestConfig) {
        return AvalikTegevuslubadeTeenusApiFp(this.configuration).getService(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab listi teenustest kindla staatuse ja tüübiga/tüüpidega.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikTegevuslubadeTeenusApi
     */
    public getServices(options?: AxiosRequestConfig) {
        return AvalikTegevuslubadeTeenusApiFp(this.configuration).getServices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Avaandmed Teenused.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikTegevuslubadeTeenusApi
     */
    public getServicesOpenData(options?: AxiosRequestConfig) {
        return AvalikTegevuslubadeTeenusApiFp(this.configuration).getServicesOpenData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab filtreeritud tegevuslubade loendi.
     * @param {ActivityLicenseSearchFilter} activityLicenseSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikTegevuslubadeTeenusApi
     */
    public searchActivityLicenseList(activityLicenseSearchFilter: ActivityLicenseSearchFilter, options?: AxiosRequestConfig) {
        return AvalikTegevuslubadeTeenusApiFp(this.configuration).searchActivityLicenseList(activityLicenseSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AvalikudIsikudLoetlevadTeenustApi - axios parameter creator
 * @export
 */
export const AvalikudIsikudLoetlevadTeenustApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab kõik erialad.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSpecialities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/persons/specialities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kõik kutsed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOccupations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/persons/occupations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Avaandmed Kutsed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOccupationsOpenData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/persons/occupations/open-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab person objekti.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPerson', 'id', id)
            const localVarPath = `/public/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Avaandmed Apteekrid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacistsOpenData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/persons/pharmacists/open-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Avaandmed Erialad.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialitiesOpenData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/persons/specialities/open-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tervishoiutöötajate otsing.
         * @param {HealthCareProfessionalPublicSearchFilter} healthCareProfessionalPublicSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchHealthcareEmployee: async (healthCareProfessionalPublicSearchFilter: HealthCareProfessionalPublicSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCareProfessionalPublicSearchFilter' is not null or undefined
            assertParamExists('searchHealthcareEmployee', 'healthCareProfessionalPublicSearchFilter', healthCareProfessionalPublicSearchFilter)
            const localVarPath = `/public/persons/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthCareProfessionalPublicSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvalikudIsikudLoetlevadTeenustApi - functional programming interface
 * @export
 */
export const AvalikudIsikudLoetlevadTeenustApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvalikudIsikudLoetlevadTeenustApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab kõik erialad.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSpecialities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Speciality>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSpecialities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kõik kutsed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOccupations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Occupation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOccupations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Avaandmed Kutsed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOccupationsOpenData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOccupationsOpenData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab person objekti.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerson(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Avaandmed Apteekrid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPharmacistsOpenData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPharmacistsOpenData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Avaandmed Erialad.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialitiesOpenData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialitiesOpenData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tervishoiutöötajate otsing.
         * @param {HealthCareProfessionalPublicSearchFilter} healthCareProfessionalPublicSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchHealthcareEmployee(healthCareProfessionalPublicSearchFilter: HealthCareProfessionalPublicSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthCareProfessionalPublicSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchHealthcareEmployee(healthCareProfessionalPublicSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AvalikudIsikudLoetlevadTeenustApi - factory interface
 * @export
 */
export const AvalikudIsikudLoetlevadTeenustApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvalikudIsikudLoetlevadTeenustApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab kõik erialad.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSpecialities(options?: any): AxiosPromise<Array<Speciality>> {
            return localVarFp.getAllSpecialities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kõik kutsed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOccupations(options?: any): AxiosPromise<Array<Occupation>> {
            return localVarFp.getOccupations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Avaandmed Kutsed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOccupationsOpenData(options?: any): AxiosPromise<any> {
            return localVarFp.getOccupationsOpenData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab person objekti.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson(id: string, options?: any): AxiosPromise<PersonPublic> {
            return localVarFp.getPerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Avaandmed Apteekrid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacistsOpenData(options?: any): AxiosPromise<any> {
            return localVarFp.getPharmacistsOpenData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Avaandmed Erialad.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialitiesOpenData(options?: any): AxiosPromise<any> {
            return localVarFp.getSpecialitiesOpenData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tervishoiutöötajate otsing.
         * @param {HealthCareProfessionalPublicSearchFilter} healthCareProfessionalPublicSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchHealthcareEmployee(healthCareProfessionalPublicSearchFilter: HealthCareProfessionalPublicSearchFilter, options?: any): AxiosPromise<HealthCareProfessionalPublicSearchResponse> {
            return localVarFp.searchHealthcareEmployee(healthCareProfessionalPublicSearchFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvalikudIsikudLoetlevadTeenustApi - object-oriented interface
 * @export
 * @class AvalikudIsikudLoetlevadTeenustApi
 * @extends {BaseAPI}
 */
export class AvalikudIsikudLoetlevadTeenustApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab kõik erialad.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikudIsikudLoetlevadTeenustApi
     */
    public getAllSpecialities(options?: AxiosRequestConfig) {
        return AvalikudIsikudLoetlevadTeenustApiFp(this.configuration).getAllSpecialities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kõik kutsed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikudIsikudLoetlevadTeenustApi
     */
    public getOccupations(options?: AxiosRequestConfig) {
        return AvalikudIsikudLoetlevadTeenustApiFp(this.configuration).getOccupations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Avaandmed Kutsed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikudIsikudLoetlevadTeenustApi
     */
    public getOccupationsOpenData(options?: AxiosRequestConfig) {
        return AvalikudIsikudLoetlevadTeenustApiFp(this.configuration).getOccupationsOpenData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab person objekti.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikudIsikudLoetlevadTeenustApi
     */
    public getPerson(id: string, options?: AxiosRequestConfig) {
        return AvalikudIsikudLoetlevadTeenustApiFp(this.configuration).getPerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Avaandmed Apteekrid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikudIsikudLoetlevadTeenustApi
     */
    public getPharmacistsOpenData(options?: AxiosRequestConfig) {
        return AvalikudIsikudLoetlevadTeenustApiFp(this.configuration).getPharmacistsOpenData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Avaandmed Erialad.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikudIsikudLoetlevadTeenustApi
     */
    public getSpecialitiesOpenData(options?: AxiosRequestConfig) {
        return AvalikudIsikudLoetlevadTeenustApiFp(this.configuration).getSpecialitiesOpenData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tervishoiutöötajate otsing.
     * @param {HealthCareProfessionalPublicSearchFilter} healthCareProfessionalPublicSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvalikudIsikudLoetlevadTeenustApi
     */
    public searchHealthcareEmployee(healthCareProfessionalPublicSearchFilter: HealthCareProfessionalPublicSearchFilter, options?: AxiosRequestConfig) {
        return AvalikudIsikudLoetlevadTeenustApiFp(this.configuration).searchHealthcareEmployee(healthCareProfessionalPublicSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigiteenindusplatvormidApi - axios parameter creator
 * @export
 */
export const DigiteenindusplatvormidApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab digiteenindusplatvormide nimekirja.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalPlatforms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/digital-platforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigiteenindusplatvormidApi - functional programming interface
 * @export
 */
export const DigiteenindusplatvormidApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigiteenindusplatvormidApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab digiteenindusplatvormide nimekirja.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalPlatforms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DigitalPlatformListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalPlatforms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigiteenindusplatvormidApi - factory interface
 * @export
 */
export const DigiteenindusplatvormidApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigiteenindusplatvormidApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab digiteenindusplatvormide nimekirja.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalPlatforms(options?: any): AxiosPromise<DigitalPlatformListResponse> {
            return localVarFp.getDigitalPlatforms(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigiteenindusplatvormidApi - object-oriented interface
 * @export
 * @class DigiteenindusplatvormidApi
 * @extends {BaseAPI}
 */
export class DigiteenindusplatvormidApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab digiteenindusplatvormide nimekirja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigiteenindusplatvormidApi
     */
    public getDigitalPlatforms(options?: AxiosRequestConfig) {
        return DigiteenindusplatvormidApiFp(this.configuration).getDigitalPlatforms(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApi - axios parameter creator
 * @export
 */
export const EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab ehis-haridused, mida andmebaasis ei ole
         * @param {string} personCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEhisEducationNotSavedInDatabase: async (personCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personCode' is not null or undefined
            assertParamExists('getEhisEducationNotSavedInDatabase', 'personCode', personCode)
            const localVarPath = `/official/persons/{personCode}/ehis/education`
                .replace(`{${"personCode"}}`, encodeURIComponent(String(personCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApi - functional programming interface
 * @export
 */
export const EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab ehis-haridused, mida andmebaasis ei ole
         * @param {string} personCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEhisEducationNotSavedInDatabase(personCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EducationInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEhisEducationNotSavedInDatabase(personCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApi - factory interface
 * @export
 */
export const EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab ehis-haridused, mida andmebaasis ei ole
         * @param {string} personCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEhisEducationNotSavedInDatabase(personCode: string, options?: any): AxiosPromise<Array<EducationInfo>> {
            return localVarFp.getEhisEducationNotSavedInDatabase(personCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApi - object-oriented interface
 * @export
 * @class EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApi
 * @extends {BaseAPI}
 */
export class EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab ehis-haridused, mida andmebaasis ei ole
     * @param {string} personCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApi
     */
    public getEhisEducationNotSavedInDatabase(personCode: string, options?: AxiosRequestConfig) {
        return EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApiFp(this.configuration).getEhisEducationNotSavedInDatabase(personCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EttevteteAndmetegaSeotudTegevusedApi - axios parameter creator
 * @export
 */
export const EttevteteAndmetegaSeotudTegevusedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lisa uue ettev�tte volituse.
         * @param {string} registryCode 
         * @param {PowerOfAttorney} powerOfAttorney 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPowersOfAttorney: async (registryCode: string, powerOfAttorney: PowerOfAttorney, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('addPowersOfAttorney', 'registryCode', registryCode)
            // verify required parameter 'powerOfAttorney' is not null or undefined
            assertParamExists('addPowersOfAttorney', 'powerOfAttorney', powerOfAttorney)
            const localVarPath = `/official/companies/{registryCode}/powers-of-attorney/add`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(powerOfAttorney, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lisa uue ettev�tte volituse.
         * @param {string} registryCode 
         * @param {PowerOfAttorney} powerOfAttorney 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPowersOfAttorney1: async (registryCode: string, powerOfAttorney: PowerOfAttorney, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('addPowersOfAttorney1', 'registryCode', registryCode)
            // verify required parameter 'powerOfAttorney' is not null or undefined
            assertParamExists('addPowersOfAttorney1', 'powerOfAttorney', powerOfAttorney)
            const localVarPath = `/companies/{registryCode}/powers-of-attorney/add`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(powerOfAttorney, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deaktiveerige ettev�tte autoriseerimine.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePowerOfAttorneyById: async (registryCode: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('deactivatePowerOfAttorneyById', 'registryCode', registryCode)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivatePowerOfAttorneyById', 'id', id)
            const localVarPath = `/official/companies/{registryCode}/powers-of-attorney/deactivate/{id}`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deaktiveerige ettev�tte autoriseerimine.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePowerOfAttorneyById1: async (registryCode: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('deactivatePowerOfAttorneyById1', 'registryCode', registryCode)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivatePowerOfAttorneyById1', 'id', id)
            const localVarPath = `/companies/{registryCode}/powers-of-attorney/deactivate/{id}`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary kustutage ettev�tte volitus.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePowerOfAttorneyById: async (registryCode: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('deletePowerOfAttorneyById', 'registryCode', registryCode)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePowerOfAttorneyById', 'id', id)
            const localVarPath = `/official/companies/{registryCode}/powers-of-attorney/delete/{id}`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary kustutage ettev�tte volitus.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePowerOfAttorneyById1: async (registryCode: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('deletePowerOfAttorneyById1', 'registryCode', registryCode)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePowerOfAttorneyById1', 'id', id)
            const localVarPath = `/companies/{registryCode}/powers-of-attorney/delete/{id}`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ekspordib CSV failina filtreeritud ettevõtete nimekirja.
         * @param {CompanySearchFilter} companySearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCompanies: async (companySearchFilter: CompanySearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySearchFilter' is not null or undefined
            assertParamExists('exportCompanies', 'companySearchFilter', companySearchFilter)
            const localVarPath = `/official/companies/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companySearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ekspordib CSV failina filtreeritud ettevõtete nimekirja.
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCompanies1: async (registryCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('exportCompanies1', 'registryCode', registryCode)
            const localVarPath = `/companies/{registryCode}/powers-of-attorney/export`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab filtreeritud ettevõtete nimekirja.
         * @param {CompanySearchFilter} companySearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterCompanies: async (companySearchFilter: CompanySearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySearchFilter' is not null or undefined
            assertParamExists('filterCompanies', 'companySearchFilter', companySearchFilter)
            const localVarPath = `/official/companies/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companySearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Avaandmed Asutused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesOpenData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/companies/open-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab ettevõtte detailse info
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompany', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab ettevõtte detailse info
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyByRegistryCode: async (registryCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('getCompanyByRegistryCode', 'registryCode', registryCode)
            const localVarPath = `/companies/registry-codes/{registryCode}`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab ettevõtte detailse info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saada k�ik ettev�tte volitused.
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowersOfAttorneyByRegistryCode: async (registryCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('getPowersOfAttorneyByRegistryCode', 'registryCode', registryCode)
            const localVarPath = `/companies/{registryCode}/powers-of-attorney/getAll`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary filtreeri ettev�tte volitused.
         * @param {string} registryCode 
         * @param {PowersOfAttorneySearchFilter} powersOfAttorneySearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPowersOfAttorney: async (registryCode: string, powersOfAttorneySearchFilter: PowersOfAttorneySearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('searchPowersOfAttorney', 'registryCode', registryCode)
            // verify required parameter 'powersOfAttorneySearchFilter' is not null or undefined
            assertParamExists('searchPowersOfAttorney', 'powersOfAttorneySearchFilter', powersOfAttorneySearchFilter)
            const localVarPath = `/companies/{registryCode}/powers-of-attorney/filter`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(powersOfAttorneySearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.update-company-powers-of-attorney.description}
         * @param {string} registryCode 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePowersOfAttorney: async (registryCode: string, file: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('updatePowersOfAttorney', 'registryCode', registryCode)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('updatePowersOfAttorney', 'file', file)
            const localVarPath = `/companies/{registryCode}/powers-of-attorney`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (file) {
                localVarQueryParameter['file'] = file;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary muuda ettev�tte volituse.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {PowerOfAttorney} powerOfAttorney 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePowersOfAttorneyById: async (registryCode: string, id: string, powerOfAttorney: PowerOfAttorney, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('updatePowersOfAttorneyById', 'registryCode', registryCode)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePowersOfAttorneyById', 'id', id)
            // verify required parameter 'powerOfAttorney' is not null or undefined
            assertParamExists('updatePowersOfAttorneyById', 'powerOfAttorney', powerOfAttorney)
            const localVarPath = `/official/companies/{registryCode}/powers-of-attorney/{id}`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(powerOfAttorney, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary muuda ettev�tte volituse.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {PowerOfAttorney} powerOfAttorney 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePowersOfAttorneyById1: async (registryCode: string, id: string, powerOfAttorney: PowerOfAttorney, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('updatePowersOfAttorneyById1', 'registryCode', registryCode)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePowersOfAttorneyById1', 'id', id)
            // verify required parameter 'powerOfAttorney' is not null or undefined
            assertParamExists('updatePowersOfAttorneyById1', 'powerOfAttorney', powerOfAttorney)
            const localVarPath = `/companies/{registryCode}/powers-of-attorney/{id}`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(powerOfAttorney, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab esindaja kontaktandmed.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {Representative} representative 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRepresentativeContactInfo: async (registryCode: string, id: string, representative: Representative, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('updateRepresentativeContactInfo', 'registryCode', registryCode)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRepresentativeContactInfo', 'id', id)
            // verify required parameter 'representative' is not null or undefined
            assertParamExists('updateRepresentativeContactInfo', 'representative', representative)
            const localVarPath = `/companies/{registryCode}/representative/{id}`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(representative, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EttevteteAndmetegaSeotudTegevusedApi - functional programming interface
 * @export
 */
export const EttevteteAndmetegaSeotudTegevusedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EttevteteAndmetegaSeotudTegevusedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lisa uue ettev�tte volituse.
         * @param {string} registryCode 
         * @param {PowerOfAttorney} powerOfAttorney 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPowersOfAttorney(registryCode: string, powerOfAttorney: PowerOfAttorney, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PowerOfAttorney>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPowersOfAttorney(registryCode, powerOfAttorney, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lisa uue ettev�tte volituse.
         * @param {string} registryCode 
         * @param {PowerOfAttorney} powerOfAttorney 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPowersOfAttorney1(registryCode: string, powerOfAttorney: PowerOfAttorney, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PowerOfAttorney>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPowersOfAttorney1(registryCode, powerOfAttorney, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deaktiveerige ettev�tte autoriseerimine.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivatePowerOfAttorneyById(registryCode: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivatePowerOfAttorneyById(registryCode, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deaktiveerige ettev�tte autoriseerimine.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivatePowerOfAttorneyById1(registryCode: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivatePowerOfAttorneyById1(registryCode, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary kustutage ettev�tte volitus.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePowerOfAttorneyById(registryCode: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePowerOfAttorneyById(registryCode, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary kustutage ettev�tte volitus.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePowerOfAttorneyById1(registryCode: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePowerOfAttorneyById1(registryCode, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ekspordib CSV failina filtreeritud ettevõtete nimekirja.
         * @param {CompanySearchFilter} companySearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCompanies(companySearchFilter: CompanySearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCompanies(companySearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ekspordib CSV failina filtreeritud ettevõtete nimekirja.
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCompanies1(registryCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCompanies1(registryCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab filtreeritud ettevõtete nimekirja.
         * @param {CompanySearchFilter} companySearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterCompanies(companySearchFilter: CompanySearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterCompanies(companySearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Avaandmed Asutused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesOpenData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesOpenData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab ettevõtte detailse info
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab ettevõtte detailse info
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyByRegistryCode(registryCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyByRegistryCode(registryCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab ettevõtte detailse info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary saada k�ik ettev�tte volitused.
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPowersOfAttorneyByRegistryCode(registryCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PowerOfAttorney>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPowersOfAttorneyByRegistryCode(registryCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary filtreeri ettev�tte volitused.
         * @param {string} registryCode 
         * @param {PowersOfAttorneySearchFilter} powersOfAttorneySearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPowersOfAttorney(registryCode: string, powersOfAttorneySearchFilter: PowersOfAttorneySearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PowersOfAttorneySearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPowersOfAttorney(registryCode, powersOfAttorneySearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.update-company-powers-of-attorney.description}
         * @param {string} registryCode 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePowersOfAttorney(registryCode: string, file: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PowerOfAttorney>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePowersOfAttorney(registryCode, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary muuda ettev�tte volituse.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {PowerOfAttorney} powerOfAttorney 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePowersOfAttorneyById(registryCode: string, id: string, powerOfAttorney: PowerOfAttorney, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PowerOfAttorney>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePowersOfAttorneyById(registryCode, id, powerOfAttorney, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary muuda ettev�tte volituse.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {PowerOfAttorney} powerOfAttorney 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePowersOfAttorneyById1(registryCode: string, id: string, powerOfAttorney: PowerOfAttorney, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PowerOfAttorney>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePowersOfAttorneyById1(registryCode, id, powerOfAttorney, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab esindaja kontaktandmed.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {Representative} representative 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRepresentativeContactInfo(registryCode: string, id: string, representative: Representative, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Representative>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRepresentativeContactInfo(registryCode, id, representative, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EttevteteAndmetegaSeotudTegevusedApi - factory interface
 * @export
 */
export const EttevteteAndmetegaSeotudTegevusedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EttevteteAndmetegaSeotudTegevusedApiFp(configuration)
    return {
        /**
         * 
         * @summary lisa uue ettev�tte volituse.
         * @param {string} registryCode 
         * @param {PowerOfAttorney} powerOfAttorney 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPowersOfAttorney(registryCode: string, powerOfAttorney: PowerOfAttorney, options?: any): AxiosPromise<PowerOfAttorney> {
            return localVarFp.addPowersOfAttorney(registryCode, powerOfAttorney, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lisa uue ettev�tte volituse.
         * @param {string} registryCode 
         * @param {PowerOfAttorney} powerOfAttorney 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPowersOfAttorney1(registryCode: string, powerOfAttorney: PowerOfAttorney, options?: any): AxiosPromise<PowerOfAttorney> {
            return localVarFp.addPowersOfAttorney1(registryCode, powerOfAttorney, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deaktiveerige ettev�tte autoriseerimine.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePowerOfAttorneyById(registryCode: string, id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deactivatePowerOfAttorneyById(registryCode, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deaktiveerige ettev�tte autoriseerimine.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePowerOfAttorneyById1(registryCode: string, id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deactivatePowerOfAttorneyById1(registryCode, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary kustutage ettev�tte volitus.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePowerOfAttorneyById(registryCode: string, id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deletePowerOfAttorneyById(registryCode, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary kustutage ettev�tte volitus.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePowerOfAttorneyById1(registryCode: string, id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deletePowerOfAttorneyById1(registryCode, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ekspordib CSV failina filtreeritud ettevõtete nimekirja.
         * @param {CompanySearchFilter} companySearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCompanies(companySearchFilter: CompanySearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.exportCompanies(companySearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ekspordib CSV failina filtreeritud ettevõtete nimekirja.
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCompanies1(registryCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.exportCompanies1(registryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab filtreeritud ettevõtete nimekirja.
         * @param {CompanySearchFilter} companySearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterCompanies(companySearchFilter: CompanySearchFilter, options?: any): AxiosPromise<CompanySearchResponse> {
            return localVarFp.filterCompanies(companySearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Avaandmed Asutused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesOpenData(options?: any): AxiosPromise<any> {
            return localVarFp.getCompaniesOpenData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab ettevõtte detailse info
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(id: string, options?: any): AxiosPromise<DetailedCompany> {
            return localVarFp.getCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab ettevõtte detailse info
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyByRegistryCode(registryCode: string, options?: any): AxiosPromise<DetailedCompany> {
            return localVarFp.getCompanyByRegistryCode(registryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab ettevõtte detailse info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentCompany(options?: any): AxiosPromise<DetailedCompany> {
            return localVarFp.getCurrentCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saada k�ik ettev�tte volitused.
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowersOfAttorneyByRegistryCode(registryCode: string, options?: any): AxiosPromise<Array<PowerOfAttorney>> {
            return localVarFp.getPowersOfAttorneyByRegistryCode(registryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary filtreeri ettev�tte volitused.
         * @param {string} registryCode 
         * @param {PowersOfAttorneySearchFilter} powersOfAttorneySearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPowersOfAttorney(registryCode: string, powersOfAttorneySearchFilter: PowersOfAttorneySearchFilter, options?: any): AxiosPromise<PowersOfAttorneySearchResponse> {
            return localVarFp.searchPowersOfAttorney(registryCode, powersOfAttorneySearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.update-company-powers-of-attorney.description}
         * @param {string} registryCode 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePowersOfAttorney(registryCode: string, file: Array<any>, options?: any): AxiosPromise<Array<PowerOfAttorney>> {
            return localVarFp.updatePowersOfAttorney(registryCode, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary muuda ettev�tte volituse.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {PowerOfAttorney} powerOfAttorney 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePowersOfAttorneyById(registryCode: string, id: string, powerOfAttorney: PowerOfAttorney, options?: any): AxiosPromise<PowerOfAttorney> {
            return localVarFp.updatePowersOfAttorneyById(registryCode, id, powerOfAttorney, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary muuda ettev�tte volituse.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {PowerOfAttorney} powerOfAttorney 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePowersOfAttorneyById1(registryCode: string, id: string, powerOfAttorney: PowerOfAttorney, options?: any): AxiosPromise<PowerOfAttorney> {
            return localVarFp.updatePowersOfAttorneyById1(registryCode, id, powerOfAttorney, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab esindaja kontaktandmed.
         * @param {string} registryCode 
         * @param {string} id 
         * @param {Representative} representative 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRepresentativeContactInfo(registryCode: string, id: string, representative: Representative, options?: any): AxiosPromise<Representative> {
            return localVarFp.updateRepresentativeContactInfo(registryCode, id, representative, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EttevteteAndmetegaSeotudTegevusedApi - object-oriented interface
 * @export
 * @class EttevteteAndmetegaSeotudTegevusedApi
 * @extends {BaseAPI}
 */
export class EttevteteAndmetegaSeotudTegevusedApi extends BaseAPI {
    /**
     * 
     * @summary lisa uue ettev�tte volituse.
     * @param {string} registryCode 
     * @param {PowerOfAttorney} powerOfAttorney 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public addPowersOfAttorney(registryCode: string, powerOfAttorney: PowerOfAttorney, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).addPowersOfAttorney(registryCode, powerOfAttorney, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lisa uue ettev�tte volituse.
     * @param {string} registryCode 
     * @param {PowerOfAttorney} powerOfAttorney 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public addPowersOfAttorney1(registryCode: string, powerOfAttorney: PowerOfAttorney, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).addPowersOfAttorney1(registryCode, powerOfAttorney, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deaktiveerige ettev�tte autoriseerimine.
     * @param {string} registryCode 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public deactivatePowerOfAttorneyById(registryCode: string, id: string, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).deactivatePowerOfAttorneyById(registryCode, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deaktiveerige ettev�tte autoriseerimine.
     * @param {string} registryCode 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public deactivatePowerOfAttorneyById1(registryCode: string, id: string, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).deactivatePowerOfAttorneyById1(registryCode, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary kustutage ettev�tte volitus.
     * @param {string} registryCode 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public deletePowerOfAttorneyById(registryCode: string, id: string, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).deletePowerOfAttorneyById(registryCode, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary kustutage ettev�tte volitus.
     * @param {string} registryCode 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public deletePowerOfAttorneyById1(registryCode: string, id: string, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).deletePowerOfAttorneyById1(registryCode, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ekspordib CSV failina filtreeritud ettevõtete nimekirja.
     * @param {CompanySearchFilter} companySearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public exportCompanies(companySearchFilter: CompanySearchFilter, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).exportCompanies(companySearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ekspordib CSV failina filtreeritud ettevõtete nimekirja.
     * @param {string} registryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public exportCompanies1(registryCode: string, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).exportCompanies1(registryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab filtreeritud ettevõtete nimekirja.
     * @param {CompanySearchFilter} companySearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public filterCompanies(companySearchFilter: CompanySearchFilter, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).filterCompanies(companySearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Avaandmed Asutused.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public getCompaniesOpenData(options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).getCompaniesOpenData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab ettevõtte detailse info
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public getCompany(id: string, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).getCompany(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab ettevõtte detailse info
     * @param {string} registryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public getCompanyByRegistryCode(registryCode: string, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).getCompanyByRegistryCode(registryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab ettevõtte detailse info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public getCurrentCompany(options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).getCurrentCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saada k�ik ettev�tte volitused.
     * @param {string} registryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public getPowersOfAttorneyByRegistryCode(registryCode: string, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).getPowersOfAttorneyByRegistryCode(registryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary filtreeri ettev�tte volitused.
     * @param {string} registryCode 
     * @param {PowersOfAttorneySearchFilter} powersOfAttorneySearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public searchPowersOfAttorney(registryCode: string, powersOfAttorneySearchFilter: PowersOfAttorneySearchFilter, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).searchPowersOfAttorney(registryCode, powersOfAttorneySearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.update-company-powers-of-attorney.description}
     * @param {string} registryCode 
     * @param {Array<any>} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public updatePowersOfAttorney(registryCode: string, file: Array<any>, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).updatePowersOfAttorney(registryCode, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary muuda ettev�tte volituse.
     * @param {string} registryCode 
     * @param {string} id 
     * @param {PowerOfAttorney} powerOfAttorney 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public updatePowersOfAttorneyById(registryCode: string, id: string, powerOfAttorney: PowerOfAttorney, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).updatePowersOfAttorneyById(registryCode, id, powerOfAttorney, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary muuda ettev�tte volituse.
     * @param {string} registryCode 
     * @param {string} id 
     * @param {PowerOfAttorney} powerOfAttorney 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public updatePowersOfAttorneyById1(registryCode: string, id: string, powerOfAttorney: PowerOfAttorney, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).updatePowersOfAttorneyById1(registryCode, id, powerOfAttorney, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab esindaja kontaktandmed.
     * @param {string} registryCode 
     * @param {string} id 
     * @param {Representative} representative 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EttevteteAndmetegaSeotudTegevusedApi
     */
    public updateRepresentativeContactInfo(registryCode: string, id: string, representative: Representative, options?: AxiosRequestConfig) {
        return EttevteteAndmetegaSeotudTegevusedApiFp(this.configuration).updateRepresentativeContactInfo(registryCode, id, representative, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FailideLeslaadimiseTeenusApi - axios parameter creator
 * @export
 */
export const FailideLeslaadimiseTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Kustutab faili.
         * @param {string} fileId 
         * @param {FileObjectType} fileReferencePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (fileId: string, fileReferencePath: FileObjectType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('_delete', 'fileId', fileId)
            // verify required parameter 'fileReferencePath' is not null or undefined
            assertParamExists('_delete', 'fileReferencePath', fileReferencePath)
            const localVarPath = `/files/{fileReferencePath}/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)))
                .replace(`{${"fileReferencePath"}}`, encodeURIComponent(String(fileReferencePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.proceeding.delete-multiple-file}
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultiple: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('deleteMultiple', 'objectId', objectId)
            const localVarPath = `/files/multiple/{objectId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Laeb faili alla.
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download2: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('download2', 'fileId', fileId)
            const localVarPath = `/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Laeb faili ülesse.
         * @param {string} objectId 
         * @param {FileObjectType} fileReferencePath 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (objectId: string, fileReferencePath: FileObjectType, file: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('uploadFile', 'objectId', objectId)
            // verify required parameter 'fileReferencePath' is not null or undefined
            assertParamExists('uploadFile', 'fileReferencePath', fileReferencePath)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFile', 'file', file)
            const localVarPath = `/files/{fileReferencePath}/{objectId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"fileReferencePath"}}`, encodeURIComponent(String(fileReferencePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication api-key required

            if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FailideLeslaadimiseTeenusApi - functional programming interface
 * @export
 */
export const FailideLeslaadimiseTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FailideLeslaadimiseTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Kustutab faili.
         * @param {string} fileId 
         * @param {FileObjectType} fileReferencePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(fileId: string, fileReferencePath: FileObjectType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(fileId, fileReferencePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.proceeding.delete-multiple-file}
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMultiple(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMultiple(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Laeb faili alla.
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download2(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download2(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Laeb faili ülesse.
         * @param {string} objectId 
         * @param {FileObjectType} fileReferencePath 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(objectId: string, fileReferencePath: FileObjectType, file: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileReference>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(objectId, fileReferencePath, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FailideLeslaadimiseTeenusApi - factory interface
 * @export
 */
export const FailideLeslaadimiseTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FailideLeslaadimiseTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Kustutab faili.
         * @param {string} fileId 
         * @param {FileObjectType} fileReferencePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(fileId: string, fileReferencePath: FileObjectType, options?: any): AxiosPromise<void> {
            return localVarFp._delete(fileId, fileReferencePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.proceeding.delete-multiple-file}
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultiple(objectId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.deleteMultiple(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Laeb faili alla.
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download2(fileId: string, options?: any): AxiosPromise<any> {
            return localVarFp.download2(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Laeb faili ülesse.
         * @param {string} objectId 
         * @param {FileObjectType} fileReferencePath 
         * @param {Array<any>} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(objectId: string, fileReferencePath: FileObjectType, file: Array<any>, options?: any): AxiosPromise<Array<FileReference>> {
            return localVarFp.uploadFile(objectId, fileReferencePath, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FailideLeslaadimiseTeenusApi - object-oriented interface
 * @export
 * @class FailideLeslaadimiseTeenusApi
 * @extends {BaseAPI}
 */
export class FailideLeslaadimiseTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Kustutab faili.
     * @param {string} fileId 
     * @param {FileObjectType} fileReferencePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FailideLeslaadimiseTeenusApi
     */
    public _delete(fileId: string, fileReferencePath: FileObjectType, options?: AxiosRequestConfig) {
        return FailideLeslaadimiseTeenusApiFp(this.configuration)._delete(fileId, fileReferencePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.proceeding.delete-multiple-file}
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FailideLeslaadimiseTeenusApi
     */
    public deleteMultiple(objectId: string, options?: AxiosRequestConfig) {
        return FailideLeslaadimiseTeenusApiFp(this.configuration).deleteMultiple(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Laeb faili alla.
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FailideLeslaadimiseTeenusApi
     */
    public download2(fileId: string, options?: AxiosRequestConfig) {
        return FailideLeslaadimiseTeenusApiFp(this.configuration).download2(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Laeb faili ülesse.
     * @param {string} objectId 
     * @param {FileObjectType} fileReferencePath 
     * @param {Array<any>} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FailideLeslaadimiseTeenusApi
     */
    public uploadFile(objectId: string, fileReferencePath: FileObjectType, file: Array<any>, options?: AxiosRequestConfig) {
        return FailideLeslaadimiseTeenusApiFp(this.configuration).uploadFile(objectId, fileReferencePath, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FirmadeAndmeteTeenusApi - axios parameter creator
 * @export
 */
export const FirmadeAndmeteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ${medre.api.messages.get-business-detailed-info.description}
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedBusinessInfo: async (registryCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('getDetailedBusinessInfo', 'registryCode', registryCode)
            const localVarPath = `/businesses/{registryCode}`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kasutaja hetkel esindatava firma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectedCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/businesses/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab listi kasutajaga seotud firmadest.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/businesses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Muudab kasutaja hetkel esindatavat firmat.
         * @param {BusinessInfo} businessInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectCompany: async (businessInfo: BusinessInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessInfo' is not null or undefined
            assertParamExists('selectCompany', 'businessInfo', businessInfo)
            const localVarPath = `/businesses/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Eemaldab kasutaja hetkel esindatava firma info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unSelectCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/businesses/unselect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirmadeAndmeteTeenusApi - functional programming interface
 * @export
 */
export const FirmadeAndmeteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirmadeAndmeteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ${medre.api.messages.get-business-detailed-info.description}
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedBusinessInfo(registryCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessRegistryDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailedBusinessInfo(registryCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kasutaja hetkel esindatava firma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectedCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectedCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab listi kasutajaga seotud firmadest.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Muudab kasutaja hetkel esindatavat firmat.
         * @param {BusinessInfo} businessInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectCompany(businessInfo: BusinessInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectCompany(businessInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Eemaldab kasutaja hetkel esindatava firma info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unSelectCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unSelectCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FirmadeAndmeteTeenusApi - factory interface
 * @export
 */
export const FirmadeAndmeteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirmadeAndmeteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary ${medre.api.messages.get-business-detailed-info.description}
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedBusinessInfo(registryCode: string, options?: any): AxiosPromise<BusinessRegistryDetailResponse> {
            return localVarFp.getDetailedBusinessInfo(registryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kasutaja hetkel esindatava firma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectedCompany(options?: any): AxiosPromise<BusinessInfo> {
            return localVarFp.getSelectedCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab listi kasutajaga seotud firmadest.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCompanies(options?: any): AxiosPromise<Array<BusinessInfo>> {
            return localVarFp.getUserCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Muudab kasutaja hetkel esindatavat firmat.
         * @param {BusinessInfo} businessInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectCompany(businessInfo: BusinessInfo, options?: any): AxiosPromise<BusinessInfo> {
            return localVarFp.selectCompany(businessInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Eemaldab kasutaja hetkel esindatava firma info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unSelectCompany(options?: any): AxiosPromise<void> {
            return localVarFp.unSelectCompany(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FirmadeAndmeteTeenusApi - object-oriented interface
 * @export
 * @class FirmadeAndmeteTeenusApi
 * @extends {BaseAPI}
 */
export class FirmadeAndmeteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary ${medre.api.messages.get-business-detailed-info.description}
     * @param {string} registryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmadeAndmeteTeenusApi
     */
    public getDetailedBusinessInfo(registryCode: string, options?: AxiosRequestConfig) {
        return FirmadeAndmeteTeenusApiFp(this.configuration).getDetailedBusinessInfo(registryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kasutaja hetkel esindatava firma.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmadeAndmeteTeenusApi
     */
    public getSelectedCompany(options?: AxiosRequestConfig) {
        return FirmadeAndmeteTeenusApiFp(this.configuration).getSelectedCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab listi kasutajaga seotud firmadest.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmadeAndmeteTeenusApi
     */
    public getUserCompanies(options?: AxiosRequestConfig) {
        return FirmadeAndmeteTeenusApiFp(this.configuration).getUserCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Muudab kasutaja hetkel esindatavat firmat.
     * @param {BusinessInfo} businessInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmadeAndmeteTeenusApi
     */
    public selectCompany(businessInfo: BusinessInfo, options?: AxiosRequestConfig) {
        return FirmadeAndmeteTeenusApiFp(this.configuration).selectCompany(businessInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Eemaldab kasutaja hetkel esindatava firma info.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmadeAndmeteTeenusApi
     */
    public unSelectCompany(options?: AxiosRequestConfig) {
        return FirmadeAndmeteTeenusApiFp(this.configuration).unSelectCompany(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HariduseSalvestamineIsikuandmeteJaoksApi - axios parameter creator
 * @export
 */
export const HariduseSalvestamineIsikuandmeteJaoksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Hariduse salvestamine andmebaasi
         * @param {string} personId 
         * @param {Education} [education] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEducation: async (personId: string, education?: Education, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('createEducation', 'personId', personId)
            const localVarPath = `/official/persons/{personId}/educations`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(education, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tühja hariduse säilitamine andmebaasis
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBlankEducation: async (personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('saveBlankEducation', 'personId', personId)
            const localVarPath = `/official/persons/{personId}/blank-educations`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hariduse salvestamine andmebaasi
         * @param {string} personId 
         * @param {Education} [education] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEducation: async (personId: string, education?: Education, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('updateEducation', 'personId', personId)
            const localVarPath = `/official/persons/{personId}/educations`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(education, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HariduseSalvestamineIsikuandmeteJaoksApi - functional programming interface
 * @export
 */
export const HariduseSalvestamineIsikuandmeteJaoksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HariduseSalvestamineIsikuandmeteJaoksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Hariduse salvestamine andmebaasi
         * @param {string} personId 
         * @param {Education} [education] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEducation(personId: string, education?: Education, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Education>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEducation(personId, education, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tühja hariduse säilitamine andmebaasis
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveBlankEducation(personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Education>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveBlankEducation(personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Hariduse salvestamine andmebaasi
         * @param {string} personId 
         * @param {Education} [education] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEducation(personId: string, education?: Education, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Education>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEducation(personId, education, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HariduseSalvestamineIsikuandmeteJaoksApi - factory interface
 * @export
 */
export const HariduseSalvestamineIsikuandmeteJaoksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HariduseSalvestamineIsikuandmeteJaoksApiFp(configuration)
    return {
        /**
         * 
         * @summary Hariduse salvestamine andmebaasi
         * @param {string} personId 
         * @param {Education} [education] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEducation(personId: string, education?: Education, options?: any): AxiosPromise<Education> {
            return localVarFp.createEducation(personId, education, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tühja hariduse säilitamine andmebaasis
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBlankEducation(personId: string, options?: any): AxiosPromise<Education> {
            return localVarFp.saveBlankEducation(personId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hariduse salvestamine andmebaasi
         * @param {string} personId 
         * @param {Education} [education] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEducation(personId: string, education?: Education, options?: any): AxiosPromise<Education> {
            return localVarFp.updateEducation(personId, education, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HariduseSalvestamineIsikuandmeteJaoksApi - object-oriented interface
 * @export
 * @class HariduseSalvestamineIsikuandmeteJaoksApi
 * @extends {BaseAPI}
 */
export class HariduseSalvestamineIsikuandmeteJaoksApi extends BaseAPI {
    /**
     * 
     * @summary Hariduse salvestamine andmebaasi
     * @param {string} personId 
     * @param {Education} [education] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HariduseSalvestamineIsikuandmeteJaoksApi
     */
    public createEducation(personId: string, education?: Education, options?: AxiosRequestConfig) {
        return HariduseSalvestamineIsikuandmeteJaoksApiFp(this.configuration).createEducation(personId, education, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tühja hariduse säilitamine andmebaasis
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HariduseSalvestamineIsikuandmeteJaoksApi
     */
    public saveBlankEducation(personId: string, options?: AxiosRequestConfig) {
        return HariduseSalvestamineIsikuandmeteJaoksApiFp(this.configuration).saveBlankEducation(personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hariduse salvestamine andmebaasi
     * @param {string} personId 
     * @param {Education} [education] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HariduseSalvestamineIsikuandmeteJaoksApi
     */
    public updateEducation(personId: string, education?: Education, options?: AxiosRequestConfig) {
        return HariduseSalvestamineIsikuandmeteJaoksApiFp(this.configuration).updateEducation(personId, education, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KasutajaAndmeteTeenusApi - axios parameter creator
 * @export
 */
export const KasutajaAndmeteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab filtreeritud isikud.
         * @param {PersonSearchFilter} personSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterPersons: async (personSearchFilter: PersonSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personSearchFilter' is not null or undefined
            assertParamExists('filterPersons', 'personSearchFilter', personSearchFilter)
            const localVarPath = `/persons/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab THT kutse koodi järgi otsides.
         * @param {string} occupationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCode: async (occupationCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'occupationCode' is not null or undefined
            assertParamExists('getByCode', 'occupationCode', occupationCode)
            const localVarPath = `/persons/code/{occupationCode}`
                .replace(`{${"occupationCode"}}`, encodeURIComponent(String(occupationCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab isikukoodi järgi otsimisel THT.
         * @param {string} personalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByPersonalCode: async (personalCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personalCode' is not null or undefined
            assertParamExists('getByPersonalCode', 'personalCode', personalCode)
            const localVarPath = `/persons/personal-code/{personalCode}`
                .replace(`{${"personalCode"}}`, encodeURIComponent(String(personalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab RR442 päringuga saadud kontaktinfo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactInfoFromRR: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/persons/rr/contact-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab EeIsikuKaart päringuga saadud filtreeritud sisse logitud kasutaja hariduse andmed.
         * @param {string} occupationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEhisStudies: async (occupationCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'occupationCode' is not null or undefined
            assertParamExists('getEhisStudies', 'occupationCode', occupationCode)
            const localVarPath = `/persons/ehis/person/{occupationCode}`
                .replace(`{${"occupationCode"}}`, encodeURIComponent(String(occupationCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab id põhjal person objekti.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPerson1', 'id', id)
            const localVarPath = `/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab RR434 päringuga saadud person objekti.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonFromRR: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/persons/rr/person`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab farmatseudid ja proviisorid kutsekoodi järgi otsides.
         * @param {string} occupationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacistsByCode: async (occupationCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'occupationCode' is not null or undefined
            assertParamExists('getPharmacistsByCode', 'occupationCode', occupationCode)
            const localVarPath = `/persons/pharmacists/code/{occupationCode}`
                .replace(`{${"occupationCode"}}`, encodeURIComponent(String(occupationCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab arstid elukutse koodi järgi.
         * @param {PersonSearchFilter} personSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDoctors: async (personSearchFilter: PersonSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personSearchFilter' is not null or undefined
            assertParamExists('searchDoctors', 'personSearchFilter', personSearchFilter)
            const localVarPath = `/persons/search-doctors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab id põhjal person objekti isikuandmed.
         * @param {PersonalData} personalData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson: async (personalData: PersonalData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personalData' is not null or undefined
            assertParamExists('updatePerson', 'personalData', personalData)
            const localVarPath = `/persons/personal-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab id põhjal person objekti kontaktinfo.
         * @param {ContactInfo} contactInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson1: async (contactInfo: ContactInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactInfo' is not null or undefined
            assertParamExists('updatePerson1', 'contactInfo', contactInfo)
            const localVarPath = `/persons/contact-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab sisseloginud kasutaja staatuse \"Taotleja\" staatuseks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusToApplied: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/persons/status/applicant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KasutajaAndmeteTeenusApi - functional programming interface
 * @export
 */
export const KasutajaAndmeteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KasutajaAndmeteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab filtreeritud isikud.
         * @param {PersonSearchFilter} personSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterPersons(personSearchFilter: PersonSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterPersons(personSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab THT kutse koodi järgi otsides.
         * @param {string} occupationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByCode(occupationCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HealthCareProfessional>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByCode(occupationCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab isikukoodi järgi otsimisel THT.
         * @param {string} personalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByPersonalCode(personalCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HealthCareProfessional>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByPersonalCode(personalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab RR442 päringuga saadud kontaktinfo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactInfoFromRR(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactInfoFromRR(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab EeIsikuKaart päringuga saadud filtreeritud sisse logitud kasutaja hariduse andmed.
         * @param {string} occupationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEhisStudies(occupationCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEhisStudies(occupationCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab id põhjal person objekti.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerson1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerson1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab RR434 päringuga saadud person objekti.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonFromRR(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonFromRR(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab farmatseudid ja proviisorid kutsekoodi järgi otsides.
         * @param {string} occupationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPharmacistsByCode(occupationCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HealthCareProfessional>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPharmacistsByCode(occupationCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab arstid elukutse koodi järgi.
         * @param {PersonSearchFilter} personSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDoctors(personSearchFilter: PersonSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDoctors(personSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab id põhjal person objekti isikuandmed.
         * @param {PersonalData} personalData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePerson(personalData: PersonalData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerson(personalData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab id põhjal person objekti kontaktinfo.
         * @param {ContactInfo} contactInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePerson1(contactInfo: ContactInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerson1(contactInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab sisseloginud kasutaja staatuse \"Taotleja\" staatuseks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStatusToApplied(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStatusToApplied(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KasutajaAndmeteTeenusApi - factory interface
 * @export
 */
export const KasutajaAndmeteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KasutajaAndmeteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab filtreeritud isikud.
         * @param {PersonSearchFilter} personSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterPersons(personSearchFilter: PersonSearchFilter, options?: any): AxiosPromise<PersonSearchResponse> {
            return localVarFp.filterPersons(personSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab THT kutse koodi järgi otsides.
         * @param {string} occupationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCode(occupationCode: string, options?: any): AxiosPromise<Array<HealthCareProfessional>> {
            return localVarFp.getByCode(occupationCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab isikukoodi järgi otsimisel THT.
         * @param {string} personalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByPersonalCode(personalCode: string, options?: any): AxiosPromise<Array<HealthCareProfessional>> {
            return localVarFp.getByPersonalCode(personalCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab RR442 päringuga saadud kontaktinfo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactInfoFromRR(options?: any): AxiosPromise<ContactInfo> {
            return localVarFp.getContactInfoFromRR(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab EeIsikuKaart päringuga saadud filtreeritud sisse logitud kasutaja hariduse andmed.
         * @param {string} occupationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEhisStudies(occupationCode: string, options?: any): AxiosPromise<EducationInfo> {
            return localVarFp.getEhisStudies(occupationCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab id põhjal person objekti.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson1(id: string, options?: any): AxiosPromise<Person> {
            return localVarFp.getPerson1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab RR434 päringuga saadud person objekti.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonFromRR(options?: any): AxiosPromise<Person> {
            return localVarFp.getPersonFromRR(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab farmatseudid ja proviisorid kutsekoodi järgi otsides.
         * @param {string} occupationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacistsByCode(occupationCode: string, options?: any): AxiosPromise<Array<HealthCareProfessional>> {
            return localVarFp.getPharmacistsByCode(occupationCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab arstid elukutse koodi järgi.
         * @param {PersonSearchFilter} personSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDoctors(personSearchFilter: PersonSearchFilter, options?: any): AxiosPromise<PersonSearchResponse> {
            return localVarFp.searchDoctors(personSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab id põhjal person objekti isikuandmed.
         * @param {PersonalData} personalData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson(personalData: PersonalData, options?: any): AxiosPromise<Person> {
            return localVarFp.updatePerson(personalData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab id põhjal person objekti kontaktinfo.
         * @param {ContactInfo} contactInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson1(contactInfo: ContactInfo, options?: any): AxiosPromise<Person> {
            return localVarFp.updatePerson1(contactInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab sisseloginud kasutaja staatuse \"Taotleja\" staatuseks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusToApplied(options?: any): AxiosPromise<Person> {
            return localVarFp.updateStatusToApplied(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KasutajaAndmeteTeenusApi - object-oriented interface
 * @export
 * @class KasutajaAndmeteTeenusApi
 * @extends {BaseAPI}
 */
export class KasutajaAndmeteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab filtreeritud isikud.
     * @param {PersonSearchFilter} personSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusApi
     */
    public filterPersons(personSearchFilter: PersonSearchFilter, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusApiFp(this.configuration).filterPersons(personSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab THT kutse koodi järgi otsides.
     * @param {string} occupationCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusApi
     */
    public getByCode(occupationCode: string, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusApiFp(this.configuration).getByCode(occupationCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab isikukoodi järgi otsimisel THT.
     * @param {string} personalCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusApi
     */
    public getByPersonalCode(personalCode: string, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusApiFp(this.configuration).getByPersonalCode(personalCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab RR442 päringuga saadud kontaktinfo.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusApi
     */
    public getContactInfoFromRR(options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusApiFp(this.configuration).getContactInfoFromRR(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab EeIsikuKaart päringuga saadud filtreeritud sisse logitud kasutaja hariduse andmed.
     * @param {string} occupationCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusApi
     */
    public getEhisStudies(occupationCode: string, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusApiFp(this.configuration).getEhisStudies(occupationCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab id põhjal person objekti.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusApi
     */
    public getPerson1(id: string, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusApiFp(this.configuration).getPerson1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab RR434 päringuga saadud person objekti.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusApi
     */
    public getPersonFromRR(options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusApiFp(this.configuration).getPersonFromRR(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab farmatseudid ja proviisorid kutsekoodi järgi otsides.
     * @param {string} occupationCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusApi
     */
    public getPharmacistsByCode(occupationCode: string, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusApiFp(this.configuration).getPharmacistsByCode(occupationCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab arstid elukutse koodi järgi.
     * @param {PersonSearchFilter} personSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusApi
     */
    public searchDoctors(personSearchFilter: PersonSearchFilter, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusApiFp(this.configuration).searchDoctors(personSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab id põhjal person objekti isikuandmed.
     * @param {PersonalData} personalData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusApi
     */
    public updatePerson(personalData: PersonalData, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusApiFp(this.configuration).updatePerson(personalData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab id põhjal person objekti kontaktinfo.
     * @param {ContactInfo} contactInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusApi
     */
    public updatePerson1(contactInfo: ContactInfo, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusApiFp(this.configuration).updatePerson1(contactInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab sisseloginud kasutaja staatuse \"Taotleja\" staatuseks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusApi
     */
    public updateStatusToApplied(options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusApiFp(this.configuration).updateStatusToApplied(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KasutajaAndmeteTeenusAmetnikeleApi - axios parameter creator
 * @export
 */
export const KasutajaAndmeteTeenusAmetnikeleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lisab THT külge süsteemivälise otsuse.
         * @param {string} personId 
         * @param {ExternalDecision} externalDecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExternalDecision: async (personId: string, externalDecision: ExternalDecision, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('addExternalDecision', 'personId', personId)
            // verify required parameter 'externalDecision' is not null or undefined
            assertParamExists('addExternalDecision', 'externalDecision', externalDecision)
            const localVarPath = `/official/persons/{personId}/external-decision`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(externalDecision, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lisab THT külge süsteemivälise otsuse.
         * @param {string} personId 
         * @param {Speciality} speciality 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPrescriptivePower: async (personId: string, speciality: Speciality, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('addPrescriptivePower', 'personId', personId)
            // verify required parameter 'speciality' is not null or undefined
            assertParamExists('addPrescriptivePower', 'speciality', speciality)
            const localVarPath = `/official/persons/{personId}/prescriptive-power`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(speciality, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lisab THT külge eelmise nime.
         * @param {string} personId 
         * @param {PreviousName} previousName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPreviousName: async (personId: string, previousName: PreviousName, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('addPreviousName', 'personId', personId)
            // verify required parameter 'previousName' is not null or undefined
            assertParamExists('addPreviousName', 'previousName', previousName)
            const localVarPath = `/official/persons/{personId}/previous-name`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(previousName, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lisandub eriõe retseptiõiguse eriala
         * @param {string} personId 
         * @param {Speciality} speciality 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSpecialPrescriptivePower: async (personId: string, speciality: Speciality, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('addSpecialPrescriptivePower', 'personId', personId)
            // verify required parameter 'speciality' is not null or undefined
            assertParamExists('addSpecialPrescriptivePower', 'speciality', speciality)
            const localVarPath = `/official/persons/{personId}/special-prescriptive-power`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(speciality, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Loob Person objekti põhjal uue kasutaja.
         * @param {PersonCreation} personCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson: async (personCreation: PersonCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personCreation' is not null or undefined
            assertParamExists('createPerson', 'personCreation', personCreation)
            const localVarPath = `/official/persons/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ekspordib TTO andmed KRA-le CSV-failina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportKraLicenses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/official/kra/export/tto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ekspordib THT andmed KRA-le CSV-failina.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportKraPersons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/official/kra/export/tht`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ekspordib filtreeritud isikud otsing CSV-failina.
         * @param {PersonSearchFilter} personSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPersons: async (personSearchFilter: PersonSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personSearchFilter' is not null or undefined
            assertParamExists('exportPersons', 'personSearchFilter', personSearchFilter)
            const localVarPath = `/official/persons/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab filtreeritud isikud.
         * @param {PersonSearchFilter} personSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterPersons1: async (personSearchFilter: PersonSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personSearchFilter' is not null or undefined
            assertParamExists('filterPersons1', 'personSearchFilter', personSearchFilter)
            const localVarPath = `/official/persons/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab Eesti isikukoodi põhjal kontaktandmed.
         * @param {string} personIdCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactInfoFromRR1: async (personIdCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personIdCode' is not null or undefined
            assertParamExists('getContactInfoFromRR1', 'personIdCode', personIdCode)
            const localVarPath = `/official/persons/contact-info/{personIdCode}`
                .replace(`{${"personIdCode"}}`, encodeURIComponent(String(personIdCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab EeIsikuKaart päringuga saadud filtreeritud isiku hariduse andmed.
         * @param {string} personId 
         * @param {string} occupationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEhisStudies1: async (personId: string, occupationCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getEhisStudies1', 'personId', personId)
            // verify required parameter 'occupationCode' is not null or undefined
            assertParamExists('getEhisStudies1', 'occupationCode', occupationCode)
            const localVarPath = `/official/persons/{personId}/ehis/{occupationCode}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)))
                .replace(`{${"occupationCode"}}`, encodeURIComponent(String(occupationCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab Eesti isikukoodi põhjal isiku-ja kontaktandmed.
         * @param {string} personalCode 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonInfoFromPersonalCode: async (personalCode: string, countryCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personalCode' is not null or undefined
            assertParamExists('getPersonInfoFromPersonalCode', 'personalCode', personalCode)
            // verify required parameter 'countryCode' is not null or undefined
            assertParamExists('getPersonInfoFromPersonalCode', 'countryCode', countryCode)
            const localVarPath = `/official/persons/{countryCode}/{personalCode}`
                .replace(`{${"personalCode"}}`, encodeURIComponent(String(personalCode)))
                .replace(`{${"countryCode"}}`, encodeURIComponent(String(countryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab Person objekti
         * @param {string} personId 
         * @param {string} residencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markPersonResidencyCompleted: async (personId: string, residencyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('markPersonResidencyCompleted', 'personId', personId)
            // verify required parameter 'residencyId' is not null or undefined
            assertParamExists('markPersonResidencyCompleted', 'residencyId', residencyId)
            const localVarPath = `/official/persons/{personId}/residency/{residencyId}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)))
                .replace(`{${"residencyId"}}`, encodeURIComponent(String(residencyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Peatab taotleja tegevusõiguse.
         * @param {string} id 
         * @param {PersonSuspension} personSuspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendPerson: async (id: string, personSuspension: PersonSuspension, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('suspendPerson', 'id', id)
            // verify required parameter 'personSuspension' is not null or undefined
            assertParamExists('suspendPerson', 'personSuspension', personSuspension)
            const localVarPath = `/official/persons/{id}/suspend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personSuspension, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab id põhjal person objekti isikuandmed.
         * @param {string} id 
         * @param {PersonalData} personalData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson2: async (id: string, personalData: PersonalData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePerson2', 'id', id)
            // verify required parameter 'personalData' is not null or undefined
            assertParamExists('updatePerson2', 'personalData', personalData)
            const localVarPath = `/official/persons/{id}/personal-data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab id põhjal person objekti kontaktinfo.
         * @param {string} id 
         * @param {ContactInfo} contactInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonContactInfo: async (id: string, contactInfo: ContactInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePersonContactInfo', 'id', id)
            // verify required parameter 'contactInfo' is not null or undefined
            assertParamExists('updatePersonContactInfo', 'contactInfo', contactInfo)
            const localVarPath = `/official/persons/{id}/contact-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KasutajaAndmeteTeenusAmetnikeleApi - functional programming interface
 * @export
 */
export const KasutajaAndmeteTeenusAmetnikeleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KasutajaAndmeteTeenusAmetnikeleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lisab THT külge süsteemivälise otsuse.
         * @param {string} personId 
         * @param {ExternalDecision} externalDecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addExternalDecision(personId: string, externalDecision: ExternalDecision, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addExternalDecision(personId, externalDecision, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lisab THT külge süsteemivälise otsuse.
         * @param {string} personId 
         * @param {Speciality} speciality 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPrescriptivePower(personId: string, speciality: Speciality, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPrescriptivePower(personId, speciality, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lisab THT külge eelmise nime.
         * @param {string} personId 
         * @param {PreviousName} previousName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPreviousName(personId: string, previousName: PreviousName, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPreviousName(personId, previousName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lisandub eriõe retseptiõiguse eriala
         * @param {string} personId 
         * @param {Speciality} speciality 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSpecialPrescriptivePower(personId: string, speciality: Speciality, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSpecialPrescriptivePower(personId, speciality, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Loob Person objekti põhjal uue kasutaja.
         * @param {PersonCreation} personCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPerson(personCreation: PersonCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPerson(personCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ekspordib TTO andmed KRA-le CSV-failina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportKraLicenses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportKraLicenses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ekspordib THT andmed KRA-le CSV-failina.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportKraPersons(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportKraPersons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ekspordib filtreeritud isikud otsing CSV-failina.
         * @param {PersonSearchFilter} personSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportPersons(personSearchFilter: PersonSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportPersons(personSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab filtreeritud isikud.
         * @param {PersonSearchFilter} personSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterPersons1(personSearchFilter: PersonSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterPersons1(personSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab Eesti isikukoodi põhjal kontaktandmed.
         * @param {string} personIdCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactInfoFromRR1(personIdCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactInfoFromRR1(personIdCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab EeIsikuKaart päringuga saadud filtreeritud isiku hariduse andmed.
         * @param {string} personId 
         * @param {string} occupationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEhisStudies1(personId: string, occupationCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEhisStudies1(personId, occupationCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab Eesti isikukoodi põhjal isiku-ja kontaktandmed.
         * @param {string} personalCode 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonInfoFromPersonalCode(personalCode: string, countryCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonInfoFromPersonalCode(personalCode, countryCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab Person objekti
         * @param {string} personId 
         * @param {string} residencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markPersonResidencyCompleted(personId: string, residencyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markPersonResidencyCompleted(personId, residencyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Peatab taotleja tegevusõiguse.
         * @param {string} id 
         * @param {PersonSuspension} personSuspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suspendPerson(id: string, personSuspension: PersonSuspension, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suspendPerson(id, personSuspension, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab id põhjal person objekti isikuandmed.
         * @param {string} id 
         * @param {PersonalData} personalData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePerson2(id: string, personalData: PersonalData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerson2(id, personalData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab id põhjal person objekti kontaktinfo.
         * @param {string} id 
         * @param {ContactInfo} contactInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePersonContactInfo(id: string, contactInfo: ContactInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonContactInfo(id, contactInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KasutajaAndmeteTeenusAmetnikeleApi - factory interface
 * @export
 */
export const KasutajaAndmeteTeenusAmetnikeleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KasutajaAndmeteTeenusAmetnikeleApiFp(configuration)
    return {
        /**
         * 
         * @summary Lisab THT külge süsteemivälise otsuse.
         * @param {string} personId 
         * @param {ExternalDecision} externalDecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExternalDecision(personId: string, externalDecision: ExternalDecision, options?: any): AxiosPromise<Person> {
            return localVarFp.addExternalDecision(personId, externalDecision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lisab THT külge süsteemivälise otsuse.
         * @param {string} personId 
         * @param {Speciality} speciality 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPrescriptivePower(personId: string, speciality: Speciality, options?: any): AxiosPromise<Person> {
            return localVarFp.addPrescriptivePower(personId, speciality, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lisab THT külge eelmise nime.
         * @param {string} personId 
         * @param {PreviousName} previousName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPreviousName(personId: string, previousName: PreviousName, options?: any): AxiosPromise<Person> {
            return localVarFp.addPreviousName(personId, previousName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lisandub eriõe retseptiõiguse eriala
         * @param {string} personId 
         * @param {Speciality} speciality 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSpecialPrescriptivePower(personId: string, speciality: Speciality, options?: any): AxiosPromise<Person> {
            return localVarFp.addSpecialPrescriptivePower(personId, speciality, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Loob Person objekti põhjal uue kasutaja.
         * @param {PersonCreation} personCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson(personCreation: PersonCreation, options?: any): AxiosPromise<PersonResponse> {
            return localVarFp.createPerson(personCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ekspordib TTO andmed KRA-le CSV-failina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportKraLicenses(options?: any): AxiosPromise<void> {
            return localVarFp.exportKraLicenses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ekspordib THT andmed KRA-le CSV-failina.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportKraPersons(options?: any): AxiosPromise<void> {
            return localVarFp.exportKraPersons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ekspordib filtreeritud isikud otsing CSV-failina.
         * @param {PersonSearchFilter} personSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPersons(personSearchFilter: PersonSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.exportPersons(personSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab filtreeritud isikud.
         * @param {PersonSearchFilter} personSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterPersons1(personSearchFilter: PersonSearchFilter, options?: any): AxiosPromise<PersonSearchResponse> {
            return localVarFp.filterPersons1(personSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab Eesti isikukoodi põhjal kontaktandmed.
         * @param {string} personIdCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactInfoFromRR1(personIdCode: string, options?: any): AxiosPromise<ContactInfo> {
            return localVarFp.getContactInfoFromRR1(personIdCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab EeIsikuKaart päringuga saadud filtreeritud isiku hariduse andmed.
         * @param {string} personId 
         * @param {string} occupationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEhisStudies1(personId: string, occupationCode: string, options?: any): AxiosPromise<EducationInfo> {
            return localVarFp.getEhisStudies1(personId, occupationCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab Eesti isikukoodi põhjal isiku-ja kontaktandmed.
         * @param {string} personalCode 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonInfoFromPersonalCode(personalCode: string, countryCode: string, options?: any): AxiosPromise<PersonDataResponse> {
            return localVarFp.getPersonInfoFromPersonalCode(personalCode, countryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab Person objekti
         * @param {string} personId 
         * @param {string} residencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markPersonResidencyCompleted(personId: string, residencyId: string, options?: any): AxiosPromise<PersonPublic> {
            return localVarFp.markPersonResidencyCompleted(personId, residencyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Peatab taotleja tegevusõiguse.
         * @param {string} id 
         * @param {PersonSuspension} personSuspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendPerson(id: string, personSuspension: PersonSuspension, options?: any): AxiosPromise<Person> {
            return localVarFp.suspendPerson(id, personSuspension, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab id põhjal person objekti isikuandmed.
         * @param {string} id 
         * @param {PersonalData} personalData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson2(id: string, personalData: PersonalData, options?: any): AxiosPromise<Person> {
            return localVarFp.updatePerson2(id, personalData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab id põhjal person objekti kontaktinfo.
         * @param {string} id 
         * @param {ContactInfo} contactInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonContactInfo(id: string, contactInfo: ContactInfo, options?: any): AxiosPromise<Person> {
            return localVarFp.updatePersonContactInfo(id, contactInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KasutajaAndmeteTeenusAmetnikeleApi - object-oriented interface
 * @export
 * @class KasutajaAndmeteTeenusAmetnikeleApi
 * @extends {BaseAPI}
 */
export class KasutajaAndmeteTeenusAmetnikeleApi extends BaseAPI {
    /**
     * 
     * @summary Lisab THT külge süsteemivälise otsuse.
     * @param {string} personId 
     * @param {ExternalDecision} externalDecision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public addExternalDecision(personId: string, externalDecision: ExternalDecision, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).addExternalDecision(personId, externalDecision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lisab THT külge süsteemivälise otsuse.
     * @param {string} personId 
     * @param {Speciality} speciality 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public addPrescriptivePower(personId: string, speciality: Speciality, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).addPrescriptivePower(personId, speciality, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lisab THT külge eelmise nime.
     * @param {string} personId 
     * @param {PreviousName} previousName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public addPreviousName(personId: string, previousName: PreviousName, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).addPreviousName(personId, previousName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lisandub eriõe retseptiõiguse eriala
     * @param {string} personId 
     * @param {Speciality} speciality 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public addSpecialPrescriptivePower(personId: string, speciality: Speciality, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).addSpecialPrescriptivePower(personId, speciality, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Loob Person objekti põhjal uue kasutaja.
     * @param {PersonCreation} personCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public createPerson(personCreation: PersonCreation, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).createPerson(personCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ekspordib TTO andmed KRA-le CSV-failina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public exportKraLicenses(options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).exportKraLicenses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ekspordib THT andmed KRA-le CSV-failina.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public exportKraPersons(options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).exportKraPersons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ekspordib filtreeritud isikud otsing CSV-failina.
     * @param {PersonSearchFilter} personSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public exportPersons(personSearchFilter: PersonSearchFilter, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).exportPersons(personSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab filtreeritud isikud.
     * @param {PersonSearchFilter} personSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public filterPersons1(personSearchFilter: PersonSearchFilter, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).filterPersons1(personSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab Eesti isikukoodi põhjal kontaktandmed.
     * @param {string} personIdCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public getContactInfoFromRR1(personIdCode: string, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).getContactInfoFromRR1(personIdCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab EeIsikuKaart päringuga saadud filtreeritud isiku hariduse andmed.
     * @param {string} personId 
     * @param {string} occupationCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public getEhisStudies1(personId: string, occupationCode: string, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).getEhisStudies1(personId, occupationCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab Eesti isikukoodi põhjal isiku-ja kontaktandmed.
     * @param {string} personalCode 
     * @param {string} countryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public getPersonInfoFromPersonalCode(personalCode: string, countryCode: string, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).getPersonInfoFromPersonalCode(personalCode, countryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab Person objekti
     * @param {string} personId 
     * @param {string} residencyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public markPersonResidencyCompleted(personId: string, residencyId: string, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).markPersonResidencyCompleted(personId, residencyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Peatab taotleja tegevusõiguse.
     * @param {string} id 
     * @param {PersonSuspension} personSuspension 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public suspendPerson(id: string, personSuspension: PersonSuspension, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).suspendPerson(id, personSuspension, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab id põhjal person objekti isikuandmed.
     * @param {string} id 
     * @param {PersonalData} personalData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public updatePerson2(id: string, personalData: PersonalData, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).updatePerson2(id, personalData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab id põhjal person objekti kontaktinfo.
     * @param {string} id 
     * @param {ContactInfo} contactInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAndmeteTeenusAmetnikeleApi
     */
    public updatePersonContactInfo(id: string, contactInfo: ContactInfo, options?: AxiosRequestConfig) {
        return KasutajaAndmeteTeenusAmetnikeleApiFp(this.configuration).updatePersonContactInfo(id, contactInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KasutajaAutentimiseTeenusApi - axios parameter creator
 * @export
 */
export const KasutajaAutentimiseTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab openid authorization tokeni openId exchange tokeni vastu.
         * @param {string} code 
         * @param {string} clientId 
         * @param {string} [authSuccessUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchange: async (code: string, clientId: string, authSuccessUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('exchange', 'code', code)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('exchange', 'clientId', clientId)
            const localVarPath = `/public/auth/exchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (authSuccessUrl !== undefined) {
                localVarQueryParameter['auth_success_url'] = authSuccessUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab autentimise jaoks vajalikud parameetrid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/auth/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab autenditud kasutaja info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/auth/userinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Iseteenindusest välja logimine client_id ja refresh_tokeni alusel.
         * @param {LogoutClientIdRequest} logoutClientIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (logoutClientIdRequest: LogoutClientIdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logoutClientIdRequest' is not null or undefined
            assertParamExists('logout', 'logoutClientIdRequest', logoutClientIdRequest)
            const localVarPath = `/public/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logoutClientIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab openid authorization tokeni openId refresh tokeni vastu.
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('refresh', 'clientId', clientId)
            const localVarPath = `/public/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KasutajaAutentimiseTeenusApi - functional programming interface
 * @export
 */
export const KasutajaAutentimiseTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KasutajaAutentimiseTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab openid authorization tokeni openId exchange tokeni vastu.
         * @param {string} code 
         * @param {string} clientId 
         * @param {string} [authSuccessUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchange(code: string, clientId: string, authSuccessUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchange(code, clientId, authSuccessUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab autentimise jaoks vajalikud parameetrid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab autenditud kasutaja info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Iseteenindusest välja logimine client_id ja refresh_tokeni alusel.
         * @param {LogoutClientIdRequest} logoutClientIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(logoutClientIdRequest: LogoutClientIdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(logoutClientIdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab openid authorization tokeni openId refresh tokeni vastu.
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KasutajaAutentimiseTeenusApi - factory interface
 * @export
 */
export const KasutajaAutentimiseTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KasutajaAutentimiseTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab openid authorization tokeni openId exchange tokeni vastu.
         * @param {string} code 
         * @param {string} clientId 
         * @param {string} [authSuccessUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchange(code: string, clientId: string, authSuccessUrl?: string, options?: any): AxiosPromise<object> {
            return localVarFp.exchange(code, clientId, authSuccessUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab autentimise jaoks vajalikud parameetrid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthConfig(options?: any): AxiosPromise<AuthConfig> {
            return localVarFp.getAuthConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab autenditud kasutaja info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Iseteenindusest välja logimine client_id ja refresh_tokeni alusel.
         * @param {LogoutClientIdRequest} logoutClientIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(logoutClientIdRequest: LogoutClientIdRequest, options?: any): AxiosPromise<void> {
            return localVarFp.logout(logoutClientIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab openid authorization tokeni openId refresh tokeni vastu.
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(clientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.refresh(clientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KasutajaAutentimiseTeenusApi - object-oriented interface
 * @export
 * @class KasutajaAutentimiseTeenusApi
 * @extends {BaseAPI}
 */
export class KasutajaAutentimiseTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab openid authorization tokeni openId exchange tokeni vastu.
     * @param {string} code 
     * @param {string} clientId 
     * @param {string} [authSuccessUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAutentimiseTeenusApi
     */
    public exchange(code: string, clientId: string, authSuccessUrl?: string, options?: AxiosRequestConfig) {
        return KasutajaAutentimiseTeenusApiFp(this.configuration).exchange(code, clientId, authSuccessUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab autentimise jaoks vajalikud parameetrid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAutentimiseTeenusApi
     */
    public getAuthConfig(options?: AxiosRequestConfig) {
        return KasutajaAutentimiseTeenusApiFp(this.configuration).getAuthConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab autenditud kasutaja info.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAutentimiseTeenusApi
     */
    public getUserInfo(options?: AxiosRequestConfig) {
        return KasutajaAutentimiseTeenusApiFp(this.configuration).getUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Iseteenindusest välja logimine client_id ja refresh_tokeni alusel.
     * @param {LogoutClientIdRequest} logoutClientIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAutentimiseTeenusApi
     */
    public logout(logoutClientIdRequest: LogoutClientIdRequest, options?: AxiosRequestConfig) {
        return KasutajaAutentimiseTeenusApiFp(this.configuration).logout(logoutClientIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab openid authorization tokeni openId refresh tokeni vastu.
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaAutentimiseTeenusApi
     */
    public refresh(clientId: string, options?: AxiosRequestConfig) {
        return KasutajaAutentimiseTeenusApiFp(this.configuration).refresh(clientId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KasutajaTegevusigusePeatamiseTeenusApi - axios parameter creator
 * @export
 */
export const KasutajaTegevusigusePeatamiseTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Eemaldab peatamise otsuse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSuspension: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSuspension', 'id', id)
            const localVarPath = `/official/persons/suspensions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KasutajaTegevusigusePeatamiseTeenusApi - functional programming interface
 * @export
 */
export const KasutajaTegevusigusePeatamiseTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KasutajaTegevusigusePeatamiseTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Eemaldab peatamise otsuse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSuspension(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSuspension(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KasutajaTegevusigusePeatamiseTeenusApi - factory interface
 * @export
 */
export const KasutajaTegevusigusePeatamiseTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KasutajaTegevusigusePeatamiseTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Eemaldab peatamise otsuse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSuspension(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteSuspension(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KasutajaTegevusigusePeatamiseTeenusApi - object-oriented interface
 * @export
 * @class KasutajaTegevusigusePeatamiseTeenusApi
 * @extends {BaseAPI}
 */
export class KasutajaTegevusigusePeatamiseTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Eemaldab peatamise otsuse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KasutajaTegevusigusePeatamiseTeenusApi
     */
    public deleteSuspension(id: string, options?: AxiosRequestConfig) {
        return KasutajaTegevusigusePeatamiseTeenusApiFp(this.configuration).deleteSuspension(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KlassifikaatoriteTeenusApi - axios parameter creator
 * @export
 */
export const KlassifikaatoriteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab tegevusloa menetluse tähtaja päevade arvu.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseProceedingDeadline: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/classifiers/activity-license/proceeding-deadline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab koolide nimed kutse või eriala koodi põhjal.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchoolNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/classifiers/schools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kuvab päevade arvu, mis on jäänud menetlemise tähtaja lõpuni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationCertificateProceedingDeadline: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/classifiers/application-certificate/proceeding-deadline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kuvab päevade arvu, mis on jäänud riigilõivu tasumiseks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationCertificateStateFeeDeadline: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/classifiers/application-certificate/state-fee-deadline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab riigi koodi põhjal menetluse tähtaja päevade arvu.
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProceedingDeadline: async (countryCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryCode' is not null or undefined
            assertParamExists('getProceedingDeadline', 'countryCode', countryCode)
            const localVarPath = `/classifiers/proceeding-deadline/{countryCode}`
                .replace(`{${"countryCode"}}`, encodeURIComponent(String(countryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab koolide nimed kutse või eriala koodi põhjal.
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchoolNames: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getSchoolNames', 'code', code)
            const localVarPath = `/classifiers/schools/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KlassifikaatoriteTeenusApi - functional programming interface
 * @export
 */
export const KlassifikaatoriteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KlassifikaatoriteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa menetluse tähtaja päevade arvu.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicenseProceedingDeadline(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicenseProceedingDeadline(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab koolide nimed kutse või eriala koodi põhjal.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSchoolNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSchoolNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kuvab päevade arvu, mis on jäänud menetlemise tähtaja lõpuni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationCertificateProceedingDeadline(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationCertificateProceedingDeadline(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kuvab päevade arvu, mis on jäänud riigilõivu tasumiseks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationCertificateStateFeeDeadline(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationCertificateStateFeeDeadline(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab riigi koodi põhjal menetluse tähtaja päevade arvu.
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProceedingDeadline(countryCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProceedingDeadline(countryCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab koolide nimed kutse või eriala koodi põhjal.
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchoolNames(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchoolNames(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KlassifikaatoriteTeenusApi - factory interface
 * @export
 */
export const KlassifikaatoriteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KlassifikaatoriteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa menetluse tähtaja päevade arvu.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseProceedingDeadline(options?: any): AxiosPromise<number> {
            return localVarFp.getActivityLicenseProceedingDeadline(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab koolide nimed kutse või eriala koodi põhjal.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchoolNames(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllSchoolNames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kuvab päevade arvu, mis on jäänud menetlemise tähtaja lõpuni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationCertificateProceedingDeadline(options?: any): AxiosPromise<number> {
            return localVarFp.getApplicationCertificateProceedingDeadline(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kuvab päevade arvu, mis on jäänud riigilõivu tasumiseks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationCertificateStateFeeDeadline(options?: any): AxiosPromise<number> {
            return localVarFp.getApplicationCertificateStateFeeDeadline(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab riigi koodi põhjal menetluse tähtaja päevade arvu.
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProceedingDeadline(countryCode: string, options?: any): AxiosPromise<number> {
            return localVarFp.getProceedingDeadline(countryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab koolide nimed kutse või eriala koodi põhjal.
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchoolNames(code: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getSchoolNames(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KlassifikaatoriteTeenusApi - object-oriented interface
 * @export
 * @class KlassifikaatoriteTeenusApi
 * @extends {BaseAPI}
 */
export class KlassifikaatoriteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab tegevusloa menetluse tähtaja päevade arvu.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KlassifikaatoriteTeenusApi
     */
    public getActivityLicenseProceedingDeadline(options?: AxiosRequestConfig) {
        return KlassifikaatoriteTeenusApiFp(this.configuration).getActivityLicenseProceedingDeadline(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab koolide nimed kutse või eriala koodi põhjal.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KlassifikaatoriteTeenusApi
     */
    public getAllSchoolNames(options?: AxiosRequestConfig) {
        return KlassifikaatoriteTeenusApiFp(this.configuration).getAllSchoolNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kuvab päevade arvu, mis on jäänud menetlemise tähtaja lõpuni
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KlassifikaatoriteTeenusApi
     */
    public getApplicationCertificateProceedingDeadline(options?: AxiosRequestConfig) {
        return KlassifikaatoriteTeenusApiFp(this.configuration).getApplicationCertificateProceedingDeadline(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kuvab päevade arvu, mis on jäänud riigilõivu tasumiseks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KlassifikaatoriteTeenusApi
     */
    public getApplicationCertificateStateFeeDeadline(options?: AxiosRequestConfig) {
        return KlassifikaatoriteTeenusApiFp(this.configuration).getApplicationCertificateStateFeeDeadline(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab riigi koodi põhjal menetluse tähtaja päevade arvu.
     * @param {string} countryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KlassifikaatoriteTeenusApi
     */
    public getProceedingDeadline(countryCode: string, options?: AxiosRequestConfig) {
        return KlassifikaatoriteTeenusApiFp(this.configuration).getProceedingDeadline(countryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab koolide nimed kutse või eriala koodi põhjal.
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KlassifikaatoriteTeenusApi
     */
    public getSchoolNames(code: string, options?: AxiosRequestConfig) {
        return KlassifikaatoriteTeenusApiFp(this.configuration).getSchoolNames(code, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KustutabAntudIdGaHariduseApi - axios parameter creator
 * @export
 */
export const KustutabAntudIdGaHariduseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Kustutage haridus
         * @param {string} educationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEducation: async (educationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationId' is not null or undefined
            assertParamExists('deleteEducation', 'educationId', educationId)
            const localVarPath = `/official/persons/educations/{educationId}`
                .replace(`{${"educationId"}}`, encodeURIComponent(String(educationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KustutabAntudIdGaHariduseApi - functional programming interface
 * @export
 */
export const KustutabAntudIdGaHariduseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KustutabAntudIdGaHariduseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Kustutage haridus
         * @param {string} educationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEducation(educationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEducation(educationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KustutabAntudIdGaHariduseApi - factory interface
 * @export
 */
export const KustutabAntudIdGaHariduseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KustutabAntudIdGaHariduseApiFp(configuration)
    return {
        /**
         * 
         * @summary Kustutage haridus
         * @param {string} educationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEducation(educationId: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteEducation(educationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KustutabAntudIdGaHariduseApi - object-oriented interface
 * @export
 * @class KustutabAntudIdGaHariduseApi
 * @extends {BaseAPI}
 */
export class KustutabAntudIdGaHariduseApi extends BaseAPI {
    /**
     * 
     * @summary Kustutage haridus
     * @param {string} educationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KustutabAntudIdGaHariduseApi
     */
    public deleteEducation(educationId: string, options?: AxiosRequestConfig) {
        return KustutabAntudIdGaHariduseApiFp(this.configuration).deleteEducation(educationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OtsusteAllkirjastamiseTeenusApi - axios parameter creator
 * @export
 */
export const OtsusteAllkirjastamiseTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Loob konteiner elemendi Siga teenusesse.
         * @param {string} decisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContainer: async (decisionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decisionId' is not null or undefined
            assertParamExists('createContainer', 'decisionId', decisionId)
            const localVarPath = `/official/sign/{decisionId}/container`
                .replace(`{${"decisionId"}}`, encodeURIComponent(String(decisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Genereerib allkirjastatud konteineri mobiili id põhjal.
         * @param {string} decisionId 
         * @param {MobileSignFinalizationDTO} mobileSignFinalizationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeMobileSigning: async (decisionId: string, mobileSignFinalizationDTO: MobileSignFinalizationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decisionId' is not null or undefined
            assertParamExists('finalizeMobileSigning', 'decisionId', decisionId)
            // verify required parameter 'mobileSignFinalizationDTO' is not null or undefined
            assertParamExists('finalizeMobileSigning', 'mobileSignFinalizationDTO', mobileSignFinalizationDTO)
            const localVarPath = `/official/sign/{decisionId}/mobile/finalize`
                .replace(`{${"decisionId"}}`, encodeURIComponent(String(decisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mobileSignFinalizationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Genereerib allkirjastatud konteineri id kaardi sertifikaadi põhjal.
         * @param {string} decisionId 
         * @param {RemoteSigningFinalizationDTO} remoteSigningFinalizationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeRemoteSigning: async (decisionId: string, remoteSigningFinalizationDTO: RemoteSigningFinalizationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decisionId' is not null or undefined
            assertParamExists('finalizeRemoteSigning', 'decisionId', decisionId)
            // verify required parameter 'remoteSigningFinalizationDTO' is not null or undefined
            assertParamExists('finalizeRemoteSigning', 'remoteSigningFinalizationDTO', remoteSigningFinalizationDTO)
            const localVarPath = `/official/sign/{decisionId}/finalize-remote-signing`
                .replace(`{${"decisionId"}}`, encodeURIComponent(String(decisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(remoteSigningFinalizationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Alustab mobiilse ID allkirjastamise protsessi.
         * @param {string} decisionId 
         * @param {MobileSigningDTO} mobileSigningDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepareMobileSigning: async (decisionId: string, mobileSigningDTO: MobileSigningDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decisionId' is not null or undefined
            assertParamExists('prepareMobileSigning', 'decisionId', decisionId)
            // verify required parameter 'mobileSigningDTO' is not null or undefined
            assertParamExists('prepareMobileSigning', 'mobileSigningDTO', mobileSigningDTO)
            const localVarPath = `/official/sign/{decisionId}/mobile`
                .replace(`{${"decisionId"}}`, encodeURIComponent(String(decisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mobileSigningDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Alustab Siga-ga ID kaardi allkirjastamise protsessi.
         * @param {string} decisionId 
         * @param {RemoteSigningDTO} remoteSigningDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepareRemoteSigning: async (decisionId: string, remoteSigningDTO: RemoteSigningDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decisionId' is not null or undefined
            assertParamExists('prepareRemoteSigning', 'decisionId', decisionId)
            // verify required parameter 'remoteSigningDTO' is not null or undefined
            assertParamExists('prepareRemoteSigning', 'remoteSigningDTO', remoteSigningDTO)
            const localVarPath = `/official/sign/{decisionId}/prepare-remote-signing`
                .replace(`{${"decisionId"}}`, encodeURIComponent(String(decisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(remoteSigningDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OtsusteAllkirjastamiseTeenusApi - functional programming interface
 * @export
 */
export const OtsusteAllkirjastamiseTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OtsusteAllkirjastamiseTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Loob konteiner elemendi Siga teenusesse.
         * @param {string} decisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContainer(decisionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateHashCodeContainerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContainer(decisionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Genereerib allkirjastatud konteineri mobiili id põhjal.
         * @param {string} decisionId 
         * @param {MobileSignFinalizationDTO} mobileSignFinalizationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalizeMobileSigning(decisionId: string, mobileSignFinalizationDTO: MobileSignFinalizationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Decision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalizeMobileSigning(decisionId, mobileSignFinalizationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Genereerib allkirjastatud konteineri id kaardi sertifikaadi põhjal.
         * @param {string} decisionId 
         * @param {RemoteSigningFinalizationDTO} remoteSigningFinalizationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalizeRemoteSigning(decisionId: string, remoteSigningFinalizationDTO: RemoteSigningFinalizationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Decision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalizeRemoteSigning(decisionId, remoteSigningFinalizationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Alustab mobiilse ID allkirjastamise protsessi.
         * @param {string} decisionId 
         * @param {MobileSigningDTO} mobileSigningDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prepareMobileSigning(decisionId: string, mobileSigningDTO: MobileSigningDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileSignInitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prepareMobileSigning(decisionId, mobileSigningDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Alustab Siga-ga ID kaardi allkirjastamise protsessi.
         * @param {string} decisionId 
         * @param {RemoteSigningDTO} remoteSigningDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prepareRemoteSigning(decisionId: string, remoteSigningDTO: RemoteSigningDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteSigningInitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prepareRemoteSigning(decisionId, remoteSigningDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OtsusteAllkirjastamiseTeenusApi - factory interface
 * @export
 */
export const OtsusteAllkirjastamiseTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OtsusteAllkirjastamiseTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Loob konteiner elemendi Siga teenusesse.
         * @param {string} decisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContainer(decisionId: string, options?: any): AxiosPromise<CreateHashCodeContainerResponse> {
            return localVarFp.createContainer(decisionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Genereerib allkirjastatud konteineri mobiili id põhjal.
         * @param {string} decisionId 
         * @param {MobileSignFinalizationDTO} mobileSignFinalizationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeMobileSigning(decisionId: string, mobileSignFinalizationDTO: MobileSignFinalizationDTO, options?: any): AxiosPromise<Decision> {
            return localVarFp.finalizeMobileSigning(decisionId, mobileSignFinalizationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Genereerib allkirjastatud konteineri id kaardi sertifikaadi põhjal.
         * @param {string} decisionId 
         * @param {RemoteSigningFinalizationDTO} remoteSigningFinalizationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeRemoteSigning(decisionId: string, remoteSigningFinalizationDTO: RemoteSigningFinalizationDTO, options?: any): AxiosPromise<Decision> {
            return localVarFp.finalizeRemoteSigning(decisionId, remoteSigningFinalizationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Alustab mobiilse ID allkirjastamise protsessi.
         * @param {string} decisionId 
         * @param {MobileSigningDTO} mobileSigningDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepareMobileSigning(decisionId: string, mobileSigningDTO: MobileSigningDTO, options?: any): AxiosPromise<MobileSignInitResponse> {
            return localVarFp.prepareMobileSigning(decisionId, mobileSigningDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Alustab Siga-ga ID kaardi allkirjastamise protsessi.
         * @param {string} decisionId 
         * @param {RemoteSigningDTO} remoteSigningDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepareRemoteSigning(decisionId: string, remoteSigningDTO: RemoteSigningDTO, options?: any): AxiosPromise<RemoteSigningInitResponse> {
            return localVarFp.prepareRemoteSigning(decisionId, remoteSigningDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OtsusteAllkirjastamiseTeenusApi - object-oriented interface
 * @export
 * @class OtsusteAllkirjastamiseTeenusApi
 * @extends {BaseAPI}
 */
export class OtsusteAllkirjastamiseTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Loob konteiner elemendi Siga teenusesse.
     * @param {string} decisionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtsusteAllkirjastamiseTeenusApi
     */
    public createContainer(decisionId: string, options?: AxiosRequestConfig) {
        return OtsusteAllkirjastamiseTeenusApiFp(this.configuration).createContainer(decisionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Genereerib allkirjastatud konteineri mobiili id põhjal.
     * @param {string} decisionId 
     * @param {MobileSignFinalizationDTO} mobileSignFinalizationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtsusteAllkirjastamiseTeenusApi
     */
    public finalizeMobileSigning(decisionId: string, mobileSignFinalizationDTO: MobileSignFinalizationDTO, options?: AxiosRequestConfig) {
        return OtsusteAllkirjastamiseTeenusApiFp(this.configuration).finalizeMobileSigning(decisionId, mobileSignFinalizationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Genereerib allkirjastatud konteineri id kaardi sertifikaadi põhjal.
     * @param {string} decisionId 
     * @param {RemoteSigningFinalizationDTO} remoteSigningFinalizationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtsusteAllkirjastamiseTeenusApi
     */
    public finalizeRemoteSigning(decisionId: string, remoteSigningFinalizationDTO: RemoteSigningFinalizationDTO, options?: AxiosRequestConfig) {
        return OtsusteAllkirjastamiseTeenusApiFp(this.configuration).finalizeRemoteSigning(decisionId, remoteSigningFinalizationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Alustab mobiilse ID allkirjastamise protsessi.
     * @param {string} decisionId 
     * @param {MobileSigningDTO} mobileSigningDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtsusteAllkirjastamiseTeenusApi
     */
    public prepareMobileSigning(decisionId: string, mobileSigningDTO: MobileSigningDTO, options?: AxiosRequestConfig) {
        return OtsusteAllkirjastamiseTeenusApiFp(this.configuration).prepareMobileSigning(decisionId, mobileSigningDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Alustab Siga-ga ID kaardi allkirjastamise protsessi.
     * @param {string} decisionId 
     * @param {RemoteSigningDTO} remoteSigningDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtsusteAllkirjastamiseTeenusApi
     */
    public prepareRemoteSigning(decisionId: string, remoteSigningDTO: RemoteSigningDTO, options?: AxiosRequestConfig) {
        return OtsusteAllkirjastamiseTeenusApiFp(this.configuration).prepareRemoteSigning(decisionId, remoteSigningDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OtsusteTeenusApi - axios parameter creator
 * @export
 */
export const OtsusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Laeb üles otsuse ning suunab selle valitud allkirjastajale.
         * @param {DecisionType} decisionType 
         * @param {string} id 
         * @param {OfficialUser} signer 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignDecisionToSigner: async (decisionType: DecisionType, id: string, signer: OfficialUser, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decisionType' is not null or undefined
            assertParamExists('assignDecisionToSigner', 'decisionType', decisionType)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignDecisionToSigner', 'id', id)
            // verify required parameter 'signer' is not null or undefined
            assertParamExists('assignDecisionToSigner', 'signer', signer)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('assignDecisionToSigner', 'file', file)
            const localVarPath = `/official/decisions/assign/{decisionType}/{id}`
                .replace(`{${"decisionType"}}`, encodeURIComponent(String(decisionType)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication api-key required


            if (signer !== undefined) { 
                localVarFormParams.append('signer', new Blob([JSON.stringify(signer)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab genereeritud otsuse malli.
         * @param {DecisionType} decisionType 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download1: async (decisionType: DecisionType, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decisionType' is not null or undefined
            assertParamExists('download1', 'decisionType', decisionType)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('download1', 'id', id)
            const localVarPath = `/official/decisions/{decisionType}/{id}`
                .replace(`{${"decisionType"}}`, encodeURIComponent(String(decisionType)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.search-decisions.description}
         * @param {'THT' | 'LICENSE'} requestType 
         * @param {DecisionSearchFilter} decisionSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDecisions: async (requestType: 'THT' | 'LICENSE', decisionSearchFilter: DecisionSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestType' is not null or undefined
            assertParamExists('searchDecisions', 'requestType', requestType)
            // verify required parameter 'decisionSearchFilter' is not null or undefined
            assertParamExists('searchDecisions', 'decisionSearchFilter', decisionSearchFilter)
            const localVarPath = `/official/decisions/filter/{requestType}`
                .replace(`{${"requestType"}}`, encodeURIComponent(String(requestType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(decisionSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OtsusteTeenusApi - functional programming interface
 * @export
 */
export const OtsusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OtsusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Laeb üles otsuse ning suunab selle valitud allkirjastajale.
         * @param {DecisionType} decisionType 
         * @param {string} id 
         * @param {OfficialUser} signer 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignDecisionToSigner(decisionType: DecisionType, id: string, signer: OfficialUser, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignDecisionToSigner(decisionType, id, signer, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab genereeritud otsuse malli.
         * @param {DecisionType} decisionType 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download1(decisionType: DecisionType, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download1(decisionType, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.search-decisions.description}
         * @param {'THT' | 'LICENSE'} requestType 
         * @param {DecisionSearchFilter} decisionSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDecisions(requestType: 'THT' | 'LICENSE', decisionSearchFilter: DecisionSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DecisionSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDecisions(requestType, decisionSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OtsusteTeenusApi - factory interface
 * @export
 */
export const OtsusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OtsusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Laeb üles otsuse ning suunab selle valitud allkirjastajale.
         * @param {DecisionType} decisionType 
         * @param {string} id 
         * @param {OfficialUser} signer 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignDecisionToSigner(decisionType: DecisionType, id: string, signer: OfficialUser, file: any, options?: any): AxiosPromise<void> {
            return localVarFp.assignDecisionToSigner(decisionType, id, signer, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab genereeritud otsuse malli.
         * @param {DecisionType} decisionType 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download1(decisionType: DecisionType, id: string, options?: any): AxiosPromise<any> {
            return localVarFp.download1(decisionType, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.search-decisions.description}
         * @param {'THT' | 'LICENSE'} requestType 
         * @param {DecisionSearchFilter} decisionSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDecisions(requestType: 'THT' | 'LICENSE', decisionSearchFilter: DecisionSearchFilter, options?: any): AxiosPromise<DecisionSearchResponse> {
            return localVarFp.searchDecisions(requestType, decisionSearchFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OtsusteTeenusApi - object-oriented interface
 * @export
 * @class OtsusteTeenusApi
 * @extends {BaseAPI}
 */
export class OtsusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Laeb üles otsuse ning suunab selle valitud allkirjastajale.
     * @param {DecisionType} decisionType 
     * @param {string} id 
     * @param {OfficialUser} signer 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtsusteTeenusApi
     */
    public assignDecisionToSigner(decisionType: DecisionType, id: string, signer: OfficialUser, file: any, options?: AxiosRequestConfig) {
        return OtsusteTeenusApiFp(this.configuration).assignDecisionToSigner(decisionType, id, signer, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab genereeritud otsuse malli.
     * @param {DecisionType} decisionType 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtsusteTeenusApi
     */
    public download1(decisionType: DecisionType, id: string, options?: AxiosRequestConfig) {
        return OtsusteTeenusApiFp(this.configuration).download1(decisionType, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.search-decisions.description}
     * @param {'THT' | 'LICENSE'} requestType 
     * @param {DecisionSearchFilter} decisionSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtsusteTeenusApi
     */
    public searchDecisions(requestType: 'THT' | 'LICENSE', decisionSearchFilter: DecisionSearchFilter, options?: AxiosRequestConfig) {
        return OtsusteTeenusApiFp(this.configuration).searchDecisions(requestType, decisionSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PankadeAndmeteTeenusApi - axios parameter creator
 * @export
 */
export const PankadeAndmeteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab pankade andmed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/banks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PankadeAndmeteTeenusApi - functional programming interface
 * @export
 */
export const PankadeAndmeteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PankadeAndmeteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab pankade andmed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBanks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Bank>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBanks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PankadeAndmeteTeenusApi - factory interface
 * @export
 */
export const PankadeAndmeteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PankadeAndmeteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab pankade andmed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanks(options?: any): AxiosPromise<Array<Bank>> {
            return localVarFp.getBanks(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PankadeAndmeteTeenusApi - object-oriented interface
 * @export
 * @class PankadeAndmeteTeenusApi
 * @extends {BaseAPI}
 */
export class PankadeAndmeteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab pankade andmed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PankadeAndmeteTeenusApi
     */
    public getBanks(options?: AxiosRequestConfig) {
        return PankadeAndmeteTeenusApiFp(this.configuration).getBanks(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApi - axios parameter creator
 * @export
 */
export const PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Loo inimese kompetentsid
         * @param {string} personId 
         * @param {Competence} competence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompetence: async (personId: string, competence: Competence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('createCompetence', 'personId', personId)
            // verify required parameter 'competence' is not null or undefined
            assertParamExists('createCompetence', 'competence', competence)
            const localVarPath = `/official/persons/{personId}/competencies/`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(competence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kustuta pädevus
         * @param {string} personId 
         * @param {string} competenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompetence: async (personId: string, competenceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('deleteCompetence', 'personId', personId)
            // verify required parameter 'competenceId' is not null or undefined
            assertParamExists('deleteCompetence', 'competenceId', competenceId)
            const localVarPath = `/official/persons/{personId}/competencies/{competenceId}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)))
                .replace(`{${"competenceId"}}`, encodeURIComponent(String(competenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab isiku jaoks kõik pädevused
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompetenciesForPerson1: async (personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getAllCompetenciesForPerson1', 'personId', personId)
            const localVarPath = `/official/persons/{personId}/competencies/`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuenda inimese pädevusi
         * @param {string} personId 
         * @param {string} competenceId 
         * @param {Competence} competence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompetence: async (personId: string, competenceId: string, competence: Competence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('updateCompetence', 'personId', personId)
            // verify required parameter 'competenceId' is not null or undefined
            assertParamExists('updateCompetence', 'competenceId', competenceId)
            // verify required parameter 'competence' is not null or undefined
            assertParamExists('updateCompetence', 'competence', competence)
            const localVarPath = `/official/persons/{personId}/competencies/{competenceId}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)))
                .replace(`{${"competenceId"}}`, encodeURIComponent(String(competenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(competence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApi - functional programming interface
 * @export
 */
export const PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Loo inimese kompetentsid
         * @param {string} personId 
         * @param {Competence} competence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompetence(personId: string, competence: Competence, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Competence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompetence(personId, competence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kustuta pädevus
         * @param {string} personId 
         * @param {string} competenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompetence(personId: string, competenceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompetence(personId, competenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab isiku jaoks kõik pädevused
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompetenciesForPerson1(personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Competence>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompetenciesForPerson1(personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuenda inimese pädevusi
         * @param {string} personId 
         * @param {string} competenceId 
         * @param {Competence} competence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompetence(personId: string, competenceId: string, competence: Competence, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Competence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompetence(personId, competenceId, competence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApi - factory interface
 * @export
 */
export const PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApiFp(configuration)
    return {
        /**
         * 
         * @summary Loo inimese kompetentsid
         * @param {string} personId 
         * @param {Competence} competence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompetence(personId: string, competence: Competence, options?: any): AxiosPromise<Competence> {
            return localVarFp.createCompetence(personId, competence, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kustuta pädevus
         * @param {string} personId 
         * @param {string} competenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompetence(personId: string, competenceId: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteCompetence(personId, competenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab isiku jaoks kõik pädevused
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompetenciesForPerson1(personId: string, options?: any): AxiosPromise<Array<Competence>> {
            return localVarFp.getAllCompetenciesForPerson1(personId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuenda inimese pädevusi
         * @param {string} personId 
         * @param {string} competenceId 
         * @param {Competence} competence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompetence(personId: string, competenceId: string, competence: Competence, options?: any): AxiosPromise<Competence> {
            return localVarFp.updateCompetence(personId, competenceId, competence, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApi - object-oriented interface
 * @export
 * @class PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApi
 * @extends {BaseAPI}
 */
export class PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApi extends BaseAPI {
    /**
     * 
     * @summary Loo inimese kompetentsid
     * @param {string} personId 
     * @param {Competence} competence 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApi
     */
    public createCompetence(personId: string, competence: Competence, options?: AxiosRequestConfig) {
        return PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApiFp(this.configuration).createCompetence(personId, competence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kustuta pädevus
     * @param {string} personId 
     * @param {string} competenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApi
     */
    public deleteCompetence(personId: string, competenceId: string, options?: AxiosRequestConfig) {
        return PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApiFp(this.configuration).deleteCompetence(personId, competenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab isiku jaoks kõik pädevused
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApi
     */
    public getAllCompetenciesForPerson1(personId: string, options?: AxiosRequestConfig) {
        return PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApiFp(this.configuration).getAllCompetenciesForPerson1(personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuenda inimese pädevusi
     * @param {string} personId 
     * @param {string} competenceId 
     * @param {Competence} competence 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApi
     */
    public updateCompetence(personId: string, competenceId: string, competence: Competence, options?: AxiosRequestConfig) {
        return PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApiFp(this.configuration).updateCompetence(personId, competenceId, competence, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PdevusteLisamineIsikuandmeteleAvalikusPortaalisApi - axios parameter creator
 * @export
 */
export const PdevusteLisamineIsikuandmeteleAvalikusPortaalisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab inimese jaoks kogu pädevuse
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompetenciesForPerson: async (personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getAllCompetenciesForPerson', 'personId', personId)
            const localVarPath = `/public/persons/{personId}/competencies/`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PdevusteLisamineIsikuandmeteleAvalikusPortaalisApi - functional programming interface
 * @export
 */
export const PdevusteLisamineIsikuandmeteleAvalikusPortaalisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PdevusteLisamineIsikuandmeteleAvalikusPortaalisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab inimese jaoks kogu pädevuse
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompetenciesForPerson(personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Competence>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompetenciesForPerson(personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PdevusteLisamineIsikuandmeteleAvalikusPortaalisApi - factory interface
 * @export
 */
export const PdevusteLisamineIsikuandmeteleAvalikusPortaalisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PdevusteLisamineIsikuandmeteleAvalikusPortaalisApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab inimese jaoks kogu pädevuse
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompetenciesForPerson(personId: string, options?: any): AxiosPromise<Array<Competence>> {
            return localVarFp.getAllCompetenciesForPerson(personId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PdevusteLisamineIsikuandmeteleAvalikusPortaalisApi - object-oriented interface
 * @export
 * @class PdevusteLisamineIsikuandmeteleAvalikusPortaalisApi
 * @extends {BaseAPI}
 */
export class PdevusteLisamineIsikuandmeteleAvalikusPortaalisApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab inimese jaoks kogu pädevuse
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdevusteLisamineIsikuandmeteleAvalikusPortaalisApi
     */
    public getAllCompetenciesForPerson(personId: string, options?: AxiosRequestConfig) {
        return PdevusteLisamineIsikuandmeteleAvalikusPortaalisApiFp(this.configuration).getAllCompetenciesForPerson(personId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PerearstiNimistudTeenusApi - axios parameter creator
 * @export
 */
export const PerearstiNimistudTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lisada töötajaid
         * @param {string} id 
         * @param {GeneralPractitionerListEmployee} generalPractitionerListEmployee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployee: async (id: string, generalPractitionerListEmployee: GeneralPractitionerListEmployee, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addEmployee', 'id', id)
            // verify required parameter 'generalPractitionerListEmployee' is not null or undefined
            assertParamExists('addEmployee', 'generalPractitionerListEmployee', generalPractitionerListEmployee)
            const localVarPath = `/general-practitioner-lists/{id}/employees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListEmployee, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lisa asendusarst
         * @param {string} id 
         * @param {GeneralPractitionerListSubstituteDoctor} generalPractitionerListSubstituteDoctor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubstituteDoctor: async (id: string, generalPractitionerListSubstituteDoctor: GeneralPractitionerListSubstituteDoctor, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addSubstituteDoctor', 'id', id)
            // verify required parameter 'generalPractitionerListSubstituteDoctor' is not null or undefined
            assertParamExists('addSubstituteDoctor', 'generalPractitionerListSubstituteDoctor', generalPractitionerListSubstituteDoctor)
            const localVarPath = `/general-practitioner-lists/{id}/substitute-doctors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListSubstituteDoctor, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lisage asendajale asendusperiood
         * @param {string} id 
         * @param {GeneralPractitionerListSubstituteDoctorPeriod} generalPractitionerListSubstituteDoctorPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubstituteDoctorsPeriod: async (id: string, generalPractitionerListSubstituteDoctorPeriod: GeneralPractitionerListSubstituteDoctorPeriod, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addSubstituteDoctorsPeriod', 'id', id)
            // verify required parameter 'generalPractitionerListSubstituteDoctorPeriod' is not null or undefined
            assertParamExists('addSubstituteDoctorsPeriod', 'generalPractitionerListSubstituteDoctorPeriod', generalPractitionerListSubstituteDoctorPeriod)
            const localVarPath = `/general-practitioner-lists/{id}/substitute-doctor-periods`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListSubstituteDoctorPeriod, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lisada vastuvõtuaegade
         * @param {string} id 
         * @param {GeneralPractitionerListAppointment} generalPractitionerListAppointment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointment: async (id: string, generalPractitionerListAppointment: GeneralPractitionerListAppointment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createAppointment', 'id', id)
            // verify required parameter 'generalPractitionerListAppointment' is not null or undefined
            assertParamExists('createAppointment', 'generalPractitionerListAppointment', generalPractitionerListAppointment)
            const localVarPath = `/general-practitioner-lists/{id}/appointments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListAppointment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lisada telefonikonsultatsiooniaegade
         * @param {string} id 
         * @param {GeneralPractitionerListConsultation} generalPractitionerListConsultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultation: async (id: string, generalPractitionerListConsultation: GeneralPractitionerListConsultation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createConsultation', 'id', id)
            // verify required parameter 'generalPractitionerListConsultation' is not null or undefined
            assertParamExists('createConsultation', 'generalPractitionerListConsultation', generalPractitionerListConsultation)
            const localVarPath = `/general-practitioner-lists/{id}/consultations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListConsultation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kustutage perearsti loendi väljad
         * @param {string} id 
         * @param {{ [key: string]: Array<string>; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGPListFields: async (id: string, requestBody: { [key: string]: Array<string>; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteGPListFields', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteGPListFields', 'requestBody', requestBody)
            const localVarPath = `/general-practitioner-lists/{id}/fields-delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lubatud töötajad
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedEmployees: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllowedEmployees', 'id', id)
            const localVarPath = `/general-practitioner-lists/{id}/allowed-employees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPractitionerList2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/general-practitioner-lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {string} id 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPractitionerList3: async (id: string, context?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGeneralPractitionerList3', 'id', id)
            const localVarPath = `/general-practitioner-lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kohtumise aja muutmine
         * @param {string} id 
         * @param {GeneralPractitionerListAppointment} generalPractitionerListAppointment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointment: async (id: string, generalPractitionerListAppointment: GeneralPractitionerListAppointment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAppointment', 'id', id)
            // verify required parameter 'generalPractitionerListAppointment' is not null or undefined
            assertParamExists('updateAppointment', 'generalPractitionerListAppointment', generalPractitionerListAppointment)
            const localVarPath = `/general-practitioner-lists/{id}/appointments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListAppointment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Muuda konsultatsiooni aega
         * @param {string} id 
         * @param {GeneralPractitionerListConsultation} generalPractitionerListConsultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsultation: async (id: string, generalPractitionerListConsultation: GeneralPractitionerListConsultation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateConsultation', 'id', id)
            // verify required parameter 'generalPractitionerListConsultation' is not null or undefined
            assertParamExists('updateConsultation', 'generalPractitionerListConsultation', generalPractitionerListConsultation)
            const localVarPath = `/general-practitioner-lists/{id}/consultations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListConsultation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Värskenda töötajat
         * @param {string} id 
         * @param {GeneralPractitionerListEmployee} generalPractitionerListEmployee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee: async (id: string, generalPractitionerListEmployee: GeneralPractitionerListEmployee, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmployee', 'id', id)
            // verify required parameter 'generalPractitionerListEmployee' is not null or undefined
            assertParamExists('updateEmployee', 'generalPractitionerListEmployee', generalPractitionerListEmployee)
            const localVarPath = `/general-practitioner-lists/{id}/employees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListEmployee, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ajakohastada üldarstide nimekirja
         * @param {string} id 
         * @param {GeneralPractitionerList} generalPractitionerList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralPractitionerList1: async (id: string, generalPractitionerList: GeneralPractitionerList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGeneralPractitionerList1', 'id', id)
            // verify required parameter 'generalPractitionerList' is not null or undefined
            assertParamExists('updateGeneralPractitionerList1', 'generalPractitionerList', generalPractitionerList)
            const localVarPath = `/general-practitioner-lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ajakohastage asendusperiood asendajale
         * @param {string} id 
         * @param {GeneralPractitionerListSubstituteDoctorPeriod} generalPractitionerListSubstituteDoctorPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubstituteDoctorsPeriod: async (id: string, generalPractitionerListSubstituteDoctorPeriod: GeneralPractitionerListSubstituteDoctorPeriod, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSubstituteDoctorsPeriod', 'id', id)
            // verify required parameter 'generalPractitionerListSubstituteDoctorPeriod' is not null or undefined
            assertParamExists('updateSubstituteDoctorsPeriod', 'generalPractitionerListSubstituteDoctorPeriod', generalPractitionerListSubstituteDoctorPeriod)
            const localVarPath = `/general-practitioner-lists/{id}/substitute-doctor-periods`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalPractitionerListSubstituteDoctorPeriod, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerearstiNimistudTeenusApi - functional programming interface
 * @export
 */
export const PerearstiNimistudTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PerearstiNimistudTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lisada töötajaid
         * @param {string} id 
         * @param {GeneralPractitionerListEmployee} generalPractitionerListEmployee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEmployee(id: string, generalPractitionerListEmployee: GeneralPractitionerListEmployee, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerListEmployee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEmployee(id, generalPractitionerListEmployee, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lisa asendusarst
         * @param {string} id 
         * @param {GeneralPractitionerListSubstituteDoctor} generalPractitionerListSubstituteDoctor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSubstituteDoctor(id: string, generalPractitionerListSubstituteDoctor: GeneralPractitionerListSubstituteDoctor, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerListSubstituteDoctor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSubstituteDoctor(id, generalPractitionerListSubstituteDoctor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lisage asendajale asendusperiood
         * @param {string} id 
         * @param {GeneralPractitionerListSubstituteDoctorPeriod} generalPractitionerListSubstituteDoctorPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSubstituteDoctorsPeriod(id: string, generalPractitionerListSubstituteDoctorPeriod: GeneralPractitionerListSubstituteDoctorPeriod, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerListSubstituteDoctorPeriod>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSubstituteDoctorsPeriod(id, generalPractitionerListSubstituteDoctorPeriod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lisada vastuvõtuaegade
         * @param {string} id 
         * @param {GeneralPractitionerListAppointment} generalPractitionerListAppointment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppointment(id: string, generalPractitionerListAppointment: GeneralPractitionerListAppointment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerListAppointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppointment(id, generalPractitionerListAppointment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lisada telefonikonsultatsiooniaegade
         * @param {string} id 
         * @param {GeneralPractitionerListConsultation} generalPractitionerListConsultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConsultation(id: string, generalPractitionerListConsultation: GeneralPractitionerListConsultation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerListConsultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConsultation(id, generalPractitionerListConsultation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kustutage perearsti loendi väljad
         * @param {string} id 
         * @param {{ [key: string]: Array<string>; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGPListFields(id: string, requestBody: { [key: string]: Array<string>; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGPListFields(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lubatud töötajad
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllowedEmployees(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HealthCareProfessional>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllowedEmployees(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralPractitionerList2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralPractitionerList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralPractitionerList2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {string} id 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralPractitionerList3(id: string, context?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralPractitionerList3(id, context, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kohtumise aja muutmine
         * @param {string} id 
         * @param {GeneralPractitionerListAppointment} generalPractitionerListAppointment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppointment(id: string, generalPractitionerListAppointment: GeneralPractitionerListAppointment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerListAppointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppointment(id, generalPractitionerListAppointment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Muuda konsultatsiooni aega
         * @param {string} id 
         * @param {GeneralPractitionerListConsultation} generalPractitionerListConsultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConsultation(id: string, generalPractitionerListConsultation: GeneralPractitionerListConsultation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerListConsultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConsultation(id, generalPractitionerListConsultation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Värskenda töötajat
         * @param {string} id 
         * @param {GeneralPractitionerListEmployee} generalPractitionerListEmployee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployee(id: string, generalPractitionerListEmployee: GeneralPractitionerListEmployee, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerListEmployee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployee(id, generalPractitionerListEmployee, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ajakohastada üldarstide nimekirja
         * @param {string} id 
         * @param {GeneralPractitionerList} generalPractitionerList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGeneralPractitionerList1(id: string, generalPractitionerList: GeneralPractitionerList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGeneralPractitionerList1(id, generalPractitionerList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ajakohastage asendusperiood asendajale
         * @param {string} id 
         * @param {GeneralPractitionerListSubstituteDoctorPeriod} generalPractitionerListSubstituteDoctorPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubstituteDoctorsPeriod(id: string, generalPractitionerListSubstituteDoctorPeriod: GeneralPractitionerListSubstituteDoctorPeriod, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralPractitionerListSubstituteDoctorPeriod>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubstituteDoctorsPeriod(id, generalPractitionerListSubstituteDoctorPeriod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PerearstiNimistudTeenusApi - factory interface
 * @export
 */
export const PerearstiNimistudTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PerearstiNimistudTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Lisada töötajaid
         * @param {string} id 
         * @param {GeneralPractitionerListEmployee} generalPractitionerListEmployee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployee(id: string, generalPractitionerListEmployee: GeneralPractitionerListEmployee, options?: any): AxiosPromise<GeneralPractitionerListEmployee> {
            return localVarFp.addEmployee(id, generalPractitionerListEmployee, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lisa asendusarst
         * @param {string} id 
         * @param {GeneralPractitionerListSubstituteDoctor} generalPractitionerListSubstituteDoctor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubstituteDoctor(id: string, generalPractitionerListSubstituteDoctor: GeneralPractitionerListSubstituteDoctor, options?: any): AxiosPromise<GeneralPractitionerListSubstituteDoctor> {
            return localVarFp.addSubstituteDoctor(id, generalPractitionerListSubstituteDoctor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lisage asendajale asendusperiood
         * @param {string} id 
         * @param {GeneralPractitionerListSubstituteDoctorPeriod} generalPractitionerListSubstituteDoctorPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubstituteDoctorsPeriod(id: string, generalPractitionerListSubstituteDoctorPeriod: GeneralPractitionerListSubstituteDoctorPeriod, options?: any): AxiosPromise<GeneralPractitionerListSubstituteDoctorPeriod> {
            return localVarFp.addSubstituteDoctorsPeriod(id, generalPractitionerListSubstituteDoctorPeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lisada vastuvõtuaegade
         * @param {string} id 
         * @param {GeneralPractitionerListAppointment} generalPractitionerListAppointment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointment(id: string, generalPractitionerListAppointment: GeneralPractitionerListAppointment, options?: any): AxiosPromise<GeneralPractitionerListAppointment> {
            return localVarFp.createAppointment(id, generalPractitionerListAppointment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lisada telefonikonsultatsiooniaegade
         * @param {string} id 
         * @param {GeneralPractitionerListConsultation} generalPractitionerListConsultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultation(id: string, generalPractitionerListConsultation: GeneralPractitionerListConsultation, options?: any): AxiosPromise<GeneralPractitionerListConsultation> {
            return localVarFp.createConsultation(id, generalPractitionerListConsultation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kustutage perearsti loendi väljad
         * @param {string} id 
         * @param {{ [key: string]: Array<string>; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGPListFields(id: string, requestBody: { [key: string]: Array<string>; }, options?: any): AxiosPromise<GeneralPractitionerList> {
            return localVarFp.deleteGPListFields(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lubatud töötajad
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedEmployees(id: string, options?: any): AxiosPromise<Array<HealthCareProfessional>> {
            return localVarFp.getAllowedEmployees(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPractitionerList2(options?: any): AxiosPromise<Array<GeneralPractitionerList>> {
            return localVarFp.getGeneralPractitionerList2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hankige perearstide nimekiri
         * @param {string} id 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPractitionerList3(id: string, context?: string, options?: any): AxiosPromise<GeneralPractitionerList> {
            return localVarFp.getGeneralPractitionerList3(id, context, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kohtumise aja muutmine
         * @param {string} id 
         * @param {GeneralPractitionerListAppointment} generalPractitionerListAppointment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointment(id: string, generalPractitionerListAppointment: GeneralPractitionerListAppointment, options?: any): AxiosPromise<GeneralPractitionerListAppointment> {
            return localVarFp.updateAppointment(id, generalPractitionerListAppointment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Muuda konsultatsiooni aega
         * @param {string} id 
         * @param {GeneralPractitionerListConsultation} generalPractitionerListConsultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsultation(id: string, generalPractitionerListConsultation: GeneralPractitionerListConsultation, options?: any): AxiosPromise<GeneralPractitionerListConsultation> {
            return localVarFp.updateConsultation(id, generalPractitionerListConsultation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Värskenda töötajat
         * @param {string} id 
         * @param {GeneralPractitionerListEmployee} generalPractitionerListEmployee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee(id: string, generalPractitionerListEmployee: GeneralPractitionerListEmployee, options?: any): AxiosPromise<GeneralPractitionerListEmployee> {
            return localVarFp.updateEmployee(id, generalPractitionerListEmployee, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ajakohastada üldarstide nimekirja
         * @param {string} id 
         * @param {GeneralPractitionerList} generalPractitionerList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralPractitionerList1(id: string, generalPractitionerList: GeneralPractitionerList, options?: any): AxiosPromise<GeneralPractitionerList> {
            return localVarFp.updateGeneralPractitionerList1(id, generalPractitionerList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ajakohastage asendusperiood asendajale
         * @param {string} id 
         * @param {GeneralPractitionerListSubstituteDoctorPeriod} generalPractitionerListSubstituteDoctorPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubstituteDoctorsPeriod(id: string, generalPractitionerListSubstituteDoctorPeriod: GeneralPractitionerListSubstituteDoctorPeriod, options?: any): AxiosPromise<GeneralPractitionerListSubstituteDoctorPeriod> {
            return localVarFp.updateSubstituteDoctorsPeriod(id, generalPractitionerListSubstituteDoctorPeriod, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PerearstiNimistudTeenusApi - object-oriented interface
 * @export
 * @class PerearstiNimistudTeenusApi
 * @extends {BaseAPI}
 */
export class PerearstiNimistudTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Lisada töötajaid
     * @param {string} id 
     * @param {GeneralPractitionerListEmployee} generalPractitionerListEmployee 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public addEmployee(id: string, generalPractitionerListEmployee: GeneralPractitionerListEmployee, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).addEmployee(id, generalPractitionerListEmployee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lisa asendusarst
     * @param {string} id 
     * @param {GeneralPractitionerListSubstituteDoctor} generalPractitionerListSubstituteDoctor 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public addSubstituteDoctor(id: string, generalPractitionerListSubstituteDoctor: GeneralPractitionerListSubstituteDoctor, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).addSubstituteDoctor(id, generalPractitionerListSubstituteDoctor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lisage asendajale asendusperiood
     * @param {string} id 
     * @param {GeneralPractitionerListSubstituteDoctorPeriod} generalPractitionerListSubstituteDoctorPeriod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public addSubstituteDoctorsPeriod(id: string, generalPractitionerListSubstituteDoctorPeriod: GeneralPractitionerListSubstituteDoctorPeriod, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).addSubstituteDoctorsPeriod(id, generalPractitionerListSubstituteDoctorPeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lisada vastuvõtuaegade
     * @param {string} id 
     * @param {GeneralPractitionerListAppointment} generalPractitionerListAppointment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public createAppointment(id: string, generalPractitionerListAppointment: GeneralPractitionerListAppointment, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).createAppointment(id, generalPractitionerListAppointment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lisada telefonikonsultatsiooniaegade
     * @param {string} id 
     * @param {GeneralPractitionerListConsultation} generalPractitionerListConsultation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public createConsultation(id: string, generalPractitionerListConsultation: GeneralPractitionerListConsultation, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).createConsultation(id, generalPractitionerListConsultation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kustutage perearsti loendi väljad
     * @param {string} id 
     * @param {{ [key: string]: Array<string>; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public deleteGPListFields(id: string, requestBody: { [key: string]: Array<string>; }, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).deleteGPListFields(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lubatud töötajad
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public getAllowedEmployees(id: string, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).getAllowedEmployees(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hankige perearstide nimekiri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public getGeneralPractitionerList2(options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).getGeneralPractitionerList2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hankige perearstide nimekiri
     * @param {string} id 
     * @param {string} [context] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public getGeneralPractitionerList3(id: string, context?: string, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).getGeneralPractitionerList3(id, context, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kohtumise aja muutmine
     * @param {string} id 
     * @param {GeneralPractitionerListAppointment} generalPractitionerListAppointment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public updateAppointment(id: string, generalPractitionerListAppointment: GeneralPractitionerListAppointment, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).updateAppointment(id, generalPractitionerListAppointment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Muuda konsultatsiooni aega
     * @param {string} id 
     * @param {GeneralPractitionerListConsultation} generalPractitionerListConsultation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public updateConsultation(id: string, generalPractitionerListConsultation: GeneralPractitionerListConsultation, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).updateConsultation(id, generalPractitionerListConsultation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Värskenda töötajat
     * @param {string} id 
     * @param {GeneralPractitionerListEmployee} generalPractitionerListEmployee 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public updateEmployee(id: string, generalPractitionerListEmployee: GeneralPractitionerListEmployee, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).updateEmployee(id, generalPractitionerListEmployee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ajakohastada üldarstide nimekirja
     * @param {string} id 
     * @param {GeneralPractitionerList} generalPractitionerList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public updateGeneralPractitionerList1(id: string, generalPractitionerList: GeneralPractitionerList, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).updateGeneralPractitionerList1(id, generalPractitionerList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ajakohastage asendusperiood asendajale
     * @param {string} id 
     * @param {GeneralPractitionerListSubstituteDoctorPeriod} generalPractitionerListSubstituteDoctorPeriod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerearstiNimistudTeenusApi
     */
    public updateSubstituteDoctorsPeriod(id: string, generalPractitionerListSubstituteDoctorPeriod: GeneralPractitionerListSubstituteDoctorPeriod, options?: AxiosRequestConfig) {
        return PerearstiNimistudTeenusApiFp(this.configuration).updateSubstituteDoctorsPeriod(id, generalPractitionerListSubstituteDoctorPeriod, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RakendussertifikaadiAndmeteenusApi - axios parameter creator
 * @export
 */
export const RakendussertifikaadiAndmeteenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lisab uue rakenduse sertifikaat
         * @param {ApplicationCertificate} applicationCertificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationCertificate: async (applicationCertificate: ApplicationCertificate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationCertificate' is not null or undefined
            assertParamExists('createApplicationCertificate', 'applicationCertificate', applicationCertificate)
            const localVarPath = `/applications/certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationCertificate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kustutage rakenduse sertifikaat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationCertificate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApplicationCertificate', 'id', id)
            const localVarPath = `/applications/certificates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab rakenduse sertifikaadi metaandmed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationCertificateMetadata1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/applications/certificates/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Esitage rakenduse sertifikaadi mustand
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitApplicationCertificate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submitApplicationCertificate', 'id', id)
            const localVarPath = `/applications/certificates/{id}/submit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Värskendage rakenduse sertifikaati
         * @param {string} id 
         * @param {ApplicationCertificate} applicationCertificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationCertificate: async (id: string, applicationCertificate: ApplicationCertificate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApplicationCertificate', 'id', id)
            // verify required parameter 'applicationCertificate' is not null or undefined
            assertParamExists('updateApplicationCertificate', 'applicationCertificate', applicationCertificate)
            const localVarPath = `/applications/certificates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationCertificate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RakendussertifikaadiAndmeteenusApi - functional programming interface
 * @export
 */
export const RakendussertifikaadiAndmeteenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RakendussertifikaadiAndmeteenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lisab uue rakenduse sertifikaat
         * @param {ApplicationCertificate} applicationCertificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplicationCertificate(applicationCertificate: ApplicationCertificate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApplicationCertificate(applicationCertificate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kustutage rakenduse sertifikaat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationCertificate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicationCertificate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab rakenduse sertifikaadi metaandmed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationCertificateMetadata1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertificateMetadata>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationCertificateMetadata1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Esitage rakenduse sertifikaadi mustand
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitApplicationCertificate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitApplicationCertificate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Värskendage rakenduse sertifikaati
         * @param {string} id 
         * @param {ApplicationCertificate} applicationCertificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationCertificate(id: string, applicationCertificate: ApplicationCertificate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationCertificate(id, applicationCertificate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RakendussertifikaadiAndmeteenusApi - factory interface
 * @export
 */
export const RakendussertifikaadiAndmeteenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RakendussertifikaadiAndmeteenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Lisab uue rakenduse sertifikaat
         * @param {ApplicationCertificate} applicationCertificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationCertificate(applicationCertificate: ApplicationCertificate, options?: any): AxiosPromise<ApplicationCertificate> {
            return localVarFp.createApplicationCertificate(applicationCertificate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kustutage rakenduse sertifikaat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationCertificate(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteApplicationCertificate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab rakenduse sertifikaadi metaandmed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationCertificateMetadata1(options?: any): AxiosPromise<CertificateMetadata> {
            return localVarFp.getApplicationCertificateMetadata1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Esitage rakenduse sertifikaadi mustand
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitApplicationCertificate(id: string, options?: any): AxiosPromise<ApplicationCertificate> {
            return localVarFp.submitApplicationCertificate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Värskendage rakenduse sertifikaati
         * @param {string} id 
         * @param {ApplicationCertificate} applicationCertificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationCertificate(id: string, applicationCertificate: ApplicationCertificate, options?: any): AxiosPromise<ApplicationCertificate> {
            return localVarFp.updateApplicationCertificate(id, applicationCertificate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RakendussertifikaadiAndmeteenusApi - object-oriented interface
 * @export
 * @class RakendussertifikaadiAndmeteenusApi
 * @extends {BaseAPI}
 */
export class RakendussertifikaadiAndmeteenusApi extends BaseAPI {
    /**
     * 
     * @summary Lisab uue rakenduse sertifikaat
     * @param {ApplicationCertificate} applicationCertificate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RakendussertifikaadiAndmeteenusApi
     */
    public createApplicationCertificate(applicationCertificate: ApplicationCertificate, options?: AxiosRequestConfig) {
        return RakendussertifikaadiAndmeteenusApiFp(this.configuration).createApplicationCertificate(applicationCertificate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kustutage rakenduse sertifikaat
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RakendussertifikaadiAndmeteenusApi
     */
    public deleteApplicationCertificate(id: string, options?: AxiosRequestConfig) {
        return RakendussertifikaadiAndmeteenusApiFp(this.configuration).deleteApplicationCertificate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab rakenduse sertifikaadi metaandmed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RakendussertifikaadiAndmeteenusApi
     */
    public getApplicationCertificateMetadata1(options?: AxiosRequestConfig) {
        return RakendussertifikaadiAndmeteenusApiFp(this.configuration).getApplicationCertificateMetadata1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Esitage rakenduse sertifikaadi mustand
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RakendussertifikaadiAndmeteenusApi
     */
    public submitApplicationCertificate(id: string, options?: AxiosRequestConfig) {
        return RakendussertifikaadiAndmeteenusApiFp(this.configuration).submitApplicationCertificate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Värskendage rakenduse sertifikaati
     * @param {string} id 
     * @param {ApplicationCertificate} applicationCertificate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RakendussertifikaadiAndmeteenusApi
     */
    public updateApplicationCertificate(id: string, applicationCertificate: ApplicationCertificate, options?: AxiosRequestConfig) {
        return RakendussertifikaadiAndmeteenusApiFp(this.configuration).updateApplicationCertificate(id, applicationCertificate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaotluseAndmeteTeenusApi - axios parameter creator
 * @export
 */
export const TaotluseAndmeteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Esitab taotluse ja kustutab mustandi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createApplication', 'id', id)
            const localVarPath = `/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationDraft2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApplicationDraft2', 'id', id)
            const localVarPath = `/applications/portal/draft/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kasutaja taotlused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kutsete nimed, koodid ja seotud erialad.
         * @param {'THT' | 'PRF' | 'SPT' | 'HEALTHCARE_PROFESSIONAL'} [occupationGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOccupations1: async (occupationGroup?: 'THT' | 'PRF' | 'SPT' | 'HEALTHCARE_PROFESSIONAL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/applications/occupations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (occupationGroup !== undefined) {
                localVarQueryParameter['occupation_group'] = occupationGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOccupationApplications1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/applications/occupations/applied`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSpecializationApplications1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/applications/specializations/applied`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab iseteeninduse pooliku avalduse.
         * @param {string} id 
         * @param {ApplicationDraft} applicationDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalApplicationDraft: async (id: string, applicationDraft: ApplicationDraft, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('savePortalApplicationDraft', 'id', id)
            // verify required parameter 'applicationDraft' is not null or undefined
            assertParamExists('savePortalApplicationDraft', 'applicationDraft', applicationDraft)
            const localVarPath = `/applications/portal/draft/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationDraft, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Loob iseteeninduse pooliku avalduse.
         * @param {ApplicationDraft} applicationDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalApplicationDraft1: async (applicationDraft: ApplicationDraft, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationDraft' is not null or undefined
            assertParamExists('savePortalApplicationDraft1', 'applicationDraft', applicationDraft)
            const localVarPath = `/applications/portal/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationDraft, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab taotluse hariduse info.
         * @param {string} id 
         * @param {EducationInfo} educationInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEducationInfo1: async (id: string, educationInfo: EducationInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEducationInfo1', 'id', id)
            // verify required parameter 'educationInfo' is not null or undefined
            assertParamExists('updateEducationInfo1', 'educationInfo', educationInfo)
            const localVarPath = `/applications/{id}/education-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaotluseAndmeteTeenusApi - functional programming interface
 * @export
 */
export const TaotluseAndmeteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaotluseAndmeteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Esitab taotluse ja kustutab mustandi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplication(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationDraft2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicationDraft2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kasutaja taotlused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplication2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserApplications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplication2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kutsete nimed, koodid ja seotud erialad.
         * @param {'THT' | 'PRF' | 'SPT' | 'HEALTHCARE_PROFESSIONAL'} [occupationGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOccupations1(occupationGroup?: 'THT' | 'PRF' | 'SPT' | 'HEALTHCARE_PROFESSIONAL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Occupation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOccupations1(occupationGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserOccupationApplications1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppliedApplications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserOccupationApplications1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSpecializationApplications1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppliedApplications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSpecializationApplications1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab iseteeninduse pooliku avalduse.
         * @param {string} id 
         * @param {ApplicationDraft} applicationDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePortalApplicationDraft(id: string, applicationDraft: ApplicationDraft, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDraft>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePortalApplicationDraft(id, applicationDraft, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Loob iseteeninduse pooliku avalduse.
         * @param {ApplicationDraft} applicationDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePortalApplicationDraft1(applicationDraft: ApplicationDraft, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDraft>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePortalApplicationDraft1(applicationDraft, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab taotluse hariduse info.
         * @param {string} id 
         * @param {EducationInfo} educationInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEducationInfo1(id: string, educationInfo: EducationInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEducationInfo1(id, educationInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaotluseAndmeteTeenusApi - factory interface
 * @export
 */
export const TaotluseAndmeteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaotluseAndmeteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Esitab taotluse ja kustutab mustandi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication(id: string, options?: any): AxiosPromise<Application> {
            return localVarFp.createApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationDraft2(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteApplicationDraft2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kasutaja taotlused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication2(options?: any): AxiosPromise<UserApplications> {
            return localVarFp.getApplication2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kutsete nimed, koodid ja seotud erialad.
         * @param {'THT' | 'PRF' | 'SPT' | 'HEALTHCARE_PROFESSIONAL'} [occupationGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOccupations1(occupationGroup?: 'THT' | 'PRF' | 'SPT' | 'HEALTHCARE_PROFESSIONAL', options?: any): AxiosPromise<Array<Occupation>> {
            return localVarFp.getOccupations1(occupationGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOccupationApplications1(options?: any): AxiosPromise<AppliedApplications> {
            return localVarFp.getUserOccupationApplications1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSpecializationApplications1(options?: any): AxiosPromise<AppliedApplications> {
            return localVarFp.getUserSpecializationApplications1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab iseteeninduse pooliku avalduse.
         * @param {string} id 
         * @param {ApplicationDraft} applicationDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalApplicationDraft(id: string, applicationDraft: ApplicationDraft, options?: any): AxiosPromise<ApplicationDraft> {
            return localVarFp.savePortalApplicationDraft(id, applicationDraft, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Loob iseteeninduse pooliku avalduse.
         * @param {ApplicationDraft} applicationDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalApplicationDraft1(applicationDraft: ApplicationDraft, options?: any): AxiosPromise<ApplicationDraft> {
            return localVarFp.savePortalApplicationDraft1(applicationDraft, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab taotluse hariduse info.
         * @param {string} id 
         * @param {EducationInfo} educationInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEducationInfo1(id: string, educationInfo: EducationInfo, options?: any): AxiosPromise<string> {
            return localVarFp.updateEducationInfo1(id, educationInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaotluseAndmeteTeenusApi - object-oriented interface
 * @export
 * @class TaotluseAndmeteTeenusApi
 * @extends {BaseAPI}
 */
export class TaotluseAndmeteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Esitab taotluse ja kustutab mustandi.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaotluseAndmeteTeenusApi
     */
    public createApplication(id: string, options?: AxiosRequestConfig) {
        return TaotluseAndmeteTeenusApiFp(this.configuration).createApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kustutab taotluse mustandi.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaotluseAndmeteTeenusApi
     */
    public deleteApplicationDraft2(id: string, options?: AxiosRequestConfig) {
        return TaotluseAndmeteTeenusApiFp(this.configuration).deleteApplicationDraft2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kasutaja taotlused.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaotluseAndmeteTeenusApi
     */
    public getApplication2(options?: AxiosRequestConfig) {
        return TaotluseAndmeteTeenusApiFp(this.configuration).getApplication2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kutsete nimed, koodid ja seotud erialad.
     * @param {'THT' | 'PRF' | 'SPT' | 'HEALTHCARE_PROFESSIONAL'} [occupationGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaotluseAndmeteTeenusApi
     */
    public getOccupations1(occupationGroup?: 'THT' | 'PRF' | 'SPT' | 'HEALTHCARE_PROFESSIONAL', options?: AxiosRequestConfig) {
        return TaotluseAndmeteTeenusApiFp(this.configuration).getOccupations1(occupationGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaotluseAndmeteTeenusApi
     */
    public getUserOccupationApplications1(options?: AxiosRequestConfig) {
        return TaotluseAndmeteTeenusApiFp(this.configuration).getUserOccupationApplications1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kasutajale omistatud kutsed ja erialad ning taotluses olevad erialad.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaotluseAndmeteTeenusApi
     */
    public getUserSpecializationApplications1(options?: AxiosRequestConfig) {
        return TaotluseAndmeteTeenusApiFp(this.configuration).getUserSpecializationApplications1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab iseteeninduse pooliku avalduse.
     * @param {string} id 
     * @param {ApplicationDraft} applicationDraft 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaotluseAndmeteTeenusApi
     */
    public savePortalApplicationDraft(id: string, applicationDraft: ApplicationDraft, options?: AxiosRequestConfig) {
        return TaotluseAndmeteTeenusApiFp(this.configuration).savePortalApplicationDraft(id, applicationDraft, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Loob iseteeninduse pooliku avalduse.
     * @param {ApplicationDraft} applicationDraft 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaotluseAndmeteTeenusApi
     */
    public savePortalApplicationDraft1(applicationDraft: ApplicationDraft, options?: AxiosRequestConfig) {
        return TaotluseAndmeteTeenusApiFp(this.configuration).savePortalApplicationDraft1(applicationDraft, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab taotluse hariduse info.
     * @param {string} id 
     * @param {EducationInfo} educationInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaotluseAndmeteTeenusApi
     */
    public updateEducationInfo1(id: string, educationInfo: EducationInfo, options?: AxiosRequestConfig) {
        return TaotluseAndmeteTeenusApiFp(this.configuration).updateEducationInfo1(id, educationInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeenusteAndmeteTeenusApi - axios parameter creator
 * @export
 */
export const TeenusteAndmeteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab listi teenustest kindla staatuse ja tüübiga/tüüpidega.
         * @param {'ACTIVE' | 'INACTIVE' | 'VIRTUAL'} [status] 
         * @param {Array<'AMBULATORY' | 'INPATIENT' | 'BOTH' | 'NOT_APPLICABLE'>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServicesByStatusAndType: async (status?: 'ACTIVE' | 'INACTIVE' | 'VIRTUAL', type?: Array<'AMBULATORY' | 'INPATIENT' | 'BOTH' | 'NOT_APPLICABLE'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeenusteAndmeteTeenusApi - functional programming interface
 * @export
 */
export const TeenusteAndmeteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeenusteAndmeteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab listi teenustest kindla staatuse ja tüübiga/tüüpidega.
         * @param {'ACTIVE' | 'INACTIVE' | 'VIRTUAL'} [status] 
         * @param {Array<'AMBULATORY' | 'INPATIENT' | 'BOTH' | 'NOT_APPLICABLE'>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServicesByStatusAndType(status?: 'ACTIVE' | 'INACTIVE' | 'VIRTUAL', type?: Array<'AMBULATORY' | 'INPATIENT' | 'BOTH' | 'NOT_APPLICABLE'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Service>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServicesByStatusAndType(status, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeenusteAndmeteTeenusApi - factory interface
 * @export
 */
export const TeenusteAndmeteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeenusteAndmeteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab listi teenustest kindla staatuse ja tüübiga/tüüpidega.
         * @param {'ACTIVE' | 'INACTIVE' | 'VIRTUAL'} [status] 
         * @param {Array<'AMBULATORY' | 'INPATIENT' | 'BOTH' | 'NOT_APPLICABLE'>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServicesByStatusAndType(status?: 'ACTIVE' | 'INACTIVE' | 'VIRTUAL', type?: Array<'AMBULATORY' | 'INPATIENT' | 'BOTH' | 'NOT_APPLICABLE'>, options?: any): AxiosPromise<Array<Service>> {
            return localVarFp.getServicesByStatusAndType(status, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeenusteAndmeteTeenusApi - object-oriented interface
 * @export
 * @class TeenusteAndmeteTeenusApi
 * @extends {BaseAPI}
 */
export class TeenusteAndmeteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab listi teenustest kindla staatuse ja tüübiga/tüüpidega.
     * @param {'ACTIVE' | 'INACTIVE' | 'VIRTUAL'} [status] 
     * @param {Array<'AMBULATORY' | 'INPATIENT' | 'BOTH' | 'NOT_APPLICABLE'>} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeenusteAndmeteTeenusApi
     */
    public getServicesByStatusAndType(status?: 'ACTIVE' | 'INACTIVE' | 'VIRTUAL', type?: Array<'AMBULATORY' | 'INPATIENT' | 'BOTH' | 'NOT_APPLICABLE'>, options?: AxiosRequestConfig) {
        return TeenusteAndmeteTeenusApiFp(this.configuration).getServicesByStatusAndType(status, type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeenusteTaastamisteTaotlusteTeenusApi - axios parameter creator
 * @export
 */
export const TeenusteTaastamisteTaotlusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab tegevusloa teenuse taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceRestoration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getServiceRestoration', 'id', id)
            const localVarPath = `/portal/activity-licenses/services/restorations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeenusteTaastamisteTaotlusteTeenusApi - functional programming interface
 * @export
 */
export const TeenusteTaastamisteTaotlusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeenusteTaastamisteTaotlusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa teenuse taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceRestoration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseRestoration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceRestoration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeenusteTaastamisteTaotlusteTeenusApi - factory interface
 * @export
 */
export const TeenusteTaastamisteTaotlusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeenusteTaastamisteTaotlusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa teenuse taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceRestoration(id: string, options?: any): AxiosPromise<ActivityLicenseRestoration> {
            return localVarFp.getServiceRestoration(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeenusteTaastamisteTaotlusteTeenusApi - object-oriented interface
 * @export
 * @class TeenusteTaastamisteTaotlusteTeenusApi
 * @extends {BaseAPI}
 */
export class TeenusteTaastamisteTaotlusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab tegevusloa teenuse taastamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeenusteTaastamisteTaotlusteTeenusApi
     */
    public getServiceRestoration(id: string, options?: AxiosRequestConfig) {
        return TeenusteTaastamisteTaotlusteTeenusApiFp(this.configuration).getServiceRestoration(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApi - axios parameter creator
 * @export
 */
export const TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseInvalidation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicenseInvalidation', 'id', id)
            const localVarPath = `/portal/activity-licenses/locations/invalidations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-location-invalidation.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateActivityLicense: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invalidateActivityLicense', 'id', id)
            const localVarPath = `/portal/activity-licenses/locations/invalidations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApi - functional programming interface
 * @export
 */
export const TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicenseInvalidation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLocationInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicenseInvalidation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-location-invalidation.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invalidateActivityLicense(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLocationInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invalidateActivityLicense(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApi - factory interface
 * @export
 */
export const TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseInvalidation(id: string, options?: any): AxiosPromise<ActivityLocationInvalidation> {
            return localVarFp.getActivityLicenseInvalidation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-location-invalidation.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateActivityLicense(id: string, options?: any): AxiosPromise<ActivityLocationInvalidation> {
            return localVarFp.invalidateActivityLicense(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApi - object-oriented interface
 * @export
 * @class TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApi
 * @extends {BaseAPI}
 */
export class TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApi
     */
    public getActivityLicenseInvalidation(id: string, options?: AxiosRequestConfig) {
        return TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApiFp(this.configuration).getActivityLicenseInvalidation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.post-portal-activity-location-invalidation.description}
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApi
     */
    public invalidateActivityLicense(id: string, options?: AxiosRequestConfig) {
        return TegevuskohtadeKehtetuksTunnistamiseTeenusIseteenindusesApiFp(this.configuration).invalidateActivityLicense(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TegevuskohtadeTaastamisteTaotlusteTeenusApi - axios parameter creator
 * @export
 */
export const TegevuskohtadeTaastamisteTaotlusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab tegevuskoha taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationRestoration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocationRestoration', 'id', id)
            const localVarPath = `/portal/activity-licenses/locations/restorations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TegevuskohtadeTaastamisteTaotlusteTeenusApi - functional programming interface
 * @export
 */
export const TegevuskohtadeTaastamisteTaotlusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TegevuskohtadeTaastamisteTaotlusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevuskoha taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationRestoration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseRestoration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationRestoration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TegevuskohtadeTaastamisteTaotlusteTeenusApi - factory interface
 * @export
 */
export const TegevuskohtadeTaastamisteTaotlusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TegevuskohtadeTaastamisteTaotlusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevuskoha taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationRestoration(id: string, options?: any): AxiosPromise<ActivityLicenseRestoration> {
            return localVarFp.getLocationRestoration(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TegevuskohtadeTaastamisteTaotlusteTeenusApi - object-oriented interface
 * @export
 * @class TegevuskohtadeTaastamisteTaotlusteTeenusApi
 * @extends {BaseAPI}
 */
export class TegevuskohtadeTaastamisteTaotlusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab tegevuskoha taastamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuskohtadeTaastamisteTaotlusteTeenusApi
     */
    public getLocationRestoration(id: string, options?: AxiosRequestConfig) {
        return TegevuskohtadeTaastamisteTaotlusteTeenusApiFp(this.configuration).getLocationRestoration(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApi - axios parameter creator
 * @export
 */
export const TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseInvalidation1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicenseInvalidation1', 'id', id)
            const localVarPath = `/portal/activity-licenses/invalidations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-license-suspension.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateActivityLicense1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invalidateActivityLicense1', 'id', id)
            const localVarPath = `/portal/activity-licenses/invalidations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApi - functional programming interface
 * @export
 */
export const TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicenseInvalidation1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicenseInvalidation1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-license-suspension.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invalidateActivityLicense1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseInvalidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invalidateActivityLicense1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApi - factory interface
 * @export
 */
export const TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseInvalidation1(id: string, options?: any): AxiosPromise<ActivityLicenseInvalidation> {
            return localVarFp.getActivityLicenseInvalidation1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-license-suspension.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateActivityLicense1(id: string, options?: any): AxiosPromise<ActivityLicenseInvalidation> {
            return localVarFp.invalidateActivityLicense1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApi - object-oriented interface
 * @export
 * @class TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApi
 * @extends {BaseAPI}
 */
export class TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab tegevusloa kehtetuks tunnistamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApi
     */
    public getActivityLicenseInvalidation1(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApiFp(this.configuration).getActivityLicenseInvalidation1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.post-portal-activity-license-suspension.description}
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApi
     */
    public invalidateActivityLicense1(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApiFp(this.configuration).invalidateActivityLicense1(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TegevuslubadeLevtmisteTeenusApi - axios parameter creator
 * @export
 */
export const TegevuslubadeLevtmisteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueApplicationProceeding2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('continueApplicationProceeding2', 'id', id)
            const localVarPath = `/license-acquisitions/{id}/continue-proceeding`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kustutab ülevõtmise taotluse id järgi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityLicenseApplication: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteActivityLicenseApplication', 'id', id)
            const localVarPath = `/license-acquisitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab ülevõtmise taotluse id järgi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalActivityLicenseApplication: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPortalActivityLicenseApplication', 'id', id)
            const localVarPath = `/license-acquisitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salvestab või uuendab tegevusloa ülevõtmise taotluse.
         * @param {LicenseAcquisition} licenseAcquisition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveApplication: async (licenseAcquisition: LicenseAcquisition, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseAcquisition' is not null or undefined
            assertParamExists('saveApplication', 'licenseAcquisition', licenseAcquisition)
            const localVarPath = `/license-acquisitions/portal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenseAcquisition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Esitab tegevusloa ülevõtmise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPortalActivityLicenseApplication: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submitPortalActivityLicenseApplication', 'id', id)
            const localVarPath = `/license-acquisitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salvestab või uuendab tegevusloa ülevõtmise taotluse.
         * @param {LicenseAcquisition} licenseAcquisition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication: async (licenseAcquisition: LicenseAcquisition, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseAcquisition' is not null or undefined
            assertParamExists('updateApplication', 'licenseAcquisition', licenseAcquisition)
            const localVarPath = `/license-acquisitions/portal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenseAcquisition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salvestab või uuendab tegevusloa ülevõtmise taotluse.
         * @param {LicenseAcquisition} licenseAcquisition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithoutValidation1: async (licenseAcquisition: LicenseAcquisition, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseAcquisition' is not null or undefined
            assertParamExists('updateWithoutValidation1', 'licenseAcquisition', licenseAcquisition)
            const localVarPath = `/license-acquisitions/portal/backward`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenseAcquisition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TegevuslubadeLevtmisteTeenusApi - functional programming interface
 * @export
 */
export const TegevuslubadeLevtmisteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TegevuslubadeLevtmisteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continueApplicationProceeding2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.continueApplicationProceeding2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kustutab ülevõtmise taotluse id järgi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivityLicenseApplication(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivityLicenseApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab ülevõtmise taotluse id järgi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortalActivityLicenseApplication(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortalActivityLicenseApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Salvestab või uuendab tegevusloa ülevõtmise taotluse.
         * @param {LicenseAcquisition} licenseAcquisition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveApplication(licenseAcquisition: LicenseAcquisition, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveApplication(licenseAcquisition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Esitab tegevusloa ülevõtmise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitPortalActivityLicenseApplication(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitPortalActivityLicenseApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Salvestab või uuendab tegevusloa ülevõtmise taotluse.
         * @param {LicenseAcquisition} licenseAcquisition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplication(licenseAcquisition: LicenseAcquisition, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplication(licenseAcquisition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Salvestab või uuendab tegevusloa ülevõtmise taotluse.
         * @param {LicenseAcquisition} licenseAcquisition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithoutValidation1(licenseAcquisition: LicenseAcquisition, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseAcquisition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithoutValidation1(licenseAcquisition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TegevuslubadeLevtmisteTeenusApi - factory interface
 * @export
 */
export const TegevuslubadeLevtmisteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TegevuslubadeLevtmisteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueApplicationProceeding2(id: string, options?: any): AxiosPromise<LicenseAcquisition> {
            return localVarFp.continueApplicationProceeding2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kustutab ülevõtmise taotluse id järgi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityLicenseApplication(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteActivityLicenseApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab ülevõtmise taotluse id järgi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalActivityLicenseApplication(id: string, options?: any): AxiosPromise<LicenseAcquisition> {
            return localVarFp.getPortalActivityLicenseApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Salvestab või uuendab tegevusloa ülevõtmise taotluse.
         * @param {LicenseAcquisition} licenseAcquisition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveApplication(licenseAcquisition: LicenseAcquisition, options?: any): AxiosPromise<LicenseAcquisition> {
            return localVarFp.saveApplication(licenseAcquisition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Esitab tegevusloa ülevõtmise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPortalActivityLicenseApplication(id: string, options?: any): AxiosPromise<LicenseAcquisition> {
            return localVarFp.submitPortalActivityLicenseApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Salvestab või uuendab tegevusloa ülevõtmise taotluse.
         * @param {LicenseAcquisition} licenseAcquisition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication(licenseAcquisition: LicenseAcquisition, options?: any): AxiosPromise<LicenseAcquisition> {
            return localVarFp.updateApplication(licenseAcquisition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Salvestab või uuendab tegevusloa ülevõtmise taotluse.
         * @param {LicenseAcquisition} licenseAcquisition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithoutValidation1(licenseAcquisition: LicenseAcquisition, options?: any): AxiosPromise<LicenseAcquisition> {
            return localVarFp.updateWithoutValidation1(licenseAcquisition, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TegevuslubadeLevtmisteTeenusApi - object-oriented interface
 * @export
 * @class TegevuslubadeLevtmisteTeenusApi
 * @extends {BaseAPI}
 */
export class TegevuslubadeLevtmisteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Jätkab taotluse menetlust.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeLevtmisteTeenusApi
     */
    public continueApplicationProceeding2(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeLevtmisteTeenusApiFp(this.configuration).continueApplicationProceeding2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kustutab ülevõtmise taotluse id järgi.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeLevtmisteTeenusApi
     */
    public deleteActivityLicenseApplication(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeLevtmisteTeenusApiFp(this.configuration).deleteActivityLicenseApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab ülevõtmise taotluse id järgi.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeLevtmisteTeenusApi
     */
    public getPortalActivityLicenseApplication(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeLevtmisteTeenusApiFp(this.configuration).getPortalActivityLicenseApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Salvestab või uuendab tegevusloa ülevõtmise taotluse.
     * @param {LicenseAcquisition} licenseAcquisition 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeLevtmisteTeenusApi
     */
    public saveApplication(licenseAcquisition: LicenseAcquisition, options?: AxiosRequestConfig) {
        return TegevuslubadeLevtmisteTeenusApiFp(this.configuration).saveApplication(licenseAcquisition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Esitab tegevusloa ülevõtmise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeLevtmisteTeenusApi
     */
    public submitPortalActivityLicenseApplication(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeLevtmisteTeenusApiFp(this.configuration).submitPortalActivityLicenseApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Salvestab või uuendab tegevusloa ülevõtmise taotluse.
     * @param {LicenseAcquisition} licenseAcquisition 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeLevtmisteTeenusApi
     */
    public updateApplication(licenseAcquisition: LicenseAcquisition, options?: AxiosRequestConfig) {
        return TegevuslubadeLevtmisteTeenusApiFp(this.configuration).updateApplication(licenseAcquisition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Salvestab või uuendab tegevusloa ülevõtmise taotluse.
     * @param {LicenseAcquisition} licenseAcquisition 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeLevtmisteTeenusApi
     */
    public updateWithoutValidation1(licenseAcquisition: LicenseAcquisition, options?: AxiosRequestConfig) {
        return TegevuslubadeLevtmisteTeenusApiFp(this.configuration).updateWithoutValidation1(licenseAcquisition, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TegevuslubadePeatamisteTaotlusteTeenusApi - axios parameter creator
 * @export
 */
export const TegevuslubadePeatamisteTaotlusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab tegevusloa peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseSuspension1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLicenseSuspension1', 'id', id)
            const localVarPath = `/activity-licenses/suspensions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-license-suspension.description}
         * @param {string} id 
         * @param {Suspension} suspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateActivityLicenseSuspension1: async (id: string, suspension: Suspension, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('initiateActivityLicenseSuspension1', 'id', id)
            // verify required parameter 'suspension' is not null or undefined
            assertParamExists('initiateActivityLicenseSuspension1', 'suspension', suspension)
            const localVarPath = `/activity-licenses/portal/suspend/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suspension, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TegevuslubadePeatamisteTaotlusteTeenusApi - functional programming interface
 * @export
 */
export const TegevuslubadePeatamisteTaotlusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TegevuslubadePeatamisteTaotlusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseSuspension1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseSuspension1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-license-suspension.description}
         * @param {string} id 
         * @param {Suspension} suspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateActivityLicenseSuspension1(id: string, suspension: Suspension, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Suspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateActivityLicenseSuspension1(id, suspension, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TegevuslubadePeatamisteTaotlusteTeenusApi - factory interface
 * @export
 */
export const TegevuslubadePeatamisteTaotlusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TegevuslubadePeatamisteTaotlusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseSuspension1(id: string, options?: any): AxiosPromise<ActivityLicenseSuspension> {
            return localVarFp.getLicenseSuspension1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-license-suspension.description}
         * @param {string} id 
         * @param {Suspension} suspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateActivityLicenseSuspension1(id: string, suspension: Suspension, options?: any): AxiosPromise<Suspension> {
            return localVarFp.initiateActivityLicenseSuspension1(id, suspension, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TegevuslubadePeatamisteTaotlusteTeenusApi - object-oriented interface
 * @export
 * @class TegevuslubadePeatamisteTaotlusteTeenusApi
 * @extends {BaseAPI}
 */
export class TegevuslubadePeatamisteTaotlusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab tegevusloa peatamise.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadePeatamisteTaotlusteTeenusApi
     */
    public getLicenseSuspension1(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadePeatamisteTaotlusteTeenusApiFp(this.configuration).getLicenseSuspension1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.post-portal-activity-license-suspension.description}
     * @param {string} id 
     * @param {Suspension} suspension 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadePeatamisteTaotlusteTeenusApi
     */
    public initiateActivityLicenseSuspension1(id: string, suspension: Suspension, options?: AxiosRequestConfig) {
        return TegevuslubadePeatamisteTaotlusteTeenusApiFp(this.configuration).initiateActivityLicenseSuspension1(id, suspension, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TegevuslubadeTaastamisteTaotlusteTeenusApi - axios parameter creator
 * @export
 */
export const TegevuslubadeTaastamisteTaotlusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab tegevusloa taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseSuspension: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLicenseSuspension', 'id', id)
            const localVarPath = `/portal/activity-licenses/restorations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TegevuslubadeTaastamisteTaotlusteTeenusApi - functional programming interface
 * @export
 */
export const TegevuslubadeTaastamisteTaotlusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TegevuslubadeTaastamisteTaotlusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseSuspension(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseRestoration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseSuspension(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TegevuslubadeTaastamisteTaotlusteTeenusApi - factory interface
 * @export
 */
export const TegevuslubadeTaastamisteTaotlusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TegevuslubadeTaastamisteTaotlusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab tegevusloa taastamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseSuspension(id: string, options?: any): AxiosPromise<ActivityLicenseRestoration> {
            return localVarFp.getLicenseSuspension(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TegevuslubadeTaastamisteTaotlusteTeenusApi - object-oriented interface
 * @export
 * @class TegevuslubadeTaastamisteTaotlusteTeenusApi
 * @extends {BaseAPI}
 */
export class TegevuslubadeTaastamisteTaotlusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab tegevusloa taastamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTaastamisteTaotlusteTeenusApi
     */
    public getLicenseSuspension(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTaastamisteTaotlusteTeenusApiFp(this.configuration).getLicenseSuspension(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TegevuslubadeTaotlusteTeenusApi - axios parameter creator
 * @export
 */
export const TegevuslubadeTaotlusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lisab töötajad teenusele
         * @param {string} id 
         * @param {Array<HealthCareProfessional>} healthCareProfessional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployees2: async (id: string, healthCareProfessional: Array<HealthCareProfessional>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addEmployees2', 'id', id)
            // verify required parameter 'healthCareProfessional' is not null or undefined
            assertParamExists('addEmployees2', 'healthCareProfessional', healthCareProfessional)
            const localVarPath = `/activity-license-applications/service/{id}/employees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthCareProfessional, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueApplicationProceeding3: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('continueApplicationProceeding3', 'id', id)
            const localVarPath = `/activity-license-applications/{id}/continue-proceeding`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityLicenseApplication1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteActivityLicenseApplication1', 'id', id)
            const localVarPath = `/activity-license-applications/portal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa id järgi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseApplication: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicenseApplication', 'id', id)
            const localVarPath = `/activity-license-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab ettevõttega seotud taotlused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyApplications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activity-license-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Eemaldab töötaja teenuselt
         * @param {string} id 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEmployee1: async (id: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeEmployee1', 'id', id)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('removeEmployee1', 'employeeId', employeeId)
            const localVarPath = `/activity-license-applications/service/{id}/employees/{employeeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.put-portal-activity-license.description}
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalActivityLicenseApplication: async (id: string, activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('savePortalActivityLicenseApplication', 'id', id)
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('savePortalActivityLicenseApplication', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/activity-license-applications/portal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-license.description}
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalActivityLicenseApplication1: async (activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('savePortalActivityLicenseApplication1', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/activity-license-applications/portal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.put-portal-activity-license-without-validations.description}
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalActivityLicenseApplicationWithoutValidations: async (id: string, activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('savePortalActivityLicenseApplicationWithoutValidations', 'id', id)
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('savePortalActivityLicenseApplicationWithoutValidations', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/activity-license-applications/portal/backward/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.post-submit-portal-activity-license.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPortalActivityLicenseApplication1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submitPortalActivityLicenseApplication1', 'id', id)
            const localVarPath = `/activity-license-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TegevuslubadeTaotlusteTeenusApi - functional programming interface
 * @export
 */
export const TegevuslubadeTaotlusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TegevuslubadeTaotlusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lisab töötajad teenusele
         * @param {string} id 
         * @param {Array<HealthCareProfessional>} healthCareProfessional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEmployees2(id: string, healthCareProfessional: Array<HealthCareProfessional>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEmployees2(id, healthCareProfessional, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continueApplicationProceeding3(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.continueApplicationProceeding3(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivityLicenseApplication1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivityLicenseApplication1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa id järgi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicenseApplication(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicenseApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab ettevõttega seotud taotlused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyApplications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyApplication>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyApplications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Eemaldab töötaja teenuselt
         * @param {string} id 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEmployee1(id: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEmployee1(id, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.put-portal-activity-license.description}
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePortalActivityLicenseApplication(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePortalActivityLicenseApplication(id, activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-license.description}
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePortalActivityLicenseApplication1(activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePortalActivityLicenseApplication1(activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.put-portal-activity-license-without-validations.description}
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePortalActivityLicenseApplicationWithoutValidations(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePortalActivityLicenseApplicationWithoutValidations(id, activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.post-submit-portal-activity-license.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitPortalActivityLicenseApplication1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitPortalActivityLicenseApplication1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TegevuslubadeTaotlusteTeenusApi - factory interface
 * @export
 */
export const TegevuslubadeTaotlusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TegevuslubadeTaotlusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Lisab töötajad teenusele
         * @param {string} id 
         * @param {Array<HealthCareProfessional>} healthCareProfessional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployees2(id: string, healthCareProfessional: Array<HealthCareProfessional>, options?: any): AxiosPromise<ActivityLicenseService> {
            return localVarFp.addEmployees2(id, healthCareProfessional, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Jätkab taotluse menetlust.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueApplicationProceeding3(id: string, options?: any): AxiosPromise<DetailedActivityLicenseApplication> {
            return localVarFp.continueApplicationProceeding3(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kustutab taotluse mustandi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityLicenseApplication1(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteActivityLicenseApplication1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa id järgi.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicenseApplication(id: string, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.getActivityLicenseApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab ettevõttega seotud taotlused.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyApplications(options?: any): AxiosPromise<Array<CompanyApplication>> {
            return localVarFp.getCompanyApplications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Eemaldab töötaja teenuselt
         * @param {string} id 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEmployee1(id: string, employeeId: string, options?: any): AxiosPromise<string> {
            return localVarFp.removeEmployee1(id, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.put-portal-activity-license.description}
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalActivityLicenseApplication(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.savePortalActivityLicenseApplication(id, activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-license.description}
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalActivityLicenseApplication1(activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.savePortalActivityLicenseApplication1(activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.put-portal-activity-license-without-validations.description}
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalActivityLicenseApplicationWithoutValidations(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.savePortalActivityLicenseApplicationWithoutValidations(id, activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.post-submit-portal-activity-license.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPortalActivityLicenseApplication1(id: string, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.submitPortalActivityLicenseApplication1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TegevuslubadeTaotlusteTeenusApi - object-oriented interface
 * @export
 * @class TegevuslubadeTaotlusteTeenusApi
 * @extends {BaseAPI}
 */
export class TegevuslubadeTaotlusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Lisab töötajad teenusele
     * @param {string} id 
     * @param {Array<HealthCareProfessional>} healthCareProfessional 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTaotlusteTeenusApi
     */
    public addEmployees2(id: string, healthCareProfessional: Array<HealthCareProfessional>, options?: AxiosRequestConfig) {
        return TegevuslubadeTaotlusteTeenusApiFp(this.configuration).addEmployees2(id, healthCareProfessional, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Jätkab taotluse menetlust.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTaotlusteTeenusApi
     */
    public continueApplicationProceeding3(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTaotlusteTeenusApiFp(this.configuration).continueApplicationProceeding3(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kustutab taotluse mustandi.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTaotlusteTeenusApi
     */
    public deleteActivityLicenseApplication1(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTaotlusteTeenusApiFp(this.configuration).deleteActivityLicenseApplication1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa id järgi.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTaotlusteTeenusApi
     */
    public getActivityLicenseApplication(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTaotlusteTeenusApiFp(this.configuration).getActivityLicenseApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab ettevõttega seotud taotlused.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTaotlusteTeenusApi
     */
    public getCompanyApplications(options?: AxiosRequestConfig) {
        return TegevuslubadeTaotlusteTeenusApiFp(this.configuration).getCompanyApplications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Eemaldab töötaja teenuselt
     * @param {string} id 
     * @param {string} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTaotlusteTeenusApi
     */
    public removeEmployee1(id: string, employeeId: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTaotlusteTeenusApiFp(this.configuration).removeEmployee1(id, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.put-portal-activity-license.description}
     * @param {string} id 
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTaotlusteTeenusApi
     */
    public savePortalActivityLicenseApplication(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return TegevuslubadeTaotlusteTeenusApiFp(this.configuration).savePortalActivityLicenseApplication(id, activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.post-portal-activity-license.description}
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTaotlusteTeenusApi
     */
    public savePortalActivityLicenseApplication1(activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return TegevuslubadeTaotlusteTeenusApiFp(this.configuration).savePortalActivityLicenseApplication1(activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.put-portal-activity-license-without-validations.description}
     * @param {string} id 
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTaotlusteTeenusApi
     */
    public savePortalActivityLicenseApplicationWithoutValidations(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return TegevuslubadeTaotlusteTeenusApiFp(this.configuration).savePortalActivityLicenseApplicationWithoutValidations(id, activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.post-submit-portal-activity-license.description}
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTaotlusteTeenusApi
     */
    public submitPortalActivityLicenseApplication1(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTaotlusteTeenusApiFp(this.configuration).submitPortalActivityLicenseApplication1(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TegevuslubadeTeenusApi - axios parameter creator
 * @export
 */
export const TegevuslubadeTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lisab spetsialisti teenusele
         * @param {string} id 
         * @param {string} specialistCodeId 
         * @param {SpecialistData} specialistData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployees: async (id: string, specialistCodeId: string, specialistData: SpecialistData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addEmployees', 'id', id)
            // verify required parameter 'specialistCodeId' is not null or undefined
            assertParamExists('addEmployees', 'specialistCodeId', specialistCodeId)
            // verify required parameter 'specialistData' is not null or undefined
            assertParamExists('addEmployees', 'specialistData', specialistData)
            const localVarPath = `/activity-licenses/portal/service/{id}/specialists/{specialistCodeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"specialistCodeId"}}`, encodeURIComponent(String(specialistCodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(specialistData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lisab töötajad teenusele
         * @param {string} id 
         * @param {Array<HealthCareProfessional>} healthCareProfessional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployees1: async (id: string, healthCareProfessional: Array<HealthCareProfessional>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addEmployees1', 'id', id)
            // verify required parameter 'healthCareProfessional' is not null or undefined
            assertParamExists('addEmployees1', 'healthCareProfessional', healthCareProfessional)
            const localVarPath = `/activity-licenses/portal/service/{id}/employees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthCareProfessional, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lisab töötajad teenusele
         * @param {string} id 
         * @param {Array<HealthCareProfessional>} healthCareProfessional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployeesToPharmacyLicense: async (id: string, healthCareProfessional: Array<HealthCareProfessional>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addEmployeesToPharmacyLicense', 'id', id)
            // verify required parameter 'healthCareProfessional' is not null or undefined
            assertParamExists('addEmployeesToPharmacyLicense', 'healthCareProfessional', healthCareProfessional)
            const localVarPath = `/activity-licenses/portal/pharmacy/{id}/employees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthCareProfessional, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab aktiivsed spetsialistide koodid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveSpecialistCodes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activity-licenses/specialist/active-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa detailse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicense3: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityLicense3', 'id', id)
            const localVarPath = `/activity-licenses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kõik aktiivsed tegevuslitsentsid, otsides ettevõtte registrikoodi järgi.
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByCompanyRegistryCode: async (registryCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('getAllByCompanyRegistryCode', 'registryCode', registryCode)
            const localVarPath = `/activity-licenses/company/{registryCode}/active`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.get-activity-license-application-service.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationService: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplicationService', 'id', id)
            const localVarPath = `/activity-licenses/application/service/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa tegevusliigid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAreaClassifiers1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activity-licenses/business-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusliigiga seotud teenused.
         * @param {string} businessAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAreaRequirements: async (businessAreaId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessAreaId' is not null or undefined
            assertParamExists('getBusinessAreaRequirements', 'businessAreaId', businessAreaId)
            const localVarPath = `/activity-licenses/business-areas/{businessAreaId}`
                .replace(`{${"businessAreaId"}}`, encodeURIComponent(String(businessAreaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa koodi järgi otsides.
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCode1: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getByCode1', 'code', code)
            const localVarPath = `/activity-licenses/code/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.submit-discard-activity-license-service-application.description}
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscardServicesApplication: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getDiscardServicesApplication', 'applicationId', applicationId)
            const localVarPath = `/activity-licenses/service/discard/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevuskoha andmed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocation1', 'id', id)
            const localVarPath = `/activity-licenses/activity-location/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa tegevuskoha peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationSuspension: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocationSuspension', 'id', id)
            const localVarPath = `/activity-licenses/activity-location/suspension/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kasutaja tegevuslubade üldinfo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRowHeaders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activity-licenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.get-activity-license-service.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getService1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getService1', 'id', id)
            const localVarPath = `/activity-licenses/service/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab tegevusloa teenuse peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceSuspension: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getServiceSuspension', 'id', id)
            const localVarPath = `/activity-licenses/service/suspension/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Eemaldab töötaja töötamise kirje
         * @param {string} id 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEmployee: async (id: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeEmployee', 'id', id)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('removeEmployee', 'employeeId', employeeId)
            const localVarPath = `/activity-licenses/portal/service/{id}/employees/{employeeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Eemaldab specialist töötaja teenuselt
         * @param {string} id 
         * @param {string} employeeId 
         * @param {RemoveSpecialistServiceEmployee} removeSpecialistServiceEmployee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSpecialistServiceEmployee: async (id: string, employeeId: string, removeSpecialistServiceEmployee: RemoveSpecialistServiceEmployee, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeSpecialistServiceEmployee', 'id', id)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('removeSpecialistServiceEmployee', 'employeeId', employeeId)
            // verify required parameter 'removeSpecialistServiceEmployee' is not null or undefined
            assertParamExists('removeSpecialistServiceEmployee', 'removeSpecialistServiceEmployee', removeSpecialistServiceEmployee)
            const localVarPath = `/activity-licenses/portal/service/{id}/employees/{employeeId}/remove-specialist`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeSpecialistServiceEmployee, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Eemaldab THT töötaja teenuselt
         * @param {string} id 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeThtServiceEmployee: async (id: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeThtServiceEmployee', 'id', id)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('removeThtServiceEmployee', 'employeeId', employeeId)
            const localVarPath = `/activity-licenses/portal/service/{id}/employees/{employeeId}/remove-tht`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.submit-discard-activity-license-service-application.description}
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitDiscardServices: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('submitDiscardServices', 'requestBody', requestBody)
            const localVarPath = `/activity-licenses/service/discard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-license-service.description}
         * @param {string} id 
         * @param {ServiceSuspension} serviceSuspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendActivityLicenseService1: async (id: string, serviceSuspension: ServiceSuspension, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('suspendActivityLicenseService1', 'id', id)
            // verify required parameter 'serviceSuspension' is not null or undefined
            assertParamExists('suspendActivityLicenseService1', 'serviceSuspension', serviceSuspension)
            const localVarPath = `/activity-licenses/portal/service/suspend/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceSuspension, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-location-suspension.description}
         * @param {string} id 
         * @param {Suspension} suspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendActivityLocation: async (id: string, suspension: Suspension, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('suspendActivityLocation', 'id', id)
            // verify required parameter 'suspension' is not null or undefined
            assertParamExists('suspendActivityLocation', 'suspension', suspension)
            const localVarPath = `/activity-licenses/portal/activity-location/suspend/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suspension, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Valideerib spetsialisti töötamist sisseloginud kasutaja valitud ettevõttes.
         * @param {string} specialistCode 
         * @param {string} personalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSpecialist: async (specialistCode: string, personalCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'specialistCode' is not null or undefined
            assertParamExists('validateSpecialist', 'specialistCode', specialistCode)
            // verify required parameter 'personalCode' is not null or undefined
            assertParamExists('validateSpecialist', 'personalCode', personalCode)
            const localVarPath = `/activity-licenses/specialist/{specialistCode}/validate/{personalCode}`
                .replace(`{${"specialistCode"}}`, encodeURIComponent(String(specialistCode)))
                .replace(`{${"personalCode"}}`, encodeURIComponent(String(personalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TegevuslubadeTeenusApi - functional programming interface
 * @export
 */
export const TegevuslubadeTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TegevuslubadeTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lisab spetsialisti teenusele
         * @param {string} id 
         * @param {string} specialistCodeId 
         * @param {SpecialistData} specialistData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEmployees(id: string, specialistCodeId: string, specialistData: SpecialistData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEmployees(id, specialistCodeId, specialistData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lisab töötajad teenusele
         * @param {string} id 
         * @param {Array<HealthCareProfessional>} healthCareProfessional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEmployees1(id: string, healthCareProfessional: Array<HealthCareProfessional>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEmployees1(id, healthCareProfessional, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lisab töötajad teenusele
         * @param {string} id 
         * @param {Array<HealthCareProfessional>} healthCareProfessional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEmployeesToPharmacyLicense(id: string, healthCareProfessional: Array<HealthCareProfessional>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEmployeesToPharmacyLicense(id, healthCareProfessional, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab aktiivsed spetsialistide koodid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveSpecialistCodes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpecialistCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveSpecialistCodes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa detailse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLicense3(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLicense3(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kõik aktiivsed tegevuslitsentsid, otsides ettevõtte registrikoodi järgi.
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllByCompanyRegistryCode(registryCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityLicenseLite>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllByCompanyRegistryCode(registryCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.get-activity-license-application-service.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationService(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationService(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa tegevusliigid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessAreaClassifiers1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseBusinessAreaClassifiers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessAreaClassifiers1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusliigiga seotud teenused.
         * @param {string} businessAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessAreaRequirements(businessAreaId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAreaServiceRequirement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessAreaRequirements(businessAreaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa koodi järgi otsides.
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByCode1(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityLicense>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByCode1(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.submit-discard-activity-license-service-application.description}
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiscardServicesApplication(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscardServiceApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiscardServicesApplication(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevuskoha andmed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocation1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLocation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocation1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa tegevuskoha peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationSuspension(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLocationSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationSuspension(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kasutaja tegevuslubade üldinfo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRowHeaders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityLicenseRowHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRowHeaders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.get-activity-license-service.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getService1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getService1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab tegevusloa teenuse peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceSuspension(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceSuspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceSuspension(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Eemaldab töötaja töötamise kirje
         * @param {string} id 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEmployee(id: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEmployee(id, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Eemaldab specialist töötaja teenuselt
         * @param {string} id 
         * @param {string} employeeId 
         * @param {RemoveSpecialistServiceEmployee} removeSpecialistServiceEmployee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSpecialistServiceEmployee(id: string, employeeId: string, removeSpecialistServiceEmployee: RemoveSpecialistServiceEmployee, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSpecialistServiceEmployee(id, employeeId, removeSpecialistServiceEmployee, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Eemaldab THT töötaja teenuselt
         * @param {string} id 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeThtServiceEmployee(id: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeThtServiceEmployee(id, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.submit-discard-activity-license-service-application.description}
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitDiscardServices(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitDiscardServices(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-license-service.description}
         * @param {string} id 
         * @param {ServiceSuspension} serviceSuspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suspendActivityLicenseService1(id: string, serviceSuspension: ServiceSuspension, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Suspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suspendActivityLicenseService1(id, serviceSuspension, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-location-suspension.description}
         * @param {string} id 
         * @param {Suspension} suspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suspendActivityLocation(id: string, suspension: Suspension, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Suspension>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suspendActivityLocation(id, suspension, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Valideerib spetsialisti töötamist sisseloginud kasutaja valitud ettevõttes.
         * @param {string} specialistCode 
         * @param {string} personalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateSpecialist(specialistCode: string, personalCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TortaWorkInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateSpecialist(specialistCode, personalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TegevuslubadeTeenusApi - factory interface
 * @export
 */
export const TegevuslubadeTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TegevuslubadeTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Lisab spetsialisti teenusele
         * @param {string} id 
         * @param {string} specialistCodeId 
         * @param {SpecialistData} specialistData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployees(id: string, specialistCodeId: string, specialistData: SpecialistData, options?: any): AxiosPromise<ActivityLicenseService> {
            return localVarFp.addEmployees(id, specialistCodeId, specialistData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lisab töötajad teenusele
         * @param {string} id 
         * @param {Array<HealthCareProfessional>} healthCareProfessional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployees1(id: string, healthCareProfessional: Array<HealthCareProfessional>, options?: any): AxiosPromise<ActivityLicenseService> {
            return localVarFp.addEmployees1(id, healthCareProfessional, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lisab töötajad teenusele
         * @param {string} id 
         * @param {Array<HealthCareProfessional>} healthCareProfessional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployeesToPharmacyLicense(id: string, healthCareProfessional: Array<HealthCareProfessional>, options?: any): AxiosPromise<ActivityLicenseService> {
            return localVarFp.addEmployeesToPharmacyLicense(id, healthCareProfessional, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab aktiivsed spetsialistide koodid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveSpecialistCodes(options?: any): AxiosPromise<Array<SpecialistCode>> {
            return localVarFp.getActiveSpecialistCodes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa detailse info.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLicense3(id: string, options?: any): AxiosPromise<ActivityLicense> {
            return localVarFp.getActivityLicense3(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kõik aktiivsed tegevuslitsentsid, otsides ettevõtte registrikoodi järgi.
         * @param {string} registryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByCompanyRegistryCode(registryCode: string, options?: any): AxiosPromise<Array<ActivityLicenseLite>> {
            return localVarFp.getAllByCompanyRegistryCode(registryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.get-activity-license-application-service.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationService(id: string, options?: any): AxiosPromise<ActivityLicenseService> {
            return localVarFp.getApplicationService(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa tegevusliigid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAreaClassifiers1(options?: any): AxiosPromise<ActivityLicenseBusinessAreaClassifiers> {
            return localVarFp.getBusinessAreaClassifiers1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusliigiga seotud teenused.
         * @param {string} businessAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAreaRequirements(businessAreaId: string, options?: any): AxiosPromise<BusinessAreaServiceRequirement> {
            return localVarFp.getBusinessAreaRequirements(businessAreaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa koodi järgi otsides.
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCode1(code: string, options?: any): AxiosPromise<Array<ActivityLicense>> {
            return localVarFp.getByCode1(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.submit-discard-activity-license-service-application.description}
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscardServicesApplication(applicationId: string, options?: any): AxiosPromise<DiscardServiceApplication> {
            return localVarFp.getDiscardServicesApplication(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevuskoha andmed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation1(id: string, options?: any): AxiosPromise<ActivityLocation> {
            return localVarFp.getLocation1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa tegevuskoha peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationSuspension(id: string, options?: any): AxiosPromise<ActivityLocationSuspension> {
            return localVarFp.getLocationSuspension(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kasutaja tegevuslubade üldinfo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRowHeaders(options?: any): AxiosPromise<Array<ActivityLicenseRowHeader>> {
            return localVarFp.getRowHeaders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.get-activity-license-service.description}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getService1(id: string, options?: any): AxiosPromise<ActivityLicenseService> {
            return localVarFp.getService1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab tegevusloa teenuse peatamise.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceSuspension(id: string, options?: any): AxiosPromise<ServiceSuspension> {
            return localVarFp.getServiceSuspension(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Eemaldab töötaja töötamise kirje
         * @param {string} id 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEmployee(id: string, employeeId: string, options?: any): AxiosPromise<string> {
            return localVarFp.removeEmployee(id, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Eemaldab specialist töötaja teenuselt
         * @param {string} id 
         * @param {string} employeeId 
         * @param {RemoveSpecialistServiceEmployee} removeSpecialistServiceEmployee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSpecialistServiceEmployee(id: string, employeeId: string, removeSpecialistServiceEmployee: RemoveSpecialistServiceEmployee, options?: any): AxiosPromise<string> {
            return localVarFp.removeSpecialistServiceEmployee(id, employeeId, removeSpecialistServiceEmployee, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Eemaldab THT töötaja teenuselt
         * @param {string} id 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeThtServiceEmployee(id: string, employeeId: string, options?: any): AxiosPromise<string> {
            return localVarFp.removeThtServiceEmployee(id, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.submit-discard-activity-license-service-application.description}
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitDiscardServices(requestBody: Array<string>, options?: any): AxiosPromise<ActivityLicense> {
            return localVarFp.submitDiscardServices(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-license-service.description}
         * @param {string} id 
         * @param {ServiceSuspension} serviceSuspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendActivityLicenseService1(id: string, serviceSuspension: ServiceSuspension, options?: any): AxiosPromise<Suspension> {
            return localVarFp.suspendActivityLicenseService1(id, serviceSuspension, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ${medre.api.messages.post-portal-activity-location-suspension.description}
         * @param {string} id 
         * @param {Suspension} suspension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendActivityLocation(id: string, suspension: Suspension, options?: any): AxiosPromise<Suspension> {
            return localVarFp.suspendActivityLocation(id, suspension, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Valideerib spetsialisti töötamist sisseloginud kasutaja valitud ettevõttes.
         * @param {string} specialistCode 
         * @param {string} personalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSpecialist(specialistCode: string, personalCode: string, options?: any): AxiosPromise<TortaWorkInfo> {
            return localVarFp.validateSpecialist(specialistCode, personalCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TegevuslubadeTeenusApi - object-oriented interface
 * @export
 * @class TegevuslubadeTeenusApi
 * @extends {BaseAPI}
 */
export class TegevuslubadeTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Lisab spetsialisti teenusele
     * @param {string} id 
     * @param {string} specialistCodeId 
     * @param {SpecialistData} specialistData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public addEmployees(id: string, specialistCodeId: string, specialistData: SpecialistData, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).addEmployees(id, specialistCodeId, specialistData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lisab töötajad teenusele
     * @param {string} id 
     * @param {Array<HealthCareProfessional>} healthCareProfessional 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public addEmployees1(id: string, healthCareProfessional: Array<HealthCareProfessional>, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).addEmployees1(id, healthCareProfessional, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lisab töötajad teenusele
     * @param {string} id 
     * @param {Array<HealthCareProfessional>} healthCareProfessional 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public addEmployeesToPharmacyLicense(id: string, healthCareProfessional: Array<HealthCareProfessional>, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).addEmployeesToPharmacyLicense(id, healthCareProfessional, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab aktiivsed spetsialistide koodid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getActiveSpecialistCodes(options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getActiveSpecialistCodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa detailse info.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getActivityLicense3(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getActivityLicense3(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kõik aktiivsed tegevuslitsentsid, otsides ettevõtte registrikoodi järgi.
     * @param {string} registryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getAllByCompanyRegistryCode(registryCode: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getAllByCompanyRegistryCode(registryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.get-activity-license-application-service.description}
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getApplicationService(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getApplicationService(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa tegevusliigid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getBusinessAreaClassifiers1(options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getBusinessAreaClassifiers1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusliigiga seotud teenused.
     * @param {string} businessAreaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getBusinessAreaRequirements(businessAreaId: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getBusinessAreaRequirements(businessAreaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa koodi järgi otsides.
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getByCode1(code: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getByCode1(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.submit-discard-activity-license-service-application.description}
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getDiscardServicesApplication(applicationId: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getDiscardServicesApplication(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevuskoha andmed.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getLocation1(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getLocation1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa tegevuskoha peatamise.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getLocationSuspension(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getLocationSuspension(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kasutaja tegevuslubade üldinfo.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getRowHeaders(options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getRowHeaders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.get-activity-license-service.description}
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getService1(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getService1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab tegevusloa teenuse peatamise.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public getServiceSuspension(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).getServiceSuspension(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Eemaldab töötaja töötamise kirje
     * @param {string} id 
     * @param {string} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public removeEmployee(id: string, employeeId: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).removeEmployee(id, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Eemaldab specialist töötaja teenuselt
     * @param {string} id 
     * @param {string} employeeId 
     * @param {RemoveSpecialistServiceEmployee} removeSpecialistServiceEmployee 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public removeSpecialistServiceEmployee(id: string, employeeId: string, removeSpecialistServiceEmployee: RemoveSpecialistServiceEmployee, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).removeSpecialistServiceEmployee(id, employeeId, removeSpecialistServiceEmployee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Eemaldab THT töötaja teenuselt
     * @param {string} id 
     * @param {string} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public removeThtServiceEmployee(id: string, employeeId: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).removeThtServiceEmployee(id, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.submit-discard-activity-license-service-application.description}
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public submitDiscardServices(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).submitDiscardServices(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.post-portal-activity-license-service.description}
     * @param {string} id 
     * @param {ServiceSuspension} serviceSuspension 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public suspendActivityLicenseService1(id: string, serviceSuspension: ServiceSuspension, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).suspendActivityLicenseService1(id, serviceSuspension, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ${medre.api.messages.post-portal-activity-location-suspension.description}
     * @param {string} id 
     * @param {Suspension} suspension 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public suspendActivityLocation(id: string, suspension: Suspension, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).suspendActivityLocation(id, suspension, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Valideerib spetsialisti töötamist sisseloginud kasutaja valitud ettevõttes.
     * @param {string} specialistCode 
     * @param {string} personalCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusApi
     */
    public validateSpecialist(specialistCode: string, personalCode: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusApiFp(this.configuration).validateSpecialist(specialistCode, personalCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TegevuslubadeTeenusteTaotlusteTeenusApi - axios parameter creator
 * @export
 */
export const TegevuslubadeTeenusteTaotlusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Salvestab teenuse lisamise taotluse.
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalActivityLicenseServiceApplication: async (activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('savePortalActivityLicenseServiceApplication', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/activity-licenses/service-applications/portal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Esitab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPortalActivityLicenseServiceApplication1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submitPortalActivityLicenseServiceApplication1', 'id', id)
            const localVarPath = `/activity-licenses/service-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortalActivityLicenseApplication1: async (id: string, activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePortalActivityLicenseApplication1', 'id', id)
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('updatePortalActivityLicenseApplication1', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/activity-licenses/service-applications/portal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithoutValidation2: async (id: string, activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithoutValidation2', 'id', id)
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('updateWithoutValidation2', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/activity-licenses/service-applications/portal/backward/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TegevuslubadeTeenusteTaotlusteTeenusApi - functional programming interface
 * @export
 */
export const TegevuslubadeTeenusteTaotlusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TegevuslubadeTeenusteTaotlusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Salvestab teenuse lisamise taotluse.
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePortalActivityLicenseServiceApplication(activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePortalActivityLicenseServiceApplication(activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Esitab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitPortalActivityLicenseServiceApplication1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitPortalActivityLicenseServiceApplication1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePortalActivityLicenseApplication1(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePortalActivityLicenseApplication1(id, activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithoutValidation2(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithoutValidation2(id, activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TegevuslubadeTeenusteTaotlusteTeenusApi - factory interface
 * @export
 */
export const TegevuslubadeTeenusteTaotlusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TegevuslubadeTeenusteTaotlusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Salvestab teenuse lisamise taotluse.
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalActivityLicenseServiceApplication(activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.savePortalActivityLicenseServiceApplication(activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Esitab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPortalActivityLicenseServiceApplication1(id: string, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.submitPortalActivityLicenseServiceApplication1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortalActivityLicenseApplication1(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.updatePortalActivityLicenseApplication1(id, activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab teenuse lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithoutValidation2(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.updateWithoutValidation2(id, activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TegevuslubadeTeenusteTaotlusteTeenusApi - object-oriented interface
 * @export
 * @class TegevuslubadeTeenusteTaotlusteTeenusApi
 * @extends {BaseAPI}
 */
export class TegevuslubadeTeenusteTaotlusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Salvestab teenuse lisamise taotluse.
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusteTaotlusteTeenusApi
     */
    public savePortalActivityLicenseServiceApplication(activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusteTaotlusteTeenusApiFp(this.configuration).savePortalActivityLicenseServiceApplication(activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Esitab teenuse lisamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusteTaotlusteTeenusApi
     */
    public submitPortalActivityLicenseServiceApplication1(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusteTaotlusteTeenusApiFp(this.configuration).submitPortalActivityLicenseServiceApplication1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab teenuse lisamise taotluse.
     * @param {string} id 
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusteTaotlusteTeenusApi
     */
    public updatePortalActivityLicenseApplication1(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusteTaotlusteTeenusApiFp(this.configuration).updatePortalActivityLicenseApplication1(id, activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab teenuse lisamise taotluse.
     * @param {string} id 
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTeenusteTaotlusteTeenusApi
     */
    public updateWithoutValidation2(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return TegevuslubadeTeenusteTaotlusteTeenusApiFp(this.configuration).updateWithoutValidation2(id, activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TegevuslubadeTegevuskohtadeTaotlusteTeenusApi - axios parameter creator
 * @export
 */
export const TegevuslubadeTegevuskohtadeTaotlusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Salvestab tegevuskoha lisamise taotluse.
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalActivityLocationApplication: async (activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('savePortalActivityLocationApplication', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/activity-location-applications/portal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Salvestab tegevuskoha taotluse ilma validatsioonideta.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalActivityLocationApplicationWithoutValidations: async (id: string, activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('savePortalActivityLocationApplicationWithoutValidations', 'id', id)
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('savePortalActivityLocationApplicationWithoutValidations', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/activity-location-applications/portal/backward/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Esitab tegevuskoha lisamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPortalActivityLocationApplication: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submitPortalActivityLocationApplication', 'id', id)
            const localVarPath = `/activity-location-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uuendab tegevuskoha lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortalActivityLocationApplication: async (id: string, activityLicenseApplication: ActivityLicenseApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePortalActivityLocationApplication', 'id', id)
            // verify required parameter 'activityLicenseApplication' is not null or undefined
            assertParamExists('updatePortalActivityLocationApplication', 'activityLicenseApplication', activityLicenseApplication)
            const localVarPath = `/activity-location-applications/portal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityLicenseApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TegevuslubadeTegevuskohtadeTaotlusteTeenusApi - functional programming interface
 * @export
 */
export const TegevuslubadeTegevuskohtadeTaotlusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TegevuslubadeTegevuskohtadeTaotlusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Salvestab tegevuskoha lisamise taotluse.
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePortalActivityLocationApplication(activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePortalActivityLocationApplication(activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Salvestab tegevuskoha taotluse ilma validatsioonideta.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePortalActivityLocationApplicationWithoutValidations(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePortalActivityLocationApplicationWithoutValidations(id, activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Esitab tegevuskoha lisamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitPortalActivityLocationApplication(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitPortalActivityLocationApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uuendab tegevuskoha lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePortalActivityLocationApplication(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLicenseApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePortalActivityLocationApplication(id, activityLicenseApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TegevuslubadeTegevuskohtadeTaotlusteTeenusApi - factory interface
 * @export
 */
export const TegevuslubadeTegevuskohtadeTaotlusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TegevuslubadeTegevuskohtadeTaotlusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Salvestab tegevuskoha lisamise taotluse.
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalActivityLocationApplication(activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.savePortalActivityLocationApplication(activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Salvestab tegevuskoha taotluse ilma validatsioonideta.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortalActivityLocationApplicationWithoutValidations(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.savePortalActivityLocationApplicationWithoutValidations(id, activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Esitab tegevuskoha lisamise taotluse.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPortalActivityLocationApplication(id: string, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.submitPortalActivityLocationApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uuendab tegevuskoha lisamise taotluse.
         * @param {string} id 
         * @param {ActivityLicenseApplication} activityLicenseApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortalActivityLocationApplication(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: any): AxiosPromise<ActivityLicenseApplication> {
            return localVarFp.updatePortalActivityLocationApplication(id, activityLicenseApplication, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TegevuslubadeTegevuskohtadeTaotlusteTeenusApi - object-oriented interface
 * @export
 * @class TegevuslubadeTegevuskohtadeTaotlusteTeenusApi
 * @extends {BaseAPI}
 */
export class TegevuslubadeTegevuskohtadeTaotlusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Salvestab tegevuskoha lisamise taotluse.
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTegevuskohtadeTaotlusteTeenusApi
     */
    public savePortalActivityLocationApplication(activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return TegevuslubadeTegevuskohtadeTaotlusteTeenusApiFp(this.configuration).savePortalActivityLocationApplication(activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Salvestab tegevuskoha taotluse ilma validatsioonideta.
     * @param {string} id 
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTegevuskohtadeTaotlusteTeenusApi
     */
    public savePortalActivityLocationApplicationWithoutValidations(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return TegevuslubadeTegevuskohtadeTaotlusteTeenusApiFp(this.configuration).savePortalActivityLocationApplicationWithoutValidations(id, activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Esitab tegevuskoha lisamise taotluse.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTegevuskohtadeTaotlusteTeenusApi
     */
    public submitPortalActivityLocationApplication(id: string, options?: AxiosRequestConfig) {
        return TegevuslubadeTegevuskohtadeTaotlusteTeenusApiFp(this.configuration).submitPortalActivityLocationApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uuendab tegevuskoha lisamise taotluse.
     * @param {string} id 
     * @param {ActivityLicenseApplication} activityLicenseApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TegevuslubadeTegevuskohtadeTaotlusteTeenusApi
     */
    public updatePortalActivityLocationApplication(id: string, activityLicenseApplication: ActivityLicenseApplication, options?: AxiosRequestConfig) {
        return TegevuslubadeTegevuskohtadeTaotlusteTeenusApiFp(this.configuration).updatePortalActivityLocationApplication(id, activityLicenseApplication, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UIRakenduseKonfiguratsiooniTeenusApi - axios parameter creator
 * @export
 */
export const UIRakenduseKonfiguratsiooniTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUiConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/ui-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UIRakenduseKonfiguratsiooniTeenusApi - functional programming interface
 * @export
 */
export const UIRakenduseKonfiguratsiooniTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UIRakenduseKonfiguratsiooniTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUiConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUiConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UIRakenduseKonfiguratsiooniTeenusApi - factory interface
 * @export
 */
export const UIRakenduseKonfiguratsiooniTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UIRakenduseKonfiguratsiooniTeenusApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUiConfiguration(options?: any): AxiosPromise<UiConfig> {
            return localVarFp.getUiConfiguration(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UIRakenduseKonfiguratsiooniTeenusApi - object-oriented interface
 * @export
 * @class UIRakenduseKonfiguratsiooniTeenusApi
 * @extends {BaseAPI}
 */
export class UIRakenduseKonfiguratsiooniTeenusApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIRakenduseKonfiguratsiooniTeenusApi
     */
    public getUiConfiguration(options?: AxiosRequestConfig) {
        return UIRakenduseKonfiguratsiooniTeenusApiFp(this.configuration).getUiConfiguration(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VastutuskindlustusteTeenusApi - axios parameter creator
 * @export
 */
export const VastutuskindlustusteTeenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tagastab kindlustusvõtja vastutuskindlustuste loendi.
         * @param {string} registryCode 
         * @param {InsuranceContractStatus} [status] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyHolderInsuranceContracts: async (registryCode: string, status?: InsuranceContractStatus, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('getPolicyHolderInsuranceContracts', 'registryCode', registryCode)
            const localVarPath = `/policy-holders/{registryCode}/insurance-contracts`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab kindlustusvõtja vastutuskindlustuste loendi.
         * @param {string} registryCode 
         * @param {InsuranceContractStatus} [status] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicPolicyHolderInsuranceContracts: async (registryCode: string, status?: InsuranceContractStatus, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCode' is not null or undefined
            assertParamExists('getPublicPolicyHolderInsuranceContracts', 'registryCode', registryCode)
            const localVarPath = `/public/policy-holders/{registryCode}/insurance-contracts`
                .replace(`{${"registryCode"}}`, encodeURIComponent(String(registryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tagastab filtreeritud vastutuskindlustuste loendi.
         * @param {InsuranceContractSearchFilter} insuranceContractSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInsuranceContracts: async (insuranceContractSearchFilter: InsuranceContractSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuranceContractSearchFilter' is not null or undefined
            assertParamExists('searchInsuranceContracts', 'insuranceContractSearchFilter', insuranceContractSearchFilter)
            const localVarPath = `/insurance-contracts/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api-key required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insuranceContractSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VastutuskindlustusteTeenusApi - functional programming interface
 * @export
 */
export const VastutuskindlustusteTeenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VastutuskindlustusteTeenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tagastab kindlustusvõtja vastutuskindlustuste loendi.
         * @param {string} registryCode 
         * @param {InsuranceContractStatus} [status] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPolicyHolderInsuranceContracts(registryCode: string, status?: InsuranceContractStatus, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPolicyHolderInsuranceContracts(registryCode, status, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab kindlustusvõtja vastutuskindlustuste loendi.
         * @param {string} registryCode 
         * @param {InsuranceContractStatus} [status] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicPolicyHolderInsuranceContracts(registryCode: string, status?: InsuranceContractStatus, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicPolicyHolderInsuranceContracts(registryCode, status, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tagastab filtreeritud vastutuskindlustuste loendi.
         * @param {InsuranceContractSearchFilter} insuranceContractSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInsuranceContracts(insuranceContractSearchFilter: InsuranceContractSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInsuranceContracts(insuranceContractSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VastutuskindlustusteTeenusApi - factory interface
 * @export
 */
export const VastutuskindlustusteTeenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VastutuskindlustusteTeenusApiFp(configuration)
    return {
        /**
         * 
         * @summary Tagastab kindlustusvõtja vastutuskindlustuste loendi.
         * @param {string} registryCode 
         * @param {InsuranceContractStatus} [status] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyHolderInsuranceContracts(registryCode: string, status?: InsuranceContractStatus, page?: number, size?: number, options?: any): AxiosPromise<InsuranceContractResponse> {
            return localVarFp.getPolicyHolderInsuranceContracts(registryCode, status, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab kindlustusvõtja vastutuskindlustuste loendi.
         * @param {string} registryCode 
         * @param {InsuranceContractStatus} [status] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicPolicyHolderInsuranceContracts(registryCode: string, status?: InsuranceContractStatus, page?: number, size?: number, options?: any): AxiosPromise<InsuranceContractResponse> {
            return localVarFp.getPublicPolicyHolderInsuranceContracts(registryCode, status, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tagastab filtreeritud vastutuskindlustuste loendi.
         * @param {InsuranceContractSearchFilter} insuranceContractSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInsuranceContracts(insuranceContractSearchFilter: InsuranceContractSearchFilter, options?: any): AxiosPromise<InsuranceContractResponse> {
            return localVarFp.searchInsuranceContracts(insuranceContractSearchFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VastutuskindlustusteTeenusApi - object-oriented interface
 * @export
 * @class VastutuskindlustusteTeenusApi
 * @extends {BaseAPI}
 */
export class VastutuskindlustusteTeenusApi extends BaseAPI {
    /**
     * 
     * @summary Tagastab kindlustusvõtja vastutuskindlustuste loendi.
     * @param {string} registryCode 
     * @param {InsuranceContractStatus} [status] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VastutuskindlustusteTeenusApi
     */
    public getPolicyHolderInsuranceContracts(registryCode: string, status?: InsuranceContractStatus, page?: number, size?: number, options?: AxiosRequestConfig) {
        return VastutuskindlustusteTeenusApiFp(this.configuration).getPolicyHolderInsuranceContracts(registryCode, status, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab kindlustusvõtja vastutuskindlustuste loendi.
     * @param {string} registryCode 
     * @param {InsuranceContractStatus} [status] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VastutuskindlustusteTeenusApi
     */
    public getPublicPolicyHolderInsuranceContracts(registryCode: string, status?: InsuranceContractStatus, page?: number, size?: number, options?: AxiosRequestConfig) {
        return VastutuskindlustusteTeenusApiFp(this.configuration).getPublicPolicyHolderInsuranceContracts(registryCode, status, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tagastab filtreeritud vastutuskindlustuste loendi.
     * @param {InsuranceContractSearchFilter} insuranceContractSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VastutuskindlustusteTeenusApi
     */
    public searchInsuranceContracts(insuranceContractSearchFilter: InsuranceContractSearchFilter, options?: AxiosRequestConfig) {
        return VastutuskindlustusteTeenusApiFp(this.configuration).searchInsuranceContracts(insuranceContractSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


