import React from "react";
import { FormattedMessage } from "react-intl";

import "./ActivityLicenseSuspensionModal.scss";
import { SuspensionModalContainer } from "./SuspensionModalContainer";
import {
  ActivityLicense,
  ActivityLicenseApplicationProceedingTypeEnum
} from "../../../../../api_client/medre_api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activityLicense?: ActivityLicense;
}

export const ActivityLicenseSuspensionModal = ({
  isOpen,
  onClose,
  activityLicense
}: Props) => {
  const createTitle = () => (
    <FormattedMessage
      id="suspendActivityLicenseModal.header"
      defaultMessage="Peata tegevusluba {activityLicenseNumber}"
      values={{ activityLicenseNumber: activityLicense?.number }}
    />
  );

  return (
    <SuspensionModalContainer
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={createTitle()}
      suspensionType={
        ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension
      }
      idToSuspend={activityLicense?.id || ""}
      totalSuspendedDays={activityLicense?.totalSuspendedDays || 0}
    />
  );
};
