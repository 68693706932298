import React, { forwardRef, Ref } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { Button } from "reactstrap";

import link_icon from "../../../../../../../assets/images/link_icon.svg";
import { LocaleMessage } from "../../../../../../Locale/LocaleMessage";
import { FileObjectType } from "../../../../../../../api_client/medre_api";

interface Props {
  type: FileObjectType;
  changeActiveAttachmentType: (type: FileObjectType) => void;
  disabled: boolean;
}

export const AttachmentButton = forwardRef(
  (
    { type, changeActiveAttachmentType, disabled }: Props,
    ref: Ref<HTMLButtonElement>
  ) => {
    const messages: Record<any, LocaleMessage> = defineMessages({
      [FileObjectType.ProceedingDiploma]: {
        id: "attachmentButton.diploma",
        defaultMessage: "Lae üles diplomi koopia (kohustuslik)"
      },
      [FileObjectType.ProceedingAcademicReport]: {
        id: "attachmentButton.academicReport",
        defaultMessage: "Lae üles akadeemilise õiendi koopia"
      },
      [FileObjectType.ProceedingAdditionalDocument]: {
        id: "attachmentButton.additionalDocument",
        defaultMessage: "Lae üles muud täiendavate dokumentide koopiad"
      },
      [FileObjectType.ProceedingNameChangeDocument]: {
        id: "attachmentButton.nameChangeDocument",
        defaultMessage: "Lae üles nimemuutust tõendava dokumendi koopia"
      },
      [FileObjectType.ProceedingComplianceExamCert]: {
        id: "attachmentButton.complianceExamCert",
        defaultMessage:
          "Lae üles vastavuseksami sooritamise tunnistus(.pdf, 30MB max)"
      },
      [FileObjectType.ProceedingAptitudeTestCert]: {
        id: "attachmentButton.aptitudeTestCert",
        defaultMessage:
          "Lae üles sobivustesti sooritamise tunnistus(.pdf, 30MB max)"
      },
      [FileObjectType.PersonPreviousNameDocument]: {
        id: "attachmentButton.previousNameDoc",
        defaultMessage: "Lae üles nimemuutust tõendava dokumendi koopia"
      },
      [FileObjectType.PersonExternalDecisionDocument]: {
        id: "attachmentButton.externalDecisionDoc",
        defaultMessage: "Lae üles uus süsteemiväline otsus"
      },
      [FileObjectType.PersonPrescriptionPowerDocument]: {
        id: "attachmentButton.prescriptionPowerDocument",
        defaultMessage: "Lae üles tõendi koopia"
      },
      [FileObjectType.ActivityLicenseMedicalTechnologyProject]: {
        id: "attachmentButton.medicalTechnologyProject",
        defaultMessage:
          "Lae üles meditsiinitehnoloogia projekt (arhitektuurilised ruumiplaanid mõõteandmete, " +
          "sisseseade ja tähistustega) *"
      },
      [FileObjectType.ActivityLicenseRightOfUseForRooms]: {
        id: "attachmentButton.rightOfUserForRooms",
        defaultMessage: "Lae üles dokument ruumide kasutusõiguse tõendamiseks *"
      },
      [FileObjectType.ActivityLicenseListOfDevices]: {
        id: "attachmentButton.listOfDevices",
        defaultMessage: "Lae üles aparatuuri ja sisseseade loetelu *"
      },
      [FileObjectType.ActivityLicensePrimaryCarePhysicianInfo]: {
        id: "attachmentButton.primaryCarePhysicianInfo",
        defaultMessage:
          "Lae üles vormid perearsti tegevuskoha ruumide, sisseseade ja aparatuuri kohta *"
      },
      [FileObjectType.ActivityLicenseSchoolNurseImmunisation]: {
        id: "attachmentButton.schoolNurseImmunisation",
        defaultMessage: "Lae üles kooliõe immuniseerimise tunnistus *"
      },
      [FileObjectType.ActivityLicenseNurseTrainings]: {
        id: "attachmentButton.nurseTrainings",
        defaultMessage:
          "Lae üles õe viimase kahe aasta jooksul läbitud kutse- ja erialaseid täienduskoolitused 120 h *"
      },
      [FileObjectType.ActivityLicenseMidwifeHomeBirth]: {
        id: "attachmentButton.midwifeHomeBirth",
        defaultMessage:
          "Lae üles kodusünnitusabi osutamiseks ämmaemanda pädevust tõendavad dokumendid *"
      },
      [FileObjectType.ActivityLicenseOther]: {
        id: "attachmentButton.otherDocuments",
        defaultMessage: "Lae üles muud dokumendid"
      },
      [FileObjectType.ActivityLicensePersonnelAgreement]: {
        id: "attachmentButton.personnelAgreement",
        defaultMessage:
          "Lae üles personali nõusoleku dokumendid uute töötajate olemasolul"
      },
      [FileObjectType.ActivityLicenseAmbulance]: {
        id: "attachmentButton.ambulance",
        defaultMessage: "Lae üles kiirabiga seotud dokumendid *"
      },
      [FileObjectType.ActivityLicenseOnSiteInspectionProtocol]: {
        id: "attachmentButton.onSiteInspectionProtocol",
        defaultMessage: "Lae üles allkirjastatud kontrollakt (.asice) *"
      },
      [FileObjectType.LicenseAcquisitionOnSiteInspectionProtocol]: {
        id: "attachmentButton.onSiteInspectionProtocol",
        defaultMessage: "Lae üles allkirjastatud kontrollakt (.asice) *"
      },
      [FileObjectType.LicenseAcquisitionEconomicActivityConfirmation]: {
        id: "attachmentButton.economicActivityConfirmation",
        defaultMessage:
          "Lae üles majandustegevuse jätkamise õigust kinnitavad dokumendid "
      },
      [FileObjectType.LicenseAcquisitionPersonnelList]: {
        id: "attachmentButton.personnelList",
        defaultMessage:
          "Lae üles personali nõusoleku dokumendid uute töötajate olemasolul "
      },
      [FileObjectType.LicenseAcquisitionHealthcareProvidingConfirmation]: {
        id: "attachmentButton.healthcareProvidingConfirmation",
        defaultMessage:
          "Lae üles tõend, et täidetud on muud tervishoiuteenuse osutamiseks kehtestatud nõuded "
      },
      [FileObjectType.LicenseAcquisitionPreviousOwnerAgreement]: {
        id: "attachmentButton.previousOwnerAgreement",
        defaultMessage:
          "Lae üles nõusolek tegevusloa üleandmiseks (võib sisalduda ettevõtte võõrandamise lepingus) "
      },
      [FileObjectType.LicenseAcquisitionPropertyTransitionConfirmation]: {
        id: "attachmentButton.propertyTransitionConfirmation",
        defaultMessage:
          "Lae üles tegevusloa kontrolliesemesse kuuluva vara ülemineku kinnitus "
      },
      [FileObjectType.ActivityLicenseInvalidationDocument]: {
        id: "attachmentButton.invalidationDocuments",
        defaultMessage: "Lae üles dokumendid"
      },
      [FileObjectType.ActivityLocationInvalidationDocument]: {
        id: "attachmentButton.locationInvalidationDocuments",
        defaultMessage: "Lae üles dokumendid"
      },
      [FileObjectType.ActivityLicenseDecision]: {
        id: "attachmentButton.ActivityLicenseDecision",
        defaultMessage: "Lae üles otsus"
      },
      [FileObjectType.ApplicationAcceptanceDecision]: {
        id: "attachmentButton.activityLicenseDecision",
        defaultMessage: "Lae üles otsus"
      },
      [FileObjectType.ApplicationDeclinationDecision]: {
        id: "attachmentButton.activityLicenseDecision",
        defaultMessage: "Lae üles otsus"
      },
      [FileObjectType.PersonSuspensionDecision]: {
        id: "attachmentButton.activityLicenseDecision",
        defaultMessage: "Lae üles otsus"
      },
      [FileObjectType.LicenseExternalFile]: {
        id: "attachmentButton.licenseExternalFile",
        defaultMessage: "Lae üles dokumendid"
      },
      [FileObjectType.LicenseRestorationOnSiteInspectionProtocol]: {
        id: "attachmentButton.onSiteInspectionProtocol",
        defaultMessage: "Lae üles allkirjastatud kontrollakt (.asice) *"
      },
      [FileObjectType.LocationRestorationOnSiteInspectionProtocol]: {
        id: "attachmentButton.onSiteInspectionProtocol",
        defaultMessage: "Lae üles allkirjastatud kontrollakt (.asice) *"
      },
      [FileObjectType.ServiceRestorationOnSiteInspectionProtocol]: {
        id: "attachmentButton.onSiteInspectionProtocol",
        defaultMessage: "Lae üles allkirjastatud kontrollakt (.asice) *"
      },
      [FileObjectType.ApplicationSendToAptitudeTestDecision]: {
        id: "attachmentButton.aptitudeTestDecision",
        defaultMessage: "Lae üles sobivustestile suunamise otsus"
      },
      [FileObjectType.ApplicationAptitudeTestResultDecision]: {
        id: "attachmentButton.aptitudeTestResult",
        defaultMessage: "Lae üles sobivustesti sooritamise otsus"
      },
      [FileObjectType.ApplicationSendToComplianceExamDecision]: {
        id: "attachmentButton.complianceExamDecision",
        defaultMessage: "Lae üles vastavuseksamile suunamise otsus"
      },
      [FileObjectType.ApplicationComplianceExamResultDecision]: {
        id: "attachmentButton.complianceExamResult",
        defaultMessage: "Lae üles vastavuseksami sooritamise otsus"
      },
      [FileObjectType.PowersOfAttornery]: {
        id: "attachmentButton.powersOfAttorney",
        defaultMessage: "Lae üles CSV faili"
      },
      [FileObjectType.ActivityLicenseFilledApplication]: {
        id: "attachmentButton.activityLicenseFilledApplication",
        defaultMessage: "Lae üles tegevusloa taotlemise täidetud avaldus *"
      },
      [FileObjectType.ActivityLicenseRequirementsTable]: {
        id: "attachmentButton.activityLicenseRequirementsTable",
        defaultMessage: "Lae üles nõuete tabel *"
      },
      [FileObjectType.ActivityLicenseEmployeeQualifications]: {
        id: "attachmentButton.activityLicenseEmployeeQualifications",
        defaultMessage: "Lae üles kvalifikatsiooni tõendavad dokumendid *"
      },
      [FileObjectType.PersonDiploma]: {
        id: "attachmentButton.personDiploma",
        defaultMessage: "Lae üles diplomi koopia *"
      },
      [FileObjectType.PersonAcademicReport]: {
        id: "attachmentButton.academicReport",
        defaultMessage: "Lae üles akadeemilise õiendi koopia"
      },
      [FileObjectType.PersonAdditionalDocument]: {
        id: "attachmentButton.additionalDocument",
        defaultMessage: "Lae üles muud täiendavate dokumentide koopiad"
      },
      [FileObjectType.PersonNameChangeDocument]: {
        id: "attachmentButton.personNameChangeDocument",
        defaultMessage: "Lae üles nimemuutust tõendav dokument"
      }
    });

    const popoverTarget = () => {
      return "POPOVER_" + type;
    };

    return (
      <Button
        innerRef={ref}
        id={popoverTarget()}
        className="attachment-button mb-4 mr-1 d-inline-block d-flex align-items-center"
        onClick={() => changeActiveAttachmentType(type)}
        disabled={disabled}
        color="primary"
      >
        <div>
          <img src={link_icon} alt="icon" className="pr-2" />
        </div>
        <FormattedMessage {...messages[type]} />
      </Button>
    );
  }
);
