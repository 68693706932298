import React, { useState } from "react";
import {
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";
import { AttachmentContainer } from "../../../../../Shared/Application/Education/Manual/Attachment/AttachmentContainer";
import {
  deleteFilesWithAlerts,
  saveFilesWithAlerts
} from "../../../../../../Component/FileDropzone/UploadFile";
import {
  FileObjectType,
  FileReference,
  OfficialUserPrivilegesEnum
} from "../../../../../../../api_client/medre_api";
import "./AddProtocolModal.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (protocolNumber: string) => Promise<void>;
  applicationId?: string;
  fileReferences: FileReference[];
  type: FileObjectType;
  refresh: () => void;
}

type AddProtocolForm = {
  protocolNumber?: string;
};

export const AddProtocolModal = ({
  isOpen,
  onClose,
  applicationId,
  fileReferences,
  type,
  refresh,
  onSubmit
}: Props) => {
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  const defaultValues: AddProtocolForm = {
    protocolNumber: undefined
  };
  const dispatch = useDispatch();

  const { handleSubmit, register, errors, reset, formState } =
    useForm<AddProtocolForm>({
      defaultValues,
      mode: "onBlur"
    });

  const { touched, isSubmitted } = formState;

  const handleSaveFiles = (files: File[]) => {
    saveFilesWithAlerts(
      files,
      type,
      applicationId!,
      dispatch,
      handleFileChange
    );
  };

  const handleFileChange = () => {
    refresh();
  };

  const handleFileDelete = (fileName: string) => {
    let toDelete = fileReferences?.filter(
      (file) => file.fileName === fileName
    )[0];
    if (toDelete && toDelete.minioFileName) {
      deleteFilesWithAlerts(
        toDelete.minioFileName,
        toDelete.fileObjectType!,
        dispatch,
        handleFileChange
      );
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
    setIsPopOverOpen(false);
  };

  const submit = (data: AddProtocolForm) => {
    onSubmit(data.protocolNumber!).then(() => reset(defaultValues));
    handleClose();
  };

  return (
    <Modal isOpen={isOpen}>
      <Form onSubmit={handleSubmit(submit)}>
        <ModalHeader>
          <FormattedMessage
            id="activityLicenseApplicationProtocolModal.header"
            defaultMessage="Lisa allkirjastatud kontrollakt"
          />
        </ModalHeader>
        <ModalBody>
          <AttachmentContainer
            key={type}
            isActive={isPopOverOpen}
            onClick={() => setIsPopOverOpen(!isPopOverOpen)}
            type={type}
            fileReferences={fileReferences}
            maxFilesCount={1}
            saveFiles={handleSaveFiles}
            handleFileDelete={handleFileDelete}
            allowedRoles={[
              OfficialUserPrivilegesEnum.Sign,
              OfficialUserPrivilegesEnum.Proceede
            ]}
          />
          <div className="d-flex">
            <div className="label">
              <FormattedMessage
                id="activityLicenseApplicationProtocolModal.body"
                defaultMessage="Kontrollakti number*"
              />
            </div>
            <Input
              type="text"
              name="protocolNumber"
              valid={
                (isSubmitted || touched.protocolNumber) &&
                !errors.protocolNumber
              }
              invalid={!!errors.protocolNumber}
              innerRef={register({ required: true })}
              rules={{ required: true }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton id="cancel" onClick={handleClose} />
          <PrimaryFormattedButton
            id="confirmProtocol"
            disabled={!!errors.protocolNumber || !fileReferences?.length}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};
