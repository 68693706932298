import React, { useState } from "react";
import { Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AxiosError } from "axios";
import { FormattedMessage } from "react-intl";
import NavigationPrompt from "react-router-navigation-prompt";

import "../../../../Component/Button/Button.scss";
import { RootState } from "../../../../rootReducer";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { personActions } from "../../../../Person/personActions";
import { applicationActions } from "../../../../Application/applicationActions";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { PortalApplicationStep } from "../ApplicationStep";
import { ApplicationSaveConfirmationModal } from "../../../Shared/Application/Footer/ApplicationSaveConfirmationModal";
import { ApplicationDiscardConfirmationModal } from "../../../Shared/Application/Footer/ApplicationDiscardConfirmationModal";
import { getBaseUrl, API } from "../../../../api";
import { ApplicationCertificateDraft } from "../../../../Dto/ApplicationCertificate/ApplicationCertificate";
import {
  Person,
  ApplicationDraft,
  KasutajaAndmeteTeenusApiFactory as userDataAPI,
  TaotluseAndmeteTeenusApiFactory as appDataAPI
} from "../../../../../api_client/medre_api";

interface Props {
  currentStep: number;
  steps: JSX.Element[];
}

export const PortalApplicationFooter = ({ currentStep, steps }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft
  );
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const [shouldShowNavigationPrompt, setShouldShowNavigationPrompt] =
    useState(true);

  const saveButtonClass = () => {
    if (currentStep >= 3) {
      return "";
    } else if (currentStep === 0) {
      return "disabled";
    } else {
      return (applicationDraft as ApplicationCertificateDraft).saveAllowed
        ? ""
        : "disabled";
    }
  };

  const saveContactInfo = () =>
    userDataAPI(undefined, getBaseUrl(), API).updatePerson1(
      (applicationDraft as ApplicationCertificateDraft).contactInfo!,
      {
        withCredentials: true
      }
    );

  const savePersonalData = () =>
    userDataAPI(undefined, getBaseUrl(), API).updatePerson(
      (applicationDraft as ApplicationCertificateDraft).personalData!,
      {
        withCredentials: true
      }
    );

  const updateDraftRequest = () =>
    appDataAPI(undefined, getBaseUrl(), API).savePortalApplicationDraft(
      applicationDraft.id!,
      applicationDraft,
      {
        withCredentials: true
      }
    );

  const createDraftRequest = () =>
    appDataAPI(undefined, getBaseUrl(), API).savePortalApplicationDraft1(
      applicationDraft,
      {
        withCredentials: true
      }
    );

  const saveDraft = () => {
    let request = applicationDraft.id
      ? updateDraftRequest()
      : createDraftRequest();
    request
      .then(() => {
        const alertMessage = <AlertMessage id="applicationSaveSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        setShouldShowNavigationPrompt(false);
        history.push("/applications");
      })
      .catch((error: AxiosError) => {
        let responseData = error.response?.data;
        let alertMessage;
        if (responseData.error === "PortalApplicationDraftSaveException") {
          alertMessage = <AlertMessage id={responseData.message} />;
        } else {
          alertMessage = <AlertMessage id="applicationSaveFailed" />;
        }
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      })
      .finally(() => setConfirmationModalIsOpen(false));
  };

  const handleSave = () => {
    switch (currentStep) {
      case PortalApplicationStep.PERSONAL_DATA:
        savePersonalData().then((response) => {
          handlePersonalDataUpdate(response.data);
          saveDraft();
        });
        break;
      case PortalApplicationStep.CONTACT_INFO:
        saveContactInfo().then((response) => {
          dispatch(
            personActions.updatePerson(
              "contactInfo",
              response.data.contactInfo!
            )
          );
          saveDraft();
        });
        break;
      default:
        saveDraft();
    }
  };

  const handleNavigation = (newStep: number) => {
    let request = applicationDraft.id
      ? updateDraftRequest()
      : createDraftRequest();
    request
      .then((response) => {
        const draft: ApplicationDraft = response.data;
        draft.currentStep = newStep;
        dispatch(applicationDraftActions.setApplicationDraft(draft));
      })
      .catch((error: AxiosError) => {
        let responseData = error.response?.data;
        let alertMessage;
        if (responseData?.error === "PortalApplicationDraftSaveException") {
          alertMessage = <AlertMessage id={responseData.message} />;
        } else {
          alertMessage = <AlertMessage id="applicationSaveFailed" />;
        }
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      });
  };

  const navigate = (changeStep: number) => {
    switch (currentStep) {
      case PortalApplicationStep.PERSONAL_DATA:
        savePersonalData().then((response) => {
          handlePersonalDataUpdate(response.data);
          handleNavigation(currentStep + changeStep);
        });
        break;
      case PortalApplicationStep.CONTACT_INFO:
        saveContactInfo().then((response) => {
          dispatch(
            personActions.updatePerson(
              "contactInfo",
              response.data.contactInfo!
            )
          );
          handleNavigation(currentStep + changeStep);
        });
        break;
      case PortalApplicationStep.OVERVIEW: {
        if (changeStep === 1) {
          appDataAPI(undefined, getBaseUrl(), API)
            .createApplication(applicationDraft.id!, {
              withCredentials: true
            })
            .then((response) => {
              setShouldShowNavigationPrompt(false);
              dispatch(applicationActions.setPortalApplication(response.data));
              history.push("/application");
            });
        } else {
          handleNavigation(currentStep + changeStep);
        }
        break;
      }
      default:
        handleNavigation(currentStep + changeStep);
    }
  };

  const handlePersonalDataUpdate = (person: Person) => {
    dispatch(personActions.updatePerson("idCode", person.idCode!));
    dispatch(personActions.updatePerson("firstName", person.firstName!));
    dispatch(personActions.updatePerson("lastName", person.lastName!));
    dispatch(personActions.updatePerson("foreigner", person.foreigner!));
    dispatch(personActions.updatePerson("citizenship", person.citizenship!));
    dispatch(personActions.updatePerson("gender", person.gender!));
    dispatch(personActions.updatePerson("dateOfBirth", person.dateOfBirth!));
  };

  const renderBackwardButton = () => {
    if (currentStep === PortalApplicationStep.SPECIALITY_OCCUPATION) {
      return (
        <SecondaryFormattedButton
          id="discard"
          onClick={() => handleDiscardModalOpenChange(true)}
        />
      );
    } else if (currentStep === PortalApplicationStep.EDUCATION_INFO) {
      return (
        <SecondaryFormattedButton id="back" onClick={() => navigate(-1)} />
      );
    } else {
      return (
        <SecondaryFormattedButton
          id="back"
          disabled={
            !(applicationDraft as ApplicationCertificateDraft).saveAllowed
          }
          onClick={() => navigate(-1)}
        />
      );
    }
  };

  const handleDiscardModalOpenChange = (isOpen: boolean) => {
    setDiscardModalIsOpen(isOpen);
    setShouldShowNavigationPrompt(!isOpen);
  };

  const toggleConfirmationModal = () => {
    setConfirmationModalIsOpen(!confirmationModalIsOpen);
  };

  const getModalMessage = () => {
    return (
      <FormattedMessage
        id="applicationSaveConfirmationModal.bodyPortal"
        defaultMessage={`Taotluse seis salvestatakse ja saate hiljem jätkata täitmist. Salvestatud taotluse leiate "Minu taotlused" alt.`}
      />
    );
  };

  return (
    <>
      <Row className="ml-0 mr-0 application-footer-buttons">
        {renderBackwardButton()}
        <LinkButton
          id="saveDraft"
          className={saveButtonClass()}
          onClick={() => setConfirmationModalIsOpen(true)}
        />
        <PrimaryFormattedButton
          className="ml-4"
          id="forward"
          disabled={
            !(applicationDraft as ApplicationCertificateDraft).saveAllowed
          }
          onClick={() => navigate(1)}
          {...(currentStep === steps.length - 1 && {
            id: "sendApplication"
          })}
        />
      </Row>
      <ApplicationDiscardConfirmationModal
        isOpen={discardModalIsOpen}
        onClose={() => handleDiscardModalOpenChange(!discardModalIsOpen)}
        onDiscard={() => history.push("/applications")}
      />
      <ApplicationSaveConfirmationModal
        isOpen={confirmationModalIsOpen}
        onClose={toggleConfirmationModal}
        onSave={handleSave}
        modalMessage={getModalMessage()}
      />
      <NavigationPrompt when={shouldShowNavigationPrompt}>
        {({ onConfirm, onCancel }) => (
          <ApplicationDiscardConfirmationModal
            isOpen={true}
            onClose={onCancel}
            onDiscard={onConfirm}
          />
        )}
      </NavigationPrompt>
    </>
  );
};
