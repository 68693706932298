import React from "react";
import { Card } from "reactstrap";
import moment from "moment";

import { EducationInfoViewRow } from "./EducationInfoViewRow";
import { Education, EducationInfo, FileObjectType, FileReference } from "../../../../../../api_client/medre_api";
import { AutomaticAttachmentFields } from "./AutomaticAttachmentFields";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../rootReducer";
import { deleteFilesWithAlerts, saveFilesWithAlerts } from "../../../../../Component/FileDropzone/UploadFile";
import { applicationDraftActions } from "../../../../../Application/applicationDraftActions";

interface Props {
  educationInfo?: EducationInfo;
  education?: Education;
  isAddEducationModalView?: boolean;
  isRenewalModalView?: boolean;
  valueClassName?: string;
  fileReferences?: FileReference[];
  applicationId?: string;
  saveFiles?: (files: File[], fileType: FileObjectType) => void;
  deleteFile?: (fileName: string, fileType: FileObjectType) => void;
}

export const EhisEducationViewCard = ({
  education,
  educationInfo,
  valueClassName,
  isAddEducationModalView = false,
  isRenewalModalView = false,
  fileReferences,
  applicationId,
  saveFiles,
  deleteFile
}: Props) => {
  const extractCommonFields = (education?, educationInfo?) => {
    return {
      schoolName: education?.facilityName || educationInfo?.schoolName,
      curriculumName: education?.curriculumName || educationInfo?.curriculumName,
      curriculumCode: education?.curriculumCode || educationInfo?.curriculumCode,
      diplomaDate: education?.diplomaDate || educationInfo?.diplomaDate,
      diplomaNumber: education?.diplomaNumber || educationInfo?.diplomaNumber,
      documentNumber: education?.documentNumber || educationInfo?.diplomaNumber,
      specialization: education?.specialization || educationInfo?.specialization,
    };
  };

  const {
    schoolName,
    curriculumName,
    curriculumCode,
    diplomaDate,
    diplomaNumber,
    documentNumber,
    specialization
  } = extractCommonFields(education, educationInfo);

  const applyElementMarginsForAddEducationModal = (): string => {
    return isAddEducationModalView ?
      "mt-lg-3" : "";
  };

  const dispatch = useDispatch();
  const applicationDraft = useSelector((state: RootState) => state.applicationDraft);

  const updateFiles = (updateFileReferences: FileReference[]): void => {
    if (!applicationDraft.fileReferences) {
      dispatch(applicationDraftActions.updateApplicationDraft("fileReferences", updateFileReferences));
    } else {
      let tempFiles = [...applicationDraft.fileReferences, ...updateFileReferences];
      dispatch(applicationDraftActions.updateApplicationDraft("fileReferences", tempFiles));
    }
  };

  const handleFileSave = (files: File[], fileType: FileObjectType): void => {
    saveFilesWithAlerts(
      files,
      fileType,
      applicationDraft.id,
      dispatch,
      updateFiles
    );
  };

  const handleFileDeletion = (minioFileName: string): void => {
    dispatch(applicationDraftActions.removeFileReference(minioFileName));
  };

  const handleFileDelete = (fileName: string, fileType: FileObjectType): void => {
    let toDelete: FileReference | undefined = applicationDraft.fileReferences?.filter((file) => {
      return file.fileName === fileName && file.fileObjectType === fileType;
    })[0];

    if (toDelete && toDelete?.minioFileName) {
      deleteFilesWithAlerts(
        toDelete.minioFileName,
        toDelete.fileObjectType!,
        dispatch,
        handleFileDeletion
      );
    }
  };

  return (
    <>
      <Card className={`education-view-card ${ applyElementMarginsForAddEducationModal() }`}>
        <EducationInfoViewRow
          id="schoolName"
          className={`first-row ${ applyElementMarginsForAddEducationModal() }`}
          value={schoolName}
          valueClassName={valueClassName}
        />
        <EducationInfoViewRow
          id="curriculumName"
          className={applyElementMarginsForAddEducationModal()}
          value={curriculumCode + " " + curriculumName}
          valueClassName={valueClassName}
        />
        <EducationInfoViewRow
          id="diplomaDate"
          className={applyElementMarginsForAddEducationModal()}
          value={moment(diplomaDate).format("DD.MM.YYYY")}
          valueClassName={valueClassName}
        />
        { !isAddEducationModalView && <EducationInfoViewRow
          id="diplomaNumber"
          className={`last-row ${ applyElementMarginsForAddEducationModal() }`}
          value={diplomaNumber}
          valueClassName={valueClassName}
        /> }
        { isAddEducationModalView && documentNumber && <EducationInfoViewRow
          id="documentNumber"
          className={applyElementMarginsForAddEducationModal()}
          value={documentNumber}
          valueClassName={valueClassName}/> }
        { isAddEducationModalView && specialization && <EducationInfoViewRow
          id="specialization"
          className={applyElementMarginsForAddEducationModal()}
          value={specialization}
          valueClassName={valueClassName}/> }
        { isAddEducationModalView && <div className="mt-lg-3"/> }
      </Card>
      { !isAddEducationModalView &&
          <AutomaticAttachmentFields
            fileReferences={!isRenewalModalView ? applicationDraft.fileReferences || [] : fileReferences!}
            saveFiles={!isRenewalModalView ? handleFileSave : saveFiles!}
            handleFileDelete={!isRenewalModalView ? handleFileDelete : deleteFile!}
            objectId={!isRenewalModalView ? applicationDraft.id! : applicationId!}
          />
      }
    </>
  );
};
