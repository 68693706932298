import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { useDispatch } from "react-redux";

import { AttachmentContainer } from "../../../Shared/Application/Education/Manual/Attachment/AttachmentContainer";
import {
  deleteFilesWithAlerts,
  saveFilesWithAlerts
} from "../../../../Component/FileDropzone/UploadFile";
import { inputRegex } from "../../../../Util/inputRegexValidation";
import { ApplicantChanges } from "../../../../Dto/Applicant/ApplicantChanges";
import { FormattedErrorMessage } from "../../../../Component/ErrorField/FormattedErrorMessage";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import {
  FileObjectType,
  Person,
  FileReference,
  OfficialUserPrivilegesEnum
} from "../../../../../api_client/medre_api";

interface Props {
  person: Person;
  applicantChanges: ApplicantChanges;
  setApplicantChanges: (
    applicantChanges: (prevState: ApplicantChanges) => ApplicantChanges
  ) => void;
  forceValidation: boolean;
}

interface FormValidity {
  firstNameValid?: boolean;
  lastNameValid?: boolean;
  filesValid?: boolean;
}

export const PreviousNameForm = ({
  applicantChanges,
  setApplicantChanges,
  forceValidation,
  person
}: Props) => {
  const dispatch = useDispatch();

  const previousName = applicantChanges.previousName;
  const [activeAttachmentType, setActiveAttachmentType] =
    useState<FileObjectType | null>(null);
  const [formValidity, setFormValidity] = useState<FormValidity>({});

  useEffect(() => {
    if (previousName) {
      const hasInputNameValue = previousName.firstName || previousName.lastName;
      const hasFileReferences =
        previousName.fileReferences && previousName.fileReferences.length > 0;
      if (!hasInputNameValue && !hasFileReferences) {
        setApplicantChanges((prevState: ApplicantChanges) => {
          return { ...prevState, previousName: undefined };
        });
        setFormValidity({});
      }
    }
  }, [applicantChanges, previousName, setApplicantChanges]);

  useEffect(() => {
    if (
      !forceValidation ||
      previousName === undefined ||
      Object.keys(previousName).length === 0
    ) {
      return;
    }
    const hasFileReferences =
      previousName?.fileReferences && previousName?.fileReferences.length > 0;
    if (!previousName?.firstName) {
      setFormValidity((prevState: FormValidity) => {
        return { ...prevState, firstNameValid: false };
      });
    }
    if (!previousName?.lastName) {
      setFormValidity((prevState: FormValidity) => {
        return { ...prevState, lastNameValid: false };
      });
    }
    if (!hasFileReferences) {
      setFormValidity((prevState: FormValidity) => {
        return { ...prevState, filesValid: false };
      });
    }
  }, [forceValidation, previousName]);

  const handleSaveFiles = (files: File[]) => {
    saveFilesWithAlerts(
      files,
      FileObjectType.PersonPreviousNameDocument,
      person.id!,
      dispatch,
      onUploadSuccess
    );
  };

  const onUploadSuccess = (files: FileReference[]) => {
    setApplicantChanges((prevState: ApplicantChanges) => {
      return {
        ...prevState,
        previousName: { ...prevState.previousName, fileReferences: files }
      };
    });
    setFormValidity((prevState: FormValidity) => {
      return { ...prevState, filesValid: true };
    });
  };

  const handleFileDelete = (fileName: string) => {
    let toDelete = previousName?.fileReferences?.filter((file) => {
      return (
        file.fileName === fileName &&
        file.fileObjectType === FileObjectType.PersonPreviousNameDocument
      );
    })[0];
    if (toDelete && toDelete.minioFileName) {
      deleteFilesWithAlerts(
        toDelete.minioFileName,
        toDelete.fileObjectType!,
        dispatch,
        removeFileReference
      );
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    setApplicantChanges((prevState: ApplicantChanges) => {
      return {
        ...prevState,
        previousName: { ...prevState.previousName, [fieldName]: fieldValue }
      };
    });
    setFormValidity((prevState: FormValidity) => {
      return { ...prevState, [fieldName + "Valid"]: true };
    });
  };

  const removeFileReference = (minioFileName: string) => {
    const references = previousName?.fileReferences?.filter(
      (ref) => ref.minioFileName !== minioFileName
    );
    setApplicantChanges((prevState: ApplicantChanges) => {
      return {
        ...prevState,
        previousName: { ...prevState.previousName, fileReferences: references }
      };
    });
  };

  return (
    <>
      <div className="applicant-changes-form mt-4">
        <FormGroup className="form-inline mb-4">
          <Label>Eesnimi</Label>
          <ProtectedFieldWrapper
            allowedRoles={[
              OfficialUserPrivilegesEnum.Sign,
              OfficialUserPrivilegesEnum.Proceede
            ]}
          >
            <Input
              value={previousName?.firstName || ""}
              name="firstName"
              invalid={formValidity?.firstNameValid === false}
              onChange={(e) => handleInputChange(inputRegex(e))}
            />
          </ProtectedFieldWrapper>
        </FormGroup>
        <FormGroup className="form-inline mb-4">
          <Label>Perenimi</Label>
          <ProtectedFieldWrapper
            allowedRoles={[
              OfficialUserPrivilegesEnum.Sign,
              OfficialUserPrivilegesEnum.Proceede
            ]}
          >
            <Input
              value={previousName?.lastName || ""}
              name="lastName"
              invalid={formValidity?.lastNameValid === false}
              onChange={(e) => handleInputChange(inputRegex(e))}
            />
          </ProtectedFieldWrapper>
        </FormGroup>
      </div>
      <AttachmentContainer
        onClick={() =>
          setActiveAttachmentType(FileObjectType.PersonPreviousNameDocument)
        }
        isActive={
          activeAttachmentType === FileObjectType.PersonPreviousNameDocument
        }
        type={FileObjectType.PersonPreviousNameDocument}
        fileReferences={previousName?.fileReferences || []}
        maxFilesCount={5}
        saveFiles={(files) => handleSaveFiles(files)}
        handleFileDelete={(fileName) => handleFileDelete(fileName)}
        allowedRoles={[
          OfficialUserPrivilegesEnum.Sign,
          OfficialUserPrivilegesEnum.Proceede
        ]}
      />
      {formValidity.filesValid === false && (
        <div className="w-25">
          <FormattedErrorMessage id="mandatoryField" />
        </div>
      )}
    </>
  );
};
