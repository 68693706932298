import { Card } from "reactstrap";
import React, { useEffect, useState } from "react";
import { ApplicationDetailsInfoCard } from "../../../../Component/Card/ApplicationDetailsInfoCard";
import {
  DetailedCompany,
  InsuranceContract,
  InsuranceContractStatus,
  VastutuskindlustusteTeenusApiFactory as insuranceContractAPI
} from "../../../../../api_client/medre_api";
import { InsuranceContractState } from "../../../Shared/InsuranceContract/InsuranceContractState";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { formatInsuranceSum } from "../../../../Util/FormatUtils";
import { InsuranceContractsAccordion } from "./InsuranceContractsAccordion";
import { API, getBaseUrl } from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { searchResultsActions } from "../../../Shared/Search/searchResultsActions";
import { ActiveFilters } from "../../../Shared/Search/Filters/FilterTypes";
import { useAuthorized } from "../../../../Hook/usePortal";

type CardContentItem = {
  key: string;
  value: string;
};

export interface Props {
  id: string;
  insurances?: InsuranceContract[];
  company?: DetailedCompany;
}

export const InsuranceDetailsCard = ({ id, insurances, company }: Props) => {
  const dispatch = useDispatch();
  const isPortal = useSelector((state: RootState) => state.config.isPortal);
  const isAuthorized = useAuthorized();

  const validInsurance = insurances?.find(i => i.status === InsuranceContractStatus.Valid);

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const { pageSize, pageIndex, pageCount, data } = useSelector((rootState: RootState) => rootState.searchResults);

  const {
    insurerName,
    periodStartDate,
    periodEndDate,
    periodNewEndDate,
    insuranceSum,
    status
  } = validInsurance || {};

  const period = periodStartDate && periodEndDate
    ? formatDate(periodStartDate) + " - " + (periodNewEndDate ? formatDate(periodNewEndDate) : formatDate(periodEndDate))
    : "";

  const cardContent = (): CardContentItem[] =>
    [
      {
        key: "Kindlustusandja",
        value: !validInsurance ? "" : insurerName || ""
      },
      {
        key: "Kindlustuskaitse periood",
        value: !validInsurance ? "" : period || ""
      },
      {
        key: "Kindlustussumma",
        value: !validInsurance ? "" : formatInsuranceSum(insuranceSum) || ""
      }
    ];

  const fetchInsurances = () => {
    if (!company || !company.registryCode) {
      return;
    }

    const api = insuranceContractAPI(undefined, getBaseUrl(), API);

    if (isPortal) {
      return isAuthorized
        ? api.getPolicyHolderInsuranceContracts(company.registryCode, undefined, pageIndex, pageSize)
        : api.getPublicPolicyHolderInsuranceContracts(company.registryCode, undefined, pageIndex, pageSize);
    } else {
      return api.searchInsuranceContracts(
        {
          policyHolderId: company.id,
          sort: ["periodStartDate:desc"],
          page: pageIndex,
          size: pageSize
        }, { withCredentials: true }
      );
    }
  };

  const fetchInsuranceResults = (): void => {
    fetchInsurances()?.then((r) => {
      dispatch(searchResultsActions.setData(r.data.content ?? [], ActiveFilters.InsuranceContract));
      dispatch(searchResultsActions.setPageCount(r.data.totalPages ?? 0));
    });
  };

  useEffect(() => {
    if (isAccordionOpen) {
      fetchInsuranceResults();
    } else {
      dispatch(searchResultsActions.setSize(10));
      dispatch(searchResultsActions.setData([], ActiveFilters.InsuranceContract));
    }
  }, [pageIndex, pageSize, isAccordionOpen]);

  return (
    <>
      <Card id = {id} className={(isPortal && isAuthorized) || !isPortal ? "mx-3 my-4 p-4" : "my-3 p-3 my-md-4 p-md-4"}>
        <div className="d-flex align-items-center mb-4">
          <h2 className="mb-0 mr-3">
            <span id="insurance" /> Vastutuskindlustus
          </h2>
          <InsuranceContractState
            currentStatus={insurances?.length === 0 || !validInsurance ? InsuranceContractStatus.None : status} />
        </div>
        <ApplicationDetailsInfoCard content={cardContent()}
        />
        <div>
          <InsuranceContractsAccordion
            isOpen={isAccordionOpen}
            handleClick={(): void => setIsAccordionOpen(!isAccordionOpen)}
            data={data[ActiveFilters.InsuranceContract]}
            pageIndex={pageIndex}
            pageCount={pageCount}
          />
        </div>
      </Card>
    </>
  );
};