import React from "react";
import { FormattedMessage } from "react-intl";

import { SuspensionModalContainer } from "./SuspensionModalContainer";
import { ActivityLicenseApplicationProceedingTypeEnum } from "../../../../../api_client/medre_api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activityLocationId: string;
  activityLocationFullAddress: string;
  totalSuspendedDays?: number;
}

export const ActivityLocationSuspensionModal = ({
  isOpen,
  onClose,
  activityLocationId,
  activityLocationFullAddress,
  totalSuspendedDays
}: Props) => {
  const createTitle = () => (
    <FormattedMessage
      id="addSuspensionModal.activityLocationHeader"
      defaultMessage="Peata {activityLicenseActivityLocationAddress} tegevuskoht"
      values={{
        activityLicenseActivityLocationAddress: activityLocationFullAddress
      }}
    />
  );

  return (
    <SuspensionModalContainer
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={createTitle()}
      suspensionType={
        ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension
      }
      idToSuspend={activityLocationId}
      totalSuspendedDays={totalSuspendedDays || 0}
    />
  );
};
